import tippy from "tippy.js";

tippy("[data-tippy-content]", {
    placement: "top",
    arrow: true,
    allowHTML: true,
    theme: "light",
    onShow(instance) {
        instance.setContent(instance.reference.dataset.tippyContent);
    },
    trigger: "mouseenter click",
    hideOnClick: true,
});
