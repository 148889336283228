import { isPhone } from "@plugins";

const tagsContainers = document.querySelectorAll(".js-tags");

if (tagsContainers && isPhone()) {
    for (const tagsContainer of tagsContainers) {
        limitTagsWrapperWidth(tagsContainer);
    }
}

function limitTagsWrapperWidth(tagsContainer) {
    const tagsWrap = tagsContainer.querySelector(".js-tags-wrap");

    if (!tagsWrap) {
        const error = "Нет обертки";
        console.error(tagsContainer, error);
    }

    const tags = tagsWrap.children;

    if (!tags) {
        const error = new Error("Нет тегов");
        console.error(tagsWrap, error);
    }

    let minTagsWrapperWidth = 0;

    minTagsWrapperWidth = (calculateMinWidth(tags, 0, tags.length) / 2) * (1 + 4 / 100);

    tagsWrap.style.minWidth = `${minTagsWrapperWidth}px`;
}

function calculateMinWidth(collection, start, end) {
    let minWidth = 0;

    for (let i = start; i < end; i++) {
        minWidth += getElementWidth(collection[i]);
    }

    return minWidth;
}

function getElementWidth(el) {
    const elementStyles = window.getComputedStyle(el);
    const elementMarginRight = elementStyles.marginRight;

    return el.offsetWidth + parseFloat(elementMarginRight);
}
