export class DropdownMenu {
    constructor(elem, options = {}) {
        const _defaults = {
            containerClass: ".js-dropdown-container",
            triggerClass: ".js-dropdown-trigger",
            eventsListeners: {},
        };

        Object.assign(this, _defaults, options);

        this.elem = elem;
        this.containers = elem.querySelectorAll(this.containerClass);
        this.triggers = elem.querySelectorAll(this.triggerClass);

        this._previousContainer = null;
        this.activeContainer = null;
        this._isOpen = false;

        this.elem.addEventListener("click", (e) => {
            const target = e.target;
            if (this.isTrigger(target)) {
                this.toggle(target);
            }
        });

        if (this.on) {
            Object.keys(this.on).forEach((eventName) => {
                this.elem.addEventListener(eventName, this.on[eventName]);
            });
        }

        document.addEventListener("click", (e) => {
            if (
                this._previousContainer &&
                !this._previousContainer.contains(e.target) &&
                this._isOpen
            ) {
                this.close(this._previousContainer);
            }
        });
    }

    isTrigger(target) {
        for (const trigger of this.triggers) {
            if (trigger === target || trigger.contains(target)) {
                return true;
            }
        }
        return false;
    }

    open(container) {
        container.classList.add("active");
        this._isOpen = true;

        if (window.mobileMedia) {
            container.scrollTop = 0;
        }

        this._previousContainer = container;
        this.activeContainer = container;

        ["open", "toggle"].forEach((evt) => {
            container.dispatchEvent(
                new Event(evt, {
                    bubbles: true,
                    compose: true,
                }),
            );
        });
    }

    close(container, isGenerateEvent = true) {
        container.classList.remove("active");
        this.activeContainer = null;
        this._isOpen = false;

        if (isGenerateEvent) {
            ["close", "toggle"].forEach((evt) => {
                container.dispatchEvent(
                    new Event(evt, {
                        bubbles: true,
                        compose: true,
                    }),
                );
            });
        }
    }

    closePrevious() {
        if (this._previousContainer) {
            this.close(this._previousContainer, false);
        }
    }

    toggle(target) {
        const container = target.closest(this.containerClass);

        if (!container) {
            console.error("Не найден контейнер для меню");
            return;
        }

        if (container.classList.contains("active")) {
            this.close(container);
        } else {
            this.closePrevious();
            this.open(container);
        }
    }
}
