import Swiper from "swiper";

function initSlider() {
    const pagination = document.querySelector(".js-categories-pagintaion");

    new Swiper(".js-categories-slider", {
        slidesPerView: 1,
        centerSlidesBounds: true,
        spaceBetween: 16,
        pagination: {
            el: pagination,
            clickable: true,
            dynamicMainBullets: 3,
            dynamicBullets: true,
        },
        watchOverflow: true,

        breakpoints: {
            // slidesPerView: auto некорректно отображает слайды - появляется смещение и отступ справа
            // поэтому сделал по брейкпоинтам
            345: {
                slidesPerView: 1.3,
            },
            420: {
                slidesPerView: 1.4,
            },
            460: {
                slidesPerView: 1.5,
            },
            500: {
                slidesPerView: 1.6,
            },
            540: {
                slidesPerView: "auto",
            },
        },
    });
}

window.addEventListener("load", () => {
    if (window.mobileMedia) {
        initSlider();
    }
});
