/* eslint-disable */
!(function (e, t, n, c, o) {
    (e[o] =
        e[o] ||
        function () {
            (e[o].a = e[o].a || []).push(arguments);
        }),
        (e[o].h = c),
        (e[o].n = o),
        (e[o].i = 1 * new Date()),
        (s = t.createElement(n)),
        (a = t.getElementsByTagName(n)[0]),
        (s.async = 1),
        (s.src = c),
        a.parentNode.insertBefore(s, a);
})(
    window,
    document,
    "script",
    "https://cdn2.searchbooster.net/scripts/v2/init.js",
    "searchbooster",
),
    searchbooster({
        apiUrl: "https://api4.searchbooster.io",
        popup: { search: true },
        theme: { variables: { "color-primary": "#fe392e" } },
        apiKey: "7326ffff-f127-40e2-837b-4de979e4d2bd",
        completions: true,
        search: { image: false, groupCategories: true, catalog: true, limit: 24 },
        offer: { cart: false },
        initialized: function (sb) {
            sb.mount(".js-open-search");
        },
    });

// import { lockBody, unlockBody, SetVhElement } from "@plugins";

// const options = {
//     reserveScrollBarGap: true,
// };

// const inner = document.querySelector(".js-header-search-inner");
// const searchWindow = document.querySelector(".js-header-search");
// const searchActivate = document.querySelector(".js-open-search");
// const searchClose = document.querySelector(".js-close-search");
// const buttons = [searchActivate, searchClose];

// new SetVhElement(searchWindow);

// function toggleModal() {
//     if (!searchWindow.classList.contains("active")) {
//         openModal();
//     } else {
//         closeModal();
//     }
// }

// function openModal() {
//     searchWindow.classList.add("active");
//     if (window.innerWidth - searchWindow.offsetWidth > 0) {
//         if (inner) inner.style.paddingRight = `${window.innerWidth - searchWindow.offsetWidth}px`;
//     }
//     lockBody(searchWindow, options);

//     // Фокус не ставится, если устанавливать его без таймаута
//     // Убрал в рамках задачи № 54003
//     // searchField.addEventListener("focus", () => {
//     //     searchField.placeholder = "";
//     // });
//     // searchField.addEventListener("blur", () => {
//     //     searchField.placeholder = "Поиск";
//     // });

//     // setTimeout(function () {
//     //     searchField.focus();
//     // }, 70);
// }

// function closeModal() {
//     if (searchWindow.classList.contains("active")) {
//         searchWindow.classList.remove("active");
//         inner.removeAttribute("style");
//         unlockBody(searchWindow);
//     }
// }

// for (const button of buttons) {
//     button.addEventListener("click", () => {
//         toggleModal();
//     });
// }

// document.addEventListener("keyup", (e) => {
//     if (e.key === "Escape" && searchWindow.classList.contains("active")) {
//         toggleModal();
//     }
// });
