import { hoverIntent } from "@plugins";

export class SwitchableMenu {
    _defaults = {
        listClass: "js-switchable-list",
        linkClass: "js-switchable-link",
        imageContainerClass: "js-switchable-photo",
        subCategoriesClass: "js-switchable-subcategory",
        subCategoriesLinksClass: "js-switchable-sub-link",
        dayProductCardClass: "js-switchable-card",
    };

    constructor(elem, options) {
        this.elem = elem;
        Object.assign(this, this._defaults, options);

        this.list = elem.querySelector(`.${this.listClass}`);
        this.links = elem.querySelectorAll(`.${this.linkClass}`);
        this.dayCards = elem.querySelectorAll(`.${this.dayProductCardClass}`);

        this.img = elem.querySelector(`.${this.imageContainerClass} img`);
        this.sources = elem.querySelectorAll(`.${this.imageContainerClass} source`);

        this.subCategories = elem.querySelectorAll(`.${this.subCategoriesClass}`);
        this.submenuLinks = elem.querySelectorAll(`.${this.subCategoriesLinksClass}`);

        this._lastActiveLink = this.links[0];
        this._lastSubLink = null;
        this._lastSubCategory = this.subCategories[0];
        this._lastActiveCard = this.dayCards[0];

        for (const link of this.links) {
            hoverIntent(link, onListOver.bind(this)).options({
                interval: 0,
                sensitivity: 1,
            });
        }

        function onListOver(e) {
            const activeLink = e.target.closest(`.${this.linkClass}`);

            if (activeLink && activeLink !== this._lastActiveLink) {
                this.toggleLink(activeLink);
                this.toggleImage(activeLink);
                this.toggleSubMenus(activeLink);
                this.toggleDayCard(activeLink);
            }
        }

        for (const link of this.submenuLinks) {
            link.addEventListener("mouseenter", (e) => {
                const activeSubLink = e.target;

                if (this.isNotLastSubLink(activeSubLink)) {
                    this.toggleImage(activeSubLink);
                }
            });
        }
    }

    toggleLink(link) {
        link.classList.add("active");
        this._lastActiveLink.classList.remove("active");
        this._lastActiveLink = link;
    }

    toggleImage(link) {
        const srcBg = link.dataset.srcBg;
        const srcBgWebp = link.dataset.srcBgWebp;

        if (srcBg && this.img) {
            this.img.setAttribute("data-src", srcBg);

            if (this.sources[0]) {
                this.sources[0].setAttribute("data-srcset", srcBgWebp ?? srcBg);
            }
        }
    }

    toggleDayCard(link) {
        const index = Array.from(this.links).findIndex((item) => item === link);
        const dayCard = this.dayCards[index];

        if (dayCard !== this._lastActiveCard) {
            dayCard.classList.add("active");
            this._lastActiveCard.classList.remove("active");
            this._lastActiveCard = dayCard;
        }
    }

    toggleSubMenus(link) {
        const index = Array.from(this.links).findIndex((item) => item === link);
        const subMenu = this.subCategories[index];

        if (subMenu !== this._lastSubCategory) {
            subMenu.classList.add("active");
            this._lastSubCategory.classList.remove("active");
            this._lastSubCategory = subMenu;
        }
    }

    isNotLastSubLink(subLink) {
        if (subLink !== this._lastSubLink) {
            this._lastSubLink = subLink;
            return true;
        }
        return false;
    }
}
