import Swiper from "swiper";
import { updateHoverSliders } from "@plugins";
import jquery from "jquery";

const $ = jquery;

window.hoverSliders = [];
$(document).ajaxSuccess(() => {
    initHoverSliders();
});
window.addEventListener("load", initHoverSliders());
window.addEventListener("resize", updateHoverSliders());

function initHoverSliders() {
    const hoverSwipers = document.querySelectorAll("[data-card-hover-slider]");
    const activatedHoverSliders = [];

    if (hoverSwipers !== null && hoverSwipers.length > 0) {
        hoverSwipers.forEach((el) => {
            const swiperContainer = el.querySelector(".swiper-container");
            const swiperPagination = el.querySelector(".js-pagination-hover");

            const swiperHover = new Swiper(swiperContainer, {
                allowTouchMove: true,
                spaceBetween: 20,
                slidesPerView: 1,
                nested: true,

                // Для перелистывания на мобильных устройствах
                speed: 0,
                effect: "fade",
                fadeEffect: {
                    crossFade: true,
                },

                pagination: {
                    el: swiperPagination,
                },

                breakpoints: {
                    768: {
                        allowTouchMove: false,
                    },
                },
            });

            activatedHoverSliders.push(swiperHover);

            el.addEventListener("mouseout", (evt) => {
                if (!el.contains(evt.relatedTarget)) {
                    swiperHover.slideTo(0);
                }
            });

            const bullets = swiperPagination.querySelectorAll(".swiper-pagination-bullet");
            for (let i = 0; i < bullets.length; i++) {
                const bullet = bullets[i];

                bullet.addEventListener("mouseenter", () => {
                    swiperHover.slideTo(i);
                });
            }

            // Исключаем повторную инициализацию
            el.removeAttribute("data-card-hover-slider");
        });

        // Объядиняем сущетсвующий глобальный массив со всеми активированными слайдерами с подготовленыыми слайдерами
        window.hoverSliders = window.hoverSliders.concat(activatedHoverSliders);
    }
}
