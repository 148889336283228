import { SpollerAnimation, elementInViewport } from "@plugins";
import { elementScrollIntoView } from "seamless-scroll-polyfill";

const spollerAnimation = new SpollerAnimation();
class TextCompressor {
    constructor(el, options) {
        this.el = el;

        this._setDefaults(options);
        this._getCustomProperties();
        // this._initObserver();

        if (this.on) {
            this._initListeners();
        }

        if (this._shouldInit()) {
            this.isOpen = false;
            this._init();
        }
    }

    _setDefaults(options) {
        const defaults = {
            isAlways: false,
            limiter: 2,
            buttonAdditionalClasses: "",
            buttomTextInitial: "Читать подробнее",
            buttonTextOpened: "Свернуть",
            transitionDuration: 200,
        };
        Object.assign(this, defaults, options);
    }

    _getCustomProperties() {
        if (this.el.dataset.textCompressor === "always") {
            this.isAlways = true;
        }
        if (this.el.dataset.compressorLimiter) {
            this.limiter = Number(this.el.dataset.compressorLimiter);
        }

        if (this.el.dataset.compressorAdditionalClasses) {
            this.buttonAdditionalClasses = this.el.dataset.compressorAdditionalClasses;
        }
    }

    _getMarginBottom(el) {
        return parseFloat(window.getComputedStyle(el).marginBottom);
    }

    _addButton() {
        if (this.hasButton) return;

        this.button = document.createElement("span");
        this.button.classList.add("text-compressor-button");
        this.button.innerHTML = this.buttomTextInitial;

        if (this.buttonAdditionalClasses) {
            this.button.classList.add(`${this.buttonAdditionalClasses}`);
        }

        this.el.after(this.button);

        this.button.addEventListener("click", () => {
            if (this.button.innerHTML !== this.buttonTextOpened) {
                spollerAnimation.slideDown(this.container, this.transitionDuration);
                this._toggleButtonClass();
                this.button.innerHTML = this.buttonTextOpened;
            } else {
                spollerAnimation.slideUp(this.container, this.transitionDuration).then(() => {
                    if (!elementInViewport(this.button)) {
                        elementScrollIntoView(this.button, {
                            behavior: "smooth",
                        });
                    }
                });
                this._toggleButtonClass();
                this.button.innerHTML = this.buttomTextInitial;
            }
        });

        this.hasButton = true;
    }

    _toggleButtonClass() {
        this.button.classList.toggle("active");
    }

    _initListeners() {
        Object.keys(this.on).forEach((eventName) => {
            this.el.addEventListener(eventName, this.on[eventName]);
        });
    }

    _destroy() {
        this.button.remove();
        this.el.removeAttribute("style");
    }

    _initObserver() {
        // TODO: Вернуть все желементы в родителя или сохранить их где-то, пересчитать количество и создать новый массив
        // Потом снова заполнить wrapper
        if (this.observer) return;

        this.observer = new MutationObserver(() => {
            if (this._shouldInit()) {
                this._init();
            }
        });
        this.observer.observe(this.el, {
            childList: true,
            subtree: true,
        });
    }

    _shouldInit() {
        if ((!this.isAlways && window.mobileMedia) || this.isAlways) {
            return true;
        }
        return false;
    }

    _calculateTagsStorage() {
        let tagsCounter = 0;
        let tagsStorage = [];

        for (let i = 0; i < this.el.children.length; i++) {
            const tagName = this.el.children[i].tagName;

            if (this._isPassedTag(tagName)) {
                tagsCounter++;
            }

            if (tagsCounter > this.limiter) {
                if (!this._lastNoWrapperElem) {
                    if (this._isPassedTag(this.el.children[i - 1].tagName)) {
                        this._lastNoWrapperElem = this.el.children[i - 1];
                    } else {
                        tagsStorage.push(this.el.children[i - 1]);
                        this._lastNoWrapperElem = this.el.children[i - 2];
                    }
                }

                tagsStorage.push(this.el.children[i]);
            }
        }

        return tagsStorage;
    }

    _isPassedTag(tagname) {
        const blockedTagNames = ["IMG", "PICTURE", "H1", "H2", "H3", "H4", "H5"];

        return !blockedTagNames.includes(tagname);
    }

    _createStructure() {
        this.container = document.createElement("div");
        this.wrapper = document.createElement("div");

        this.container.classList.add("text-compressor-container");
        this.wrapper.classList.add("text-compressor-wrapper");

        this.el.append(this.container);
        this.container.append(this.wrapper);
    }

    _setWrapperPadding(firstElemInStorage) {
        const lastNotWrapperElemMargin = Number(
            window.getComputedStyle(this._lastNoWrapperElem).marginBottom.replace(/\D/g, ""),
        );

        const firstElemInStorageMargin = Number(
            window.getComputedStyle(firstElemInStorage).marginTop.replace(/\D/g, ""),
        );

        if (lastNotWrapperElemMargin > firstElemInStorageMargin) {
            this.wrapper.style.setProperty("padding-top", `${lastNotWrapperElemMargin}px`);
        }
    }

    _fillWrapper(tags) {
        this.wrapper.append(...tags);
    }

    _removeLastNoWrapperElemMargin() {
        this._lastNoWrapperElem.style.setProperty("margin", "0");
    }

    _getLastNoWrapperElemMargin() {
        return this._getMarginBottom(this._lastNoWrapperElem);
    }

    _init() {
        const tagsStorage = this._calculateTagsStorage();

        if (tagsStorage.length > 0) {
            this._createStructure();
            this._fillWrapper(tagsStorage);
            this._setWrapperPadding(tagsStorage[0]);
            this._removeLastNoWrapperElemMargin();
            this._addButton();
        }
    }
}

const texts = document.querySelectorAll("[data-text-compressor]");
if (texts && texts.length > 0) {
    window.addEventListener("load", () => {
        for (let text of texts) {
            text = new TextCompressor(text);
        }
    });
}
