import { moveBetweenAnchors } from "@plugins";

{
    const anchorInitial = document.getElementById("js-sidebar-additional-anchor-initial");
    const anchorEndpoint = document.getElementById("js-sidebar-additional-anchor-endpoint");
    const section = document.querySelector(".js-sidebar-additional");

    if (section && anchorEndpoint && anchorInitial) {
        moveBetweenAnchors(anchorInitial, anchorEndpoint, section);
        window.addEventListener("resize", () => {
            moveBetweenAnchors(anchorInitial, anchorEndpoint, section);
        });
    }
}
