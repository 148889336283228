import Swiper, { Navigation, Pagination, Thumbs } from "swiper";

Swiper.use([Navigation, Pagination, Thumbs]);

const benefitsThumbsOptions = {
    // задается стилями, иначе слайдер будет работать некорректно
    // spaceBetween: 32,
    slidesPerView: "auto",
    slidesPerColumn: 1,
    observer: true,
    shortSwipes: false,
    watchOverflow: true,
};

const benefitsDefaultSliderOptions = {
    spaceBetween: 10,
    preventInteractionOnTransition: true,
    observer: true,
    observeParents: true,
};

const benefits = document.querySelectorAll(".js-benefits");

for (const section of benefits) {
    initSlider(section);
}

function initSlider(section) {
    const benefitsThumbsContainer = section.querySelector(".js-benefits-thumbs");
    const benefitsSliderContainer = section.querySelector(".js-benefits-slider");

    const benefitsControls = section.querySelector(".js-benefits-controls");

    if (!benefitsControls) {
        console.error(new Error("Элементы управления слайдером не найдены для"), section);
        return;
    }

    if (!benefitsThumbsContainer || !benefitsSliderContainer) {
        console.error(
            new Error("Контейнер или переключатели не найдены"),
            "Переключатели:",
            benefitsThumbsContainer,
            "Слайдер:",
            benefitsSliderContainer,
        );
        return;
    }

    const pagination = benefitsControls.querySelector(".swiper-pagination");
    const buttonNext = benefitsControls.querySelector(".swiper-button-next");
    const buttonPrev = benefitsControls.querySelector(".swiper-button-prev");

    const benefitsThumbs = new Swiper(benefitsThumbsContainer, benefitsThumbsOptions);
    const benefitsSliderOptions = Object.assign({}, benefitsDefaultSliderOptions, {
        navigation: {
            nextEl: buttonNext,
            prevEl: buttonPrev,
        },
        pagination: {
            el: pagination,
            dynamicBullets: true,
            dynamicMainBullets: 3,
            clickable: true,
        },
        thumbs: {
            swiper: benefitsThumbs,
        },
        on: {
            touchStart() {
                if (window.mobileMedia) {
                    benefitsThumbsContainer.classList.remove("active");
                }
            },
            reachBeginning() {
                benefitsThumbs.setProgress(0, 300);
            },
        },
    });

    new Swiper(benefitsSliderContainer, benefitsSliderOptions);

    if (benefitsThumbsContainer) {
        document.addEventListener("click", (e) => {
            if (window.mobileMedia) {
                if (!benefitsThumbsContainer.contains(e.target)) {
                    benefitsThumbsContainer.classList.remove("active");
                }
            }
        });

        benefitsThumbsContainer.addEventListener("click", () => {
            if (window.mobileMedia) {
                benefitsThumbsContainer.classList.toggle("active");
            }
        });
    }
}
