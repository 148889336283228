import { updateHoverSliders } from "@plugins";

const layoutTriggersContainer = document.querySelector(".js-change-layout");
const triggers = document.querySelectorAll("[data-layout-trigger]");
const layoutFiled = document.querySelector(".js-layout-field");

if (layoutFiled && layoutTriggersContainer && triggers) {
    layoutTriggersContainer.addEventListener("click", (e) => {
        e.preventDefault();
        const trigger = e.target.closest("[data-layout-trigger]");
        if (trigger) {
            toggleLayout(trigger);
        }
    });
}

function checkFieldClass() {
    return !!layoutFiled.classList.contains("horizontal");
}

function toggleFiledClass() {
    layoutFiled.classList.toggle("horizontal");
}

function toggleClasesOfAllTriggers() {
    for (const trigger of triggers) {
        trigger.classList.toggle("active");
    }
}

function toggleLayout(trigger) {
    const layout = trigger.dataset.layoutTrigger;

    if ((layout === "row" && !checkFieldClass()) || (layout === "grid" && checkFieldClass())) {
        toggleFiledClass();
        toggleClasesOfAllTriggers();
        updateHoverSliders();
    }
}
