import Swiper from "swiper";

{
    const benefitsThumbs = new Swiper(".js-benefits-thumbs-thumbs", {
        spaceBetween: 24,
        slidesPerView: 3,
        observer: true,
        shortSwipes: false,
        watchOverflow: true,
    });

    new Swiper(".js-benefits-thumbs-slider", {
        spaceBetween: 10,

        navigation: {
            nextEl: ".js-benefits-thumbs-controls .swiper-button-next",
            prevEl: ".js-benefits-thumbs-controls .swiper-button-prev",
        },
        pagination: {
            el: ".js-benefits-thumbs-controls .swiper-pagination",
            dynamicBullets: true,
            dynamicMainBullets: 3,
            clickable: true,
        },
        thumbs: {
            swiper: benefitsThumbs,
        },
    });
}
