// Улучшенная версия move between anchors
// TODO: Переделать на систему, завязанную на комментариях
// ? Начальный вариант кода
// let nodeIterator = document.createNodeIterator(document.body, NodeFilter.SHOW_COMMENT);
// while (nodeIterator.nextNode()) {
//     const commentNode = nodeIterator.referenceNode;
//     let commentValue = commentNode.nodeValue;
//     if (commentValue.includes("mover-anchor")) {
//         let endpoint, initial;
//         commentValue.includes("endpoint") ? (endpoint = commentNode) : (initial = commentNode);
//         let elem = document.querySelector(".js-sidebar-additional");
//         if (endpoint) endpoint.after(elem);
//     }
// }
class AnchorsMover {
    constructor(data) {
        this.initialAnchor = data.initialAnchor;
        this.endPointAnchor = data.endPointAnchor;
        this.relocatableElem = data.affiliationElem;
        this.init();
    }

    init() {
        if (window.mobileMedia) {
            this.moveBetweenAnchors();
        }
        window.addEventListener("resize", () => {
            this.moveBetweenAnchors();
        });
    }

    moveBetweenAnchors() {
        if (window.mobileMedia) {
            if (this.relocatableElem.previousSibling !== this.endPointAnchor) {
                this.endPointAnchor.after(this.relocatableElem);
            }
        } else if (this.relocatableElem.previousSibling !== this.initialAnchor) {
            this.initialAnchor.after(this.relocatableElem);
        }
    }
}

const anchors = document.querySelectorAll(".js-mover-anchor");

if (anchors && anchors.length > 0) {
    const clasesOfMoverElements = [];
    const moverData = new Set();

    for (const anchor of anchors) {
        if (anchor.getAttribute("data-anchor-type") === "initial") {
            const affiliation = anchor.getAttribute("data-affiliation");
            const affiliationElement = document.querySelector(affiliation);
            const endPoint = document.querySelector(
                `[data-affiliation = "${affiliation}"][data-anchor-type ="endpoint"]`,
            );
            const errors = [];

            if (!affiliation) {
                errors.push("Не найдена ассоциация с элементом");
            }
            if (!affiliationElement) {
                errors.push(`По ассоциации ${affiliationElement} элемент не найден`);
            }
            if (!endPoint) {
                errors.push("Не найдена пара");
            }
            if (errors.length > 0) {
                console.error("Для якоря не составлена карта перемещений", anchor, errors);
                break;
            }

            const moverObj = {
                initialAnchor: anchor,
                affiliationElem: affiliationElement,
                endPointAnchor: endPoint,
            };
            moverData.add(moverObj);
        }
    }

    for (const item of moverData) {
        const mover = new AnchorsMover(item);
    }
}
