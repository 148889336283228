const mobileDetect = /Android|iPhone|iPad|iPod|BlackBerry|WPDesktop|IEMobile|Opera Mini/i.test(
    navigator.userAgent,
);
const event = mobileDetect ? "touchend" : "mouseup";

export function Ratings(userOptions) {
    const defaults = {
        element: "",
        countRate: 5,
        clickFn() {},
    };
    const opts = Object.assign({}, defaults, userOptions);

    this.element = opts.element;
    if (!this.element) {
        return;
    }
    this.init(opts);
    this.events(opts);
}

Ratings.prototype = {
    init(opts) {
        this.tags = {};
        this.tags.default_state = this.element.querySelector(".js-ratings-disable");
        this.tags.hover = this.element.querySelector(".js-ratings-hover");
        this.tags.click = this.element.querySelector(".js-ratings-click");
        this.widthElement = this.element.offsetWidth / opts.countRate;
        this.width_votes = 0;
        this.votes = 0;
    },
    events(opts) {
        const obj = this;
        this.element.addEventListener(
            "mouseover",
            () => {
                obj.tags.default_state.style.display = "block";
                obj.tags.hover.style.display = "block";
            },
            false,
        );
        this.element.addEventListener(
            "mouseout",
            () => {
                obj.tags.default_state.style.display = "none";
                obj.tags.hover.style.display = "none";
            },
            false,
        );
        this.element.addEventListener(
            "mousemove",
            (e) => {
                obj.width_votes = e.clientX - obj.element.getBoundingClientRect().left;
                obj.votes = Math.ceil(obj.width_votes / obj.widthElement);
                obj.tags.hover.style.width = `${obj.votes * obj.widthElement}px`;
            },
            false,
        );
        this.element.addEventListener(
            event,
            function () {
                obj.tags.click.style.width = `${obj.votes * obj.widthElement}px`;
                opts.clickFn.call(this, obj.votes);
            },
            false,
        );
    },
};
