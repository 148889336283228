import Swiper from "swiper";
import {
    compareSlidersDefaults,
    rowSlidersOptions,
    compareItemsSliderOptions,
} from "./js/sliders-options";
import SlidesCounter from "./js/slides-counter";
import CompareTable from "./compare-table/compare-table";

function initTableSliders(compareTable) {
    if (!compareTable) return;

    const filterButtons = document.querySelectorAll(".js-compare-sort-rows-trigger");
    const compareToggler = document.querySelector(".js-compare-toggle-all");
    const rows = compareTable.querySelectorAll(".js-compare-row");

    new CompareTable({
        table: compareTable,
        filterButtons,
        toggleAllBlocksTrigger: compareToggler,
    });

    // Массив для будущих слайдеров. Записываем в него, чтобы потом синхронизировать все между собой
    const slidersArr = [];

    // Слайдер товаров
    let compareItemsSlider = document.querySelector(".js-compare-items-slider");
    let slidesCounterElems = document.querySelectorAll(".js-compare-items-slides-counter");

    if (compareItemsSlider) {
        slidesCounterElems = Array.from(slidesCounterElems);
        const slidesCounterElemsActivated = [];

        for (const item of slidesCounterElems) {
            const slidesCounter = new SlidesCounter(item);
            slidesCounterElemsActivated.push(slidesCounter);
        }

        compareItemsSlider = new Swiper(compareItemsSlider, compareItemsSliderOptions);
        slidersArr.push(compareItemsSlider);

        compareItemsSlider.on("activeIndexChange", () => {
            for (const slidesCounter of slidesCounterElemsActivated) {
                slidesCounter.changeText(compareItemsSlider);
            }
        });

        for (const slidesCounter of slidesCounterElemsActivated) {
            slidesCounter.setValueOfAllCounter(compareItemsSlider);
        }

        if (compareItemsSlider.slides.length < 3) {
            for (const slidesCounter of slidesCounterElemsActivated) {
                slidesCounter.hide();
            }
        }

        if (compareItemsSlider.slides.length < 2) {
            const radioDiff = document.querySelector("#compare-diff");
            radioDiff.setAttribute("disabled", "disabled");
        }
    }

    // Слайдеры строк
    for (const row of rows) {
        const rowSliderContainer = row.querySelector(".swiper-container");
        const rowSliderOptions = Object.assign({}, rowSlidersOptions, {
            controller: {
                control: slidersArr[0],
            },
        });

        const rowSlider = new Swiper(rowSliderContainer, rowSliderOptions);
        slidersArr.push(rowSlider);
    }

    // Слайдер фиксированного хедера
    const fixedHeader = document.querySelector(".js-fixed-header");

    if (fixedHeader) {
        window.addEventListener("scroll", () => {
            fixedHeader.classList[
                window.pageYOffset > window.innerHeight * 0.65 ? "add" : "remove"
            ]("active");
        });

        const fixedHeaderSliderContainer = document.querySelector(
            ".js-compare-fixed-header-slider",
        );

        const fixedHeaderSliderOptions = Object.assign({}, compareSlidersDefaults, {
            pagination: {
                el: ".js-compare-fixed-pagination",
                clickable: true,
            },
            controller: {
                control: slidersArr[0],
            },
        });

        const fixedHeaderSlider = new Swiper(fixedHeaderSliderContainer, fixedHeaderSliderOptions);
        slidersArr.push(fixedHeaderSlider);
    }

    // Синхронизируем первую строку со всем массивом элементов
    slidersArr[0].controller.control = slidersArr;
}

const compareTable = document.querySelector(".js-compare-table");
initTableSliders(compareTable);
