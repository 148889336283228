import { Clippy, addToGlobalThisRet } from "@plugins";

const textInnersFormFilesAttacher = new Clippy(".js-text-inners-form-files", {
    multi: true,
    accept: [".jpg", ".pdf", ".png", ".doc", ".docx"],
    input: {
        el: ".js-text-inners-form-files-input",
    },
    filesList: {
        classes: ["text-inners-form__files-list"],
        items: {
            template(file) {
                return `
                    <li class="text-inners-form__files-item">
                        <div class="text-inners-form__file-info">
                            <span class="text-inners-form__file-name">${file.name}</span>
                            <div class="text-inners-form__file-extension-and-size">
                                <span>${file.extension}</span>
                                <span>${file.size}</span>
                            </div>
                        </div>
                        <button class="text-inners-form__files-item-remove" type="button" data-clippy-remover></button>
                    </li>
                `;
            },
        },
        insert: {
            functionType: "append",
        },
    },
    messages: {
        classes: ["text-inners-form__files-message"],
        types: {
            error: {
                classes: ["text-inners-form__files-message--error"],
            },
        },
        insert: {
            el: ".js-text-inners-form-files-label",
            functionType: "after",
        },
    },
});

addToGlobalThisRet(textInnersFormFilesAttacher, "textInnersFormFilesAttacher");
