import Swiper from "swiper";

const sliderContainer = document.querySelector(".js-rersently-viewed-container");
const prevButton = document.querySelector(".js-resently-viewed-prev");
const nextButton = document.querySelector(".js-resently-viewed-next");

if (sliderContainer) {
    new Swiper(sliderContainer, {
        speed: 400,
        spaceBetween: 8,
        slidesPerView: 1.35,
        navigation: {
            nextEl: nextButton,
            prevEl: prevButton,
        },
        watchOverflow: true,
        observer: true,
        preventClicks: false,
        breakpoints: {
            375: {
                slidesPerView: 1.65,
            },
            390: {
                slidesPerView: 1.75,
            },
            500: {
                slidesPerView: "auto",
            },
            768: {
                slidesPerView: 5,
                spaceBetween: 28,
                shortSwipes: false,
            },
        },
    });
}
