import jquery from "jquery";
import { lockBody, unlockBody } from "@plugins";
window.$ = window.jQuery = jquery;
require("@fancyapps/fancybox");

// Оnключаем смену фокуса при закрытии фото Fancybox , чтобы не ломать swiper
$.fancybox.defaults.backFocus = false;
$.fancybox.defaults.hideScrollbar = false;

$("[data-fancybox]").fancybox({
    animationEffect: "zoom",
    animationDuration: 250,
    hideScrollbar: false,
    backFocus: false,
    padding: 0,
    helpers: {
        overlay: {
            locked: false, // отключаем блокировку overlay
        },
    },
    onActivate: (instance) => {
        lockBody(instance, {
            reserveScrollBarGap: true,
        });
    },
    beforeClose: (instance) => {
        unlockBody(instance);
    },
});
