import tippy from "tippy.js";
import jquery from "jquery";

const $ = jquery;

function initCardActionTooltips() {
    const cardActionTooltips = document.querySelectorAll("[data-card-action-tooltip]");
    if (cardActionTooltips && cardActionTooltips.length > 0) {
        cardActionTooltips.forEach((tooltip) => {
            if (!tooltip.hasAttribute(["data-tippy-activated"])) {
                tippy(tooltip, {
                    placement: "top",
                    trigger: "mouseenter",
                    arrow: true,
                    theme: "light",
                    touch: false,
                    hideOnClick: true,
                    onShow(instance) {
                        instance.setContent(instance.reference.dataset.cardActionTooltip);
                        instance.hideEventListener = hideListener.bind(null, null, instance);
                        instance.reference.addEventListener("click", instance.hideEventListener);
                    },
                    onCreate(instance) {
                        instance.reference.setAttribute("data-tippy-activated", "true");
                    },
                    onHide(instance) {
                        instance.reference.removeEventListener("click", instance.hideEventListener);
                    },
                });
            }
        });
    }
}

function hideListener(event, instance) {
    instance.hide();
}

$(document).ajaxSuccess(() => {
    initCardActionTooltips();
});

window.addEventListener("load", initCardActionTooltips());
