import { lockBody, unlockBody, SetVhElement } from "@plugins";

const headerHamburger = document.querySelector(".js-header-hamburger");
const headerDropDownContainer = document.querySelector(".js-header-hamburger-target");

if (headerHamburger) {
    new SetVhElement(headerDropDownContainer);

    headerHamburger.addEventListener("click", () => {
        toggleMenu();
    });

    const toggleMenu = () => {
        headerHamburger.classList.toggle("is-active");

        if (toggleMenu.count % 2 === 0) {
            headerDropDownContainer.classList.add("active");
            headerDropDownContainer.scrollTop = 0;
            lockBody(headerDropDownContainer);
        } else {
            headerDropDownContainer.classList.remove("active");
            unlockBody(headerDropDownContainer);
        }

        toggleMenu.count++;
    };

    toggleMenu.count = 0;
}
