import { moveBetweenAnchors } from "@plugins";

{
    const sorting = document.querySelector(".js-catalog-sorting");
    const anchorEndpoint = document.getElementById("js-catalog-sorting-anchor-endpoint");
    const anchorInitial = document.getElementById("js-catalog-sorting-anchor-initial");

    if (sorting && anchorEndpoint && anchorInitial) {
        moveBetweenAnchors(anchorInitial, anchorEndpoint, sorting);
        window.addEventListener("resize", () => {
            moveBetweenAnchors(anchorInitial, anchorEndpoint, sorting);
        });
    }
}
