// *** Подменю с ссылками вверху header'a ***
class SubMenu {
    constructor(elem) {
        this.elem = elem;
        this.parent = elem.parentElement;

        this.elem.addEventListener("click", () => {
            this.parent.classList.toggle("active");
        });

        document.addEventListener("click", (e) => {
            if (!this.parent.contains(e.target)) {
                this.parent.classList.remove("active");
            }
        });
    }
}

{
    const submenuTriggers = document.querySelectorAll(".js-submenu-opener");

    if (submenuTriggers && submenuTriggers.length > 0) {
        for (const item of submenuTriggers) {
            new SubMenu(item);
        }
    }
}
