const searchInput = document.querySelector(".js-search-input");
const cleanButton = document.querySelector(".js-search-clean");

if (searchInput && cleanButton) {
    searchInput.addEventListener("input", () => {
        checkValue(searchInput, cleanButton);
    });
    window.addEventListener("load", () => {
        checkValue(searchInput, cleanButton);
    });

    cleanButton.addEventListener("click", (e) => {
        e.preventDefault();
        searchInput.value = "";
        cleanButton.classList.remove("active");
    });
}

function checkValue(input, button) {
    button.classList[input.value !== "" ? "add" : "remove"]("active");
}
