import { addToGlobalThisRet } from "@plugins";

class SimplePopup {
    constructor(el, options) {
        if (!el) return;
        this.el = el;
        this.o = options;
        this.closeBtn = this.el.querySelector(".js-simple-popup-close");
        this.init();
    }

    close() {
        this.el.classList.remove("active");
    }

    open() {
        this.el.classList.add("active");
        // this.setTimer();
    }

    // Постепенное исчезание через какое-то время после открытия
    // setTimer() {
    //     setTimeout(() => {
    //         const interval = setInterval(() => {
    //             this.el.style.opacity = this.el.style.opacity
    //                 ? this.el.style.opacity - 0.01
    //                 : 1 - 0.01;
    //             if(this.el.style.opacity <= 0) {
    //                 clearInterval(interval);
    //                 this.close();
    //                 this.el.style = "";
    //             }
    //         }, 46);
    //     }, 5000);
    // }

    init() {
        this.closeBtn.addEventListener("click", () => {
            this.close();
        });

        ["click", "touchstart"].forEach((eventType) => {
            document.addEventListener(eventType, (evt) => {
                if (!this.el.contains(evt.target)) {
                    this.close();
                }
            });
        });
    }
}

let popup = document.querySelector(".js-simple-popup");
popup = new SimplePopup(popup);

addToGlobalThisRet(popup, "simplePopup");
