/* eslint-disable */
import { elementScrollIntoView } from "seamless-scroll-polyfill";

const mapContainer = document.querySelector(".js-interactive-map");
let mapToInteract;

if (mapContainer) {
    const blocksContainer = mapContainer.querySelector(".js-interactive-map-blocks-container");
    const blocks = mapContainer.querySelectorAll(".js-interactive-map-block");
    const coords = [];

    for (const block of blocks) {
        let cordsOfBlock = block.dataset.cords.split(",");
        cordsOfBlock = cordsOfBlock.map((value) => Number(value));
        coords.push(cordsOfBlock);

        block.addEventListener("click", {
            handleEvent(event) {
                mapTo(event, block, cordsOfBlock);
            },
        });
    }

    ymaps.ready(() => {
        // Создание карты.
        const interactiveMap = new ymaps.Map("interactive-map", {
            // Координаты центра карты.
            // Порядок по умолчанию: «широта, долгота».
            // Чтобы не определять координаты центра карты вручную,
            // воспользуйтесь инструментом Определение координат.
            center: [55.76, 37.64],
            // Уровень масштабирования. Допустимые значения:
            // от 0 (весь мир) до 19.
            zoom: 10,
        });
        mapToInteract = interactiveMap;

        for (let i = 0; i < coords.length; i++) {
            const coordsOfPoint = coords[i];
            const point = new ymaps.Placemark(coordsOfPoint);

            point.events.add("click", () => {
                const block = blocks[i];
                mapTo(null, block, coordsOfPoint);
                elementScrollIntoView(block, {
                    behavior: "smooth",
                    block: "center",
                    inline: "center",
                });
            });
            interactiveMap.geoObjects.add(point);
        }
    });
}

function mapTo(evt, block, cords) {
    if (mapTo.previousActiveBlock) {
        mapTo.previousActiveBlock.classList.remove("active");
    }
    mapTo.previousActiveBlock = block;
    block.classList.add("active");
    mapToInteract.panTo(cords, {
        flying: true,
    });
}
