import Swiper from "swiper";
import { updateHoverSliders } from "@plugins";

function initSlider() {
    const pagination = document.querySelector(".js-new-products-controls > .swiper-pagination");

    new Swiper(".js-new-products-slider > .swiper-container", {
        slidesPerView: 1,
        spaceBetween: 17,
        pagination: {
            el: pagination,
            dynamicBullets: true,
            dynamicMainBullets: 3,
            clickable: true,
        },
        breakpoints: {
            406: {
                slidesPerView: 1.4,
                spaceBetween: 20,
            },
            567: {
                slidesPerView: 2,
                spaceBetween: 20,
            },
            768: {
                slidesPerView: 3,
                spaceBetween: 28,
            },
            992: {
                slidesPerView: 4,
                spaceBetween: 28,
            },
        },
    });
}

window.addEventListener("load", () => {
    if (window.mobileMedia) {
        initSlider();
        updateHoverSliders();
    }
});
