import Swiper from "swiper";
import { addToGlobalThisRet } from "@plugins";

const selectionDefaultBreakpoints = {
    406: {
        slidesPerView: 1.4,
        spaceBetween: 20,
    },
    567: {
        slidesPerView: 2,
        spaceBetween: 20,
    },
    768: {
        shortSwipes: false,
        slidesPerView: 3,
        spaceBetween: 28,
    },
    992: {
        shortSwipes: false,
        slidesPerView: 4,
        spaceBetween: 28,
    },
};

const selectionNarrowBreakpoints = {
    406: {
        slidesPerView: 1.4,
        spaceBetween: 20,
    },
    567: {
        slidesPerView: 2,
        spaceBetween: 20,
    },
    768: {
        shortSwipes: false,
        slidesPerView: 3,
        spaceBetween: 28,
    },
};

function initSelections() {
    const selections = document.querySelectorAll(".js-selection");

    if (selections && selections.length > 0) {
        for (const selection of selections) {
            let isNarrow = false;

            const sliderContainer = selection.querySelector(".js-selection-slider");
            const controls = findConrols(selection);

            if (selection.classList.contains("js-selection-narrow")) {
                isNarrow = true;
            }

            new Swiper(sliderContainer, {
                slidesPerView: 1,
                spaceBetween: 17,
                preventClicks: false,
                pagination: {
                    el: controls.pagination,
                    dynamicBullets: true,
                    dynamicMainBullets: 3,
                    clickable: true,
                },
                navigation: {
                    prevEl: controls.buttonPrev,
                    nextEl: controls.buttonNext,
                },
                observer: true,
                observeParents: true,
                watchOverflow: true,
                breakpoints: isNarrow ? selectionNarrowBreakpoints : selectionDefaultBreakpoints,
            });
        }
    }
}
initSelections();
addToGlobalThisRet(initSelections, "initSelections");

function findConrols(selection) {
    const controls = selection.querySelector(".js-selection-controls");
    const buttonPrev = controls.querySelector(".swiper-button-prev");
    const buttonNext = controls.querySelector(".swiper-button-next");
    const pagination = controls.querySelector(".swiper-pagination");

    return { buttonPrev, buttonNext, pagination };
}
