import Swiper from "swiper";

const slider = document.querySelector(".js-events-slider");
const sliderPagination = document.querySelector(".js-events-slider-pagination");

if (slider && sliderPagination) {
    if (window.mobileMedia) {
        initSlider();
    }
}

function initSlider() {
    new Swiper(slider, {
        slidesPerView: 1,
        spaceBetween: 16,
        pagination: {
            el: sliderPagination,
            dynamicBullets: true,
            dynamicMainBullets: 3,
            clickable: true,
        },
        observer: true,
        watchOverflow: true,
        breakpoints: {
            360: {
                slidesPerView: 1.3,
            },
            400: {
                slidesPerView: 1.4,
            },
            440: {
                slidesPerView: 1.7,
            },
            567: {
                slidesPerView: 2,
                spaceBetween: 20,
            },
            768: {
                slidesPerView: 4,
                spaceBetween: 28,
            },
        },
    });
}
