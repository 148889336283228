export function isPhone() {
    const mobileMedia = window.matchMedia("(max-width: 767px)").matches;
    window.mobileMedia = mobileMedia;
    return mobileMedia;
}

isPhone();
window.addEventListener("resize", () => {
    isPhone();
});
