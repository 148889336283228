import Swiper, { Navigation, Pagination } from "swiper";

Swiper.use([Navigation, Pagination]);

function initSlider(slider, options, sliderControls) {
    const controls = document.querySelector(sliderControls);

    let buttonPrev;
    let buttonNext;
    let pagination;

    if (controls) {
        buttonPrev = controls.querySelector(".swiper-button-prev");
        buttonNext = controls.querySelector(".swiper-button-next");
        pagination = controls.querySelector(".swiper-pagination");
    }

    const PAGINATION = {
        pagination: {
            el: pagination,
            dynamicBullets: true,
            dynamicMainBullets: 3,
            clickable: true,
        },
    };
    const NAVIGATION = {
        navigation: {
            prevEl: buttonPrev,
            nextEl: buttonNext,
        },
    };

    if (pagination) {
        Object.assign(options, PAGINATION);
    }

    if (buttonPrev && buttonNext) {
        Object.assign(options, NAVIGATION);
    }

    // new Swiper(slider, options);
    new Swiper(slider, options);
}

// Настройки слайдера с карточками товаров
const sliderCard = document.querySelector(".js-sidebar-helpful-slider");
const optionsSlider = {
    slidesPerView: 1,
    spaceBetween: 16,
    watchOverflow: true,
};
if (sliderCard) {
    initSlider(sliderCard, optionsSlider, ".js-sidebar-helpful-controls");
}
