import Swiper from "swiper";

new Swiper(".js-kit-slider", {
    spaceBetween: 8,
    slidesPerView: "auto",
    navigation: {
        nextEl: ".js-kit-controls > .swiper-button-next",
        prevEl: ".js-kit-controls > .swiper-button-prev",
    },
    pagination: {
        el: ".js-kit-controls > .swiper-pagination",
        dynamicBullets: true,
        dynamicMainBullets: 3,
        clickable: true,
    },
    breakpoints: {
        768: {
            spaceBetween: 27,
            slidesPerView: 3,
        },
    },
});
