/* eslint-disable */
import { lockBody, unlockBody } from "@plugins";
import jquery from "jquery";

const $ = jquery;

(function (global) {
    const bugIOS = /iPhone|iPad|iPod/i.test(navigator.userAgent);
    const ie10 = navigator.appVersion.indexOf("MSIE 10") !== -1;
    const ie9 = navigator.appVersion.indexOf("MSIE 9") !== -1;

    const createElement = function (cls, parent) {
        const obj = document.createElement("div");
        obj.className = cls;
        if (parent) {
            parent.appendChild(obj);
        }
        return obj;
    };

    function Popup() {
        this.tags = {};
        this.tags.popup = createElement(
            `popup${ie10 ? " popup_ie10" : ""}${ie9 ? " popup_ie9" : ""}`,
            document.body,
        );
        this.tags.popup__overlay = createElement("popup__overlay", this.tags.popup);
        this.tags.popup__table = createElement("popup__table", this.tags.popup);
        this.tags.popup__cell = createElement("popup__cell", this.tags.popup__table);
        this.tags.popup__block = createElement("popup__block", this.tags.popup__cell);
        this.tags.popup__ajax = createElement("popup__ajax", this.tags.popup__block);
        this.tags.popup__close = createElement("popup__close", this.tags.popup__block);
        this.tags.popup__change = createElement("popup__change", this.tags.popup__block);

        this.eventsTrigger = "click";

        this.events();
        this.scrollWidth = this.scrollWidthElement();

        this.defaults = {
            clearClose: false,
            bodyHidden: true,
            addClassNamePopup: "",
            closeOverlay: true,
            closeShow: true,
            background: "",
            closeButtons: "",
            offsetY: 0,
            offsetX: 0,
            coordElement: "",
        };
    }

    Popup.prototype = {
        isOpen: false,

        options(opts) {
            this.defaults = this.extend(
                {
                    clearClose: true,
                    bodyHidden: true,
                    addClassNamePopup: "",
                    closeOverlay: true,
                    closeShow: true,
                    background: "",
                    closeButtons: "",
                    offsetY: 0,
                    offsetX: 0,
                    coordElement: "",
                },
                opts,
            );

            if (this.defaults.background) {
                this.tags.popup.style.background = this.defaults.background;
            }

            return this;
        },

        extend(defaults, source) {
            for (const key in source) {
                if (source.hasOwnProperty(key)) {
                    defaults[key] = source[key];
                }
            }

            return defaults;
        },

        addCloseButtons() {
            const obj = this;
            const buttons = this.defaults.closeButtons.split(",");

            buttons.forEach((item, index) => {
                const selectors = document.querySelectorAll(item.replace(/\s+/g, ""));

                Array.prototype.forEach.call(selectors, (element, i) => {
                    element.addEventListener(
                        obj.eventsTrigger,
                        (evt) => {
                            evt.preventDefault();
                            obj.close();

                            return false;
                        },
                        false,
                    );
                });
            });
        },

        coordSet() {
            const obj = this;
            const button = document.querySelector(this.defaults.coordElement);

            if (button) {
                this.coords = button.getBoundingClientRect();

                this.tags.popup__block.style.left = `${
                    (this.defaults.bodyHidden ? 0 : window.pageXOffset) +
                    this.coords.left +
                    this.defaults.offsetX
                }px`;
                this.tags.popup__block.style.top = `${
                    (this.defaults.bodyHidden ? 0 : window.pageYOffset) +
                    this.coords.top +
                    this.defaults.offsetY
                }px`;
                this.tags.popup__block.style.position = "absolute";
            }

            return this;
        },

        coordReset() {
            const obj = this;

            this.defaults = {
                clearClose: true,
                bodyHidden: true,
                addClassNamePopup: "",
                closeOverlay: true,
                closeShow: true,
                background: "",
                closeButtons: "",
                offsetY: 0,
                offsetX: 0,
                coordElement: "",
            };

            setTimeout(() => {
                obj.tags.popup.style.background = "";
            }, 500); // указан в файле стилей transition

            this.tags.popup__block.style.left = "";
            this.tags.popup__block.style.top = "";
            this.tags.popup__block.style.position = "";
            return this;
        },

        setBodyStyle() {
            lockBody(this.tags.popup, {
                reserveScrollBarGap: true,
            });
            return this;
        },

        clearBodyStyle() {
            unlockBody(this.tags.popup);
            return this;
        },

        html(response, callback) {
            $(this.tags.popup__change).html(response);

            if (callback) {
                callback.call(this.tags.popup, this.defaults, this.eventsTrigger);
            }

            return this;
        },

        append(response, callback) {
            $(this.tags.popup__change).html(response);

            if (callback) {
                callback.call(this.tags.popup, this.defaults, this.eventsTrigger);
            }

            if (this.defaults.closeButtons) {
                this.addCloseButtons();
            }

            return this;
        },

        clear(clear) {
            if (this.defaults.clearClose || clear) {
                this.tags.popup__change.innerHTML = "";
            }
            return this;
        },

        show(callback) {
            if (!this.isOpen) {
                this.setBodyStyle();
            }
            this.offsetTop = window.pageYOffset;
            document.body.style.top = `${this.offsetTop * -1}px`;

            this.isOpen = true;

            this.tags.popup.className = "popup";

            if (bugIOS) {
                document.documentElement.classList.add("popup_iphone");
            }

            if (this.defaults.closeShow) {
                this.tags.popup__close.style.display = "";
            } else {
                this.tags.popup__close.style.display = "none";
            }

            if (this.defaults.coordElement) {
                this.coordSet();
            }

            if (this.defaults.closeButtons) {
                this.addCloseButtons();
            }

            if (this.defaults.addClassNamePopup) {
                this.tags.popup.classList.add(this.defaults.addClassNamePopup);
            }
            this.tags.popup.classList.add("popup_active");

            if (callback) {
                callback.call(this.tags.popup, this.defaults, this.eventsTrigger);
            }

            return this;
        },

        close(clear, callback) {
            const obj = this;

            setTimeout(() => {
                obj.tags.popup.classList.remove("popup_active");

                if (bugIOS) {
                    document.documentElement.classList.remove("popup_iphone");
                }

                if (obj.defaults.addClassNamePopup) {
                    obj.tags.popup.classList.remove(obj.defaults.addClassNamePopup);
                }

                if (clear == "clear") {
                    obj.tags.popup__change.innerHTML = "";
                }

                obj.coordReset();

                if (callback) {
                    callback.call(obj.tags.popup, obj.defaults, obj.eventsTrigger);
                }

                obj.clearBodyStyle();

                obj.isOpen = false;
                document.body.style.top = "";
                window.scrollTo(0, obj.offsetTop);
            }, 50);

            return this;
        },

        events() {
            const obj = this;

            this.tags.popup__close.addEventListener(
                this.eventsTrigger,
                (e) => {
                    obj.close();
                    return false;
                },
                false,
            );

            this.tags.popup__overlay.addEventListener(
                this.eventsTrigger,
                (e) => {
                    if (obj.defaults.closeOverlay) {
                        obj.close();
                    }

                    return false;
                },
                false,
            );

            document.addEventListener(
                "keydown",
                (e) => {
                    if (e.key === "Escape" || e.key === "Esc") {
                        if (obj.isOpen) {
                            // "Esc": IE/Edge specific value
                            obj.close();
                        }
                    }
                },
                false,
            );
        },

        loader(trigger) {
            trigger
                ? this.tags.popup__ajax.classList.add("show")
                : this.tags.popup__ajax.classList.remove("show");
        },

        scrollWidthElement() {
            const div = document.createElement("div");
            div.style.overflowY = "scroll";
            div.style.width = "50px";
            div.style.height = "50px";
            div.style.visibility = "hidden";

            document.body.appendChild(div);
            const scrollWidth = div.offsetWidth - div.clientWidth;
            document.body.removeChild(div);

            return scrollWidth;
        },
    };

    window.Popup = Popup;
})(window);
