/*! jQuery UI - v1.12.1 - 2016-09-14
 * http://jqueryui.com
 * Includes: widget.js, position.js, data.js, disable-selection.js, effect.js, effects/effect-blind.js, effects/effect-bounce.js, effects/effect-clip.js, effects/effect-drop.js, effects/effect-explode.js, effects/effect-fade.js, effects/effect-fold.js, effects/effect-highlight.js, effects/effect-puff.js, effects/effect-pulsate.js, effects/effect-scale.js, effects/effect-shake.js, effects/effect-size.js, effects/effect-slide.js, effects/effect-transfer.js, focusable.js, form-reset-mixin.js, jquery-1-7.js, keycode.js, labels.js, scroll-parent.js, tabbable.js, unique-id.js, widgets/accordion.js, widgets/autocomplete.js, widgets/button.js, widgets/checkboxradio.js, widgets/controlgroup.js, widgets/datepicker.js, widgets/dialog.js, widgets/draggable.js, widgets/droppable.js, widgets/menu.js, widgets/mouse.js, widgets/progressbar.js, widgets/resizable.js, widgets/selectable.js, widgets/selectmenu.js, widgets/slider.js, widgets/sortable.js, widgets/spinner.js, widgets/tabs.js, widgets/tooltip.js
 * Copyright jQuery Foundation and other contributors; Licensed MIT */

(function (t) {
    typeof define === "function" && define.amd ? define(["jquery"], t) : t(jQuery);
})((t) => {
    function e(t) {
        for (var e = t.css("visibility"); e === "inherit"; )
            (t = t.parent()), (e = t.css("visibility"));
        return e !== "hidden";
    }
    function i(t) {
        for (var e, i; t.length && t[0] !== document; ) {
            if (
                ((e = t.css("position")),
                (e === "absolute" || e === "relative" || e === "fixed") &&
                    ((i = parseInt(t.css("zIndex"), 10)), !isNaN(i) && i !== 0))
            )
                return i;
            t = t.parent();
        }
        return 0;
    }
    function s() {
        (this._curInst = null),
        (this._keyEvent = !1),
        (this._disabledInputs = []),
        (this._datepickerShowing = !1),
        (this._inDialog = !1),
        (this._mainDivId = "ui-datepicker-div"),
        (this._inlineClass = "ui-datepicker-inline"),
        (this._appendClass = "ui-datepicker-append"),
        (this._triggerClass = "ui-datepicker-trigger"),
        (this._dialogClass = "ui-datepicker-dialog"),
        (this._disableClass = "ui-datepicker-disabled"),
        (this._unselectableClass = "ui-datepicker-unselectable"),
        (this._currentClass = "ui-datepicker-current-day"),
        (this._dayOverClass = "ui-datepicker-days-cell-over"),
        (this.regional = []),
        (this.regional[""] = {
            closeText: "Done",
            prevText: "Prev",
            nextText: "Next",
            currentText: "Today",
            monthNames: [
                "January",
                "February",
                "March",
                "April",
                "May",
                "June",
                "July",
                "August",
                "September",
                "October",
                "November",
                "December",
            ],
            monthNamesShort: [
                "Jan",
                "Feb",
                "Mar",
                "Apr",
                "May",
                "Jun",
                "Jul",
                "Aug",
                "Sep",
                "Oct",
                "Nov",
                "Dec",
            ],
            dayNames: [
                "Sunday",
                "Monday",
                "Tuesday",
                "Wednesday",
                "Thursday",
                "Friday",
                "Saturday",
            ],
            dayNamesShort: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
            dayNamesMin: ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"],
            weekHeader: "Wk",
            dateFormat: "mm/dd/yy",
            firstDay: 0,
            isRTL: !1,
            showMonthAfterYear: !1,
            yearSuffix: "",
        }),
        (this._defaults = {
            showOn: "focus",
            showAnim: "fadeIn",
            showOptions: {},
            defaultDate: null,
            appendText: "",
            buttonText: "...",
            buttonImage: "",
            buttonImageOnly: !1,
            hideIfNoPrevNext: !1,
            navigationAsDateFormat: !1,
            gotoCurrent: !1,
            changeMonth: !1,
            changeYear: !1,
            yearRange: "c-10:c+10",
            showOtherMonths: !1,
            selectOtherMonths: !1,
            showWeek: !1,
            calculateWeek: this.iso8601Week,
            shortYearCutoff: "+10",
            minDate: null,
            maxDate: null,
            duration: "fast",
            beforeShowDay: null,
            beforeShow: null,
            onSelect: null,
            onChangeMonthYear: null,
            onClose: null,
            numberOfMonths: 1,
            showCurrentAtPos: 0,
            stepMonths: 1,
            stepBigMonths: 12,
            altField: "",
            altFormat: "",
            constrainInput: !0,
            showButtonPanel: !1,
            autoSize: !1,
            disabled: !1,
        }),
        t.extend(this._defaults, this.regional[""]),
        (this.regional.en = t.extend(!0, {}, this.regional[""])),
        (this.regional["en-US"] = t.extend(!0, {}, this.regional.en)),
        (this.dpDiv = n(
            t(
                `<div id='${this._mainDivId}' class='ui-datepicker ui-widget ui-widget-content ui-helper-clearfix ui-corner-all'></div>`,
            ),
        ));
    }
    function n(e) {
        const i = "button, .ui-datepicker-prev, .ui-datepicker-next, .ui-datepicker-calendar td a";
        return e
            .on("mouseout", i, function () {
                t(this).removeClass("ui-state-hover"),
                this.className.indexOf("ui-datepicker-prev") !== -1 &&
                        t(this).removeClass("ui-datepicker-prev-hover"),
                this.className.indexOf("ui-datepicker-next") !== -1 &&
                        t(this).removeClass("ui-datepicker-next-hover");
            })
            .on("mouseover", i, o);
    }
    function o() {
        t.datepicker._isDisabledDatepicker(m.inline ? m.dpDiv.parent()[0] : m.input[0]) ||
            (t(this).parents(".ui-datepicker-calendar").find("a").removeClass("ui-state-hover"),
            t(this).addClass("ui-state-hover"),
            this.className.indexOf("ui-datepicker-prev") !== -1 &&
                t(this).addClass("ui-datepicker-prev-hover"),
            this.className.indexOf("ui-datepicker-next") !== -1 &&
                t(this).addClass("ui-datepicker-next-hover"));
    }
    function a(e, i) {
        t.extend(e, i);
        for (const s in i) i[s] == null && (e[s] = i[s]);
        return e;
    }
    function r(t) {
        return function () {
            const e = this.element.val();
            t.apply(this, arguments),
            this._refresh(),
            e !== this.element.val() && this._trigger("change");
        };
    }
    (t.ui = t.ui || {}), (t.ui.version = "1.12.1");
    let h = 0;
    const l = Array.prototype.slice;
    (t.cleanData = (function (e) {
        return function (i) {
            let s;
            let n;
            let o;
            for (o = 0; (n = i[o]) != null; o++)
                try {
                    (s = t._data(n, "events")), s && s.remove && t(n).triggerHandler("remove");
                } catch (a) {}
            e(i);
        };
    })(t.cleanData)),
    (t.widget = function (e, i, s) {
        let n;
        let o;
        let a;
        const r = {};
        const h = e.split(".")[0];
        e = e.split(".")[1];
        const l = `${h}-${e}`;
        return (
            s || ((s = i), (i = t.Widget)),
            t.isArray(s) && (s = t.extend.apply(null, [{}].concat(s))),
            (t.expr[":"][l.toLowerCase()] = function (e) {
                return !!t.data(e, l);
            }),
            (t[h] = t[h] || {}),
            (n = t[h][e]),
            (o = t[h][e] =
                    function (t, e) {
                        return this._createWidget
                            ? (arguments.length && this._createWidget(t, e), void 0)
                            : new o(t, e);
                    }),
            t.extend(o, n, {
                version: s.version,
                _proto: t.extend({}, s),
                _childConstructors: [],
            }),
            (a = new i()),
            (a.options = t.widget.extend({}, a.options)),
            t.each(s, (e, s) =>
                t.isFunction(s)
                    ? ((r[e] = (function () {
                        function t() {
                            return i.prototype[e].apply(this, arguments);
                        }
                        function n(t) {
                            return i.prototype[e].apply(this, t);
                        }
                        return function () {
                            let e;
                            const i = this._super;
                            const o = this._superApply;
                            return (
                                (this._super = t),
                                (this._superApply = n),
                                (e = s.apply(this, arguments)),
                                (this._super = i),
                                (this._superApply = o),
                                e
                            );
                        };
                    })()),
                    void 0)
                    : ((r[e] = s), void 0),
            ),
            (o.prototype = t.widget.extend(
                a,
                { widgetEventPrefix: n ? a.widgetEventPrefix || e : e },
                r,
                {
                    constructor: o,
                    namespace: h,
                    widgetName: e,
                    widgetFullName: l,
                },
            )),
            n
                ? (t.each(n._childConstructors, (e, i) => {
                    const s = i.prototype;
                    t.widget(`${s.namespace}.${s.widgetName}`, o, i._proto);
                }),
                delete n._childConstructors)
                : i._childConstructors.push(o),
            t.widget.bridge(e, o),
            o
        );
    }),
    (t.widget.extend = function (e) {
        for (var i, s, n = l.call(arguments, 1), o = 0, a = n.length; a > o; o++)
            for (i in n[o])
                (s = n[o][i]),
                n[o].hasOwnProperty(i) &&
                            void 0 !== s &&
                            (e[i] = t.isPlainObject(s)
                                ? t.isPlainObject(e[i])
                                    ? t.widget.extend({}, e[i], s)
                                    : t.widget.extend({}, s)
                                : s);
        return e;
    }),
    (t.widget.bridge = function (e, i) {
        const s = i.prototype.widgetFullName || e;
        t.fn[e] = function (n) {
            const o = typeof n === "string";
            const a = l.call(arguments, 1);
            let r = this;
            return (
                o
                    ? this.length || n !== "instance"
                        ? this.each(function () {
                            let i;
                            const o = t.data(this, s);
                            return n === "instance"
                                ? ((r = o), !1)
                                : o
                                    ? t.isFunction(o[n]) && n.charAt(0) !== "_"
                                        ? ((i = o[n].apply(o, a)),
                                        i !== o && void 0 !== i
                                            ? ((r = i && i.jquery ? r.pushStack(i.get()) : i),
                                            !1)
                                            : void 0)
                                        : t.error(
                                            `no such method '${n}' for ${e} widget instance`,
                                        )
                                    : t.error(
                                        `cannot call methods on ${e} prior to initialization; ` +
                                                `attempted to call method '${n}'`,
                                    );
                        })
                        : (r = void 0)
                    : (a.length && (n = t.widget.extend.apply(null, [n].concat(a))),
                    this.each(function () {
                        const e = t.data(this, s);
                        e
                            ? (e.option(n || {}), e._init && e._init())
                            : t.data(this, s, new i(n, this));
                    })),
                r
            );
        };
    }),
    (t.Widget = function () {}),
    (t.Widget._childConstructors = []),
    (t.Widget.prototype = {
        widgetName: "widget",
        widgetEventPrefix: "",
        defaultElement: "<div>",
        options: { classes: {}, disabled: !1, create: null },
        _createWidget(e, i) {
            (i = t(i || this.defaultElement || this)[0]),
            (this.element = t(i)),
            (this.uuid = h++),
            (this.eventNamespace = `.${this.widgetName}${this.uuid}`),
            (this.bindings = t()),
            (this.hoverable = t()),
            (this.focusable = t()),
            (this.classesElementLookup = {}),
            i !== this &&
                        (t.data(i, this.widgetFullName, this),
                        this._on(!0, this.element, {
                            remove(t) {
                                t.target === i && this.destroy();
                            },
                        }),
                        (this.document = t(i.style ? i.ownerDocument : i.document || i)),
                        (this.window = t(
                            this.document[0].defaultView || this.document[0].parentWindow,
                        ))),
            (this.options = t.widget.extend({}, this.options, this._getCreateOptions(), e)),
            this._create(),
            this.options.disabled && this._setOptionDisabled(this.options.disabled),
            this._trigger("create", null, this._getCreateEventData()),
            this._init();
        },
        _getCreateOptions() {
            return {};
        },
        _getCreateEventData: t.noop,
        _create: t.noop,
        _init: t.noop,
        destroy() {
            const e = this;
            this._destroy(),
            t.each(this.classesElementLookup, (t, i) => {
                e._removeClass(i, t);
            }),
            this.element.off(this.eventNamespace).removeData(this.widgetFullName),
            this.widget().off(this.eventNamespace).removeAttr("aria-disabled"),
            this.bindings.off(this.eventNamespace);
        },
        _destroy: t.noop,
        widget() {
            return this.element;
        },
        option(e, i) {
            let s;
            let n;
            let o;
            let a = e;
            if (arguments.length === 0) return t.widget.extend({}, this.options);
            if (typeof e === "string")
                if (((a = {}), (s = e.split(".")), (e = s.shift()), s.length)) {
                    for (
                        n = a[e] = t.widget.extend({}, this.options[e]), o = 0;
                        s.length - 1 > o;
                        o++
                    )
                        (n[s[o]] = n[s[o]] || {}), (n = n[s[o]]);
                    if (((e = s.pop()), arguments.length === 1))
                        return void 0 === n[e] ? null : n[e];
                    n[e] = i;
                } else {
                    if (arguments.length === 1)
                        return void 0 === this.options[e] ? null : this.options[e];
                    a[e] = i;
                }
            return this._setOptions(a), this;
        },
        _setOptions(t) {
            let e;
            for (e in t) this._setOption(e, t[e]);
            return this;
        },
        _setOption(t, e) {
            return (
                t === "classes" && this._setOptionClasses(e),
                (this.options[t] = e),
                t === "disabled" && this._setOptionDisabled(e),
                this
            );
        },
        _setOptionClasses(e) {
            let i;
            let s;
            let n;
            for (i in e) {
                (n = this.classesElementLookup[i]),
                e[i] !== this.options.classes[i] &&
                            n &&
                            n.length &&
                            ((s = t(n.get())),
                            this._removeClass(n, i),
                            s.addClass(
                                this._classes({
                                    element: s,
                                    keys: i,
                                    classes: e,
                                    add: !0,
                                }),
                            ));
            }
        },
        _setOptionDisabled(t) {
            this._toggleClass(this.widget(), `${this.widgetFullName}-disabled`, null, !!t),
            t &&
                        (this._removeClass(this.hoverable, null, "ui-state-hover"),
                        this._removeClass(this.focusable, null, "ui-state-focus"));
        },
        enable() {
            return this._setOptions({ disabled: !1 });
        },
        disable() {
            return this._setOptions({ disabled: !0 });
        },
        _classes(e) {
            function i(i, o) {
                let a;
                let r;
                for (r = 0; i.length > r; r++)
                    (a = n.classesElementLookup[i[r]] || t()),
                    (a = e.add
                        ? t(t.unique(a.get().concat(e.element.get())))
                        : t(a.not(e.element).get())),
                    (n.classesElementLookup[i[r]] = a),
                    s.push(i[r]),
                    o && e.classes[i[r]] && s.push(e.classes[i[r]]);
            }
            var s = [];
            var n = this;
            return (
                (e = t.extend(
                    { element: this.element, classes: this.options.classes || {} },
                    e,
                )),
                this._on(e.element, { remove: "_untrackClassesElement" }),
                e.keys && i(e.keys.match(/\S+/g) || [], !0),
                e.extra && i(e.extra.match(/\S+/g) || []),
                s.join(" ")
            );
        },
        _untrackClassesElement(e) {
            const i = this;
            t.each(i.classesElementLookup, (s, n) => {
                t.inArray(e.target, n) !== -1 &&
                        (i.classesElementLookup[s] = t(n.not(e.target).get()));
            });
        },
        _removeClass(t, e, i) {
            return this._toggleClass(t, e, i, !1);
        },
        _addClass(t, e, i) {
            return this._toggleClass(t, e, i, !0);
        },
        _toggleClass(t, e, i, s) {
            s = typeof s === "boolean" ? s : i;
            const n = typeof t === "string" || t === null;
            const o = {
                extra: n ? e : i,
                keys: n ? t : e,
                element: n ? this.element : t,
                add: s,
            };
            return o.element.toggleClass(this._classes(o), s), this;
        },
        _on(e, i, s) {
            let n;
            const o = this;
            typeof e !== "boolean" && ((s = i), (i = e), (e = !1)),
            s
                ? ((i = n = t(i)), (this.bindings = this.bindings.add(i)))
                : ((s = i), (i = this.element), (n = this.widget())),
            t.each(s, (s, a) => {
                function r() {
                    return e ||
                                (o.options.disabled !== !0 &&
                                    !t(this).hasClass("ui-state-disabled"))
                        ? (typeof a === "string" ? o[a] : a).apply(o, arguments)
                        : void 0;
                }
                typeof a !== "string" && (r.guid = a.guid = a.guid || r.guid || t.guid++);
                const h = s.match(/^([\w:-]*)\s*(.*)$/);
                const l = h[1] + o.eventNamespace;
                const c = h[2];
                c ? n.on(l, c, r) : i.on(l, r);
            });
        },
        _off(e, i) {
            (i = (i || "").split(" ").join(`${this.eventNamespace} `) + this.eventNamespace),
            e.off(i).off(i),
            (this.bindings = t(this.bindings.not(e).get())),
            (this.focusable = t(this.focusable.not(e).get())),
            (this.hoverable = t(this.hoverable.not(e).get()));
        },
        _delay(t, e) {
            function i() {
                return (typeof t === "string" ? s[t] : t).apply(s, arguments);
            }
            var s = this;
            return setTimeout(i, e || 0);
        },
        _hoverable(e) {
            (this.hoverable = this.hoverable.add(e)),
            this._on(e, {
                mouseenter(e) {
                    this._addClass(t(e.currentTarget), null, "ui-state-hover");
                },
                mouseleave(e) {
                    this._removeClass(t(e.currentTarget), null, "ui-state-hover");
                },
            });
        },
        _focusable(e) {
            (this.focusable = this.focusable.add(e)),
            this._on(e, {
                focusin(e) {
                    this._addClass(t(e.currentTarget), null, "ui-state-focus");
                },
                focusout(e) {
                    this._removeClass(t(e.currentTarget), null, "ui-state-focus");
                },
            });
        },
        _trigger(e, i, s) {
            let n;
            let o;
            const a = this.options[e];
            if (
                ((s = s || {}),
                (i = t.Event(i)),
                (i.type = (
                    e === this.widgetEventPrefix ? e : this.widgetEventPrefix + e
                ).toLowerCase()),
                (i.target = this.element[0]),
                (o = i.originalEvent))
            )
                for (n in o) n in i || (i[n] = o[n]);
            return (
                this.element.trigger(i, s),
                !(
                    (t.isFunction(a) && a.apply(this.element[0], [i].concat(s)) === !1) ||
                        i.isDefaultPrevented()
                )
            );
        },
    }),
    t.each({ show: "fadeIn", hide: "fadeOut" }, (e, i) => {
        t.Widget.prototype[`_${e}`] = function (s, n, o) {
            typeof n === "string" && (n = { effect: n });
            let a;
            const r = n ? (n === !0 || typeof n === "number" ? i : n.effect || i) : e;
            (n = n || {}),
            typeof n === "number" && (n = { duration: n }),
            (a = !t.isEmptyObject(n)),
            (n.complete = o),
            n.delay && s.delay(n.delay),
            a && t.effects && t.effects.effect[r]
                ? s[e](n)
                : r !== e && s[r]
                    ? s[r](n.duration, n.easing, o)
                    : s.queue(function (i) {
                        t(this)[e](), o && o.call(s[0]), i();
                    });
        };
    }),
    t.widget,
    (function () {
        function e(t, e, i) {
            return [
                parseFloat(t[0]) * (u.test(t[0]) ? e / 100 : 1),
                parseFloat(t[1]) * (u.test(t[1]) ? i / 100 : 1),
            ];
        }
        function i(e, i) {
            return parseInt(t.css(e, i), 10) || 0;
        }
        function s(e) {
            const i = e[0];
            return i.nodeType === 9
                ? { width: e.width(), height: e.height(), offset: { top: 0, left: 0 } }
                : t.isWindow(i)
                    ? {
                        width: e.width(),
                        height: e.height(),
                        offset: { top: e.scrollTop(), left: e.scrollLeft() },
                    }
                    : i.preventDefault
                        ? { width: 0, height: 0, offset: { top: i.pageY, left: i.pageX } }
                        : { width: e.outerWidth(), height: e.outerHeight(), offset: e.offset() };
        }
        let n;
        const o = Math.max;
        const a = Math.abs;
        const r = /left|center|right/;
        const h = /top|center|bottom/;
        const l = /[\+\-]\d+(\.[\d]+)?%?/;
        const c = /^\w+/;
        var u = /%$/;
        const d = t.fn.position;
        (t.position = {
            scrollbarWidth() {
                if (void 0 !== n) return n;
                let e;
                let i;
                const s = t(
                    "<div style='display:block;position:absolute;width:50px;height:50px;overflow:hidden;'><div style='height:100px;width:auto;'></div></div>",
                );
                const o = s.children()[0];
                return (
                    t("body").append(s),
                    (e = o.offsetWidth),
                    s.css("overflow", "scroll"),
                    (i = o.offsetWidth),
                    e === i && (i = s[0].clientWidth),
                    s.remove(),
                    (n = e - i)
                );
            },
            getScrollInfo(e) {
                const i = e.isWindow || e.isDocument ? "" : e.element.css("overflow-x");
                const s = e.isWindow || e.isDocument ? "" : e.element.css("overflow-y");
                const n =
                        i === "scroll" || (i === "auto" && e.width < e.element[0].scrollWidth);
                const o =
                        s === "scroll" || (s === "auto" && e.height < e.element[0].scrollHeight);
                return {
                    width: o ? t.position.scrollbarWidth() : 0,
                    height: n ? t.position.scrollbarWidth() : 0,
                };
            },
            getWithinInfo(e) {
                const i = t(e || window);
                const s = t.isWindow(i[0]);
                const n = !!i[0] && i[0].nodeType === 9;
                const o = !s && !n;
                return {
                    element: i,
                    isWindow: s,
                    isDocument: n,
                    offset: o ? t(e).offset() : { left: 0, top: 0 },
                    scrollLeft: i.scrollLeft(),
                    scrollTop: i.scrollTop(),
                    width: i.outerWidth(),
                    height: i.outerHeight(),
                };
            },
        }),
        (t.fn.position = function (n) {
            if (!n || !n.of) return d.apply(this, arguments);
            n = t.extend({}, n);
            let u;
            let p;
            let f;
            let g;
            let m;
            let _;
            const v = t(n.of);
            const b = t.position.getWithinInfo(n.within);
            const y = t.position.getScrollInfo(b);
            const w = (n.collision || "flip").split(" ");
            const k = {};
            return (
                (_ = s(v)),
                v[0].preventDefault && (n.at = "left top"),
                (p = _.width),
                (f = _.height),
                (g = _.offset),
                (m = t.extend({}, g)),
                t.each(["my", "at"], function () {
                    let t;
                    let e;
                    let i = (n[this] || "").split(" ");
                    i.length === 1 &&
                                (i = r.test(i[0])
                                    ? i.concat(["center"])
                                    : h.test(i[0])
                                        ? ["center"].concat(i)
                                        : ["center", "center"]),
                    (i[0] = r.test(i[0]) ? i[0] : "center"),
                    (i[1] = h.test(i[1]) ? i[1] : "center"),
                    (t = l.exec(i[0])),
                    (e = l.exec(i[1])),
                    (k[this] = [t ? t[0] : 0, e ? e[0] : 0]),
                    (n[this] = [c.exec(i[0])[0], c.exec(i[1])[0]]);
                }),
                w.length === 1 && (w[1] = w[0]),
                n.at[0] === "right"
                    ? (m.left += p)
                    : n.at[0] === "center" && (m.left += p / 2),
                n.at[1] === "bottom"
                    ? (m.top += f)
                    : n.at[1] === "center" && (m.top += f / 2),
                (u = e(k.at, p, f)),
                (m.left += u[0]),
                (m.top += u[1]),
                this.each(function () {
                    let s;
                    let r;
                    const h = t(this);
                    const l = h.outerWidth();
                    const c = h.outerHeight();
                    const d = i(this, "marginLeft");
                    const _ = i(this, "marginTop");
                    const x = l + d + i(this, "marginRight") + y.width;
                    const C = c + _ + i(this, "marginBottom") + y.height;
                    const D = t.extend({}, m);
                    const I = e(k.my, h.outerWidth(), h.outerHeight());
                    n.my[0] === "right"
                        ? (D.left -= l)
                        : n.my[0] === "center" && (D.left -= l / 2),
                    n.my[1] === "bottom"
                        ? (D.top -= c)
                        : n.my[1] === "center" && (D.top -= c / 2),
                    (D.left += I[0]),
                    (D.top += I[1]),
                    (s = { marginLeft: d, marginTop: _ }),
                    t.each(["left", "top"], (e, i) => {
                        t.ui.position[w[e]] &&
                                        t.ui.position[w[e]][i](D, {
                                            targetWidth: p,
                                            targetHeight: f,
                                            elemWidth: l,
                                            elemHeight: c,
                                            collisionPosition: s,
                                            collisionWidth: x,
                                            collisionHeight: C,
                                            offset: [u[0] + I[0], u[1] + I[1]],
                                            my: n.my,
                                            at: n.at,
                                            within: b,
                                            elem: h,
                                        });
                    }),
                    n.using &&
                                    (r = function (t) {
                                        const e = g.left - D.left;
                                        const i = e + p - l;
                                        const s = g.top - D.top;
                                        const r = s + f - c;
                                        const u = {
                                            target: {
                                                element: v,
                                                left: g.left,
                                                top: g.top,
                                                width: p,
                                                height: f,
                                            },
                                            element: {
                                                element: h,
                                                left: D.left,
                                                top: D.top,
                                                width: l,
                                                height: c,
                                            },
                                            horizontal: i < 0 ? "left" : e > 0 ? "right" : "center",
                                            vertical: r < 0 ? "top" : s > 0 ? "bottom" : "middle",
                                        };
                                        l > p && p > a(e + i) && (u.horizontal = "center"),
                                        c > f && f > a(s + r) && (u.vertical = "middle"),
                                        (u.important =
                                                o(a(e), a(i)) > o(a(s), a(r))
                                                    ? "horizontal"
                                                    : "vertical"),
                                        n.using.call(this, t, u);
                                    }),
                    h.offset(t.extend(D, { using: r }));
                })
            );
        }),
        (t.ui.position = {
            fit: {
                left(t, e) {
                    let i;
                    const s = e.within;
                    const n = s.isWindow ? s.scrollLeft : s.offset.left;
                    const a = s.width;
                    const r = t.left - e.collisionPosition.marginLeft;
                    const h = n - r;
                    const l = r + e.collisionWidth - a - n;
                    e.collisionWidth > a
                        ? h > 0 && l <= 0
                            ? ((i = t.left + h + e.collisionWidth - a - n),
                            (t.left += h - i))
                            : (t.left =
                                          l > 0 && h <= 0
                                              ? n
                                              : h > l
                                                  ? n + a - e.collisionWidth
                                                  : n)
                        : h > 0
                            ? (t.left += h)
                            : l > 0
                                ? (t.left -= l)
                                : (t.left = o(t.left - r, t.left));
                },
                top(t, e) {
                    let i;
                    const s = e.within;
                    const n = s.isWindow ? s.scrollTop : s.offset.top;
                    const a = e.within.height;
                    const r = t.top - e.collisionPosition.marginTop;
                    const h = n - r;
                    const l = r + e.collisionHeight - a - n;
                    e.collisionHeight > a
                        ? h > 0 && l <= 0
                            ? ((i = t.top + h + e.collisionHeight - a - n),
                            (t.top += h - i))
                            : (t.top =
                                          l > 0 && h <= 0
                                              ? n
                                              : h > l
                                                  ? n + a - e.collisionHeight
                                                  : n)
                        : h > 0
                            ? (t.top += h)
                            : l > 0
                                ? (t.top -= l)
                                : (t.top = o(t.top - r, t.top));
                },
            },
            flip: {
                left(t, e) {
                    let i;
                    let s;
                    const n = e.within;
                    const o = n.offset.left + n.scrollLeft;
                    const r = n.width;
                    const h = n.isWindow ? n.scrollLeft : n.offset.left;
                    const l = t.left - e.collisionPosition.marginLeft;
                    const c = l - h;
                    const u = l + e.collisionWidth - r - h;
                    const d =
                                e.my[0] === "left"
                                    ? -e.elemWidth
                                    : e.my[0] === "right"
                                        ? e.elemWidth
                                        : 0;
                    const p =
                                e.at[0] === "left"
                                    ? e.targetWidth
                                    : e.at[0] === "right"
                                        ? -e.targetWidth
                                        : 0;
                    const f = -2 * e.offset[0];
                    c < 0
                        ? ((i = t.left + d + p + f + e.collisionWidth - r - o),
                        (i < 0 || a(c) > i) && (t.left += d + p + f))
                        : u > 0 &&
                                  ((s = t.left - e.collisionPosition.marginLeft + d + p + f - h),
                                  (s > 0 || u > a(s)) && (t.left += d + p + f));
                },
                top(t, e) {
                    let i;
                    let s;
                    const n = e.within;
                    const o = n.offset.top + n.scrollTop;
                    const r = n.height;
                    const h = n.isWindow ? n.scrollTop : n.offset.top;
                    const l = t.top - e.collisionPosition.marginTop;
                    const c = l - h;
                    const u = l + e.collisionHeight - r - h;
                    const d = e.my[1] === "top";
                    const p = d ? -e.elemHeight : e.my[1] === "bottom" ? e.elemHeight : 0;
                    const f =
                                e.at[1] === "top"
                                    ? e.targetHeight
                                    : e.at[1] === "bottom"
                                        ? -e.targetHeight
                                        : 0;
                    const g = -2 * e.offset[1];
                    c < 0
                        ? ((s = t.top + p + f + g + e.collisionHeight - r - o),
                        (s < 0 || a(c) > s) && (t.top += p + f + g))
                        : u > 0 &&
                                  ((i = t.top - e.collisionPosition.marginTop + p + f + g - h),
                                  (i > 0 || u > a(i)) && (t.top += p + f + g));
                },
            },
            flipfit: {
                left() {
                    t.ui.position.flip.left.apply(this, arguments),
                    t.ui.position.fit.left.apply(this, arguments);
                },
                top() {
                    t.ui.position.flip.top.apply(this, arguments),
                    t.ui.position.fit.top.apply(this, arguments);
                },
            },
        });
    })(),
    t.ui.position,
    t.extend(t.expr[":"], {
        data: t.expr.createPseudo
            ? t.expr.createPseudo(
                (e) =>
                    function (i) {
                        return !!t.data(i, e);
                    },
            )
            : function (e, i, s) {
                return !!t.data(e, s[3]);
            },
    }),
    t.fn.extend({
        disableSelection: (function () {
            const t =
                    "onselectstart" in document.createElement("div") ? "selectstart" : "mousedown";
            return function () {
                return this.on(`${t}.ui-disableSelection`, (t) => {
                    t.preventDefault();
                });
            };
        })(),
        enableSelection() {
            return this.off(".ui-disableSelection");
        },
    });
    const c = "ui-effects-";
    const u = "ui-effects-style";
    const d = "ui-effects-animated";
    const p = t;
    (t.effects = { effect: {} }),
    (function (t, e) {
        function i(t, e, i) {
            const s = u[e.type] || {};
            return t == null
                ? i || !e.def
                    ? null
                    : e.def
                : ((t = s.floor ? ~~t : parseFloat(t)),
                isNaN(t)
                    ? e.def
                    : s.mod
                        ? (t + s.mod) % s.mod
                        : t < 0
                            ? 0
                            : t > s.max
                                ? s.max
                                : t);
        }
        function s(i) {
            const s = l();
            let n = (s._rgba = []);
            return (
                (i = i.toLowerCase()),
                f(h, (t, o) => {
                    let a;
                    const r = o.re.exec(i);
                    const h = r && o.parse(r);
                    const l = o.space || "rgba";
                    return h
                        ? ((a = s[l](h)),
                        (s[c[l].cache] = a[c[l].cache]),
                        (n = s._rgba = a._rgba),
                        !1)
                        : e;
                }),
                n.length ? (n.join() === "0,0,0,0" && t.extend(n, o.transparent), s) : o[i]
            );
        }
        function n(t, e, i) {
            return (
                (i = (i + 1) % 1),
                6 * i < 1
                    ? t + 6 * (e - t) * i
                    : 2 * i < 1
                        ? e
                        : 3 * i < 2
                            ? t + 6 * (e - t) * (2 / 3 - i)
                            : t
            );
        }
        let o;
        const a =
                "backgroundColor borderBottomColor borderLeftColor borderRightColor borderTopColor color columnRuleColor outlineColor textDecorationColor textEmphasisColor";
        const r = /^([\-+])=\s*(\d+\.?\d*)/;
        var h = [
            {
                re: /rgba?\(\s*(\d{1,3})\s*,\s*(\d{1,3})\s*,\s*(\d{1,3})\s*(?:,\s*(\d?(?:\.\d+)?)\s*)?\)/,
                parse(t) {
                    return [t[1], t[2], t[3], t[4]];
                },
            },
            {
                re: /rgba?\(\s*(\d+(?:\.\d+)?)\%\s*,\s*(\d+(?:\.\d+)?)\%\s*,\s*(\d+(?:\.\d+)?)\%\s*(?:,\s*(\d?(?:\.\d+)?)\s*)?\)/,
                parse(t) {
                    return [2.55 * t[1], 2.55 * t[2], 2.55 * t[3], t[4]];
                },
            },
            {
                re: /#([a-f0-9]{2})([a-f0-9]{2})([a-f0-9]{2})/,
                parse(t) {
                    return [parseInt(t[1], 16), parseInt(t[2], 16), parseInt(t[3], 16)];
                },
            },
            {
                re: /#([a-f0-9])([a-f0-9])([a-f0-9])/,
                parse(t) {
                    return [
                        parseInt(t[1] + t[1], 16),
                        parseInt(t[2] + t[2], 16),
                        parseInt(t[3] + t[3], 16),
                    ];
                },
            },
            {
                re: /hsla?\(\s*(\d+(?:\.\d+)?)\s*,\s*(\d+(?:\.\d+)?)\%\s*,\s*(\d+(?:\.\d+)?)\%\s*(?:,\s*(\d?(?:\.\d+)?)\s*)?\)/,
                space: "hsla",
                parse(t) {
                    return [t[1], t[2] / 100, t[3] / 100, t[4]];
                },
            },
        ];
        var l = (t.Color = function (e, i, s, n) {
            return new t.Color.fn.parse(e, i, s, n);
        });
        var c = {
            rgba: {
                props: {
                    red: { idx: 0, type: "byte" },
                    green: { idx: 1, type: "byte" },
                    blue: { idx: 2, type: "byte" },
                },
            },
            hsla: {
                props: {
                    hue: { idx: 0, type: "degrees" },
                    saturation: { idx: 1, type: "percent" },
                    lightness: { idx: 2, type: "percent" },
                },
            },
        };
        var u = {
            byte: { floor: !0, max: 255 },
            percent: { max: 1 },
            degrees: { mod: 360, floor: !0 },
        };
        const d = (l.support = {});
        const p = t("<p>")[0];
        var f = t.each;
        (p.style.cssText = "background-color:rgba(1,1,1,.5)"),
        (d.rgba = p.style.backgroundColor.indexOf("rgba") > -1),
        f(c, (t, e) => {
            (e.cache = `_${t}`), (e.props.alpha = { idx: 3, type: "percent", def: 1 });
        }),
        (l.fn = t.extend(l.prototype, {
            parse(n, a, r, h) {
                if (n === e) return (this._rgba = [null, null, null, null]), this;
                (n.jquery || n.nodeType) && ((n = t(n).css(a)), (a = e));
                const u = this;
                let d = t.type(n);
                const p = (this._rgba = []);
                return (
                    a !== e && ((n = [n, a, r, h]), (d = "array")),
                    d === "string"
                        ? this.parse(s(n) || o._default)
                        : d === "array"
                            ? (f(c.rgba.props, (t, e) => {
                                p[e.idx] = i(n[e.idx], e);
                            }),
                            this)
                            : d === "object"
                                ? (n instanceof l
                                    ? f(c, (t, e) => {
                                        n[e.cache] && (u[e.cache] = n[e.cache].slice());
                                    })
                                    : f(c, (e, s) => {
                                        const o = s.cache;
                                        f(s.props, (t, e) => {
                                            if (!u[o] && s.to) {
                                                if (t === "alpha" || n[t] == null) return;
                                                u[o] = s.to(u._rgba);
                                            }
                                            u[o][e.idx] = i(n[t], e, !0);
                                        }),
                                        u[o] &&
                                                    t.inArray(null, u[o].slice(0, 3)) < 0 &&
                                                    ((u[o][3] = 1),
                                                    s.from && (u._rgba = s.from(u[o])));
                                    }),
                                this)
                                : e
                );
            },
            is(t) {
                const i = l(t);
                let s = !0;
                const n = this;
                return (
                    f(c, (t, o) => {
                        let a;
                        const r = i[o.cache];
                        return (
                            r &&
                                        ((a = n[o.cache] || (o.to && o.to(n._rgba)) || []),
                                        f(o.props, (t, i) =>
                                            r[i.idx] != null ? (s = r[i.idx] === a[i.idx]) : e,
                                        )),
                            s
                        );
                    }),
                    s
                );
            },
            _space() {
                const t = [];
                const e = this;
                return (
                    f(c, (i, s) => {
                        e[s.cache] && t.push(i);
                    }),
                    t.pop()
                );
            },
            transition(t, e) {
                let s = l(t);
                const n = s._space();
                const o = c[n];
                const a = this.alpha() === 0 ? l("transparent") : this;
                const r = a[o.cache] || o.to(a._rgba);
                const h = r.slice();
                return (
                    (s = s[o.cache]),
                    f(o.props, (t, n) => {
                        const o = n.idx;
                        let a = r[o];
                        const l = s[o];
                        const c = u[n.type] || {};
                        l !== null &&
                                    (a === null
                                        ? (h[o] = l)
                                        : (c.mod &&
                                              (l - a > c.mod / 2
                                                  ? (a += c.mod)
                                                  : a - l > c.mod / 2 && (a -= c.mod)),
                                        (h[o] = i((l - a) * e + a, n))));
                    }),
                    this[n](h)
                );
            },
            blend(e) {
                if (this._rgba[3] === 1) return this;
                const i = this._rgba.slice();
                const s = i.pop();
                const n = l(e)._rgba;
                return l(t.map(i, (t, e) => (1 - s) * n[e] + s * t));
            },
            toRgbaString() {
                let e = "rgba(";
                const i = t.map(this._rgba, (t, e) => (t == null ? (e > 2 ? 1 : 0) : t));
                return i[3] === 1 && (i.pop(), (e = "rgb(")), `${e + i.join()})`;
            },
            toHslaString() {
                let e = "hsla(";
                const i = t.map(
                    this.hsla(),
                    (t, e) => (
                        t == null && (t = e > 2 ? 1 : 0),
                        e && e < 3 && (t = `${Math.round(100 * t)}%`),
                        t
                    ),
                );
                return i[3] === 1 && (i.pop(), (e = "hsl(")), `${e + i.join()})`;
            },
            toHexString(e) {
                const i = this._rgba.slice();
                const s = i.pop();
                return (
                    e && i.push(~~(255 * s)),
                    `#${t
                        .map(
                            i,
                            (t) => (
                                (t = (t || 0).toString(16)), t.length === 1 ? `0${t}` : t
                            ),
                        )
                        .join("")}`
                );
            },
            toString() {
                return this._rgba[3] === 0 ? "transparent" : this.toRgbaString();
            },
        })),
        (l.fn.parse.prototype = l.fn),
        (c.hsla.to = function (t) {
            if (t[0] == null || t[1] == null || t[2] == null)
                return [null, null, null, t[3]];
            let e;
            let i;
            const s = t[0] / 255;
            const n = t[1] / 255;
            const o = t[2] / 255;
            const a = t[3];
            const r = Math.max(s, n, o);
            const h = Math.min(s, n, o);
            const l = r - h;
            const c = r + h;
            const u = 0.5 * c;
            return (
                (e =
                            h === r
                                ? 0
                                : s === r
                                    ? (60 * (n - o)) / l + 360
                                    : n === r
                                        ? (60 * (o - s)) / l + 120
                                        : (60 * (s - n)) / l + 240),
                (i = l === 0 ? 0 : u <= 0.5 ? l / c : l / (2 - c)),
                [Math.round(e) % 360, i, u, a == null ? 1 : a]
            );
        }),
        (c.hsla.from = function (t) {
            if (t[0] == null || t[1] == null || t[2] == null)
                return [null, null, null, t[3]];
            const e = t[0] / 360;
            const i = t[1];
            const s = t[2];
            const o = t[3];
            const a = s <= 0.5 ? s * (1 + i) : s + i - s * i;
            const r = 2 * s - a;
            return [
                Math.round(255 * n(r, a, e + 1 / 3)),
                Math.round(255 * n(r, a, e)),
                Math.round(255 * n(r, a, e - 1 / 3)),
                o,
            ];
        }),
        f(c, (s, n) => {
            const o = n.props;
            const a = n.cache;
            const h = n.to;
            const c = n.from;
            (l.fn[s] = function (s) {
                if ((h && !this[a] && (this[a] = h(this._rgba)), s === e))
                    return this[a].slice();
                let n;
                const r = t.type(s);
                const u = r === "array" || r === "object" ? s : arguments;
                const d = this[a].slice();
                return (
                    f(o, (t, e) => {
                        let s = u[r === "object" ? t : e.idx];
                        s == null && (s = d[e.idx]), (d[e.idx] = i(s, e));
                    }),
                    c ? ((n = l(c(d))), (n[a] = d), n) : l(d)
                );
            }),
            f(o, (e, i) => {
                l.fn[e] ||
                                (l.fn[e] = function (n) {
                                    let o;
                                    let a = t.type(n);
                                    const h = e === "alpha" ? (this._hsla ? "hsla" : "rgba") : s;
                                    const l = this[h]();
                                    const c = l[i.idx];
                                    return a === "undefined"
                                        ? c
                                        : (a === "function" &&
                                              ((n = n.call(this, c)), (a = t.type(n))),
                                        n == null && i.empty
                                            ? this
                                            : (a === "string" &&
                                                    ((o = r.exec(n)),
                                                    o &&
                                                        (n =
                                                            c +
                                                            parseFloat(o[2]) *
                                                                (o[1] === "+" ? 1 : -1))),
                                            (l[i.idx] = n),
                                            this[h](l)));
                                });
            });
        }),
        (l.hook = function (e) {
            const i = e.split(" ");
            f(i, (e, i) => {
                (t.cssHooks[i] = {
                    set(e, n) {
                        let o;
                        let a;
                        let r = "";
                        if (n !== "transparent" && (t.type(n) !== "string" || (o = s(n)))) {
                            if (((n = l(o || n)), !d.rgba && n._rgba[3] !== 1)) {
                                for (
                                    a = i === "backgroundColor" ? e.parentNode : e;
                                    (r === "" || r === "transparent") && a && a.style;

                                )
                                    try {
                                        (r = t.css(a, "backgroundColor")),
                                        (a = a.parentNode);
                                    } catch (h) {}
                                n = n.blend(r && r !== "transparent" ? r : "_default");
                            }
                            n = n.toRgbaString();
                        }
                        try {
                            e.style[i] = n;
                        } catch (h) {}
                    },
                }),
                (t.fx.step[i] = function (e) {
                    e.colorInit ||
                                    ((e.start = l(e.elem, i)),
                                    (e.end = l(e.end)),
                                    (e.colorInit = !0)),
                    t.cssHooks[i].set(e.elem, e.start.transition(e.end, e.pos));
                });
            });
        }),
        l.hook(a),
        (t.cssHooks.borderColor = {
            expand(t) {
                const e = {};
                return (
                    f(["Top", "Right", "Bottom", "Left"], (i, s) => {
                        e[`border${s}Color`] = t;
                    }),
                    e
                );
            },
        }),
        (o = t.Color.names =
                    {
                        aqua: "#00ffff",
                        black: "#000000",
                        blue: "#0000ff",
                        fuchsia: "#ff00ff",
                        gray: "#808080",
                        green: "#008000",
                        lime: "#00ff00",
                        maroon: "#800000",
                        navy: "#000080",
                        olive: "#808000",
                        purple: "#800080",
                        red: "#ff0000",
                        silver: "#c0c0c0",
                        teal: "#008080",
                        white: "#ffffff",
                        yellow: "#ffff00",
                        transparent: [null, null, null, 0],
                        _default: "#ffffff",
                    });
    })(p),
    (function () {
        function e(e) {
            let i;
            let s;
            const n = e.ownerDocument.defaultView
                ? e.ownerDocument.defaultView.getComputedStyle(e, null)
                : e.currentStyle;
            const o = {};
            if (n && n.length && n[0] && n[n[0]])
                for (s = n.length; s--; )
                    (i = n[s]), typeof n[i] === "string" && (o[t.camelCase(i)] = n[i]);
            else for (i in n) typeof n[i] === "string" && (o[i] = n[i]);
            return o;
        }
        function i(e, i) {
            let s;
            let o;
            const a = {};
            for (s in i)
                (o = i[s]),
                e[s] !== o &&
                            (n[s] || ((t.fx.step[s] || !isNaN(parseFloat(o))) && (a[s] = o)));
            return a;
        }
        const s = ["add", "remove", "toggle"];
        var n = {
            border: 1,
            borderBottom: 1,
            borderColor: 1,
            borderLeft: 1,
            borderRight: 1,
            borderTop: 1,
            borderWidth: 1,
            margin: 1,
            padding: 1,
        };
        t.each(
            ["borderLeftStyle", "borderRightStyle", "borderBottomStyle", "borderTopStyle"],
            (e, i) => {
                t.fx.step[i] = function (t) {
                    ((t.end !== "none" && !t.setAttr) || (t.pos === 1 && !t.setAttr)) &&
                            (p.style(t.elem, i, t.end), (t.setAttr = !0));
                };
            },
        ),
        t.fn.addBack ||
                    (t.fn.addBack = function (t) {
                        return this.add(t == null ? this.prevObject : this.prevObject.filter(t));
                    }),
        (t.effects.animateClass = function (n, o, a, r) {
            const h = t.speed(o, a, r);
            return this.queue(function () {
                let o;
                const a = t(this);
                const r = a.attr("class") || "";
                let l = h.children ? a.find("*").addBack() : a;
                (l = l.map(function () {
                    const i = t(this);
                    return { el: i, start: e(this) };
                })),
                (o = function () {
                    t.each(s, (t, e) => {
                        n[e] && a[`${e}Class`](n[e]);
                    });
                }),
                o(),
                (l = l.map(function () {
                    return (
                        (this.end = e(this.el[0])),
                        (this.diff = i(this.start, this.end)),
                        this
                    );
                })),
                a.attr("class", r),
                (l = l.map(function () {
                    const e = this;
                    const i = t.Deferred();
                    const s = t.extend({}, h, {
                        queue: !1,
                        complete() {
                            i.resolve(e);
                        },
                    });
                    return this.el.animate(this.diff, s), i.promise();
                })),
                t.when.apply(t, l.get()).done(function () {
                    o(),
                    t.each(arguments, function () {
                        const e = this.el;
                        t.each(this.diff, (t) => {
                            e.css(t, "");
                        });
                    }),
                    h.complete.call(a[0]);
                });
            });
        }),
        t.fn.extend({
            addClass: (function (e) {
                return function (i, s, n, o) {
                    return s
                        ? t.effects.animateClass.call(this, { add: i }, s, n, o)
                        : e.apply(this, arguments);
                };
            })(t.fn.addClass),
            removeClass: (function (e) {
                return function (i, s, n, o) {
                    return arguments.length > 1
                        ? t.effects.animateClass.call(this, { remove: i }, s, n, o)
                        : e.apply(this, arguments);
                };
            })(t.fn.removeClass),
            toggleClass: (function (e) {
                return function (i, s, n, o, a) {
                    return typeof s === "boolean" || void 0 === s
                        ? n
                            ? t.effects.animateClass.call(
                                this,
                                s ? { add: i } : { remove: i },
                                n,
                                o,
                                a,
                            )
                            : e.apply(this, arguments)
                        : t.effects.animateClass.call(this, { toggle: i }, s, n, o);
                };
            })(t.fn.toggleClass),
            switchClass(e, i, s, n, o) {
                return t.effects.animateClass.call(this, { add: i, remove: e }, s, n, o);
            },
        });
    })(),
    (function () {
        function e(e, i, s, n) {
            return (
                t.isPlainObject(e) && ((i = e), (e = e.effect)),
                (e = { effect: e }),
                i == null && (i = {}),
                t.isFunction(i) && ((n = i), (s = null), (i = {})),
                (typeof i === "number" || t.fx.speeds[i]) && ((n = s), (s = i), (i = {})),
                t.isFunction(s) && ((n = s), (s = null)),
                i && t.extend(e, i),
                (s = s || i.duration),
                (e.duration = t.fx.off
                    ? 0
                    : typeof s === "number"
                        ? s
                        : s in t.fx.speeds
                            ? t.fx.speeds[s]
                            : t.fx.speeds._default),
                (e.complete = n || i.complete),
                e
            );
        }
        function i(e) {
            return !e || typeof e === "number" || t.fx.speeds[e]
                ? !0
                : typeof e !== "string" || t.effects.effect[e]
                    ? t.isFunction(e)
                        ? !0
                        : typeof e !== "object" || e.effect
                            ? !1
                            : !0
                    : !0;
        }
        function s(t, e) {
            const i = e.outerWidth();
            const s = e.outerHeight();
            const n =
                    /^rect\((-?\d*\.?\d*px|-?\d+%|auto),?\s*(-?\d*\.?\d*px|-?\d+%|auto),?\s*(-?\d*\.?\d*px|-?\d+%|auto),?\s*(-?\d*\.?\d*px|-?\d+%|auto)\)$/;
            const o = n.exec(t) || ["", 0, i, s, 0];
            return {
                top: parseFloat(o[1]) || 0,
                right: o[2] === "auto" ? i : parseFloat(o[2]),
                bottom: o[3] === "auto" ? s : parseFloat(o[3]),
                left: parseFloat(o[4]) || 0,
            };
        }
        t.expr &&
                t.expr.filters &&
                t.expr.filters.animated &&
                (t.expr.filters.animated = (function (e) {
                    return function (i) {
                        return !!t(i).data(d) || e(i);
                    };
                })(t.expr.filters.animated)),
        t.uiBackCompat !== !1 &&
                    t.extend(t.effects, {
                        save(t, e) {
                            for (let i = 0, s = e.length; s > i; i++)
                                e[i] !== null && t.data(c + e[i], t[0].style[e[i]]);
                        },
                        restore(t, e) {
                            for (var i, s = 0, n = e.length; n > s; s++)
                                e[s] !== null && ((i = t.data(c + e[s])), t.css(e[s], i));
                        },
                        setMode(t, e) {
                            return e === "toggle" && (e = t.is(":hidden") ? "show" : "hide"), e;
                        },
                        createWrapper(e) {
                            if (e.parent().is(".ui-effects-wrapper")) return e.parent();
                            const i = {
                                width: e.outerWidth(!0),
                                height: e.outerHeight(!0),
                                float: e.css("float"),
                            };
                            let s = t("<div></div>").addClass("ui-effects-wrapper").css({
                                fontSize: "100%",
                                background: "transparent",
                                border: "none",
                                margin: 0,
                                padding: 0,
                            });
                            const n = { width: e.width(), height: e.height() };
                            let o = document.activeElement;
                            try {
                                o.id;
                            } catch (a) {
                                o = document.body;
                            }
                            return (
                                e.wrap(s),
                                (e[0] === o || t.contains(e[0], o)) && t(o).trigger("focus"),
                                (s = e.parent()),
                                e.css("position") === "static"
                                    ? (s.css({ position: "relative" }),
                                    e.css({ position: "relative" }))
                                    : (t.extend(i, {
                                        position: e.css("position"),
                                        zIndex: e.css("z-index"),
                                    }),
                                    t.each(["top", "left", "bottom", "right"], (t, s) => {
                                        (i[s] = e.css(s)),
                                        isNaN(parseInt(i[s], 10)) && (i[s] = "auto");
                                    }),
                                    e.css({
                                        position: "relative",
                                        top: 0,
                                        left: 0,
                                        right: "auto",
                                        bottom: "auto",
                                    })),
                                e.css(n),
                                s.css(i).show()
                            );
                        },
                        removeWrapper(e) {
                            const i = document.activeElement;
                            return (
                                e.parent().is(".ui-effects-wrapper") &&
                                    (e.parent().replaceWith(e),
                                    (e[0] === i || t.contains(e[0], i)) && t(i).trigger("focus")),
                                e
                            );
                        },
                    }),
        t.extend(t.effects, {
            version: "1.12.1",
            define(e, i, s) {
                return (
                    s || ((s = i), (i = "effect")),
                    (t.effects.effect[e] = s),
                    (t.effects.effect[e].mode = i),
                    s
                );
            },
            scaledDimensions(t, e, i) {
                if (e === 0) {
                    return {
                        height: 0,
                        width: 0,
                        outerHeight: 0,
                        outerWidth: 0,
                    };
                }
                const s = i !== "horizontal" ? (e || 100) / 100 : 1;
                const n = i !== "vertical" ? (e || 100) / 100 : 1;
                return {
                    height: t.height() * n,
                    width: t.width() * s,
                    outerHeight: t.outerHeight() * n,
                    outerWidth: t.outerWidth() * s,
                };
            },
            clipToBox(t) {
                return {
                    width: t.clip.right - t.clip.left,
                    height: t.clip.bottom - t.clip.top,
                    left: t.clip.left,
                    top: t.clip.top,
                };
            },
            unshift(t, e, i) {
                const s = t.queue();
                e > 1 && s.splice.apply(s, [1, 0].concat(s.splice(e, i))), t.dequeue();
            },
            saveStyle(t) {
                t.data(u, t[0].style.cssText);
            },
            restoreStyle(t) {
                (t[0].style.cssText = t.data(u) || ""), t.removeData(u);
            },
            mode(t, e) {
                const i = t.is(":hidden");
                return (
                    e === "toggle" && (e = i ? "show" : "hide"),
                    (i ? e === "hide" : e === "show") && (e = "none"),
                    e
                );
            },
            getBaseline(t, e) {
                let i;
                let s;
                switch (t[0]) {
                case "top":
                    i = 0;
                    break;
                case "middle":
                    i = 0.5;
                    break;
                case "bottom":
                    i = 1;
                    break;
                default:
                    i = t[0] / e.height;
                }
                switch (t[1]) {
                case "left":
                    s = 0;
                    break;
                case "center":
                    s = 0.5;
                    break;
                case "right":
                    s = 1;
                    break;
                default:
                    s = t[1] / e.width;
                }
                return { x: s, y: i };
            },
            createPlaceholder(e) {
                let i;
                let s = e.css("position");
                const n = e.position();
                return (
                    e
                        .css({
                            marginTop: e.css("marginTop"),
                            marginBottom: e.css("marginBottom"),
                            marginLeft: e.css("marginLeft"),
                            marginRight: e.css("marginRight"),
                        })
                        .outerWidth(e.outerWidth())
                        .outerHeight(e.outerHeight()),
                    /^(static|relative)/.test(s) &&
                                ((s = "absolute"),
                                (i = t(`<${e[0].nodeName}>`)
                                    .insertAfter(e)
                                    .css({
                                        display: /^(inline|ruby)/.test(e.css("display"))
                                            ? "inline-block"
                                            : "block",
                                        visibility: "hidden",
                                        marginTop: e.css("marginTop"),
                                        marginBottom: e.css("marginBottom"),
                                        marginLeft: e.css("marginLeft"),
                                        marginRight: e.css("marginRight"),
                                        float: e.css("float"),
                                    })
                                    .outerWidth(e.outerWidth())
                                    .outerHeight(e.outerHeight())
                                    .addClass("ui-effects-placeholder")),
                                e.data(`${c}placeholder`, i)),
                    e.css({ position: s, left: n.left, top: n.top }),
                    i
                );
            },
            removePlaceholder(t) {
                const e = `${c}placeholder`;
                const i = t.data(e);
                i && (i.remove(), t.removeData(e));
            },
            cleanUp(e) {
                t.effects.restoreStyle(e), t.effects.removePlaceholder(e);
            },
            setTransition(e, i, s, n) {
                return (
                    (n = n || {}),
                    t.each(i, (t, i) => {
                        const o = e.cssUnit(i);
                        o[0] > 0 && (n[i] = o[0] * s + o[1]);
                    }),
                    n
                );
            },
        }),
        t.fn.extend({
            effect() {
                function i(e) {
                    function i() {
                        r.removeData(d),
                        t.effects.cleanUp(r),
                        s.mode === "hide" && r.hide(),
                        a();
                    }
                    function a() {
                        t.isFunction(h) && h.call(r[0]), t.isFunction(e) && e();
                    }
                    var r = t(this);
                    (s.mode = c.shift()),
                    t.uiBackCompat === !1 || o
                        ? s.mode === "none"
                            ? (r[l](), a())
                            : n.call(r[0], s, i)
                        : (r.is(":hidden") ? l === "hide" : l === "show")
                            ? (r[l](), a())
                            : n.call(r[0], s, a);
                }
                var s = e.apply(this, arguments);
                var n = t.effects.effect[s.effect];
                var o = n.mode;
                const a = s.queue;
                const r = a || "fx";
                var h = s.complete;
                var l = s.mode;
                var c = [];
                const u = function (e) {
                    const i = t(this);
                    const s = t.effects.mode(i, l) || o;
                    i.data(d, !0),
                    c.push(s),
                    o && (s === "show" || (s === o && s === "hide")) && i.show(),
                    (o && s === "none") || t.effects.saveStyle(i),
                    t.isFunction(e) && e();
                };
                return t.fx.off || !n
                    ? l
                        ? this[l](s.duration, h)
                        : this.each(function () {
                            h && h.call(this);
                        })
                    : a === !1
                        ? this.each(u).each(i)
                        : this.queue(r, u).queue(r, i);
            },
            show: (function (t) {
                return function (s) {
                    if (i(s)) return t.apply(this, arguments);
                    const n = e.apply(this, arguments);
                    return (n.mode = "show"), this.effect.call(this, n);
                };
            })(t.fn.show),
            hide: (function (t) {
                return function (s) {
                    if (i(s)) return t.apply(this, arguments);
                    const n = e.apply(this, arguments);
                    return (n.mode = "hide"), this.effect.call(this, n);
                };
            })(t.fn.hide),
            toggle: (function (t) {
                return function (s) {
                    if (i(s) || typeof s === "boolean") return t.apply(this, arguments);
                    const n = e.apply(this, arguments);
                    return (n.mode = "toggle"), this.effect.call(this, n);
                };
            })(t.fn.toggle),
            cssUnit(e) {
                const i = this.css(e);
                let s = [];
                return (
                    t.each(["em", "px", "%", "pt"], (t, e) => {
                        i.indexOf(e) > 0 && (s = [parseFloat(i), e]);
                    }),
                    s
                );
            },
            cssClip(t) {
                return t
                    ? this.css(
                        "clip",
                        `rect(${t.top}px ${t.right}px ${t.bottom}px ${t.left}px)`,
                    )
                    : s(this.css("clip"), this);
            },
            transfer(e, i) {
                const s = t(this);
                const n = t(e.to);
                const o = n.css("position") === "fixed";
                const a = t("body");
                const r = o ? a.scrollTop() : 0;
                const h = o ? a.scrollLeft() : 0;
                const l = n.offset();
                const c = {
                    top: l.top - r,
                    left: l.left - h,
                    height: n.innerHeight(),
                    width: n.innerWidth(),
                };
                const u = s.offset();
                var d = t("<div class='ui-effects-transfer'></div>")
                    .appendTo("body")
                    .addClass(e.className)
                    .css({
                        top: u.top - r,
                        left: u.left - h,
                        height: s.innerHeight(),
                        width: s.innerWidth(),
                        position: o ? "fixed" : "absolute",
                    })
                    .animate(c, e.duration, e.easing, () => {
                        d.remove(), t.isFunction(i) && i();
                    });
            },
        }),
        (t.fx.step.clip = function (e) {
            e.clipInit ||
                        ((e.start = t(e.elem).cssClip()),
                        typeof e.end === "string" && (e.end = s(e.end, e.elem)),
                        (e.clipInit = !0)),
            t(e.elem).cssClip({
                top: e.pos * (e.end.top - e.start.top) + e.start.top,
                right: e.pos * (e.end.right - e.start.right) + e.start.right,
                bottom: e.pos * (e.end.bottom - e.start.bottom) + e.start.bottom,
                left: e.pos * (e.end.left - e.start.left) + e.start.left,
            });
        });
    })(),
    (function () {
        const e = {};
        t.each(["Quad", "Cubic", "Quart", "Quint", "Expo"], (t, i) => {
            e[i] = function (e) {
                return Math.pow(e, t + 2);
            };
        }),
        t.extend(e, {
            Sine(t) {
                return 1 - Math.cos((t * Math.PI) / 2);
            },
            Circ(t) {
                return 1 - Math.sqrt(1 - t * t);
            },
            Elastic(t) {
                return t === 0 || t === 1
                    ? t
                    : -Math.pow(2, 8 * (t - 1)) *
                                  Math.sin(((80 * (t - 1) - 7.5) * Math.PI) / 15);
            },
            Back(t) {
                return t * t * (3 * t - 2);
            },
            Bounce(t) {
                for (var e, i = 4; ((e = Math.pow(2, --i)) - 1) / 11 > t; );
                return 1 / Math.pow(4, 3 - i) - 7.5625 * Math.pow((3 * e - 2) / 22 - t, 2);
            },
        }),
        t.each(e, (e, i) => {
            (t.easing[`easeIn${e}`] = i),
            (t.easing[`easeOut${e}`] = function (t) {
                return 1 - i(1 - t);
            }),
            (t.easing[`easeInOut${e}`] = function (t) {
                return t < 0.5 ? i(2 * t) / 2 : 1 - i(-2 * t + 2) / 2;
            });
        });
    })();
    var f = t.effects;
    t.effects.define("blind", "hide", function (e, i) {
        const s = {
            up: ["bottom", "top"],
            vertical: ["bottom", "top"],
            down: ["top", "bottom"],
            left: ["right", "left"],
            horizontal: ["right", "left"],
            right: ["left", "right"],
        };
        const n = t(this);
        const o = e.direction || "up";
        const a = n.cssClip();
        const r = { clip: t.extend({}, a) };
        const h = t.effects.createPlaceholder(n);
        (r.clip[s[o][0]] = r.clip[s[o][1]]),
        e.mode === "show" &&
                (n.cssClip(r.clip), h && h.css(t.effects.clipToBox(r)), (r.clip = a)),
        h && h.animate(t.effects.clipToBox(r), e.duration, e.easing),
        n.animate(r, {
            queue: !1,
            duration: e.duration,
            easing: e.easing,
            complete: i,
        });
    }),
    t.effects.define("bounce", function (e, i) {
        let s;
        let n;
        let o;
        const a = t(this);
        const r = e.mode;
        const h = r === "hide";
        const l = r === "show";
        const c = e.direction || "up";
        let u = e.distance;
        const d = e.times || 5;
        const p = 2 * d + (l || h ? 1 : 0);
        const f = e.duration / p;
        const g = e.easing;
        const m = c === "up" || c === "down" ? "top" : "left";
        const _ = c === "up" || c === "left";
        let v = 0;
        const b = a.queue().length;
        for (
            t.effects.createPlaceholder(a),
            o = a.css(m),
            u || (u = a[m === "top" ? "outerHeight" : "outerWidth"]() / 3),
            l &&
                        ((n = { opacity: 1 }),
                        (n[m] = o),
                        a
                            .css("opacity", 0)
                            .css(m, _ ? 2 * -u : 2 * u)
                            .animate(n, f, g)),
            h && (u /= Math.pow(2, d - 1)),
            n = {},
            n[m] = o;
            d > v;
            v++
        )
            (s = {}),
            (s[m] = (_ ? "-=" : "+=") + u),
            a.animate(s, f, g).animate(n, f, g),
            (u = h ? 2 * u : u / 2);
        h && ((s = { opacity: 0 }), (s[m] = (_ ? "-=" : "+=") + u), a.animate(s, f, g)),
        a.queue(i),
        t.effects.unshift(a, b, p + 1);
    }),
    t.effects.define("clip", "hide", function (e, i) {
        let s;
        const n = {};
        const o = t(this);
        const a = e.direction || "vertical";
        const r = a === "both";
        const h = r || a === "horizontal";
        const l = r || a === "vertical";
        (s = o.cssClip()),
        (n.clip = {
            top: l ? (s.bottom - s.top) / 2 : s.top,
            right: h ? (s.right - s.left) / 2 : s.right,
            bottom: l ? (s.bottom - s.top) / 2 : s.bottom,
            left: h ? (s.right - s.left) / 2 : s.left,
        }),
        t.effects.createPlaceholder(o),
        e.mode === "show" && (o.cssClip(n.clip), (n.clip = s)),
        o.animate(n, {
            queue: !1,
            duration: e.duration,
            easing: e.easing,
            complete: i,
        });
    }),
    t.effects.define("drop", "hide", function (e, i) {
        let s;
        const n = t(this);
        const o = e.mode;
        const a = o === "show";
        const r = e.direction || "left";
        const h = r === "up" || r === "down" ? "top" : "left";
        const l = r === "up" || r === "left" ? "-=" : "+=";
        const c = l === "+=" ? "-=" : "+=";
        const u = { opacity: 0 };
        t.effects.createPlaceholder(n),
        (s = e.distance || n[h === "top" ? "outerHeight" : "outerWidth"](!0) / 2),
        (u[h] = l + s),
        a && (n.css(u), (u[h] = c + s), (u.opacity = 1)),
        n.animate(u, {
            queue: !1,
            duration: e.duration,
            easing: e.easing,
            complete: i,
        });
    }),
    t.effects.define("explode", "hide", function (e, i) {
        function s() {
            b.push(this), b.length === u * d && n();
        }
        function n() {
            p.css({ visibility: "visible" }), t(b).remove(), i();
        }
        let o;
        let a;
        let r;
        let h;
        let l;
        let c;
        var u = e.pieces ? Math.round(Math.sqrt(e.pieces)) : 3;
        var d = u;
        var p = t(this);
        const f = e.mode;
        const g = f === "show";
        const m = p.show().css("visibility", "hidden").offset();
        const _ = Math.ceil(p.outerWidth() / d);
        const v = Math.ceil(p.outerHeight() / u);
        var b = [];
        for (o = 0; u > o; o++) {
            for (h = m.top + o * v, c = o - (u - 1) / 2, a = 0; d > a; a++) {
                (r = m.left + a * _),
                (l = a - (d - 1) / 2),
                p
                    .clone()
                    .appendTo("body")
                    .wrap("<div></div>")
                    .css({
                        position: "absolute",
                        visibility: "visible",
                        left: -a * _,
                        top: -o * v,
                    })
                    .parent()
                    .addClass("ui-effects-explode")
                    .css({
                        position: "absolute",
                        overflow: "hidden",
                        width: _,
                        height: v,
                        left: r + (g ? l * _ : 0),
                        top: h + (g ? c * v : 0),
                        opacity: g ? 0 : 1,
                    })
                    .animate(
                        {
                            left: r + (g ? 0 : l * _),
                            top: h + (g ? 0 : c * v),
                            opacity: g ? 1 : 0,
                        },
                        e.duration || 500,
                        e.easing,
                        s,
                    );
            }
        }
    }),
    t.effects.define("fade", "toggle", function (e, i) {
        const s = e.mode === "show";
        t(this)
            .css("opacity", s ? 0 : 1)
            .animate(
                { opacity: s ? 1 : 0 },
                {
                    queue: !1,
                    duration: e.duration,
                    easing: e.easing,
                    complete: i,
                },
            );
    }),
    t.effects.define("fold", "hide", function (e, i) {
        const s = t(this);
        const n = e.mode;
        const o = n === "show";
        const a = n === "hide";
        let r = e.size || 15;
        const h = /([0-9]+)%/.exec(r);
        const l = !!e.horizFirst;
        const c = l ? ["right", "bottom"] : ["bottom", "right"];
        const u = e.duration / 2;
        const d = t.effects.createPlaceholder(s);
        const p = s.cssClip();
        const f = { clip: t.extend({}, p) };
        const g = { clip: t.extend({}, p) };
        const m = [p[c[0]], p[c[1]]];
        const _ = s.queue().length;
        h && (r = (parseInt(h[1], 10) / 100) * m[a ? 0 : 1]),
        (f.clip[c[0]] = r),
        (g.clip[c[0]] = r),
        (g.clip[c[1]] = 0),
        o && (s.cssClip(g.clip), d && d.css(t.effects.clipToBox(g)), (g.clip = p)),
        s
            .queue((i) => {
                d &&
                            d
                                .animate(t.effects.clipToBox(f), u, e.easing)
                                .animate(t.effects.clipToBox(g), u, e.easing),
                i();
            })
            .animate(f, u, e.easing)
            .animate(g, u, e.easing)
            .queue(i),
        t.effects.unshift(s, _, 4);
    }),
    t.effects.define("highlight", "show", function (e, i) {
        const s = t(this);
        const n = { backgroundColor: s.css("backgroundColor") };
        e.mode === "hide" && (n.opacity = 0),
        t.effects.saveStyle(s),
        s
            .css({ backgroundImage: "none", backgroundColor: e.color || "#ffff99" })
            .animate(n, {
                queue: !1,
                duration: e.duration,
                easing: e.easing,
                complete: i,
            });
    }),
    t.effects.define("size", function (e, i) {
        let s;
        let n;
        let o;
        const a = t(this);
        const r = ["fontSize"];
        let h = ["borderTopWidth", "borderBottomWidth", "paddingTop", "paddingBottom"];
        let l = ["borderLeftWidth", "borderRightWidth", "paddingLeft", "paddingRight"];
        const c = e.mode;
        const u = c !== "effect";
        const d = e.scale || "both";
        const p = e.origin || ["middle", "center"];
        const f = a.css("position");
        const g = a.position();
        const m = t.effects.scaledDimensions(a);
        let _ = e.from || m;
        let v = e.to || t.effects.scaledDimensions(a, 0);
        t.effects.createPlaceholder(a),
        c === "show" && ((o = _), (_ = v), (v = o)),
        (n = {
            from: { y: _.height / m.height, x: _.width / m.width },
            to: { y: v.height / m.height, x: v.width / m.width },
        }),
        (d === "box" || d === "both") &&
                    (n.from.y !== n.to.y &&
                        ((_ = t.effects.setTransition(a, h, n.from.y, _)),
                        (v = t.effects.setTransition(a, h, n.to.y, v))),
                    n.from.x !== n.to.x &&
                        ((_ = t.effects.setTransition(a, l, n.from.x, _)),
                        (v = t.effects.setTransition(a, l, n.to.x, v)))),
        (d === "content" || d === "both") &&
                    n.from.y !== n.to.y &&
                    ((_ = t.effects.setTransition(a, r, n.from.y, _)),
                    (v = t.effects.setTransition(a, r, n.to.y, v))),
        p &&
                    ((s = t.effects.getBaseline(p, m)),
                    (_.top = (m.outerHeight - _.outerHeight) * s.y + g.top),
                    (_.left = (m.outerWidth - _.outerWidth) * s.x + g.left),
                    (v.top = (m.outerHeight - v.outerHeight) * s.y + g.top),
                    (v.left = (m.outerWidth - v.outerWidth) * s.x + g.left)),
        a.css(_),
        (d === "content" || d === "both") &&
                    ((h = h.concat(["marginTop", "marginBottom"]).concat(r)),
                    (l = l.concat(["marginLeft", "marginRight"])),
                    a.find("*[width]").each(function () {
                        const i = t(this);
                        const s = t.effects.scaledDimensions(i);
                        let o = {
                            height: s.height * n.from.y,
                            width: s.width * n.from.x,
                            outerHeight: s.outerHeight * n.from.y,
                            outerWidth: s.outerWidth * n.from.x,
                        };
                        let a = {
                            height: s.height * n.to.y,
                            width: s.width * n.to.x,
                            outerHeight: s.height * n.to.y,
                            outerWidth: s.width * n.to.x,
                        };
                        n.from.y !== n.to.y &&
                            ((o = t.effects.setTransition(i, h, n.from.y, o)),
                            (a = t.effects.setTransition(i, h, n.to.y, a))),
                        n.from.x !== n.to.x &&
                                ((o = t.effects.setTransition(i, l, n.from.x, o)),
                                (a = t.effects.setTransition(i, l, n.to.x, a))),
                        u && t.effects.saveStyle(i),
                        i.css(o),
                        i.animate(a, e.duration, e.easing, () => {
                            u && t.effects.restoreStyle(i);
                        });
                    })),
        a.animate(v, {
            queue: !1,
            duration: e.duration,
            easing: e.easing,
            complete() {
                const e = a.offset();
                v.opacity === 0 && a.css("opacity", _.opacity),
                u ||
                                (a.css("position", f === "static" ? "relative" : f).offset(e),
                                t.effects.saveStyle(a)),
                i();
            },
        });
    }),
    t.effects.define("scale", function (e, i) {
        const s = t(this);
        const n = e.mode;
        const o =
                parseInt(e.percent, 10) ||
                (parseInt(e.percent, 10) === 0 ? 0 : n !== "effect" ? 0 : 100);
        const a = t.extend(
            !0,
            {
                from: t.effects.scaledDimensions(s),
                to: t.effects.scaledDimensions(s, o, e.direction || "both"),
                origin: e.origin || ["middle", "center"],
            },
            e,
        );
        e.fade && ((a.from.opacity = 1), (a.to.opacity = 0)),
        t.effects.effect.size.call(this, a, i);
    }),
    t.effects.define("puff", "hide", function (e, i) {
        const s = t.extend(!0, {}, e, { fade: !0, percent: parseInt(e.percent, 10) || 150 });
        t.effects.effect.scale.call(this, s, i);
    }),
    t.effects.define("pulsate", "show", function (e, i) {
        const s = t(this);
        const n = e.mode;
        const o = n === "show";
        const a = n === "hide";
        const r = o || a;
        const h = 2 * (e.times || 5) + (r ? 1 : 0);
        const l = e.duration / h;
        let c = 0;
        let u = 1;
        const d = s.queue().length;
        for ((o || !s.is(":visible")) && (s.css("opacity", 0).show(), (c = 1)); h > u; u++)
            s.animate({ opacity: c }, l, e.easing), (c = 1 - c);
        s.animate({ opacity: c }, l, e.easing), s.queue(i), t.effects.unshift(s, d, h + 1);
    }),
    t.effects.define("shake", function (e, i) {
        let s = 1;
        const n = t(this);
        const o = e.direction || "left";
        const a = e.distance || 20;
        const r = e.times || 3;
        const h = 2 * r + 1;
        const l = Math.round(e.duration / h);
        const c = o === "up" || o === "down" ? "top" : "left";
        const u = o === "up" || o === "left";
        const d = {};
        const p = {};
        const f = {};
        const g = n.queue().length;
        for (
            t.effects.createPlaceholder(n),
            d[c] = (u ? "-=" : "+=") + a,
            p[c] = (u ? "+=" : "-=") + 2 * a,
            f[c] = (u ? "-=" : "+=") + 2 * a,
            n.animate(d, l, e.easing);
            r > s;
            s++
        )
            n.animate(p, l, e.easing).animate(f, l, e.easing);
        n
            .animate(p, l, e.easing)
            .animate(d, l / 2, e.easing)
            .queue(i),
        t.effects.unshift(n, g, h + 1);
    }),
    t.effects.define("slide", "show", function (e, i) {
        let s;
        let n;
        const o = t(this);
        const a = {
            up: ["bottom", "top"],
            down: ["top", "bottom"],
            left: ["right", "left"],
            right: ["left", "right"],
        };
        const r = e.mode;
        const h = e.direction || "left";
        const l = h === "up" || h === "down" ? "top" : "left";
        const c = h === "up" || h === "left";
        const u = e.distance || o[l === "top" ? "outerHeight" : "outerWidth"](!0);
        const d = {};
        t.effects.createPlaceholder(o),
        (s = o.cssClip()),
        (n = o.position()[l]),
        (d[l] = (c ? -1 : 1) * u + n),
        (d.clip = o.cssClip()),
        (d.clip[a[h][1]] = d.clip[a[h][0]]),
        r === "show" && (o.cssClip(d.clip), o.css(l, d[l]), (d.clip = s), (d[l] = n)),
        o.animate(d, {
            queue: !1,
            duration: e.duration,
            easing: e.easing,
            complete: i,
        });
    });
    var f;
    t.uiBackCompat !== !1 &&
        (f = t.effects.define("transfer", function (e, i) {
            t(this).transfer(e, i);
        })),
    (t.ui.focusable = function (i, s) {
        let n;
        let o;
        let a;
        let r;
        let h;
        const l = i.nodeName.toLowerCase();
        return l === "area"
            ? ((n = i.parentNode),
            (o = n.name),
            i.href && o && n.nodeName.toLowerCase() === "map"
                ? ((a = t(`img[usemap='#${o}']`)), a.length > 0 && a.is(":visible"))
                : !1)
            : (/^(input|select|textarea|button|object)$/.test(l)
                ? ((r = !i.disabled),
                r && ((h = t(i).closest("fieldset")[0]), h && (r = !h.disabled)))
                : (r = l === "a" ? i.href || s : s),
            r && t(i).is(":visible") && e(t(i)));
    }),
    t.extend(t.expr[":"], {
        focusable(e) {
            return t.ui.focusable(e, t.attr(e, "tabindex") != null);
        },
    }),
    t.ui.focusable,
    (t.fn.form = function () {
        return typeof this[0].form === "string" ? this.closest("form") : t(this[0].form);
    }),
    (t.ui.formResetMixin = {
        _formResetHandler() {
            const e = t(this);
            setTimeout(() => {
                const i = e.data("ui-form-reset-instances");
                t.each(i, function () {
                    this.refresh();
                });
            });
        },
        _bindFormResetHandler() {
            if (((this.form = this.element.form()), this.form.length)) {
                const t = this.form.data("ui-form-reset-instances") || [];
                t.length || this.form.on("reset.ui-form-reset", this._formResetHandler),
                t.push(this),
                this.form.data("ui-form-reset-instances", t);
            }
        },
        _unbindFormResetHandler() {
            if (this.form.length) {
                const e = this.form.data("ui-form-reset-instances");
                e.splice(t.inArray(this, e), 1),
                e.length
                    ? this.form.data("ui-form-reset-instances", e)
                    : this.form
                        .removeData("ui-form-reset-instances")
                        .off("reset.ui-form-reset");
            }
        },
    }),
    t.fn.jquery.substring(0, 3) === "1.7" &&
            (t.each(["Width", "Height"], (e, i) => {
                function s(e, i, s, o) {
                    return (
                        t.each(n, function () {
                            (i -= parseFloat(t.css(e, `padding${this}`)) || 0),
                            s && (i -= parseFloat(t.css(e, `border${this}Width`)) || 0),
                            o && (i -= parseFloat(t.css(e, `margin${this}`)) || 0);
                        }),
                        i
                    );
                }
                var n = i === "Width" ? ["Left", "Right"] : ["Top", "Bottom"];
                const o = i.toLowerCase();
                const a = {
                    innerWidth: t.fn.innerWidth,
                    innerHeight: t.fn.innerHeight,
                    outerWidth: t.fn.outerWidth,
                    outerHeight: t.fn.outerHeight,
                };
                (t.fn[`inner${i}`] = function (e) {
                    return void 0 === e
                        ? a[`inner${i}`].call(this)
                        : this.each(function () {
                            t(this).css(o, `${s(this, e)}px`);
                        });
                }),
                (t.fn[`outer${i}`] = function (e, n) {
                    return typeof e !== "number"
                        ? a[`outer${i}`].call(this, e)
                        : this.each(function () {
                            t(this).css(o, `${s(this, e, !0, n)}px`);
                        });
                });
            }),
            (t.fn.addBack = function (t) {
                return this.add(t == null ? this.prevObject : this.prevObject.filter(t));
            })),
    (t.ui.keyCode = {
        BACKSPACE: 8,
        COMMA: 188,
        DELETE: 46,
        DOWN: 40,
        END: 35,
        ENTER: 13,
        ESCAPE: 27,
        HOME: 36,
        LEFT: 37,
        PAGE_DOWN: 34,
        PAGE_UP: 33,
        PERIOD: 190,
        RIGHT: 39,
        SPACE: 32,
        TAB: 9,
        UP: 38,
    }),
    (t.ui.escapeSelector = (function () {
        const t = /([!"#$%&'()*+,.\/:;<=>?@[\]^`{|}~])/g;
        return function (e) {
            return e.replace(t, "\\$1");
        };
    })()),
    (t.fn.labels = function () {
        let e;
        let i;
        let s;
        let n;
        let o;
        return this[0].labels && this[0].labels.length
            ? this.pushStack(this[0].labels)
            : ((n = this.eq(0).parents("label")),
            (s = this.attr("id")),
            s &&
                      ((e = this.eq(0).parents().last()),
                      (o = e.add(e.length ? e.siblings() : this.siblings())),
                      (i = `label[for='${t.ui.escapeSelector(s)}']`),
                      (n = n.add(o.find(i).addBack(i)))),
            this.pushStack(n));
    }),
    (t.fn.scrollParent = function (e) {
        const i = this.css("position");
        const s = i === "absolute";
        const n = e ? /(auto|scroll|hidden)/ : /(auto|scroll)/;
        const o = this.parents()
            .filter(function () {
                const e = t(this);
                return s && e.css("position") === "static"
                    ? !1
                    : n.test(e.css("overflow") + e.css("overflow-y") + e.css("overflow-x"));
            })
            .eq(0);
        return i !== "fixed" && o.length ? o : t(this[0].ownerDocument || document);
    }),
    t.extend(t.expr[":"], {
        tabbable(e) {
            const i = t.attr(e, "tabindex");
            const s = i != null;
            return (!s || i >= 0) && t.ui.focusable(e, s);
        },
    }),
    t.fn.extend({
        uniqueId: (function () {
            let t = 0;
            return function () {
                return this.each(function () {
                    this.id || (this.id = `ui-id-${++t}`);
                });
            };
        })(),
        removeUniqueId() {
            return this.each(function () {
                /^ui-id-\d+$/.test(this.id) && t(this).removeAttr("id");
            });
        },
    }),
    t.widget("ui.accordion", {
        version: "1.12.1",
        options: {
            active: 0,
            animate: {},
            classes: {
                "ui-accordion-header": "ui-corner-top",
                "ui-accordion-header-collapsed": "ui-corner-all",
                "ui-accordion-content": "ui-corner-bottom",
            },
            collapsible: !1,
            event: "click",
            header: "> li > :first-child, > :not(li):even",
            heightStyle: "auto",
            icons: { activeHeader: "ui-icon-triangle-1-s", header: "ui-icon-triangle-1-e" },
            activate: null,
            beforeActivate: null,
        },
        hideProps: {
            borderTopWidth: "hide",
            borderBottomWidth: "hide",
            paddingTop: "hide",
            paddingBottom: "hide",
            height: "hide",
        },
        showProps: {
            borderTopWidth: "show",
            borderBottomWidth: "show",
            paddingTop: "show",
            paddingBottom: "show",
            height: "show",
        },
        _create() {
            const e = this.options;
            (this.prevShow = this.prevHide = t()),
            this._addClass("ui-accordion", "ui-widget ui-helper-reset"),
            this.element.attr("role", "tablist"),
            e.collapsible || (e.active !== !1 && e.active != null) || (e.active = 0),
            this._processPanels(),
            e.active < 0 && (e.active += this.headers.length),
            this._refresh();
        },
        _getCreateEventData() {
            return {
                header: this.active,
                panel: this.active.length ? this.active.next() : t(),
            };
        },
        _createIcons() {
            let e;
            let i;
            const s = this.options.icons;
            s &&
                    ((e = t("<span>")),
                    this._addClass(e, "ui-accordion-header-icon", `ui-icon ${s.header}`),
                    e.prependTo(this.headers),
                    (i = this.active.children(".ui-accordion-header-icon")),
                    this._removeClass(i, s.header)
                        ._addClass(i, null, s.activeHeader)
                        ._addClass(this.headers, "ui-accordion-icons"));
        },
        _destroyIcons() {
            this._removeClass(this.headers, "ui-accordion-icons"),
            this.headers.children(".ui-accordion-header-icon").remove();
        },
        _destroy() {
            let t;
            this.element.removeAttr("role"),
            this.headers
                .removeAttr("role aria-expanded aria-selected aria-controls tabIndex")
                .removeUniqueId(),
            this._destroyIcons(),
            (t = this.headers
                .next()
                .css("display", "")
                .removeAttr("role aria-hidden aria-labelledby")
                .removeUniqueId()),
            this.options.heightStyle !== "content" && t.css("height", "");
        },
        _setOption(t, e) {
            return t === "active"
                ? (this._activate(e), void 0)
                : (t === "event" &&
                          (this.options.event && this._off(this.headers, this.options.event),
                          this._setupEvents(e)),
                this._super(t, e),
                t !== "collapsible" || e || this.options.active !== !1 || this._activate(0),
                t === "icons" && (this._destroyIcons(), e && this._createIcons()),
                void 0);
        },
        _setOptionDisabled(t) {
            this._super(t),
            this.element.attr("aria-disabled", t),
            this._toggleClass(null, "ui-state-disabled", !!t),
            this._toggleClass(
                this.headers.add(this.headers.next()),
                null,
                "ui-state-disabled",
                !!t,
            );
        },
        _keydown(e) {
            if (!e.altKey && !e.ctrlKey) {
                const i = t.ui.keyCode;
                const s = this.headers.length;
                const n = this.headers.index(e.target);
                let o = !1;
                switch (e.keyCode) {
                case i.RIGHT:
                case i.DOWN:
                    o = this.headers[(n + 1) % s];
                    break;
                case i.LEFT:
                case i.UP:
                    o = this.headers[(n - 1 + s) % s];
                    break;
                case i.SPACE:
                case i.ENTER:
                    this._eventHandler(e);
                    break;
                case i.HOME:
                    o = this.headers[0];
                    break;
                case i.END:
                    o = this.headers[s - 1];
                }
                o &&
                        (t(e.target).attr("tabIndex", -1),
                        t(o).attr("tabIndex", 0),
                        t(o).trigger("focus"),
                        e.preventDefault());
            }
        },
        _panelKeyDown(e) {
            e.keyCode === t.ui.keyCode.UP &&
                    e.ctrlKey &&
                    t(e.currentTarget).prev().trigger("focus");
        },
        refresh() {
            const e = this.options;
            this._processPanels(),
            (e.active === !1 && e.collapsible === !0) || !this.headers.length
                ? ((e.active = !1), (this.active = t()))
                : e.active === !1
                    ? this._activate(0)
                    : this.active.length && !t.contains(this.element[0], this.active[0])
                        ? this.headers.length === this.headers.find(".ui-state-disabled").length
                            ? ((e.active = !1), (this.active = t()))
                            : this._activate(Math.max(0, e.active - 1))
                        : (e.active = this.headers.index(this.active)),
            this._destroyIcons(),
            this._refresh();
        },
        _processPanels() {
            const t = this.headers;
            const e = this.panels;
            (this.headers = this.element.find(this.options.header)),
            this._addClass(
                this.headers,
                "ui-accordion-header ui-accordion-header-collapsed",
                "ui-state-default",
            ),
            (this.panels = this.headers
                .next()
                .filter(":not(.ui-accordion-content-active)")
                .hide()),
            this._addClass(
                this.panels,
                "ui-accordion-content",
                "ui-helper-reset ui-widget-content",
            ),
            e && (this._off(t.not(this.headers)), this._off(e.not(this.panels)));
        },
        _refresh() {
            let e;
            const i = this.options;
            const s = i.heightStyle;
            const n = this.element.parent();
            (this.active = this._findActive(i.active)),
            this._addClass(
                this.active,
                "ui-accordion-header-active",
                "ui-state-active",
            )._removeClass(this.active, "ui-accordion-header-collapsed"),
            this._addClass(this.active.next(), "ui-accordion-content-active"),
            this.active.next().show(),
            this.headers
                .attr("role", "tab")
                .each(function () {
                    const e = t(this);
                    const i = e.uniqueId().attr("id");
                    const s = e.next();
                    const n = s.uniqueId().attr("id");
                    e.attr("aria-controls", n), s.attr("aria-labelledby", i);
                })
                .next()
                .attr("role", "tabpanel"),
            this.headers
                .not(this.active)
                .attr({ "aria-selected": "false", "aria-expanded": "false", tabIndex: -1 })
                .next()
                .attr({ "aria-hidden": "true" })
                .hide(),
            this.active.length
                ? this.active
                    .attr({
                        "aria-selected": "true",
                        "aria-expanded": "true",
                        tabIndex: 0,
                    })
                    .next()
                    .attr({ "aria-hidden": "false" })
                : this.headers.eq(0).attr("tabIndex", 0),
            this._createIcons(),
            this._setupEvents(i.event),
            s === "fill"
                ? ((e = n.height()),
                this.element.siblings(":visible").each(function () {
                    const i = t(this);
                    const s = i.css("position");
                    s !== "absolute" && s !== "fixed" && (e -= i.outerHeight(!0));
                }),
                this.headers.each(function () {
                    e -= t(this).outerHeight(!0);
                }),
                this.headers
                    .next()
                    .each(function () {
                        t(this).height(
                            Math.max(0, e - t(this).innerHeight() + t(this).height()),
                        );
                    })
                    .css("overflow", "auto"))
                : s === "auto" &&
                          ((e = 0),
                          this.headers
                              .next()
                              .each(function () {
                                  const i = t(this).is(":visible");
                                  i || t(this).show(),
                                  (e = Math.max(e, t(this).css("height", "").height())),
                                  i || t(this).hide();
                              })
                              .height(e));
        },
        _activate(e) {
            let i = this._findActive(e)[0];
            i !== this.active[0] &&
                    ((i = i || this.active[0]),
                    this._eventHandler({ target: i, currentTarget: i, preventDefault: t.noop }));
        },
        _findActive(e) {
            return typeof e === "number" ? this.headers.eq(e) : t();
        },
        _setupEvents(e) {
            const i = { keydown: "_keydown" };
            e &&
                    t.each(e.split(" "), (t, e) => {
                        i[e] = "_eventHandler";
                    }),
            this._off(this.headers.add(this.headers.next())),
            this._on(this.headers, i),
            this._on(this.headers.next(), { keydown: "_panelKeyDown" }),
            this._hoverable(this.headers),
            this._focusable(this.headers);
        },
        _eventHandler(e) {
            let i;
            let s;
            const n = this.options;
            const o = this.active;
            const a = t(e.currentTarget);
            const r = a[0] === o[0];
            const h = r && n.collapsible;
            const l = h ? t() : a.next();
            const c = o.next();
            const u = {
                oldHeader: o,
                oldPanel: c,
                newHeader: h ? t() : a,
                newPanel: l,
            };
            e.preventDefault(),
            (r && !n.collapsible) ||
                        this._trigger("beforeActivate", e, u) === !1 ||
                        ((n.active = h ? !1 : this.headers.index(a)),
                        (this.active = r ? t() : a),
                        this._toggle(u),
                        this._removeClass(o, "ui-accordion-header-active", "ui-state-active"),
                        n.icons &&
                            ((i = o.children(".ui-accordion-header-icon")),
                            this._removeClass(i, null, n.icons.activeHeader)._addClass(
                                i,
                                null,
                                n.icons.header,
                            )),
                        r ||
                            (this._removeClass(a, "ui-accordion-header-collapsed")._addClass(
                                a,
                                "ui-accordion-header-active",
                                "ui-state-active",
                            ),
                            n.icons &&
                                ((s = a.children(".ui-accordion-header-icon")),
                                this._removeClass(s, null, n.icons.header)._addClass(
                                    s,
                                    null,
                                    n.icons.activeHeader,
                                )),
                            this._addClass(a.next(), "ui-accordion-content-active")));
        },
        _toggle(e) {
            const i = e.newPanel;
            const s = this.prevShow.length ? this.prevShow : e.oldPanel;
            this.prevShow.add(this.prevHide).stop(!0, !0),
            (this.prevShow = i),
            (this.prevHide = s),
            this.options.animate
                ? this._animate(i, s, e)
                : (s.hide(), i.show(), this._toggleComplete(e)),
            s.attr({ "aria-hidden": "true" }),
            s.prev().attr({ "aria-selected": "false", "aria-expanded": "false" }),
            i.length && s.length
                ? s.prev().attr({ tabIndex: -1, "aria-expanded": "false" })
                : i.length &&
                          this.headers
                              .filter(function () {
                                  return parseInt(t(this).attr("tabIndex"), 10) === 0;
                              })
                              .attr("tabIndex", -1),
            i
                .attr("aria-hidden", "false")
                .prev()
                .attr({ "aria-selected": "true", "aria-expanded": "true", tabIndex: 0 });
        },
        _animate(t, e, i) {
            let s;
            let n;
            let o;
            const a = this;
            let r = 0;
            const h = t.css("box-sizing");
            const l = t.length && (!e.length || t.index() < e.index());
            const c = this.options.animate || {};
            const u = (l && c.down) || c;
            const d = function () {
                a._toggleComplete(i);
            };
            return (
                typeof u === "number" && (o = u),
                typeof u === "string" && (n = u),
                (n = n || u.easing || c.easing),
                (o = o || u.duration || c.duration),
                e.length
                    ? t.length
                        ? ((s = t.show().outerHeight()),
                        e.animate(this.hideProps, {
                            duration: o,
                            easing: n,
                            step(t, e) {
                                e.now = Math.round(t);
                            },
                        }),
                        t.hide().animate(this.showProps, {
                            duration: o,
                            easing: n,
                            complete: d,
                            step(t, i) {
                                (i.now = Math.round(t)),
                                i.prop !== "height"
                                    ? h === "content-box" && (r += i.now)
                                    : a.options.heightStyle !== "content" &&
                                                ((i.now = Math.round(s - e.outerHeight() - r)),
                                                (r = 0));
                            },
                        }),
                        void 0)
                        : e.animate(this.hideProps, o, n, d)
                    : t.animate(this.showProps, o, n, d)
            );
        },
        _toggleComplete(t) {
            const e = t.oldPanel;
            const i = e.prev();
            this._removeClass(e, "ui-accordion-content-active"),
            this._removeClass(i, "ui-accordion-header-active")._addClass(
                i,
                "ui-accordion-header-collapsed",
            ),
            e.length && (e.parent()[0].className = e.parent()[0].className),
            this._trigger("activate", null, t);
        },
    }),
    (t.ui.safeActiveElement = function (t) {
        let e;
        try {
            e = t.activeElement;
        } catch (i) {
            e = t.body;
        }
        return e || (e = t.body), e.nodeName || (e = t.body), e;
    }),
    t.widget("ui.menu", {
        version: "1.12.1",
        defaultElement: "<ul>",
        delay: 300,
        options: {
            icons: { submenu: "ui-icon-caret-1-e" },
            items: "> *",
            menus: "ul",
            position: { my: "left top", at: "right top" },
            role: "menu",
            blur: null,
            focus: null,
            select: null,
        },
        _create() {
            (this.activeMenu = this.element),
            (this.mouseHandled = !1),
            this.element.uniqueId().attr({ role: this.options.role, tabIndex: 0 }),
            this._addClass("ui-menu", "ui-widget ui-widget-content"),
            this._on({
                "mousedown .ui-menu-item": function (t) {
                    t.preventDefault();
                },
                "click .ui-menu-item": function (e) {
                    const i = t(e.target);
                    const s = t(t.ui.safeActiveElement(this.document[0]));
                    !this.mouseHandled &&
                                i.not(".ui-state-disabled").length &&
                                (this.select(e),
                                e.isPropagationStopped() || (this.mouseHandled = !0),
                                i.has(".ui-menu").length
                                    ? this.expand(e)
                                    : !this.element.is(":focus") &&
                                      s.closest(".ui-menu").length &&
                                      (this.element.trigger("focus", [!0]),
                                      this.active &&
                                          this.active.parents(".ui-menu").length === 1 &&
                                          clearTimeout(this.timer)));
                },
                "mouseenter .ui-menu-item": function (e) {
                    if (!this.previousFilter) {
                        const i = t(e.target).closest(".ui-menu-item");
                        const s = t(e.currentTarget);
                        i[0] === s[0] &&
                                    (this._removeClass(
                                        s.siblings().children(".ui-state-active"),
                                        null,
                                        "ui-state-active",
                                    ),
                                    this.focus(e, s));
                    }
                },
                mouseleave: "collapseAll",
                "mouseleave .ui-menu": "collapseAll",
                focus(t, e) {
                    const i = this.active || this.element.find(this.options.items).eq(0);
                    e || this.focus(t, i);
                },
                blur(e) {
                    this._delay(function () {
                        const i = !t.contains(
                            this.element[0],
                            t.ui.safeActiveElement(this.document[0]),
                        );
                        i && this.collapseAll(e);
                    });
                },
                keydown: "_keydown",
            }),
            this.refresh(),
            this._on(this.document, {
                click(t) {
                    this._closeOnDocumentClick(t) && this.collapseAll(t),
                    (this.mouseHandled = !1);
                },
            });
        },
        _destroy() {
            const e = this.element.find(".ui-menu-item").removeAttr("role aria-disabled");
            const i = e
                .children(".ui-menu-item-wrapper")
                .removeUniqueId()
                .removeAttr("tabIndex role aria-haspopup");
            this.element
                .removeAttr("aria-activedescendant")
                .find(".ui-menu")
                .addBack()
                .removeAttr(
                    "role aria-labelledby aria-expanded aria-hidden aria-disabled tabIndex",
                )
                .removeUniqueId()
                .show(),
            i.children().each(function () {
                const e = t(this);
                e.data("ui-menu-submenu-caret") && e.remove();
            });
        },
        _keydown(e) {
            let i;
            let s;
            let n;
            let o;
            let a = !0;
            switch (e.keyCode) {
            case t.ui.keyCode.PAGE_UP:
                this.previousPage(e);
                break;
            case t.ui.keyCode.PAGE_DOWN:
                this.nextPage(e);
                break;
            case t.ui.keyCode.HOME:
                this._move("first", "first", e);
                break;
            case t.ui.keyCode.END:
                this._move("last", "last", e);
                break;
            case t.ui.keyCode.UP:
                this.previous(e);
                break;
            case t.ui.keyCode.DOWN:
                this.next(e);
                break;
            case t.ui.keyCode.LEFT:
                this.collapse(e);
                break;
            case t.ui.keyCode.RIGHT:
                this.active && !this.active.is(".ui-state-disabled") && this.expand(e);
                break;
            case t.ui.keyCode.ENTER:
            case t.ui.keyCode.SPACE:
                this._activate(e);
                break;
            case t.ui.keyCode.ESCAPE:
                this.collapse(e);
                break;
            default:
                (a = !1),
                (s = this.previousFilter || ""),
                (o = !1),
                (n =
                                e.keyCode >= 96 && e.keyCode <= 105
                                    ? `${e.keyCode - 96}`
                                    : String.fromCharCode(e.keyCode)),
                clearTimeout(this.filterTimer),
                n === s ? (o = !0) : (n = s + n),
                (i = this._filterMenuItems(n)),
                (i =
                                o && i.index(this.active.next()) !== -1
                                    ? this.active.nextAll(".ui-menu-item")
                                    : i),
                i.length ||
                                ((n = String.fromCharCode(e.keyCode)),
                                (i = this._filterMenuItems(n))),
                i.length
                    ? (this.focus(e, i),
                    (this.previousFilter = n),
                    (this.filterTimer = this._delay(function () {
                        delete this.previousFilter;
                    }, 1e3)))
                    : delete this.previousFilter;
            }
            a && e.preventDefault();
        },
        _activate(t) {
            this.active &&
                    !this.active.is(".ui-state-disabled") &&
                    (this.active.children("[aria-haspopup='true']").length
                        ? this.expand(t)
                        : this.select(t));
        },
        refresh() {
            let e;
            let i;
            let s;
            let n;
            let o;
            const a = this;
            const r = this.options.icons.submenu;
            const h = this.element.find(this.options.menus);
            this._toggleClass("ui-menu-icons", null, !!this.element.find(".ui-icon").length),
            (s = h
                .filter(":not(.ui-menu)")
                .hide()
                .attr({
                    role: this.options.role,
                    "aria-hidden": "true",
                    "aria-expanded": "false",
                })
                .each(function () {
                    const e = t(this);
                    const i = e.prev();
                    const s = t("<span>").data("ui-menu-submenu-caret", !0);
                    a._addClass(s, "ui-menu-icon", `ui-icon ${r}`),
                    i.attr("aria-haspopup", "true").prepend(s),
                    e.attr("aria-labelledby", i.attr("id"));
                })),
            this._addClass(s, "ui-menu", "ui-widget ui-widget-content ui-front"),
            (e = h.add(this.element)),
            (i = e.find(this.options.items)),
            i.not(".ui-menu-item").each(function () {
                const e = t(this);
                a._isDivider(e) && a._addClass(e, "ui-menu-divider", "ui-widget-content");
            }),
            (n = i.not(".ui-menu-item, .ui-menu-divider")),
            (o = n
                .children()
                .not(".ui-menu")
                .uniqueId()
                .attr({ tabIndex: -1, role: this._itemRole() })),
            this._addClass(n, "ui-menu-item")._addClass(o, "ui-menu-item-wrapper"),
            i.filter(".ui-state-disabled").attr("aria-disabled", "true"),
            this.active && !t.contains(this.element[0], this.active[0]) && this.blur();
        },
        _itemRole() {
            return { menu: "menuitem", listbox: "option" }[this.options.role];
        },
        _setOption(t, e) {
            if (t === "icons") {
                const i = this.element.find(".ui-menu-icon");
                this._removeClass(i, null, this.options.icons.submenu)._addClass(
                    i,
                    null,
                    e.submenu,
                );
            }
            this._super(t, e);
        },
        _setOptionDisabled(t) {
            this._super(t),
            this.element.attr("aria-disabled", `${t}`),
            this._toggleClass(null, "ui-state-disabled", !!t);
        },
        focus(t, e) {
            let i;
            let s;
            let n;
            this.blur(t, t && t.type === "focus"),
            this._scrollIntoView(e),
            (this.active = e.first()),
            (s = this.active.children(".ui-menu-item-wrapper")),
            this._addClass(s, null, "ui-state-active"),
            this.options.role && this.element.attr("aria-activedescendant", s.attr("id")),
            (n = this.active
                .parent()
                .closest(".ui-menu-item")
                .children(".ui-menu-item-wrapper")),
            this._addClass(n, null, "ui-state-active"),
            t && t.type === "keydown"
                ? this._close()
                : (this.timer = this._delay(function () {
                    this._close();
                }, this.delay)),
            (i = e.children(".ui-menu")),
            i.length && t && /^mouse/.test(t.type) && this._startOpening(i),
            (this.activeMenu = e.parent()),
            this._trigger("focus", t, { item: e });
        },
        _scrollIntoView(e) {
            let i;
            let s;
            let n;
            let o;
            let a;
            let r;
            this._hasScroll() &&
                    ((i = parseFloat(t.css(this.activeMenu[0], "borderTopWidth")) || 0),
                    (s = parseFloat(t.css(this.activeMenu[0], "paddingTop")) || 0),
                    (n = e.offset().top - this.activeMenu.offset().top - i - s),
                    (o = this.activeMenu.scrollTop()),
                    (a = this.activeMenu.height()),
                    (r = e.outerHeight()),
                    n < 0
                        ? this.activeMenu.scrollTop(o + n)
                        : n + r > a && this.activeMenu.scrollTop(o + n - a + r));
        },
        blur(t, e) {
            e || clearTimeout(this.timer),
            this.active &&
                        (this._removeClass(
                            this.active.children(".ui-menu-item-wrapper"),
                            null,
                            "ui-state-active",
                        ),
                        this._trigger("blur", t, { item: this.active }),
                        (this.active = null));
        },
        _startOpening(t) {
            clearTimeout(this.timer),
            t.attr("aria-hidden") === "true" &&
                        (this.timer = this._delay(function () {
                            this._close(), this._open(t);
                        }, this.delay));
        },
        _open(e) {
            const i = t.extend({ of: this.active }, this.options.position);
            clearTimeout(this.timer),
            this.element
                .find(".ui-menu")
                .not(e.parents(".ui-menu"))
                .hide()
                .attr("aria-hidden", "true"),
            e.show().removeAttr("aria-hidden").attr("aria-expanded", "true").position(i);
        },
        collapseAll(e, i) {
            clearTimeout(this.timer),
            (this.timer = this._delay(function () {
                let s = i
                    ? this.element
                    : t(e && e.target).closest(this.element.find(".ui-menu"));
                s.length || (s = this.element),
                this._close(s),
                this.blur(e),
                this._removeClass(s.find(".ui-state-active"), null, "ui-state-active"),
                (this.activeMenu = s);
            }, this.delay));
        },
        _close(t) {
            t || (t = this.active ? this.active.parent() : this.element),
            t
                .find(".ui-menu")
                .hide()
                .attr("aria-hidden", "true")
                .attr("aria-expanded", "false");
        },
        _closeOnDocumentClick(e) {
            return !t(e.target).closest(".ui-menu").length;
        },
        _isDivider(t) {
            return !/[^\-\u2014\u2013\s]/.test(t.text());
        },
        collapse(t) {
            const e =
                    this.active && this.active.parent().closest(".ui-menu-item", this.element);
            e && e.length && (this._close(), this.focus(t, e));
        },
        expand(t) {
            const e =
                    this.active &&
                    this.active.children(".ui-menu ").find(this.options.items).first();
            e &&
                    e.length &&
                    (this._open(e.parent()),
                    this._delay(function () {
                        this.focus(t, e);
                    }));
        },
        next(t) {
            this._move("next", "first", t);
        },
        previous(t) {
            this._move("prev", "last", t);
        },
        isFirstItem() {
            return this.active && !this.active.prevAll(".ui-menu-item").length;
        },
        isLastItem() {
            return this.active && !this.active.nextAll(".ui-menu-item").length;
        },
        _move(t, e, i) {
            let s;
            this.active &&
                    (s =
                        t === "first" || t === "last"
                            ? this.active[t === "first" ? "prevAll" : "nextAll"](
                                ".ui-menu-item",
                            ).eq(-1)
                            : this.active[`${t}All`](".ui-menu-item").eq(0)),
            (s && s.length && this.active) ||
                        (s = this.activeMenu.find(this.options.items)[e]()),
            this.focus(i, s);
        },
        nextPage(e) {
            let i;
            let s;
            let n;
            return this.active
                ? (this.isLastItem() ||
                          (this._hasScroll()
                              ? ((s = this.active.offset().top),
                              (n = this.element.height()),
                              this.active.nextAll(".ui-menu-item").each(function () {
                                  return (i = t(this)), i.offset().top - s - n < 0;
                              }),
                              this.focus(e, i))
                              : this.focus(
                                  e,
                                  this.activeMenu
                                      .find(this.options.items)
                                      [this.active ? "last" : "first"](),
                              )),
                void 0)
                : (this.next(e), void 0);
        },
        previousPage(e) {
            let i;
            let s;
            let n;
            return this.active
                ? (this.isFirstItem() ||
                          (this._hasScroll()
                              ? ((s = this.active.offset().top),
                              (n = this.element.height()),
                              this.active.prevAll(".ui-menu-item").each(function () {
                                  return (i = t(this)), i.offset().top - s + n > 0;
                              }),
                              this.focus(e, i))
                              : this.focus(e, this.activeMenu.find(this.options.items).first())),
                void 0)
                : (this.next(e), void 0);
        },
        _hasScroll() {
            return this.element.outerHeight() < this.element.prop("scrollHeight");
        },
        select(e) {
            this.active = this.active || t(e.target).closest(".ui-menu-item");
            const i = { item: this.active };
            this.active.has(".ui-menu").length || this.collapseAll(e, !0),
            this._trigger("select", e, i);
        },
        _filterMenuItems(e) {
            const i = e.replace(/[\-\[\]{}()*+?.,\\\^$|#\s]/g, "\\$&");
            const s = RegExp(`^${i}`, "i");
            return this.activeMenu
                .find(this.options.items)
                .filter(".ui-menu-item")
                .filter(function () {
                    return s.test(t.trim(t(this).children(".ui-menu-item-wrapper").text()));
                });
        },
    }),
    t.widget("ui.autocomplete", {
        version: "1.12.1",
        defaultElement: "<input>",
        options: {
            appendTo: null,
            autoFocus: !1,
            delay: 300,
            minLength: 1,
            position: { my: "left top", at: "left bottom", collision: "none" },
            source: null,
            change: null,
            close: null,
            focus: null,
            open: null,
            response: null,
            search: null,
            select: null,
        },
        requestIndex: 0,
        pending: 0,
        _create() {
            let e;
            let i;
            let s;
            const n = this.element[0].nodeName.toLowerCase();
            const o = n === "textarea";
            const a = n === "input";
            (this.isMultiLine = o || (!a && this._isContentEditable(this.element))),
            (this.valueMethod = this.element[o || a ? "val" : "text"]),
            (this.isNewMenu = !0),
            this._addClass("ui-autocomplete-input"),
            this.element.attr("autocomplete", "off"),
            this._on(this.element, {
                keydown(n) {
                    if (this.element.prop("readOnly"))
                        return (e = !0), (s = !0), (i = !0), void 0;
                    (e = !1), (s = !1), (i = !1);
                    const o = t.ui.keyCode;
                    switch (n.keyCode) {
                    case o.PAGE_UP:
                        (e = !0), this._move("previousPage", n);
                        break;
                    case o.PAGE_DOWN:
                        (e = !0), this._move("nextPage", n);
                        break;
                    case o.UP:
                        (e = !0), this._keyEvent("previous", n);
                        break;
                    case o.DOWN:
                        (e = !0), this._keyEvent("next", n);
                        break;
                    case o.ENTER:
                        this.menu.active &&
                                        ((e = !0), n.preventDefault(), this.menu.select(n));
                        break;
                    case o.TAB:
                        this.menu.active && this.menu.select(n);
                        break;
                    case o.ESCAPE:
                        this.menu.element.is(":visible") &&
                                        (this.isMultiLine || this._value(this.term),
                                        this.close(n),
                                        n.preventDefault());
                        break;
                    default:
                        (i = !0), this._searchTimeout(n);
                    }
                },
                keypress(s) {
                    if (e)
                        return (
                            (e = !1),
                            (!this.isMultiLine || this.menu.element.is(":visible")) &&
                                        s.preventDefault(),
                            void 0
                        );
                    if (!i) {
                        const n = t.ui.keyCode;
                        switch (s.keyCode) {
                        case n.PAGE_UP:
                            this._move("previousPage", s);
                            break;
                        case n.PAGE_DOWN:
                            this._move("nextPage", s);
                            break;
                        case n.UP:
                            this._keyEvent("previous", s);
                            break;
                        case n.DOWN:
                            this._keyEvent("next", s);
                        }
                    }
                },
                input(t) {
                    return s
                        ? ((s = !1), t.preventDefault(), void 0)
                        : (this._searchTimeout(t), void 0);
                },
                focus() {
                    (this.selectedItem = null), (this.previous = this._value());
                },
                blur(t) {
                    return this.cancelBlur
                        ? (delete this.cancelBlur, void 0)
                        : (clearTimeout(this.searching),
                        this.close(t),
                        this._change(t),
                        void 0);
                },
            }),
            this._initSource(),
            (this.menu = t("<ul>")
                .appendTo(this._appendTo())
                .menu({ role: null })
                .hide()
                .menu("instance")),
            this._addClass(this.menu.element, "ui-autocomplete", "ui-front"),
            this._on(this.menu.element, {
                mousedown(e) {
                    e.preventDefault(),
                    (this.cancelBlur = !0),
                    this._delay(function () {
                        delete this.cancelBlur,
                        this.element[0] !==
                                            t.ui.safeActiveElement(this.document[0]) &&
                                            this.element.trigger("focus");
                    });
                },
                menufocus(e, i) {
                    let s;
                    let n;
                    return this.isNewMenu &&
                                ((this.isNewMenu = !1),
                                e.originalEvent && /^mouse/.test(e.originalEvent.type))
                        ? (this.menu.blur(),
                        this.document.one("mousemove", () => {
                            t(e.target).trigger(e.originalEvent);
                        }),
                        void 0)
                        : ((n = i.item.data("ui-autocomplete-item")),
                        !1 !== this._trigger("focus", e, { item: n }) &&
                                      e.originalEvent &&
                                      /^key/.test(e.originalEvent.type) &&
                                      this._value(n.value),
                        (s = i.item.attr("aria-label") || n.value),
                        s &&
                                      t.trim(s).length &&
                                      (this.liveRegion.children().hide(),
                                      t("<div>").text(s).appendTo(this.liveRegion)),
                        void 0);
                },
                menuselect(e, i) {
                    const s = i.item.data("ui-autocomplete-item");
                    const n = this.previous;
                    this.element[0] !== t.ui.safeActiveElement(this.document[0]) &&
                                (this.element.trigger("focus"),
                                (this.previous = n),
                                this._delay(function () {
                                    (this.previous = n), (this.selectedItem = s);
                                })),
                    !1 !== this._trigger("select", e, { item: s }) &&
                                    this._value(s.value),
                    (this.term = this._value()),
                    this.close(e),
                    (this.selectedItem = s);
                },
            }),
            (this.liveRegion = t("<div>", {
                role: "status",
                "aria-live": "assertive",
                "aria-relevant": "additions",
            }).appendTo(this.document[0].body)),
            this._addClass(this.liveRegion, null, "ui-helper-hidden-accessible"),
            this._on(this.window, {
                beforeunload() {
                    this.element.removeAttr("autocomplete");
                },
            });
        },
        _destroy() {
            clearTimeout(this.searching),
            this.element.removeAttr("autocomplete"),
            this.menu.element.remove(),
            this.liveRegion.remove();
        },
        _setOption(t, e) {
            this._super(t, e),
            t === "source" && this._initSource(),
            t === "appendTo" && this.menu.element.appendTo(this._appendTo()),
            t === "disabled" && e && this.xhr && this.xhr.abort();
        },
        _isEventTargetInWidget(e) {
            const i = this.menu.element[0];
            return e.target === this.element[0] || e.target === i || t.contains(i, e.target);
        },
        _closeOnClickOutside(t) {
            this._isEventTargetInWidget(t) || this.close();
        },
        _appendTo() {
            let e = this.options.appendTo;
            return (
                e && (e = e.jquery || e.nodeType ? t(e) : this.document.find(e).eq(0)),
                (e && e[0]) || (e = this.element.closest(".ui-front, dialog")),
                e.length || (e = this.document[0].body),
                e
            );
        },
        _initSource() {
            let e;
            let i;
            const s = this;
            t.isArray(this.options.source)
                ? ((e = this.options.source),
                (this.source = function (i, s) {
                    s(t.ui.autocomplete.filter(e, i.term));
                }))
                : typeof this.options.source === "string"
                    ? ((i = this.options.source),
                    (this.source = function (e, n) {
                        s.xhr && s.xhr.abort(),
                        (s.xhr = t.ajax({
                            url: i,
                            data: e,
                            dataType: "json",
                            success(t) {
                                n(t);
                            },
                            error() {
                                n([]);
                            },
                        }));
                    }))
                    : (this.source = this.options.source);
        },
        _searchTimeout(t) {
            clearTimeout(this.searching),
            (this.searching = this._delay(function () {
                const e = this.term === this._value();
                const i = this.menu.element.is(":visible");
                const s = t.altKey || t.ctrlKey || t.metaKey || t.shiftKey;
                (!e || (e && !i && !s)) &&
                            ((this.selectedItem = null), this.search(null, t));
            }, this.options.delay));
        },
        search(t, e) {
            return (
                (t = t != null ? t : this._value()),
                (this.term = this._value()),
                t.length < this.options.minLength
                    ? this.close(e)
                    : this._trigger("search", e) !== !1
                        ? this._search(t)
                        : void 0
            );
        },
        _search(t) {
            this.pending++,
            this._addClass("ui-autocomplete-loading"),
            (this.cancelSearch = !1),
            this.source({ term: t }, this._response());
        },
        _response() {
            const e = ++this.requestIndex;
            return t.proxy(function (t) {
                e === this.requestIndex && this.__response(t),
                this.pending--,
                this.pending || this._removeClass("ui-autocomplete-loading");
            }, this);
        },
        __response(t) {
            t && (t = this._normalize(t)),
            this._trigger("response", null, { content: t }),
            !this.options.disabled && t && t.length && !this.cancelSearch
                ? (this._suggest(t), this._trigger("open"))
                : this._close();
        },
        close(t) {
            (this.cancelSearch = !0), this._close(t);
        },
        _close(t) {
            this._off(this.document, "mousedown"),
            this.menu.element.is(":visible") &&
                        (this.menu.element.hide(),
                        this.menu.blur(),
                        (this.isNewMenu = !0),
                        this._trigger("close", t));
        },
        _change(t) {
            this.previous !== this._value() &&
                    this._trigger("change", t, { item: this.selectedItem });
        },
        _normalize(e) {
            return e.length && e[0].label && e[0].value
                ? e
                : t.map(e, (e) =>
                    typeof e === "string"
                        ? { label: e, value: e }
                        : t.extend({}, e, {
                            label: e.label || e.value,
                            value: e.value || e.label,
                        }),
                );
        },
        _suggest(e) {
            const i = this.menu.element.empty();
            this._renderMenu(i, e),
            (this.isNewMenu = !0),
            this.menu.refresh(),
            i.show(),
            this._resizeMenu(),
            i.position(t.extend({ of: this.element }, this.options.position)),
            this.options.autoFocus && this.menu.next(),
            this._on(this.document, { mousedown: "_closeOnClickOutside" });
        },
        _resizeMenu() {
            const t = this.menu.element;
            t.outerWidth(Math.max(t.width("").outerWidth() + 1, this.element.outerWidth()));
        },
        _renderMenu(e, i) {
            const s = this;
            t.each(i, (t, i) => {
                s._renderItemData(e, i);
            });
        },
        _renderItemData(t, e) {
            return this._renderItem(t, e).data("ui-autocomplete-item", e);
        },
        _renderItem(e, i) {
            return t("<li>").append(t("<div>").text(i.label)).appendTo(e);
        },
        _move(t, e) {
            return this.menu.element.is(":visible")
                ? (this.menu.isFirstItem() && /^previous/.test(t)) ||
                      (this.menu.isLastItem() && /^next/.test(t))
                    ? (this.isMultiLine || this._value(this.term), this.menu.blur(), void 0)
                    : (this.menu[t](e), void 0)
                : (this.search(null, e), void 0);
        },
        widget() {
            return this.menu.element;
        },
        _value() {
            return this.valueMethod.apply(this.element, arguments);
        },
        _keyEvent(t, e) {
            (!this.isMultiLine || this.menu.element.is(":visible")) &&
                    (this._move(t, e), e.preventDefault());
        },
        _isContentEditable(t) {
            if (!t.length) return !1;
            const e = t.prop("contentEditable");
            return e === "inherit" ? this._isContentEditable(t.parent()) : e === "true";
        },
    }),
    t.extend(t.ui.autocomplete, {
        escapeRegex(t) {
            return t.replace(/[\-\[\]{}()*+?.,\\\^$|#\s]/g, "\\$&");
        },
        filter(e, i) {
            const s = RegExp(t.ui.autocomplete.escapeRegex(i), "i");
            return t.grep(e, (t) => s.test(t.label || t.value || t));
        },
    }),
    t.widget("ui.autocomplete", t.ui.autocomplete, {
        options: {
            messages: {
                noResults: "No search results.",
                results(t) {
                    return `${
                        t + (t > 1 ? " results are" : " result is")
                    } available, use up and down arrow keys to navigate.`;
                },
            },
        },
        __response(e) {
            let i;
            this._superApply(arguments),
            this.options.disabled ||
                        this.cancelSearch ||
                        ((i =
                            e && e.length
                                ? this.options.messages.results(e.length)
                                : this.options.messages.noResults),
                        this.liveRegion.children().hide(),
                        t("<div>").text(i).appendTo(this.liveRegion));
        },
    }),
    t.ui.autocomplete;
    const g = /ui-corner-([a-z]){2,6}/g;
    t.widget("ui.controlgroup", {
        version: "1.12.1",
        defaultElement: "<div>",
        options: {
            direction: "horizontal",
            disabled: null,
            onlyVisible: !0,
            items: {
                button: "input[type=button], input[type=submit], input[type=reset], button, a",
                controlgroupLabel: ".ui-controlgroup-label",
                checkboxradio: "input[type='checkbox'], input[type='radio']",
                selectmenu: "select",
                spinner: ".ui-spinner-input",
            },
        },
        _create() {
            this._enhance();
        },
        _enhance() {
            this.element.attr("role", "toolbar"), this.refresh();
        },
        _destroy() {
            this._callChildMethod("destroy"),
            this.childWidgets.removeData("ui-controlgroup-data"),
            this.element.removeAttr("role"),
            this.options.items.controlgroupLabel &&
                    this.element
                        .find(this.options.items.controlgroupLabel)
                        .find(".ui-controlgroup-label-contents")
                        .contents()
                        .unwrap();
        },
        _initWidgets() {
            const e = this;
            let i = [];
            t.each(this.options.items, (s, n) => {
                let o;
                let a = {};
                return n
                    ? s === "controlgroupLabel"
                        ? ((o = e.element.find(n)),
                        o.each(function () {
                            const e = t(this);
                            e.children(".ui-controlgroup-label-contents").length ||
                                  e
                                      .contents()
                                      .wrapAll(
                                          "<span class='ui-controlgroup-label-contents'></span>",
                                      );
                        }),
                        e._addClass(o, null, "ui-widget ui-widget-content ui-state-default"),
                        (i = i.concat(o.get())),
                        void 0)
                        : (t.fn[s] &&
                              ((a = e[`_${s}Options`]
                                  ? e[`_${s}Options`]("middle")
                                  : { classes: {} }),
                              e.element.find(n).each(function () {
                                  const n = t(this);
                                  let o = n[s]("instance");
                                  const r = t.widget.extend({}, a);
                                  if (s !== "button" || !n.parent(".ui-spinner").length) {
                                      o || (o = n[s]()[s]("instance")),
                                      o && (r.classes = e._resolveClassesValues(r.classes, o)),
                                      n[s](r);
                                      const h = n[s]("widget");
                                      t.data(h[0], "ui-controlgroup-data", o || n[s]("instance")),
                                      i.push(h[0]);
                                  }
                              })),
                        void 0)
                    : void 0;
            }),
            (this.childWidgets = t(t.unique(i))),
            this._addClass(this.childWidgets, "ui-controlgroup-item");
        },
        _callChildMethod(e) {
            this.childWidgets.each(function () {
                const i = t(this);
                const s = i.data("ui-controlgroup-data");
                s && s[e] && s[e]();
            });
        },
        _updateCornerClass(t, e) {
            const i = "ui-corner-top ui-corner-bottom ui-corner-left ui-corner-right ui-corner-all";
            const s = this._buildSimpleOptions(e, "label").classes.label;
            this._removeClass(t, null, i), this._addClass(t, null, s);
        },
        _buildSimpleOptions(t, e) {
            const i = this.options.direction === "vertical";
            const s = { classes: {} };
            return (
                (s.classes[e] = {
                    middle: "",
                    first: `ui-corner-${i ? "top" : "left"}`,
                    last: `ui-corner-${i ? "bottom" : "right"}`,
                    only: "ui-corner-all",
                }[t]),
                s
            );
        },
        _spinnerOptions(t) {
            const e = this._buildSimpleOptions(t, "ui-spinner");
            return (e.classes["ui-spinner-up"] = ""), (e.classes["ui-spinner-down"] = ""), e;
        },
        _buttonOptions(t) {
            return this._buildSimpleOptions(t, "ui-button");
        },
        _checkboxradioOptions(t) {
            return this._buildSimpleOptions(t, "ui-checkboxradio-label");
        },
        _selectmenuOptions(t) {
            const e = this.options.direction === "vertical";
            return {
                width: e ? "auto" : !1,
                classes: {
                    middle: { "ui-selectmenu-button-open": "", "ui-selectmenu-button-closed": "" },
                    first: {
                        "ui-selectmenu-button-open": `ui-corner-${e ? "top" : "tl"}`,
                        "ui-selectmenu-button-closed": `ui-corner-${e ? "top" : "left"}`,
                    },
                    last: {
                        "ui-selectmenu-button-open": e ? "" : "ui-corner-tr",
                        "ui-selectmenu-button-closed": `ui-corner-${e ? "bottom" : "right"}`,
                    },
                    only: {
                        "ui-selectmenu-button-open": "ui-corner-top",
                        "ui-selectmenu-button-closed": "ui-corner-all",
                    },
                }[t],
            };
        },
        _resolveClassesValues(e, i) {
            const s = {};
            return (
                t.each(e, (n) => {
                    let o = i.options.classes[n] || "";
                    (o = t.trim(o.replace(g, ""))), (s[n] = `${o} ${e[n]}`.replace(/\s+/g, " "));
                }),
                s
            );
        },
        _setOption(t, e) {
            return (
                t === "direction" && this._removeClass(`ui-controlgroup-${this.options.direction}`),
                this._super(t, e),
                t === "disabled"
                    ? (this._callChildMethod(e ? "disable" : "enable"), void 0)
                    : (this.refresh(), void 0)
            );
        },
        refresh() {
            let e;
            const i = this;
            this._addClass(`ui-controlgroup ui-controlgroup-${this.options.direction}`),
            this.options.direction === "horizontal" &&
                    this._addClass(null, "ui-helper-clearfix"),
            this._initWidgets(),
            (e = this.childWidgets),
            this.options.onlyVisible && (e = e.filter(":visible")),
            e.length &&
                    (t.each(["first", "last"], (t, s) => {
                        const n = e[s]().data("ui-controlgroup-data");
                        if (n && i[`_${n.widgetName}Options`]) {
                            const o = i[`_${n.widgetName}Options`](e.length === 1 ? "only" : s);
                            (o.classes = i._resolveClassesValues(o.classes, n)),
                            n.element[n.widgetName](o);
                        } else i._updateCornerClass(e[s](), s);
                    }),
                    this._callChildMethod("refresh"));
        },
    }),
    t.widget("ui.checkboxradio", [
        t.ui.formResetMixin,
        {
            version: "1.12.1",
            options: {
                disabled: null,
                label: null,
                icon: !0,
                classes: {
                    "ui-checkboxradio-label": "ui-corner-all",
                    "ui-checkboxradio-icon": "ui-corner-all",
                },
            },
            _getCreateOptions() {
                let e;
                let i;
                const s = this;
                const n = this._super() || {};
                return (
                    this._readType(),
                    (i = this.element.labels()),
                    (this.label = t(i[i.length - 1])),
                    this.label.length || t.error("No label found for checkboxradio widget"),
                    (this.originalLabel = ""),
                    this.label
                        .contents()
                        .not(this.element[0])
                        .each(function () {
                            s.originalLabel +=
                                    this.nodeType === 3 ? t(this).text() : this.outerHTML;
                        }),
                    this.originalLabel && (n.label = this.originalLabel),
                    (e = this.element[0].disabled),
                    e != null && (n.disabled = e),
                    n
                );
            },
            _create() {
                const t = this.element[0].checked;
                this._bindFormResetHandler(),
                this.options.disabled == null &&
                            (this.options.disabled = this.element[0].disabled),
                this._setOption("disabled", this.options.disabled),
                this._addClass("ui-checkboxradio", "ui-helper-hidden-accessible"),
                this._addClass(this.label, "ui-checkboxradio-label", "ui-button ui-widget"),
                this.type === "radio" &&
                            this._addClass(this.label, "ui-checkboxradio-radio-label"),
                this.options.label && this.options.label !== this.originalLabel
                    ? this._updateLabel()
                    : this.originalLabel && (this.options.label = this.originalLabel),
                this._enhance(),
                t &&
                            (this._addClass(
                                this.label,
                                "ui-checkboxradio-checked",
                                "ui-state-active",
                            ),
                            this.icon && this._addClass(this.icon, null, "ui-state-hover")),
                this._on({
                    change: "_toggleClasses",
                    focus() {
                        this._addClass(this.label, null, "ui-state-focus ui-visual-focus");
                    },
                    blur() {
                        this._removeClass(
                            this.label,
                            null,
                            "ui-state-focus ui-visual-focus",
                        );
                    },
                });
            },
            _readType() {
                const e = this.element[0].nodeName.toLowerCase();
                (this.type = this.element[0].type),
                (e === "input" && /radio|checkbox/.test(this.type)) ||
                            t.error(
                                `Can't create checkboxradio on element.nodeName=${e} and element.type=${this.type}`,
                            );
            },
            _enhance() {
                this._updateIcon(this.element[0].checked);
            },
            widget() {
                return this.label;
            },
            _getRadioGroup() {
                let e;
                const i = this.element[0].name;
                const s = `input[name='${t.ui.escapeSelector(i)}']`;
                return i
                    ? ((e = this.form.length
                        ? t(this.form[0].elements).filter(s)
                        : t(s).filter(function () {
                            return t(this).form().length === 0;
                        })),
                    e.not(this.element))
                    : t([]);
            },
            _toggleClasses() {
                const e = this.element[0].checked;
                this._toggleClass(this.label, "ui-checkboxradio-checked", "ui-state-active", e),
                this.options.icon &&
                            this.type === "checkbox" &&
                            this._toggleClass(
                                this.icon,
                                null,
                                "ui-icon-check ui-state-checked",
                                e,
                            )._toggleClass(this.icon, null, "ui-icon-blank", !e),
                this.type === "radio" &&
                            this._getRadioGroup().each(function () {
                                const e = t(this).checkboxradio("instance");
                                e &&
                                    e._removeClass(
                                        e.label,
                                        "ui-checkboxradio-checked",
                                        "ui-state-active",
                                    );
                            });
            },
            _destroy() {
                this._unbindFormResetHandler(),
                this.icon && (this.icon.remove(), this.iconSpace.remove());
            },
            _setOption(t, e) {
                return t !== "label" || e
                    ? (this._super(t, e),
                    t === "disabled"
                        ? (this._toggleClass(this.label, null, "ui-state-disabled", e),
                        (this.element[0].disabled = e),
                        void 0)
                        : (this.refresh(), void 0))
                    : void 0;
            },
            _updateIcon(e) {
                let i = "ui-icon ui-icon-background ";
                this.options.icon
                    ? (this.icon ||
                              ((this.icon = t("<span>")),
                              (this.iconSpace = t("<span> </span>")),
                              this._addClass(this.iconSpace, "ui-checkboxradio-icon-space")),
                    this.type === "checkbox"
                        ? ((i += e ? "ui-icon-check ui-state-checked" : "ui-icon-blank"),
                        this._removeClass(
                            this.icon,
                            null,
                            e ? "ui-icon-blank" : "ui-icon-check",
                        ))
                        : (i += "ui-icon-blank"),
                    this._addClass(this.icon, "ui-checkboxradio-icon", i),
                    e || this._removeClass(this.icon, null, "ui-icon-check ui-state-checked"),
                    this.icon.prependTo(this.label).after(this.iconSpace))
                    : void 0 !== this.icon &&
                          (this.icon.remove(), this.iconSpace.remove(), delete this.icon);
            },
            _updateLabel() {
                let t = this.label.contents().not(this.element[0]);
                this.icon && (t = t.not(this.icon[0])),
                this.iconSpace && (t = t.not(this.iconSpace[0])),
                t.remove(),
                this.label.append(this.options.label);
            },
            refresh() {
                const t = this.element[0].checked;
                const e = this.element[0].disabled;
                this._updateIcon(t),
                this._toggleClass(
                    this.label,
                    "ui-checkboxradio-checked",
                    "ui-state-active",
                    t,
                ),
                this.options.label !== null && this._updateLabel(),
                e !== this.options.disabled && this._setOptions({ disabled: e });
            },
        },
    ]),
    t.ui.checkboxradio,
    t.widget("ui.button", {
        version: "1.12.1",
        defaultElement: "<button>",
        options: {
            classes: { "ui-button": "ui-corner-all" },
            disabled: null,
            icon: null,
            iconPosition: "beginning",
            label: null,
            showLabel: !0,
        },
        _getCreateOptions() {
            let t;
            const e = this._super() || {};
            return (
                (this.isInput = this.element.is("input")),
                (t = this.element[0].disabled),
                t != null && (e.disabled = t),
                (this.originalLabel = this.isInput ? this.element.val() : this.element.html()),
                this.originalLabel && (e.label = this.originalLabel),
                e
            );
        },
        _create() {
            !this.option.showLabel & !this.options.icon && (this.options.showLabel = !0),
            this.options.disabled == null &&
                        (this.options.disabled = this.element[0].disabled || !1),
            (this.hasTitle = !!this.element.attr("title")),
            this.options.label &&
                        this.options.label !== this.originalLabel &&
                        (this.isInput
                            ? this.element.val(this.options.label)
                            : this.element.html(this.options.label)),
            this._addClass("ui-button", "ui-widget"),
            this._setOption("disabled", this.options.disabled),
            this._enhance(),
            this.element.is("a") &&
                        this._on({
                            keyup(e) {
                                e.keyCode === t.ui.keyCode.SPACE &&
                                    (e.preventDefault(),
                                    this.element[0].click
                                        ? this.element[0].click()
                                        : this.element.trigger("click"));
                            },
                        });
        },
        _enhance() {
            this.element.is("button") || this.element.attr("role", "button"),
            this.options.icon &&
                        (this._updateIcon("icon", this.options.icon), this._updateTooltip());
        },
        _updateTooltip() {
            (this.title = this.element.attr("title")),
            this.options.showLabel ||
                        this.title ||
                        this.element.attr("title", this.options.label);
        },
        _updateIcon(e, i) {
            const s = e !== "iconPosition";
            const n = s ? this.options.iconPosition : i;
            const o = n === "top" || n === "bottom";
            this.icon
                ? s && this._removeClass(this.icon, null, this.options.icon)
                : ((this.icon = t("<span>")),
                this._addClass(this.icon, "ui-button-icon", "ui-icon"),
                this.options.showLabel || this._addClass("ui-button-icon-only")),
            s && this._addClass(this.icon, null, i),
            this._attachIcon(n),
            o
                ? (this._addClass(this.icon, null, "ui-widget-icon-block"),
                this.iconSpace && this.iconSpace.remove())
                : (this.iconSpace ||
                              ((this.iconSpace = t("<span> </span>")),
                              this._addClass(this.iconSpace, "ui-button-icon-space")),
                this._removeClass(this.icon, null, "ui-wiget-icon-block"),
                this._attachIconSpace(n));
        },
        _destroy() {
            this.element.removeAttr("role"),
            this.icon && this.icon.remove(),
            this.iconSpace && this.iconSpace.remove(),
            this.hasTitle || this.element.removeAttr("title");
        },
        _attachIconSpace(t) {
            this.icon[/^(?:end|bottom)/.test(t) ? "before" : "after"](this.iconSpace);
        },
        _attachIcon(t) {
            this.element[/^(?:end|bottom)/.test(t) ? "append" : "prepend"](this.icon);
        },
        _setOptions(t) {
            const e = void 0 === t.showLabel ? this.options.showLabel : t.showLabel;
            const i = void 0 === t.icon ? this.options.icon : t.icon;
            e || i || (t.showLabel = !0), this._super(t);
        },
        _setOption(t, e) {
            t === "icon" &&
                    (e
                        ? this._updateIcon(t, e)
                        : this.icon &&
                          (this.icon.remove(), this.iconSpace && this.iconSpace.remove())),
            t === "iconPosition" && this._updateIcon(t, e),
            t === "showLabel" &&
                        (this._toggleClass("ui-button-icon-only", null, !e), this._updateTooltip()),
            t === "label" &&
                        (this.isInput
                            ? this.element.val(e)
                            : (this.element.html(e),
                            this.icon &&
                                  (this._attachIcon(this.options.iconPosition),
                                  this._attachIconSpace(this.options.iconPosition)))),
            this._super(t, e),
            t === "disabled" &&
                        (this._toggleClass(null, "ui-state-disabled", e),
                        (this.element[0].disabled = e),
                        e && this.element.blur());
        },
        refresh() {
            const t = this.element.is("input, button")
                ? this.element[0].disabled
                : this.element.hasClass("ui-button-disabled");
            t !== this.options.disabled && this._setOptions({ disabled: t }),
            this._updateTooltip();
        },
    }),
    t.uiBackCompat !== !1 &&
            (t.widget("ui.button", t.ui.button, {
                options: { text: !0, icons: { primary: null, secondary: null } },
                _create() {
                    this.options.showLabel &&
                        !this.options.text &&
                        (this.options.showLabel = this.options.text),
                    !this.options.showLabel &&
                            this.options.text &&
                            (this.options.text = this.options.showLabel),
                    this.options.icon ||
                        (!this.options.icons.primary && !this.options.icons.secondary)
                        ? this.options.icon && (this.options.icons.primary = this.options.icon)
                        : this.options.icons.primary
                            ? (this.options.icon = this.options.icons.primary)
                            : ((this.options.icon = this.options.icons.secondary),
                            (this.options.iconPosition = "end")),
                    this._super();
                },
                _setOption(t, e) {
                    return t === "text"
                        ? (this._super("showLabel", e), void 0)
                        : (t === "showLabel" && (this.options.text = e),
                        t === "icon" && (this.options.icons.primary = e),
                        t === "icons" &&
                              (e.primary
                                  ? (this._super("icon", e.primary),
                                  this._super("iconPosition", "beginning"))
                                  : e.secondary &&
                                    (this._super("icon", e.secondary),
                                    this._super("iconPosition", "end"))),
                        this._superApply(arguments),
                        void 0);
                },
            }),
            (t.fn.button = (function (e) {
                return function () {
                    return !this.length ||
                        (this.length && this[0].tagName !== "INPUT") ||
                        (this.length &&
                            this[0].tagName === "INPUT" &&
                            this.attr("type") !== "checkbox" &&
                            this.attr("type") !== "radio")
                        ? e.apply(this, arguments)
                        : (t.ui.checkboxradio || t.error("Checkboxradio widget missing"),
                        arguments.length === 0
                            ? this.checkboxradio({ icon: !1 })
                            : this.checkboxradio.apply(this, arguments));
                };
            })(t.fn.button)),
            (t.fn.buttonset = function () {
                return (
                    t.ui.controlgroup || t.error("Controlgroup widget missing"),
                    arguments[0] === "option" && arguments[1] === "items" && arguments[2]
                        ? this.controlgroup.apply(this, [
                            arguments[0],
                            "items.button",
                            arguments[2],
                        ])
                        : arguments[0] === "option" && arguments[1] === "items"
                            ? this.controlgroup.apply(this, [arguments[0], "items.button"])
                            : (typeof arguments[0] === "object" &&
                              arguments[0].items &&
                              (arguments[0].items = { button: arguments[0].items }),
                            this.controlgroup.apply(this, arguments))
                );
            })),
    t.ui.button,
    t.extend(t.ui, { datepicker: { version: "1.12.1" } });
    let m;
    t.extend(s.prototype, {
        markerClassName: "hasDatepicker",
        maxRows: 4,
        _widgetDatepicker() {
            return this.dpDiv;
        },
        setDefaults(t) {
            return a(this._defaults, t || {}), this;
        },
        _attachDatepicker(e, i) {
            let s;
            let n;
            let o;
            (s = e.nodeName.toLowerCase()),
            (n = s === "div" || s === "span"),
            e.id || ((this.uuid += 1), (e.id = `dp${this.uuid}`)),
            (o = this._newInst(t(e), n)),
            (o.settings = t.extend({}, i || {})),
            s === "input" ? this._connectDatepicker(e, o) : n && this._inlineDatepicker(e, o);
        },
        _newInst(e, i) {
            const s = e[0].id.replace(/([^A-Za-z0-9_\-])/g, "\\\\$1");
            return {
                id: s,
                input: e,
                selectedDay: 0,
                selectedMonth: 0,
                selectedYear: 0,
                drawMonth: 0,
                drawYear: 0,
                inline: i,
                dpDiv: i
                    ? n(
                        t(
                            `<div class='${this._inlineClass} ui-datepicker ui-widget ui-widget-content ui-helper-clearfix ui-corner-all'></div>`,
                        ),
                    )
                    : this.dpDiv,
            };
        },
        _connectDatepicker(e, i) {
            const s = t(e);
            (i.append = t([])),
            (i.trigger = t([])),
            s.hasClass(this.markerClassName) ||
                    (this._attachments(s, i),
                    s
                        .addClass(this.markerClassName)
                        .on("keydown", this._doKeyDown)
                        .on("keypress", this._doKeyPress)
                        .on("keyup", this._doKeyUp),
                    this._autoSize(i),
                    t.data(e, "datepicker", i),
                    i.settings.disabled && this._disableDatepicker(e));
        },
        _attachments(e, i) {
            let s;
            let n;
            let o;
            const a = this._get(i, "appendText");
            const r = this._get(i, "isRTL");
            i.append && i.append.remove(),
            a &&
                    ((i.append = t(`<span class='${this._appendClass}'>${a}</span>`)),
                    e[r ? "before" : "after"](i.append)),
            e.off("focus", this._showDatepicker),
            i.trigger && i.trigger.remove(),
            (s = this._get(i, "showOn")),
            (s === "focus" || s === "both") && e.on("focus", this._showDatepicker),
            (s === "button" || s === "both") &&
                    ((n = this._get(i, "buttonText")),
                    (o = this._get(i, "buttonImage")),
                    (i.trigger = t(
                        this._get(i, "buttonImageOnly")
                            ? t("<img/>")
                                .addClass(this._triggerClass)
                                .attr({ src: o, alt: n, title: n })
                            : t("<button type='button'></button>")
                                .addClass(this._triggerClass)
                                .html(o ? t("<img/>").attr({ src: o, alt: n, title: n }) : n),
                    )),
                    e[r ? "before" : "after"](i.trigger),
                    i.trigger.on(
                        "click",
                        () => (
                            t.datepicker._datepickerShowing && t.datepicker._lastInput === e[0]
                                ? t.datepicker._hideDatepicker()
                                : t.datepicker._datepickerShowing &&
                                  t.datepicker._lastInput !== e[0]
                                    ? (t.datepicker._hideDatepicker(),
                                    t.datepicker._showDatepicker(e[0]))
                                    : t.datepicker._showDatepicker(e[0]),
                            !1
                        ),
                    ));
        },
        _autoSize(t) {
            if (this._get(t, "autoSize") && !t.inline) {
                let e;
                let i;
                let s;
                let n;
                const o = new Date(2009, 11, 20);
                const a = this._get(t, "dateFormat");
                a.match(/[DM]/) &&
                    ((e = function (t) {
                        for (i = 0, s = 0, n = 0; t.length > n; n++)
                            t[n].length > i && ((i = t[n].length), (s = n));
                        return s;
                    }),
                    o.setMonth(e(this._get(t, a.match(/MM/) ? "monthNames" : "monthNamesShort"))),
                    o.setDate(
                        e(this._get(t, a.match(/DD/) ? "dayNames" : "dayNamesShort")) +
                            20 -
                            o.getDay(),
                    )),
                t.input.attr("size", this._formatDate(t, o).length);
            }
        },
        _inlineDatepicker(e, i) {
            const s = t(e);
            s.hasClass(this.markerClassName) ||
                (s.addClass(this.markerClassName).append(i.dpDiv),
                t.data(e, "datepicker", i),
                this._setDate(i, this._getDefaultDate(i), !0),
                this._updateDatepicker(i),
                this._updateAlternate(i),
                i.settings.disabled && this._disableDatepicker(e),
                i.dpDiv.css("display", "block"));
        },
        _dialogDatepicker(e, i, s, n, o) {
            let r;
            let h;
            let l;
            let c;
            let u;
            let d = this._dialogInst;
            return (
                d ||
                    ((this.uuid += 1),
                    (r = `dp${this.uuid}`),
                    (this._dialogInput = t(
                        `<input type='text' id='${r}' style='position: absolute; top: -100px; width: 0px;'/>`,
                    )),
                    this._dialogInput.on("keydown", this._doKeyDown),
                    t("body").append(this._dialogInput),
                    (d = this._dialogInst = this._newInst(this._dialogInput, !1)),
                    (d.settings = {}),
                    t.data(this._dialogInput[0], "datepicker", d)),
                a(d.settings, n || {}),
                (i = i && i.constructor === Date ? this._formatDate(d, i) : i),
                this._dialogInput.val(i),
                (this._pos = o ? (o.length ? o : [o.pageX, o.pageY]) : null),
                this._pos ||
                    ((h = document.documentElement.clientWidth),
                    (l = document.documentElement.clientHeight),
                    (c = document.documentElement.scrollLeft || document.body.scrollLeft),
                    (u = document.documentElement.scrollTop || document.body.scrollTop),
                    (this._pos = [h / 2 - 100 + c, l / 2 - 150 + u])),
                this._dialogInput
                    .css("left", `${this._pos[0] + 20}px`)
                    .css("top", `${this._pos[1]}px`),
                (d.settings.onSelect = s),
                (this._inDialog = !0),
                this.dpDiv.addClass(this._dialogClass),
                this._showDatepicker(this._dialogInput[0]),
                t.blockUI && t.blockUI(this.dpDiv),
                t.data(this._dialogInput[0], "datepicker", d),
                this
            );
        },
        _destroyDatepicker(e) {
            let i;
            const s = t(e);
            const n = t.data(e, "datepicker");
            s.hasClass(this.markerClassName) &&
                ((i = e.nodeName.toLowerCase()),
                t.removeData(e, "datepicker"),
                i === "input"
                    ? (n.append.remove(),
                    n.trigger.remove(),
                    s
                        .removeClass(this.markerClassName)
                        .off("focus", this._showDatepicker)
                        .off("keydown", this._doKeyDown)
                        .off("keypress", this._doKeyPress)
                        .off("keyup", this._doKeyUp))
                    : (i === "div" || i === "span") && s.removeClass(this.markerClassName).empty(),
                m === n && (m = null));
        },
        _enableDatepicker(e) {
            let i;
            let s;
            const n = t(e);
            const o = t.data(e, "datepicker");
            n.hasClass(this.markerClassName) &&
                ((i = e.nodeName.toLowerCase()),
                i === "input"
                    ? ((e.disabled = !1),
                    o.trigger
                        .filter("button")
                        .each(function () {
                            this.disabled = !1;
                        })
                        .end()
                        .filter("img")
                        .css({ opacity: "1.0", cursor: "" }))
                    : (i === "div" || i === "span") &&
                      ((s = n.children(`.${this._inlineClass}`)),
                      s.children().removeClass("ui-state-disabled"),
                      s
                          .find("select.ui-datepicker-month, select.ui-datepicker-year")
                          .prop("disabled", !1)),
                (this._disabledInputs = t.map(this._disabledInputs, (t) => (t === e ? null : t))));
        },
        _disableDatepicker(e) {
            let i;
            let s;
            const n = t(e);
            const o = t.data(e, "datepicker");
            n.hasClass(this.markerClassName) &&
                ((i = e.nodeName.toLowerCase()),
                i === "input"
                    ? ((e.disabled = !0),
                    o.trigger
                        .filter("button")
                        .each(function () {
                            this.disabled = !0;
                        })
                        .end()
                        .filter("img")
                        .css({ opacity: "0.5", cursor: "default" }))
                    : (i === "div" || i === "span") &&
                      ((s = n.children(`.${this._inlineClass}`)),
                      s.children().addClass("ui-state-disabled"),
                      s
                          .find("select.ui-datepicker-month, select.ui-datepicker-year")
                          .prop("disabled", !0)),
                (this._disabledInputs = t.map(this._disabledInputs, (t) => (t === e ? null : t))),
                (this._disabledInputs[this._disabledInputs.length] = e));
        },
        _isDisabledDatepicker(t) {
            if (!t) return !1;
            for (let e = 0; this._disabledInputs.length > e; e++)
                if (this._disabledInputs[e] === t) return !0;
            return !1;
        },
        _getInst(e) {
            try {
                return t.data(e, "datepicker");
            } catch (i) {
                throw "Missing instance data for this datepicker";
            }
        },
        _optionDatepicker(e, i, s) {
            let n;
            let o;
            let r;
            let h;
            const l = this._getInst(e);
            return arguments.length === 2 && typeof i === "string"
                ? i === "defaults"
                    ? t.extend({}, t.datepicker._defaults)
                    : l
                        ? i === "all"
                            ? t.extend({}, l.settings)
                            : this._get(l, i)
                        : null
                : ((n = i || {}),
                typeof i === "string" && ((n = {}), (n[i] = s)),
                l &&
                      (this._curInst === l && this._hideDatepicker(),
                      (o = this._getDateDatepicker(e, !0)),
                      (r = this._getMinMaxDate(l, "min")),
                      (h = this._getMinMaxDate(l, "max")),
                      a(l.settings, n),
                      r !== null &&
                          void 0 !== n.dateFormat &&
                          void 0 === n.minDate &&
                          (l.settings.minDate = this._formatDate(l, r)),
                      h !== null &&
                          void 0 !== n.dateFormat &&
                          void 0 === n.maxDate &&
                          (l.settings.maxDate = this._formatDate(l, h)),
                      "disabled" in n &&
                          (n.disabled ? this._disableDatepicker(e) : this._enableDatepicker(e)),
                      this._attachments(t(e), l),
                      this._autoSize(l),
                      this._setDate(l, o),
                      this._updateAlternate(l),
                      this._updateDatepicker(l)),
                void 0);
        },
        _changeDatepicker(t, e, i) {
            this._optionDatepicker(t, e, i);
        },
        _refreshDatepicker(t) {
            const e = this._getInst(t);
            e && this._updateDatepicker(e);
        },
        _setDateDatepicker(t, e) {
            const i = this._getInst(t);
            i && (this._setDate(i, e), this._updateDatepicker(i), this._updateAlternate(i));
        },
        _getDateDatepicker(t, e) {
            const i = this._getInst(t);
            return i && !i.inline && this._setDateFromField(i, e), i ? this._getDate(i) : null;
        },
        _doKeyDown(e) {
            let i;
            let s;
            let n;
            const o = t.datepicker._getInst(e.target);
            let a = !0;
            const r = o.dpDiv.is(".ui-datepicker-rtl");
            if (((o._keyEvent = !0), t.datepicker._datepickerShowing))
                switch (e.keyCode) {
                case 9:
                    t.datepicker._hideDatepicker(), (a = !1);
                    break;
                case 13:
                    return (
                        (n = t(
                            `td.${t.datepicker._dayOverClass}:not(.${t.datepicker._currentClass})`,
                            o.dpDiv,
                        )),
                        n[0] &&
                                t.datepicker._selectDay(
                                    e.target,
                                    o.selectedMonth,
                                    o.selectedYear,
                                    n[0],
                                ),
                        (i = t.datepicker._get(o, "onSelect")),
                        i
                            ? ((s = t.datepicker._formatDate(o)),
                            i.apply(o.input ? o.input[0] : null, [s, o]))
                            : t.datepicker._hideDatepicker(),
                        !1
                    );
                case 27:
                    t.datepicker._hideDatepicker();
                    break;
                case 33:
                    t.datepicker._adjustDate(
                        e.target,
                        e.ctrlKey
                            ? -t.datepicker._get(o, "stepBigMonths")
                            : -t.datepicker._get(o, "stepMonths"),
                        "M",
                    );
                    break;
                case 34:
                    t.datepicker._adjustDate(
                        e.target,
                        e.ctrlKey
                            ? +t.datepicker._get(o, "stepBigMonths")
                            : +t.datepicker._get(o, "stepMonths"),
                        "M",
                    );
                    break;
                case 35:
                    (e.ctrlKey || e.metaKey) && t.datepicker._clearDate(e.target),
                    (a = e.ctrlKey || e.metaKey);
                    break;
                case 36:
                    (e.ctrlKey || e.metaKey) && t.datepicker._gotoToday(e.target),
                    (a = e.ctrlKey || e.metaKey);
                    break;
                case 37:
                    (e.ctrlKey || e.metaKey) &&
                            t.datepicker._adjustDate(e.target, r ? 1 : -1, "D"),
                    (a = e.ctrlKey || e.metaKey),
                    e.originalEvent.altKey &&
                                t.datepicker._adjustDate(
                                    e.target,
                                    e.ctrlKey
                                        ? -t.datepicker._get(o, "stepBigMonths")
                                        : -t.datepicker._get(o, "stepMonths"),
                                    "M",
                                );
                    break;
                case 38:
                    (e.ctrlKey || e.metaKey) && t.datepicker._adjustDate(e.target, -7, "D"),
                    (a = e.ctrlKey || e.metaKey);
                    break;
                case 39:
                    (e.ctrlKey || e.metaKey) &&
                            t.datepicker._adjustDate(e.target, r ? -1 : 1, "D"),
                    (a = e.ctrlKey || e.metaKey),
                    e.originalEvent.altKey &&
                                t.datepicker._adjustDate(
                                    e.target,
                                    e.ctrlKey
                                        ? +t.datepicker._get(o, "stepBigMonths")
                                        : +t.datepicker._get(o, "stepMonths"),
                                    "M",
                                );
                    break;
                case 40:
                    (e.ctrlKey || e.metaKey) && t.datepicker._adjustDate(e.target, 7, "D"),
                    (a = e.ctrlKey || e.metaKey);
                    break;
                default:
                    a = !1;
                }
            else e.keyCode === 36 && e.ctrlKey ? t.datepicker._showDatepicker(this) : (a = !1);
            a && (e.preventDefault(), e.stopPropagation());
        },
        _doKeyPress(e) {
            let i;
            let s;
            const n = t.datepicker._getInst(e.target);
            return t.datepicker._get(n, "constrainInput")
                ? ((i = t.datepicker._possibleChars(t.datepicker._get(n, "dateFormat"))),
                (s = String.fromCharCode(e.charCode == null ? e.keyCode : e.charCode)),
                e.ctrlKey || e.metaKey || s < " " || !i || i.indexOf(s) > -1)
                : void 0;
        },
        _doKeyUp(e) {
            let i;
            const s = t.datepicker._getInst(e.target);
            if (s.input.val() !== s.lastVal)
                try {
                    (i = t.datepicker.parseDate(
                        t.datepicker._get(s, "dateFormat"),
                        s.input ? s.input.val() : null,
                        t.datepicker._getFormatConfig(s),
                    )),
                    i &&
                            (t.datepicker._setDateFromField(s),
                            t.datepicker._updateAlternate(s),
                            t.datepicker._updateDatepicker(s));
                } catch (n) {}
            return !0;
        },
        _showDatepicker(e) {
            if (
                ((e = e.target || e),
                e.nodeName.toLowerCase() !== "input" && (e = t("input", e.parentNode)[0]),
                !t.datepicker._isDisabledDatepicker(e) && t.datepicker._lastInput !== e)
            ) {
                let s;
                let n;
                let o;
                let r;
                let h;
                let l;
                let c;
                (s = t.datepicker._getInst(e)),
                t.datepicker._curInst &&
                        t.datepicker._curInst !== s &&
                        (t.datepicker._curInst.dpDiv.stop(!0, !0),
                        s &&
                            t.datepicker._datepickerShowing &&
                            t.datepicker._hideDatepicker(t.datepicker._curInst.input[0])),
                (n = t.datepicker._get(s, "beforeShow")),
                (o = n ? n.apply(e, [e, s]) : {}),
                o !== !1 &&
                        (a(s.settings, o),
                        (s.lastVal = null),
                        (t.datepicker._lastInput = e),
                        t.datepicker._setDateFromField(s),
                        t.datepicker._inDialog && (e.value = ""),
                        t.datepicker._pos ||
                            ((t.datepicker._pos = t.datepicker._findPos(e)),
                            (t.datepicker._pos[1] += e.offsetHeight)),
                        (r = !1),
                        t(e)
                            .parents()
                            .each(function () {
                                return (r |= t(this).css("position") === "fixed"), !r;
                            }),
                        (h = { left: t.datepicker._pos[0], top: t.datepicker._pos[1] }),
                        (t.datepicker._pos = null),
                        s.dpDiv.empty(),
                        s.dpDiv.css({ position: "absolute", display: "block", top: "-1000px" }),
                        t.datepicker._updateDatepicker(s),
                        (h = t.datepicker._checkOffset(s, h, r)),
                        s.dpDiv.css({
                            position:
                                t.datepicker._inDialog && t.blockUI
                                    ? "static"
                                    : r
                                        ? "fixed"
                                        : "absolute",
                            display: "none",
                            left: `${h.left}px`,
                            top: `${h.top}px`,
                        }),
                        s.inline ||
                            ((l = t.datepicker._get(s, "showAnim")),
                            (c = t.datepicker._get(s, "duration")),
                            s.dpDiv.css("z-index", i(t(e)) + 1),
                            (t.datepicker._datepickerShowing = !0),
                            t.effects && t.effects.effect[l]
                                ? s.dpDiv.show(l, t.datepicker._get(s, "showOptions"), c)
                                : s.dpDiv[l || "show"](l ? c : null),
                            t.datepicker._shouldFocusInput(s) && s.input.trigger("focus"),
                            (t.datepicker._curInst = s)));
            }
        },
        _updateDatepicker(e) {
            (this.maxRows = 4),
            (m = e),
            e.dpDiv.empty().append(this._generateHTML(e)),
            this._attachHandlers(e);
            let i;
            const s = this._getNumberOfMonths(e);
            const n = s[1];
            const a = 17;
            const r = e.dpDiv.find(`.${this._dayOverClass} a`);
            r.length > 0 && o.apply(r.get(0)),
            e.dpDiv
                .removeClass(
                    "ui-datepicker-multi-2 ui-datepicker-multi-3 ui-datepicker-multi-4",
                )
                .width(""),
            n > 1 && e.dpDiv.addClass(`ui-datepicker-multi-${n}`).css("width", `${a * n}em`),
            e.dpDiv[`${s[0] !== 1 || s[1] !== 1 ? "add" : "remove"}Class`](
                "ui-datepicker-multi",
            ),
            e.dpDiv[`${this._get(e, "isRTL") ? "add" : "remove"}Class`]("ui-datepicker-rtl"),
            e === t.datepicker._curInst &&
                    t.datepicker._datepickerShowing &&
                    t.datepicker._shouldFocusInput(e) &&
                    e.input.trigger("focus"),
            e.yearshtml &&
                    ((i = e.yearshtml),
                    setTimeout(() => {
                        i === e.yearshtml &&
                            e.yearshtml &&
                            e.dpDiv
                                .find("select.ui-datepicker-year:first")
                                .replaceWith(e.yearshtml),
                        (i = e.yearshtml = null);
                    }, 0));
        },
        _shouldFocusInput(t) {
            return (
                t.input &&
                t.input.is(":visible") &&
                !t.input.is(":disabled") &&
                !t.input.is(":focus")
            );
        },
        _checkOffset(e, i, s) {
            const n = e.dpDiv.outerWidth();
            const o = e.dpDiv.outerHeight();
            const a = e.input ? e.input.outerWidth() : 0;
            const r = e.input ? e.input.outerHeight() : 0;
            const h = document.documentElement.clientWidth + (s ? 0 : t(document).scrollLeft());
            const l = document.documentElement.clientHeight + (s ? 0 : t(document).scrollTop());
            return (
                (i.left -= this._get(e, "isRTL") ? n - a : 0),
                (i.left -= s && i.left === e.input.offset().left ? t(document).scrollLeft() : 0),
                (i.top -= s && i.top === e.input.offset().top + r ? t(document).scrollTop() : 0),
                (i.left -= Math.min(
                    i.left,
                    i.left + n > h && h > n ? Math.abs(i.left + n - h) : 0,
                )),
                (i.top -= Math.min(i.top, i.top + o > l && l > o ? Math.abs(o + r) : 0)),
                i
            );
        },
        _findPos(e) {
            for (
                var i, s = this._getInst(e), n = this._get(s, "isRTL");
                e && (e.type === "hidden" || e.nodeType !== 1 || t.expr.filters.hidden(e));

            )
                e = e[n ? "previousSibling" : "nextSibling"];
            return (i = t(e).offset()), [i.left, i.top];
        },
        _hideDatepicker(e) {
            let i;
            let s;
            let n;
            let o;
            const a = this._curInst;
            !a ||
                (e && a !== t.data(e, "datepicker")) ||
                (this._datepickerShowing &&
                    ((i = this._get(a, "showAnim")),
                    (s = this._get(a, "duration")),
                    (n = function () {
                        t.datepicker._tidyDialog(a);
                    }),
                    t.effects && (t.effects.effect[i] || t.effects[i])
                        ? a.dpDiv.hide(i, t.datepicker._get(a, "showOptions"), s, n)
                        : a.dpDiv[
                            i === "slideDown" ? "slideUp" : i === "fadeIn" ? "fadeOut" : "hide"
                        ](i ? s : null, n),
                    i || n(),
                    (this._datepickerShowing = !1),
                    (o = this._get(a, "onClose")),
                    o && o.apply(a.input ? a.input[0] : null, [a.input ? a.input.val() : "", a]),
                    (this._lastInput = null),
                    this._inDialog &&
                        (this._dialogInput.css({ position: "absolute", left: "0", top: "-100px" }),
                        t.blockUI && (t.unblockUI(), t("body").append(this.dpDiv))),
                    (this._inDialog = !1)));
        },
        _tidyDialog(t) {
            t.dpDiv.removeClass(this._dialogClass).off(".ui-datepicker-calendar");
        },
        _checkExternalClick(e) {
            if (t.datepicker._curInst) {
                const i = t(e.target);
                const s = t.datepicker._getInst(i[0]);
                ((i[0].id !== t.datepicker._mainDivId &&
                    i.parents(`#${t.datepicker._mainDivId}`).length === 0 &&
                    !i.hasClass(t.datepicker.markerClassName) &&
                    !i.closest(`.${t.datepicker._triggerClass}`).length &&
                    t.datepicker._datepickerShowing &&
                    (!t.datepicker._inDialog || !t.blockUI)) ||
                    (i.hasClass(t.datepicker.markerClassName) && t.datepicker._curInst !== s)) &&
                    t.datepicker._hideDatepicker();
            }
        },
        _adjustDate(e, i, s) {
            const n = t(e);
            const o = this._getInst(n[0]);
            this._isDisabledDatepicker(n[0]) ||
                (this._adjustInstDate(o, i + (s === "M" ? this._get(o, "showCurrentAtPos") : 0), s),
                this._updateDatepicker(o));
        },
        _gotoToday(e) {
            let i;
            const s = t(e);
            const n = this._getInst(s[0]);
            this._get(n, "gotoCurrent") && n.currentDay
                ? ((n.selectedDay = n.currentDay),
                (n.drawMonth = n.selectedMonth = n.currentMonth),
                (n.drawYear = n.selectedYear = n.currentYear))
                : ((i = new Date()),
                (n.selectedDay = i.getDate()),
                (n.drawMonth = n.selectedMonth = i.getMonth()),
                (n.drawYear = n.selectedYear = i.getFullYear())),
            this._notifyChange(n),
            this._adjustDate(s);
        },
        _selectMonthYear(e, i, s) {
            const n = t(e);
            const o = this._getInst(n[0]);
            (o[`selected${s === "M" ? "Month" : "Year"}`] = o[
                `draw${s === "M" ? "Month" : "Year"}`
            ] =
                parseInt(i.options[i.selectedIndex].value, 10)),
            this._notifyChange(o),
            this._adjustDate(n);
        },
        _selectDay(e, i, s, n) {
            let o;
            const a = t(e);
            t(n).hasClass(this._unselectableClass) ||
                this._isDisabledDatepicker(a[0]) ||
                ((o = this._getInst(a[0])),
                (o.selectedDay = o.currentDay = t("a", n).html()),
                (o.selectedMonth = o.currentMonth = i),
                (o.selectedYear = o.currentYear = s),
                this._selectDate(
                    e,
                    this._formatDate(o, o.currentDay, o.currentMonth, o.currentYear),
                ));
        },
        _clearDate(e) {
            const i = t(e);
            this._selectDate(i, "");
        },
        _selectDate(e, i) {
            let s;
            const n = t(e);
            const o = this._getInst(n[0]);
            (i = i != null ? i : this._formatDate(o)),
            o.input && o.input.val(i),
            this._updateAlternate(o),
            (s = this._get(o, "onSelect")),
            s
                ? s.apply(o.input ? o.input[0] : null, [i, o])
                : o.input && o.input.trigger("change"),
            o.inline
                ? this._updateDatepicker(o)
                : (this._hideDatepicker(),
                (this._lastInput = o.input[0]),
                typeof o.input[0] !== "object" && o.input.trigger("focus"),
                (this._lastInput = null));
        },
        _updateAlternate(e) {
            let i;
            let s;
            let n;
            const o = this._get(e, "altField");
            o &&
                ((i = this._get(e, "altFormat") || this._get(e, "dateFormat")),
                (s = this._getDate(e)),
                (n = this.formatDate(i, s, this._getFormatConfig(e))),
                t(o).val(n));
        },
        noWeekends(t) {
            const e = t.getDay();
            return [e > 0 && e < 6, ""];
        },
        iso8601Week(t) {
            let e;
            const i = new Date(t.getTime());
            return (
                i.setDate(i.getDate() + 4 - (i.getDay() || 7)),
                (e = i.getTime()),
                i.setMonth(0),
                i.setDate(1),
                Math.floor(Math.round((e - i) / 864e5) / 7) + 1
            );
        },
        parseDate(e, i, s) {
            if (e == null || i == null) throw "Invalid arguments";
            if (((i = typeof i === "object" ? `${i}` : `${i}`), i === "")) return null;
            let n;
            let o;
            let a;
            let r;
            let h = 0;
            const l = (s ? s.shortYearCutoff : null) || this._defaults.shortYearCutoff;
            const c =
                typeof l !== "string" ? l : (new Date().getFullYear() % 100) + parseInt(l, 10);
            const u = (s ? s.dayNamesShort : null) || this._defaults.dayNamesShort;
            const d = (s ? s.dayNames : null) || this._defaults.dayNames;
            const p = (s ? s.monthNamesShort : null) || this._defaults.monthNamesShort;
            const f = (s ? s.monthNames : null) || this._defaults.monthNames;
            let g = -1;
            let m = -1;
            let _ = -1;
            let v = -1;
            let b = !1;
            const y = function (t) {
                const i = e.length > n + 1 && e.charAt(n + 1) === t;
                return i && n++, i;
            };
            const w = function (t) {
                const e = y(t);
                const s = t === "@" ? 14 : t === "!" ? 20 : t === "y" && e ? 4 : t === "o" ? 3 : 2;
                const n = t === "y" ? s : 1;
                const o = RegExp(`^\\d{${n},${s}}`);
                const a = i.substring(h).match(o);
                if (!a) throw `Missing number at position ${h}`;
                return (h += a[0].length), parseInt(a[0], 10);
            };
            const k = function (e, s, n) {
                let o = -1;
                const a = t
                    .map(y(e) ? n : s, (t, e) => [[e, t]])
                    .sort((t, e) => -(t[1].length - e[1].length));
                if (
                    (t.each(a, (t, e) => {
                        const s = e[1];
                        return i.substr(h, s.length).toLowerCase() === s.toLowerCase()
                            ? ((o = e[0]), (h += s.length), !1)
                            : void 0;
                    }),
                    o !== -1)
                )
                    return o + 1;
                throw `Unknown name at position ${h}`;
            };
            const x = function () {
                if (i.charAt(h) !== e.charAt(n)) throw `Unexpected literal at position ${h}`;
                h++;
            };
            for (n = 0; e.length > n; n++)
                if (b) e.charAt(n) !== "'" || y("'") ? x() : (b = !1);
                else
                    switch (e.charAt(n)) {
                    case "d":
                        _ = w("d");
                        break;
                    case "D":
                        k("D", u, d);
                        break;
                    case "o":
                        v = w("o");
                        break;
                    case "m":
                        m = w("m");
                        break;
                    case "M":
                        m = k("M", p, f);
                        break;
                    case "y":
                        g = w("y");
                        break;
                    case "@":
                        (r = new Date(w("@"))),
                        (g = r.getFullYear()),
                        (m = r.getMonth() + 1),
                        (_ = r.getDate());
                        break;
                    case "!":
                        (r = new Date((w("!") - this._ticksTo1970) / 1e4)),
                        (g = r.getFullYear()),
                        (m = r.getMonth() + 1),
                        (_ = r.getDate());
                        break;
                    case "'":
                        y("'") ? x() : (b = !0);
                        break;
                    default:
                        x();
                    }
            if (i.length > h && ((a = i.substr(h)), !/^\s+/.test(a)))
                throw `Extra/unparsed characters found in date: ${a}`;
            if (
                (g === -1
                    ? (g = new Date().getFullYear())
                    : g < 100 &&
                      (g +=
                          new Date().getFullYear() -
                          (new Date().getFullYear() % 100) +
                          (c >= g ? 0 : -100)),
                v > -1)
            )
                for (m = 1, _ = v; ; ) {
                    if (((o = this._getDaysInMonth(g, m - 1)), o >= _)) break;
                    m++, (_ -= o);
                }
            if (
                ((r = this._daylightSavingAdjust(new Date(g, m - 1, _))),
                r.getFullYear() !== g || r.getMonth() + 1 !== m || r.getDate() !== _)
            )
                throw "Invalid date";
            return r;
        },
        ATOM: "yy-mm-dd",
        COOKIE: "D, dd M yy",
        ISO_8601: "yy-mm-dd",
        RFC_822: "D, d M y",
        RFC_850: "DD, dd-M-y",
        RFC_1036: "D, d M y",
        RFC_1123: "D, d M yy",
        RFC_2822: "D, d M yy",
        RSS: "D, d M y",
        TICKS: "!",
        TIMESTAMP: "@",
        W3C: "yy-mm-dd",
        _ticksTo1970:
            1e7 *
            60 *
            60 *
            24 *
            (718685 + Math.floor(492.5) - Math.floor(19.7) + Math.floor(4.925)),
        formatDate(t, e, i) {
            if (!e) return "";
            let s;
            const n = (i ? i.dayNamesShort : null) || this._defaults.dayNamesShort;
            const o = (i ? i.dayNames : null) || this._defaults.dayNames;
            const a = (i ? i.monthNamesShort : null) || this._defaults.monthNamesShort;
            const r = (i ? i.monthNames : null) || this._defaults.monthNames;
            const h = function (e) {
                const i = t.length > s + 1 && t.charAt(s + 1) === e;
                return i && s++, i;
            };
            const l = function (t, e, i) {
                let s = `${e}`;
                if (h(t)) for (; i > s.length; ) s = `0${s}`;
                return s;
            };
            const c = function (t, e, i, s) {
                return h(t) ? s[e] : i[e];
            };
            let u = "";
            let d = !1;
            if (e)
                for (s = 0; t.length > s; s++)
                    if (d) t.charAt(s) !== "'" || h("'") ? (u += t.charAt(s)) : (d = !1);
                    else
                        switch (t.charAt(s)) {
                        case "d":
                            u += l("d", e.getDate(), 2);
                            break;
                        case "D":
                            u += c("D", e.getDay(), n, o);
                            break;
                        case "o":
                            u += l(
                                "o",
                                Math.round(
                                    (new Date(
                                        e.getFullYear(),
                                        e.getMonth(),
                                        e.getDate(),
                                    ).getTime() -
                                            new Date(e.getFullYear(), 0, 0).getTime()) /
                                            864e5,
                                ),
                                3,
                            );
                            break;
                        case "m":
                            u += l("m", e.getMonth() + 1, 2);
                            break;
                        case "M":
                            u += c("M", e.getMonth(), a, r);
                            break;
                        case "y":
                            u += h("y")
                                ? e.getFullYear()
                                : (e.getFullYear() % 100 < 10 ? "0" : "") +
                                      (e.getFullYear() % 100);
                            break;
                        case "@":
                            u += e.getTime();
                            break;
                        case "!":
                            u += 1e4 * e.getTime() + this._ticksTo1970;
                            break;
                        case "'":
                            h("'") ? (u += "'") : (d = !0);
                            break;
                        default:
                            u += t.charAt(s);
                        }
            return u;
        },
        _possibleChars(t) {
            let e;
            let i = "";
            let s = !1;
            const n = function (i) {
                const s = t.length > e + 1 && t.charAt(e + 1) === i;
                return s && e++, s;
            };
            for (e = 0; t.length > e; e++)
                if (s) t.charAt(e) !== "'" || n("'") ? (i += t.charAt(e)) : (s = !1);
                else
                    switch (t.charAt(e)) {
                    case "d":
                    case "m":
                    case "y":
                    case "@":
                        i += "0123456789";
                        break;
                    case "D":
                    case "M":
                        return null;
                    case "'":
                        n("'") ? (i += "'") : (s = !0);
                        break;
                    default:
                        i += t.charAt(e);
                    }
            return i;
        },
        _get(t, e) {
            return void 0 !== t.settings[e] ? t.settings[e] : this._defaults[e];
        },
        _setDateFromField(t, e) {
            if (t.input.val() !== t.lastVal) {
                const i = this._get(t, "dateFormat");
                let s = (t.lastVal = t.input ? t.input.val() : null);
                const n = this._getDefaultDate(t);
                let o = n;
                const a = this._getFormatConfig(t);
                try {
                    o = this.parseDate(i, s, a) || n;
                } catch (r) {
                    s = e ? "" : s;
                }
                (t.selectedDay = o.getDate()),
                (t.drawMonth = t.selectedMonth = o.getMonth()),
                (t.drawYear = t.selectedYear = o.getFullYear()),
                (t.currentDay = s ? o.getDate() : 0),
                (t.currentMonth = s ? o.getMonth() : 0),
                (t.currentYear = s ? o.getFullYear() : 0),
                this._adjustInstDate(t);
            }
        },
        _getDefaultDate(t) {
            return this._restrictMinMax(
                t,
                this._determineDate(t, this._get(t, "defaultDate"), new Date()),
            );
        },
        _determineDate(e, i, s) {
            const n = function (t) {
                const e = new Date();
                return e.setDate(e.getDate() + t), e;
            };
            const o = function (i) {
                try {
                    return t.datepicker.parseDate(
                        t.datepicker._get(e, "dateFormat"),
                        i,
                        t.datepicker._getFormatConfig(e),
                    );
                } catch (s) {}
                for (
                    var n =
                            (i.toLowerCase().match(/^c/) ? t.datepicker._getDate(e) : null) ||
                            new Date(),
                        o = n.getFullYear(),
                        a = n.getMonth(),
                        r = n.getDate(),
                        h = /([+\-]?[0-9]+)\s*(d|D|w|W|m|M|y|Y)?/g,
                        l = h.exec(i);
                    l;

                ) {
                    switch (l[2] || "d") {
                    case "d":
                    case "D":
                        r += parseInt(l[1], 10);
                        break;
                    case "w":
                    case "W":
                        r += 7 * parseInt(l[1], 10);
                        break;
                    case "m":
                    case "M":
                        (a += parseInt(l[1], 10)),
                        (r = Math.min(r, t.datepicker._getDaysInMonth(o, a)));
                        break;
                    case "y":
                    case "Y":
                        (o += parseInt(l[1], 10)),
                        (r = Math.min(r, t.datepicker._getDaysInMonth(o, a)));
                    }
                    l = h.exec(i);
                }
                return new Date(o, a, r);
            };
            let a =
                i == null || i === ""
                    ? s
                    : typeof i === "string"
                        ? o(i)
                        : typeof i === "number"
                            ? isNaN(i)
                                ? s
                                : n(i)
                            : new Date(i.getTime());
            return (
                (a = a && `${a}` == "Invalid Date" ? s : a),
                a && (a.setHours(0), a.setMinutes(0), a.setSeconds(0), a.setMilliseconds(0)),
                this._daylightSavingAdjust(a)
            );
        },
        _daylightSavingAdjust(t) {
            return t ? (t.setHours(t.getHours() > 12 ? t.getHours() + 2 : 0), t) : null;
        },
        _setDate(t, e, i) {
            const s = !e;
            const n = t.selectedMonth;
            const o = t.selectedYear;
            const a = this._restrictMinMax(t, this._determineDate(t, e, new Date()));
            (t.selectedDay = t.currentDay = a.getDate()),
            (t.drawMonth = t.selectedMonth = t.currentMonth = a.getMonth()),
            (t.drawYear = t.selectedYear = t.currentYear = a.getFullYear()),
            (n === t.selectedMonth && o === t.selectedYear) || i || this._notifyChange(t),
            this._adjustInstDate(t),
            t.input && t.input.val(s ? "" : this._formatDate(t));
        },
        _getDate(t) {
            const e =
                !t.currentYear || (t.input && t.input.val() === "")
                    ? null
                    : this._daylightSavingAdjust(
                        new Date(t.currentYear, t.currentMonth, t.currentDay),
                    );
            return e;
        },
        _attachHandlers(e) {
            const i = this._get(e, "stepMonths");
            const s = `#${e.id.replace(/\\\\/g, "\\")}`;
            e.dpDiv.find("[data-handler]").map(function () {
                const e = {
                    prev() {
                        t.datepicker._adjustDate(s, -i, "M");
                    },
                    next() {
                        t.datepicker._adjustDate(s, +i, "M");
                    },
                    hide() {
                        t.datepicker._hideDatepicker();
                    },
                    today() {
                        t.datepicker._gotoToday(s);
                    },
                    selectDay() {
                        return (
                            t.datepicker._selectDay(
                                s,
                                +this.getAttribute("data-month"),
                                +this.getAttribute("data-year"),
                                this,
                            ),
                            !1
                        );
                    },
                    selectMonth() {
                        return t.datepicker._selectMonthYear(s, this, "M"), !1;
                    },
                    selectYear() {
                        return t.datepicker._selectMonthYear(s, this, "Y"), !1;
                    },
                };
                t(this).on(this.getAttribute("data-event"), e[this.getAttribute("data-handler")]);
            });
        },
        _generateHTML(t) {
            let e;
            let i;
            let s;
            let n;
            let o;
            let a;
            let r;
            let h;
            let l;
            let c;
            let u;
            let d;
            let p;
            let f;
            let g;
            let m;
            let _;
            let v;
            let b;
            let y;
            let w;
            let k;
            let x;
            let C;
            let D;
            let I;
            let T;
            let P;
            let M;
            let S;
            let H;
            let z;
            let O;
            let A;
            let N;
            let W;
            let E;
            let F;
            let L;
            const R = new Date();
            const B = this._daylightSavingAdjust(
                new Date(R.getFullYear(), R.getMonth(), R.getDate()),
            );
            const Y = this._get(t, "isRTL");
            const j = this._get(t, "showButtonPanel");
            const q = this._get(t, "hideIfNoPrevNext");
            const K = this._get(t, "navigationAsDateFormat");
            const U = this._getNumberOfMonths(t);
            const V = this._get(t, "showCurrentAtPos");
            const $ = this._get(t, "stepMonths");
            const X = U[0] !== 1 || U[1] !== 1;
            const G = this._daylightSavingAdjust(
                t.currentDay
                    ? new Date(t.currentYear, t.currentMonth, t.currentDay)
                    : new Date(9999, 9, 9),
            );
            const Q = this._getMinMaxDate(t, "min");
            const J = this._getMinMaxDate(t, "max");
            let Z = t.drawMonth - V;
            let te = t.drawYear;
            if ((Z < 0 && ((Z += 12), te--), J))
                for (
                    e = this._daylightSavingAdjust(
                        new Date(J.getFullYear(), J.getMonth() - U[0] * U[1] + 1, J.getDate()),
                    ),
                    e = Q && Q > e ? Q : e;
                    this._daylightSavingAdjust(new Date(te, Z, 1)) > e;

                )
                    Z--, Z < 0 && ((Z = 11), te--);
            for (
                t.drawMonth = Z,
                t.drawYear = te,
                i = this._get(t, "prevText"),
                i = K
                    ? this.formatDate(
                        i,
                        this._daylightSavingAdjust(new Date(te, Z - $, 1)),
                        this._getFormatConfig(t),
                    )
                    : i,
                s = this._canAdjustMonth(t, -1, te, Z)
                    ? `<a class='ui-datepicker-prev ui-corner-all' data-handler='prev' data-event='click' title='${i}'><span class='ui-icon ui-icon-circle-triangle-${
                        Y ? "e" : "w"
                    }'>${i}</span></a>`
                    : q
                        ? ""
                        : `<a class='ui-datepicker-prev ui-corner-all ui-state-disabled' title='${i}'><span class='ui-icon ui-icon-circle-triangle-${
                            Y ? "e" : "w"
                        }'>${i}</span></a>`,
                n = this._get(t, "nextText"),
                n = K
                    ? this.formatDate(
                        n,
                        this._daylightSavingAdjust(new Date(te, Z + $, 1)),
                        this._getFormatConfig(t),
                    )
                    : n,
                o = this._canAdjustMonth(t, 1, te, Z)
                    ? `<a class='ui-datepicker-next ui-corner-all' data-handler='next' data-event='click' title='${n}'><span class='ui-icon ui-icon-circle-triangle-${
                        Y ? "w" : "e"
                    }'>${n}</span></a>`
                    : q
                        ? ""
                        : `<a class='ui-datepicker-next ui-corner-all ui-state-disabled' title='${n}'><span class='ui-icon ui-icon-circle-triangle-${
                            Y ? "w" : "e"
                        }'>${n}</span></a>`,
                a = this._get(t, "currentText"),
                r = this._get(t, "gotoCurrent") && t.currentDay ? G : B,
                a = K ? this.formatDate(a, r, this._getFormatConfig(t)) : a,
                h = t.inline
                    ? ""
                    : `<button type='button' class='ui-datepicker-close ui-state-default ui-priority-primary ui-corner-all' data-handler='hide' data-event='click'>${this._get(
                        t,
                        "closeText",
                    )}</button>`,
                l = j
                    ? `<div class='ui-datepicker-buttonpane ui-widget-content'>${Y ? h : ""}${
                        this._isInRange(t, r)
                            ? `<button type='button' class='ui-datepicker-current ui-state-default ui-priority-secondary ui-corner-all' data-handler='today' data-event='click'>${a}</button>`
                            : ""
                    }${Y ? "" : h}</div>`
                    : "",
                c = parseInt(this._get(t, "firstDay"), 10),
                c = isNaN(c) ? 0 : c,
                u = this._get(t, "showWeek"),
                d = this._get(t, "dayNames"),
                p = this._get(t, "dayNamesMin"),
                f = this._get(t, "monthNames"),
                g = this._get(t, "monthNamesShort"),
                m = this._get(t, "beforeShowDay"),
                _ = this._get(t, "showOtherMonths"),
                v = this._get(t, "selectOtherMonths"),
                b = this._getDefaultDate(t),
                y = "",
                k = 0;
                U[0] > k;
                k++
            ) {
                for (x = "", this.maxRows = 4, C = 0; U[1] > C; C++) {
                    if (
                        ((D = this._daylightSavingAdjust(new Date(te, Z, t.selectedDay))),
                        (I = " ui-corner-all"),
                        (T = ""),
                        X)
                    ) {
                        if (((T += "<div class='ui-datepicker-group"), U[1] > 1))
                            switch (C) {
                            case 0:
                                (T += " ui-datepicker-group-first"),
                                (I = ` ui-corner-${Y ? "right" : "left"}`);
                                break;
                            case U[1] - 1:
                                (T += " ui-datepicker-group-last"),
                                (I = ` ui-corner-${Y ? "left" : "right"}`);
                                break;
                            default:
                                (T += " ui-datepicker-group-middle"), (I = "");
                            }
                        T += "'>";
                    }
                    for (
                        T +=
                            `<div class='ui-datepicker-header ui-widget-header ui-helper-clearfix${I}'>${
                                /all|left/.test(I) && k === 0 ? (Y ? o : s) : ""
                            }${
                                /all|right/.test(I) && k === 0 ? (Y ? s : o) : ""
                            }${this._generateMonthYearHeader(
                                t,
                                Z,
                                te,
                                Q,
                                J,
                                k > 0 || C > 0,
                                f,
                                g,
                            )}</div><table class='ui-datepicker-calendar'><thead>` + "<tr>",
                        P = u
                            ? `<th class='ui-datepicker-week-col'>${this._get(
                                t,
                                "weekHeader",
                            )}</th>`
                            : "",
                        w = 0;
                        w < 7;
                        w++
                    )
                        (M = (w + c) % 7),
                        (P +=
                                `<th scope='col'${
                                    (w + c + 6) % 7 >= 5 ? " class='ui-datepicker-week-end'" : ""
                                }>` + `<span title='${d[M]}'>${p[M]}</span></th>`);
                    for (
                        T += `${P}</tr></thead><tbody>`,
                        S = this._getDaysInMonth(te, Z),
                        te === t.selectedYear &&
                                Z === t.selectedMonth &&
                                (t.selectedDay = Math.min(t.selectedDay, S)),
                        H = (this._getFirstDayOfMonth(te, Z) - c + 7) % 7,
                        z = Math.ceil((H + S) / 7),
                        O = X ? (this.maxRows > z ? this.maxRows : z) : z,
                        this.maxRows = O,
                        A = this._daylightSavingAdjust(new Date(te, Z, 1 - H)),
                        N = 0;
                        O > N;
                        N++
                    ) {
                        for (
                            T += "<tr>",
                            W = u
                                ? `<td class='ui-datepicker-week-col'>${this._get(
                                    t,
                                    "calculateWeek",
                                )(A)}</td>`
                                : "",
                            w = 0;
                            w < 7;
                            w++
                        )
                            (E = m ? m.apply(t.input ? t.input[0] : null, [A]) : [!0, ""]),
                            (F = A.getMonth() !== Z),
                            (L = (F && !v) || !E[0] || (Q && Q > A) || (J && A > J)),
                            (W += `<td class='${
                                (w + c + 6) % 7 >= 5 ? " ui-datepicker-week-end" : ""
                            }${F ? " ui-datepicker-other-month" : ""}${
                                (A.getTime() === D.getTime() &&
                                        Z === t.selectedMonth &&
                                        t._keyEvent) ||
                                    (b.getTime() === A.getTime() && b.getTime() === D.getTime())
                                    ? ` ${this._dayOverClass}`
                                    : ""
                            }${L ? ` ${this._unselectableClass} ui-state-disabled` : ""}${
                                F && !_
                                    ? ""
                                    : ` ${E[1]}${
                                        A.getTime() === G.getTime()
                                            ? ` ${this._currentClass}`
                                            : ""
                                    }${
                                        A.getTime() === B.getTime()
                                            ? " ui-datepicker-today"
                                            : ""
                                    }`
                            }'${
                                (F && !_) || !E[2]
                                    ? ""
                                    : ` title='${E[2].replace(/'/g, "&#39;")}'`
                            }${
                                L
                                    ? ""
                                    : ` data-handler='selectDay' data-event='click' data-month='${A.getMonth()}' data-year='${A.getFullYear()}'`
                            }>${
                                F && !_
                                    ? "&#xa0;"
                                    : L
                                        ? `<span class='ui-state-default'>${A.getDate()}</span>`
                                        : `<a class='ui-state-default${
                                            A.getTime() === B.getTime()
                                                ? " ui-state-highlight"
                                                : ""
                                        }${
                                            A.getTime() === G.getTime() ? " ui-state-active" : ""
                                        }${
                                            F ? " ui-priority-secondary" : ""
                                        }' href='#'>${A.getDate()}</a>`
                            }</td>`),
                            A.setDate(A.getDate() + 1),
                            (A = this._daylightSavingAdjust(A));
                        T += `${W}</tr>`;
                    }
                    Z++,
                    Z > 11 && ((Z = 0), te++),
                    (T += `</tbody></table>${
                        X
                            ? `</div>${
                                U[0] > 0 && C === U[1] - 1
                                    ? "<div class='ui-datepicker-row-break'></div>"
                                    : ""
                            }`
                            : ""
                    }`),
                    (x += T);
                }
                y += x;
            }
            return (y += l), (t._keyEvent = !1), y;
        },
        _generateMonthYearHeader(t, e, i, s, n, o, a, r) {
            let h;
            let l;
            let c;
            let u;
            let d;
            let p;
            let f;
            let g;
            const m = this._get(t, "changeMonth");
            const _ = this._get(t, "changeYear");
            const v = this._get(t, "showMonthAfterYear");
            let b = "<div class='ui-datepicker-title'>";
            let y = "";
            if (o || !m) y += `<span class='ui-datepicker-month'>${a[e]}</span>`;
            else {
                for (
                    h = s && s.getFullYear() === i,
                    l = n && n.getFullYear() === i,
                    y +=
                            "<select class='ui-datepicker-month' data-handler='selectMonth' data-event='change'>",
                    c = 0;
                    c < 12;
                    c++
                )
                    (!h || c >= s.getMonth()) &&
                        (!l || n.getMonth() >= c) &&
                        (y += `<option value='${c}'${c === e ? " selected='selected'" : ""}>${
                            r[c]
                        }</option>`);
                y += "</select>";
            }
            if ((v || (b += y + (!o && m && _ ? "" : "&#xa0;")), !t.yearshtml))
                if (((t.yearshtml = ""), o || !_))
                    b += `<span class='ui-datepicker-year'>${i}</span>`;
                else {
                    for (
                        u = this._get(t, "yearRange").split(":"),
                        d = new Date().getFullYear(),
                        p = function (t) {
                            const e = t.match(/c[+\-].*/)
                                ? i + parseInt(t.substring(1), 10)
                                : t.match(/[+\-].*/)
                                    ? d + parseInt(t, 10)
                                    : parseInt(t, 10);
                            return isNaN(e) ? d : e;
                        },
                        f = p(u[0]),
                        g = Math.max(f, p(u[1] || "")),
                        f = s ? Math.max(f, s.getFullYear()) : f,
                        g = n ? Math.min(g, n.getFullYear()) : g,
                        t.yearshtml +=
                                "<select class='ui-datepicker-year' data-handler='selectYear' data-event='change'>";
                        g >= f;
                        f++
                    )
                        t.yearshtml += `<option value='${f}'${
                            f === i ? " selected='selected'" : ""
                        }>${f}</option>`;
                    (t.yearshtml += "</select>"), (b += t.yearshtml), (t.yearshtml = null);
                }
            return (
                (b += this._get(t, "yearSuffix")),
                v && (b += (!o && m && _ ? "" : "&#xa0;") + y),
                (b += "</div>")
            );
        },
        _adjustInstDate(t, e, i) {
            const s = t.selectedYear + (i === "Y" ? e : 0);
            const n = t.selectedMonth + (i === "M" ? e : 0);
            const o = Math.min(t.selectedDay, this._getDaysInMonth(s, n)) + (i === "D" ? e : 0);
            const a = this._restrictMinMax(t, this._daylightSavingAdjust(new Date(s, n, o)));
            (t.selectedDay = a.getDate()),
            (t.drawMonth = t.selectedMonth = a.getMonth()),
            (t.drawYear = t.selectedYear = a.getFullYear()),
            (i === "M" || i === "Y") && this._notifyChange(t);
        },
        _restrictMinMax(t, e) {
            const i = this._getMinMaxDate(t, "min");
            const s = this._getMinMaxDate(t, "max");
            const n = i && i > e ? i : e;
            return s && n > s ? s : n;
        },
        _notifyChange(t) {
            const e = this._get(t, "onChangeMonthYear");
            e && e.apply(t.input ? t.input[0] : null, [t.selectedYear, t.selectedMonth + 1, t]);
        },
        _getNumberOfMonths(t) {
            const e = this._get(t, "numberOfMonths");
            return e == null ? [1, 1] : typeof e === "number" ? [1, e] : e;
        },
        _getMinMaxDate(t, e) {
            return this._determineDate(t, this._get(t, `${e}Date`), null);
        },
        _getDaysInMonth(t, e) {
            return 32 - this._daylightSavingAdjust(new Date(t, e, 32)).getDate();
        },
        _getFirstDayOfMonth(t, e) {
            return new Date(t, e, 1).getDay();
        },
        _canAdjustMonth(t, e, i, s) {
            const n = this._getNumberOfMonths(t);
            const o = this._daylightSavingAdjust(new Date(i, s + (e < 0 ? e : n[0] * n[1]), 1));
            return (
                e < 0 && o.setDate(this._getDaysInMonth(o.getFullYear(), o.getMonth())),
                this._isInRange(t, o)
            );
        },
        _isInRange(t, e) {
            let i;
            let s;
            const n = this._getMinMaxDate(t, "min");
            const o = this._getMinMaxDate(t, "max");
            let a = null;
            let r = null;
            const h = this._get(t, "yearRange");
            return (
                h &&
                    ((i = h.split(":")),
                    (s = new Date().getFullYear()),
                    (a = parseInt(i[0], 10)),
                    (r = parseInt(i[1], 10)),
                    i[0].match(/[+\-].*/) && (a += s),
                    i[1].match(/[+\-].*/) && (r += s)),
                (!n || e.getTime() >= n.getTime()) &&
                    (!o || e.getTime() <= o.getTime()) &&
                    (!a || e.getFullYear() >= a) &&
                    (!r || r >= e.getFullYear())
            );
        },
        _getFormatConfig(t) {
            let e = this._get(t, "shortYearCutoff");
            return (
                (e =
                    typeof e !== "string" ? e : (new Date().getFullYear() % 100) + parseInt(e, 10)),
                {
                    shortYearCutoff: e,
                    dayNamesShort: this._get(t, "dayNamesShort"),
                    dayNames: this._get(t, "dayNames"),
                    monthNamesShort: this._get(t, "monthNamesShort"),
                    monthNames: this._get(t, "monthNames"),
                }
            );
        },
        _formatDate(t, e, i, s) {
            e ||
                ((t.currentDay = t.selectedDay),
                (t.currentMonth = t.selectedMonth),
                (t.currentYear = t.selectedYear));
            const n = e
                ? typeof e === "object"
                    ? e
                    : this._daylightSavingAdjust(new Date(s, i, e))
                : this._daylightSavingAdjust(new Date(t.currentYear, t.currentMonth, t.currentDay));
            return this.formatDate(this._get(t, "dateFormat"), n, this._getFormatConfig(t));
        },
    }),
    (t.fn.datepicker = function (e) {
        if (!this.length) return this;
        t.datepicker.initialized ||
                (t(document).on("mousedown", t.datepicker._checkExternalClick),
                (t.datepicker.initialized = !0)),
        t(`#${t.datepicker._mainDivId}`).length === 0 &&
                    t("body").append(t.datepicker.dpDiv);
        const i = Array.prototype.slice.call(arguments, 1);
        return typeof e !== "string" ||
                (e !== "isDisabled" && e !== "getDate" && e !== "widget")
            ? e === "option" && arguments.length === 2 && typeof arguments[1] === "string"
                ? t.datepicker[`_${e}Datepicker`].apply(t.datepicker, [this[0]].concat(i))
                : this.each(function () {
                    typeof e === "string"
                        ? t.datepicker[`_${e}Datepicker`].apply(
                            t.datepicker,
                            [this].concat(i),
                        )
                        : t.datepicker._attachDatepicker(this, e);
                })
            : t.datepicker[`_${e}Datepicker`].apply(t.datepicker, [this[0]].concat(i));
    }),
    (t.datepicker = new s()),
    (t.datepicker.initialized = !1),
    (t.datepicker.uuid = new Date().getTime()),
    (t.datepicker.version = "1.12.1"),
    t.datepicker,
    (t.ui.ie = !!/msie [\w.]+/.exec(navigator.userAgent.toLowerCase()));
    let _ = !1;
    t(document).on("mouseup", () => {
        _ = !1;
    }),
    t.widget("ui.mouse", {
        version: "1.12.1",
        options: { cancel: "input, textarea, button, select, option", distance: 1, delay: 0 },
        _mouseInit() {
            const e = this;
            this.element
                .on(`mousedown.${this.widgetName}`, (t) => e._mouseDown(t))
                .on(`click.${this.widgetName}`, (i) =>
                    !0 === t.data(i.target, `${e.widgetName}.preventClickEvent`)
                        ? (t.removeData(i.target, `${e.widgetName}.preventClickEvent`),
                        i.stopImmediatePropagation(),
                        !1)
                        : void 0,
                ),
            (this.started = !1);
        },
        _mouseDestroy() {
            this.element.off(`.${this.widgetName}`),
            this._mouseMoveDelegate &&
                        this.document
                            .off(`mousemove.${this.widgetName}`, this._mouseMoveDelegate)
                            .off(`mouseup.${this.widgetName}`, this._mouseUpDelegate);
        },
        _mouseDown(e) {
            if (!_) {
                (this._mouseMoved = !1),
                this._mouseStarted && this._mouseUp(e),
                (this._mouseDownEvent = e);
                const i = this;
                const s = e.which === 1;
                const n =
                        typeof this.options.cancel === "string" && e.target.nodeName
                            ? t(e.target).closest(this.options.cancel).length
                            : !1;
                return s && !n && this._mouseCapture(e)
                    ? ((this.mouseDelayMet = !this.options.delay),
                    this.mouseDelayMet ||
                              (this._mouseDelayTimer = setTimeout(() => {
                                  i.mouseDelayMet = !0;
                              }, this.options.delay)),
                    this._mouseDistanceMet(e) &&
                          this._mouseDelayMet(e) &&
                          ((this._mouseStarted = this._mouseStart(e) !== !1), !this._mouseStarted)
                        ? (e.preventDefault(), !0)
                        : (!0 === t.data(e.target, `${this.widgetName}.preventClickEvent`) &&
                                    t.removeData(e.target, `${this.widgetName}.preventClickEvent`),
                        (this._mouseMoveDelegate = function (t) {
                            return i._mouseMove(t);
                        }),
                        (this._mouseUpDelegate = function (t) {
                            return i._mouseUp(t);
                        }),
                        this.document
                            .on(`mousemove.${this.widgetName}`, this._mouseMoveDelegate)
                            .on(`mouseup.${this.widgetName}`, this._mouseUpDelegate),
                        e.preventDefault(),
                        (_ = !0),
                        !0))
                    : !0;
            }
        },
        _mouseMove(e) {
            if (this._mouseMoved) {
                if (
                    t.ui.ie &&
                        (!document.documentMode || document.documentMode < 9) &&
                        !e.button
                )
                    return this._mouseUp(e);
                if (!e.which)
                    if (
                        e.originalEvent.altKey ||
                            e.originalEvent.ctrlKey ||
                            e.originalEvent.metaKey ||
                            e.originalEvent.shiftKey
                    )
                        this.ignoreMissingWhich = !0;
                    else if (!this.ignoreMissingWhich) return this._mouseUp(e);
            }
            return (
                (e.which || e.button) && (this._mouseMoved = !0),
                this._mouseStarted
                    ? (this._mouseDrag(e), e.preventDefault())
                    : (this._mouseDistanceMet(e) &&
                              this._mouseDelayMet(e) &&
                              ((this._mouseStarted =
                                  this._mouseStart(this._mouseDownEvent, e) !== !1),
                              this._mouseStarted ? this._mouseDrag(e) : this._mouseUp(e)),
                    !this._mouseStarted)
            );
        },
        _mouseUp(e) {
            this.document
                .off(`mousemove.${this.widgetName}`, this._mouseMoveDelegate)
                .off(`mouseup.${this.widgetName}`, this._mouseUpDelegate),
            this._mouseStarted &&
                        ((this._mouseStarted = !1),
                        e.target === this._mouseDownEvent.target &&
                            t.data(e.target, `${this.widgetName}.preventClickEvent`, !0),
                        this._mouseStop(e)),
            this._mouseDelayTimer &&
                        (clearTimeout(this._mouseDelayTimer), delete this._mouseDelayTimer),
            (this.ignoreMissingWhich = !1),
            (_ = !1),
            e.preventDefault();
        },
        _mouseDistanceMet(t) {
            return (
                Math.max(
                    Math.abs(this._mouseDownEvent.pageX - t.pageX),
                    Math.abs(this._mouseDownEvent.pageY - t.pageY),
                ) >= this.options.distance
            );
        },
        _mouseDelayMet() {
            return this.mouseDelayMet;
        },
        _mouseStart() {},
        _mouseDrag() {},
        _mouseStop() {},
        _mouseCapture() {
            return !0;
        },
    }),
    (t.ui.plugin = {
        add(e, i, s) {
            let n;
            const o = t.ui[e].prototype;
            for (n in s) (o.plugins[n] = o.plugins[n] || []), o.plugins[n].push([i, s[n]]);
        },
        call(t, e, i, s) {
            let n;
            const o = t.plugins[e];
            if (
                o &&
                    (s || (t.element[0].parentNode && t.element[0].parentNode.nodeType !== 11))
            )
                for (n = 0; o.length > n; n++)
                    t.options[o[n][0]] && o[n][1].apply(t.element, i);
        },
    }),
    (t.ui.safeBlur = function (e) {
        e && e.nodeName.toLowerCase() !== "body" && t(e).trigger("blur");
    }),
    t.widget("ui.draggable", t.ui.mouse, {
        version: "1.12.1",
        widgetEventPrefix: "drag",
        options: {
            addClasses: !0,
            appendTo: "parent",
            axis: !1,
            connectToSortable: !1,
            containment: !1,
            cursor: "auto",
            cursorAt: !1,
            grid: !1,
            handle: !1,
            helper: "original",
            iframeFix: !1,
            opacity: !1,
            refreshPositions: !1,
            revert: !1,
            revertDuration: 500,
            scope: "default",
            scroll: !0,
            scrollSensitivity: 20,
            scrollSpeed: 20,
            snap: !1,
            snapMode: "both",
            snapTolerance: 20,
            stack: !1,
            zIndex: !1,
            drag: null,
            start: null,
            stop: null,
        },
        _create() {
            this.options.helper === "original" && this._setPositionRelative(),
            this.options.addClasses && this._addClass("ui-draggable"),
            this._setHandleClassName(),
            this._mouseInit();
        },
        _setOption(t, e) {
            this._super(t, e),
            t === "handle" && (this._removeHandleClassName(), this._setHandleClassName());
        },
        _destroy() {
            return (this.helper || this.element).is(".ui-draggable-dragging")
                ? ((this.destroyOnClear = !0), void 0)
                : (this._removeHandleClassName(), this._mouseDestroy(), void 0);
        },
        _mouseCapture(e) {
            const i = this.options;
            return this.helper ||
                    i.disabled ||
                    t(e.target).closest(".ui-resizable-handle").length > 0
                ? !1
                : ((this.handle = this._getHandle(e)),
                this.handle
                    ? (this._blurActiveElement(e),
                    this._blockFrames(i.iframeFix === !0 ? "iframe" : i.iframeFix),
                    !0)
                    : !1);
        },
        _blockFrames(e) {
            this.iframeBlocks = this.document.find(e).map(function () {
                const e = t(this);
                return t("<div>")
                    .css("position", "absolute")
                    .appendTo(e.parent())
                    .outerWidth(e.outerWidth())
                    .outerHeight(e.outerHeight())
                    .offset(e.offset())[0];
            });
        },
        _unblockFrames() {
            this.iframeBlocks && (this.iframeBlocks.remove(), delete this.iframeBlocks);
        },
        _blurActiveElement(e) {
            const i = t.ui.safeActiveElement(this.document[0]);
            const s = t(e.target);
            s.closest(i).length || t.ui.safeBlur(i);
        },
        _mouseStart(e) {
            const i = this.options;
            return (
                (this.helper = this._createHelper(e)),
                this._addClass(this.helper, "ui-draggable-dragging"),
                this._cacheHelperProportions(),
                t.ui.ddmanager && (t.ui.ddmanager.current = this),
                this._cacheMargins(),
                (this.cssPosition = this.helper.css("position")),
                (this.scrollParent = this.helper.scrollParent(!0)),
                (this.offsetParent = this.helper.offsetParent()),
                (this.hasFixedAncestor =
                        this.helper.parents().filter(function () {
                            return t(this).css("position") === "fixed";
                        }).length > 0),
                (this.positionAbs = this.element.offset()),
                this._refreshOffsets(e),
                (this.originalPosition = this.position = this._generatePosition(e, !1)),
                (this.originalPageX = e.pageX),
                (this.originalPageY = e.pageY),
                i.cursorAt && this._adjustOffsetFromHelper(i.cursorAt),
                this._setContainment(),
                this._trigger("start", e) === !1
                    ? (this._clear(), !1)
                    : (this._cacheHelperProportions(),
                    t.ui.ddmanager &&
                              !i.dropBehaviour &&
                              t.ui.ddmanager.prepareOffsets(this, e),
                    this._mouseDrag(e, !0),
                    t.ui.ddmanager && t.ui.ddmanager.dragStart(this, e),
                    !0)
            );
        },
        _refreshOffsets(t) {
            (this.offset = {
                top: this.positionAbs.top - this.margins.top,
                left: this.positionAbs.left - this.margins.left,
                scroll: !1,
                parent: this._getParentOffset(),
                relative: this._getRelativeOffset(),
            }),
            (this.offset.click = {
                left: t.pageX - this.offset.left,
                top: t.pageY - this.offset.top,
            });
        },
        _mouseDrag(e, i) {
            if (
                (this.hasFixedAncestor && (this.offset.parent = this._getParentOffset()),
                (this.position = this._generatePosition(e, !0)),
                (this.positionAbs = this._convertPositionTo("absolute")),
                !i)
            ) {
                const s = this._uiHash();
                if (this._trigger("drag", e, s) === !1)
                    return this._mouseUp(new t.Event("mouseup", e)), !1;
                this.position = s.position;
            }
            return (
                (this.helper[0].style.left = `${this.position.left}px`),
                (this.helper[0].style.top = `${this.position.top}px`),
                t.ui.ddmanager && t.ui.ddmanager.drag(this, e),
                !1
            );
        },
        _mouseStop(e) {
            const i = this;
            let s = !1;
            return (
                t.ui.ddmanager &&
                        !this.options.dropBehaviour &&
                        (s = t.ui.ddmanager.drop(this, e)),
                this.dropped && ((s = this.dropped), (this.dropped = !1)),
                (this.options.revert === "invalid" && !s) ||
                    (this.options.revert === "valid" && s) ||
                    this.options.revert === !0 ||
                    (t.isFunction(this.options.revert) && this.options.revert.call(this.element, s))
                    ? t(this.helper).animate(
                        this.originalPosition,
                        parseInt(this.options.revertDuration, 10),
                        () => {
                            i._trigger("stop", e) !== !1 && i._clear();
                        },
                    )
                    : this._trigger("stop", e) !== !1 && this._clear(),
                !1
            );
        },
        _mouseUp(e) {
            return (
                this._unblockFrames(),
                t.ui.ddmanager && t.ui.ddmanager.dragStop(this, e),
                this.handleElement.is(e.target) && this.element.trigger("focus"),
                t.ui.mouse.prototype._mouseUp.call(this, e)
            );
        },
        cancel() {
            return (
                this.helper.is(".ui-draggable-dragging")
                    ? this._mouseUp(new t.Event("mouseup", { target: this.element[0] }))
                    : this._clear(),
                this
            );
        },
        _getHandle(e) {
            return this.options.handle
                ? !!t(e.target).closest(this.element.find(this.options.handle)).length
                : !0;
        },
        _setHandleClassName() {
            (this.handleElement = this.options.handle
                ? this.element.find(this.options.handle)
                : this.element),
            this._addClass(this.handleElement, "ui-draggable-handle");
        },
        _removeHandleClassName() {
            this._removeClass(this.handleElement, "ui-draggable-handle");
        },
        _createHelper(e) {
            const i = this.options;
            const s = t.isFunction(i.helper);
            const n = s
                ? t(i.helper.apply(this.element[0], [e]))
                : i.helper === "clone"
                    ? this.element.clone().removeAttr("id")
                    : this.element;
            return (
                n.parents("body").length ||
                        n.appendTo(
                            i.appendTo === "parent" ? this.element[0].parentNode : i.appendTo,
                        ),
                s && n[0] === this.element[0] && this._setPositionRelative(),
                n[0] === this.element[0] ||
                        /(fixed|absolute)/.test(n.css("position")) ||
                        n.css("position", "absolute"),
                n
            );
        },
        _setPositionRelative() {
            /^(?:r|a|f)/.test(this.element.css("position")) ||
                    (this.element[0].style.position = "relative");
        },
        _adjustOffsetFromHelper(e) {
            typeof e === "string" && (e = e.split(" ")),
            t.isArray(e) && (e = { left: +e[0], top: +e[1] || 0 }),
            "left" in e && (this.offset.click.left = e.left + this.margins.left),
            "right" in e &&
                        (this.offset.click.left =
                            this.helperProportions.width - e.right + this.margins.left),
            "top" in e && (this.offset.click.top = e.top + this.margins.top),
            "bottom" in e &&
                        (this.offset.click.top =
                            this.helperProportions.height - e.bottom + this.margins.top);
        },
        _isRootNode(t) {
            return /(html|body)/i.test(t.tagName) || t === this.document[0];
        },
        _getParentOffset() {
            let e = this.offsetParent.offset();
            const i = this.document[0];
            return (
                this.cssPosition === "absolute" &&
                        this.scrollParent[0] !== i &&
                        t.contains(this.scrollParent[0], this.offsetParent[0]) &&
                        ((e.left += this.scrollParent.scrollLeft()),
                        (e.top += this.scrollParent.scrollTop())),
                this._isRootNode(this.offsetParent[0]) && (e = { top: 0, left: 0 }),
                {
                    top: e.top + (parseInt(this.offsetParent.css("borderTopWidth"), 10) || 0),
                    left:
                            e.left + (parseInt(this.offsetParent.css("borderLeftWidth"), 10) || 0),
                }
            );
        },
        _getRelativeOffset() {
            if (this.cssPosition !== "relative") return { top: 0, left: 0 };
            const t = this.element.position();
            const e = this._isRootNode(this.scrollParent[0]);
            return {
                top:
                        t.top -
                        (parseInt(this.helper.css("top"), 10) || 0) +
                        (e ? 0 : this.scrollParent.scrollTop()),
                left:
                        t.left -
                        (parseInt(this.helper.css("left"), 10) || 0) +
                        (e ? 0 : this.scrollParent.scrollLeft()),
            };
        },
        _cacheMargins() {
            this.margins = {
                left: parseInt(this.element.css("marginLeft"), 10) || 0,
                top: parseInt(this.element.css("marginTop"), 10) || 0,
                right: parseInt(this.element.css("marginRight"), 10) || 0,
                bottom: parseInt(this.element.css("marginBottom"), 10) || 0,
            };
        },
        _cacheHelperProportions() {
            this.helperProportions = {
                width: this.helper.outerWidth(),
                height: this.helper.outerHeight(),
            };
        },
        _setContainment() {
            let e;
            let i;
            let s;
            const n = this.options;
            const o = this.document[0];
            return (
                (this.relativeContainer = null),
                n.containment
                    ? n.containment === "window"
                        ? ((this.containment = [
                            t(window).scrollLeft() -
                                      this.offset.relative.left -
                                      this.offset.parent.left,
                            t(window).scrollTop() -
                                      this.offset.relative.top -
                                      this.offset.parent.top,
                            t(window).scrollLeft() +
                                      t(window).width() -
                                      this.helperProportions.width -
                                      this.margins.left,
                            t(window).scrollTop() +
                                      (t(window).height() || o.body.parentNode.scrollHeight) -
                                      this.helperProportions.height -
                                      this.margins.top,
                        ]),
                        void 0)
                        : n.containment === "document"
                            ? ((this.containment = [
                                0,
                                0,
                                t(o).width() - this.helperProportions.width - this.margins.left,
                                (t(o).height() || o.body.parentNode.scrollHeight) -
                                      this.helperProportions.height -
                                      this.margins.top,
                            ]),
                            void 0)
                            : n.containment.constructor === Array
                                ? ((this.containment = n.containment), void 0)
                                : (n.containment === "parent" &&
                                  (n.containment = this.helper[0].parentNode),
                                (i = t(n.containment)),
                                (s = i[0]),
                                s &&
                                  ((e = /(scroll|auto)/.test(i.css("overflow"))),
                                  (this.containment = [
                                      (parseInt(i.css("borderLeftWidth"), 10) || 0) +
                                          (parseInt(i.css("paddingLeft"), 10) || 0),
                                      (parseInt(i.css("borderTopWidth"), 10) || 0) +
                                          (parseInt(i.css("paddingTop"), 10) || 0),
                                      (e ? Math.max(s.scrollWidth, s.offsetWidth) : s.offsetWidth) -
                                          (parseInt(i.css("borderRightWidth"), 10) || 0) -
                                          (parseInt(i.css("paddingRight"), 10) || 0) -
                                          this.helperProportions.width -
                                          this.margins.left -
                                          this.margins.right,
                                      (e
                                          ? Math.max(s.scrollHeight, s.offsetHeight)
                                          : s.offsetHeight) -
                                          (parseInt(i.css("borderBottomWidth"), 10) || 0) -
                                          (parseInt(i.css("paddingBottom"), 10) || 0) -
                                          this.helperProportions.height -
                                          this.margins.top -
                                          this.margins.bottom,
                                  ]),
                                  (this.relativeContainer = i)),
                                void 0)
                    : ((this.containment = null), void 0)
            );
        },
        _convertPositionTo(t, e) {
            e || (e = this.position);
            const i = t === "absolute" ? 1 : -1;
            const s = this._isRootNode(this.scrollParent[0]);
            return {
                top:
                        e.top +
                        this.offset.relative.top * i +
                        this.offset.parent.top * i -
                        (this.cssPosition === "fixed"
                            ? -this.offset.scroll.top
                            : s
                                ? 0
                                : this.offset.scroll.top) *
                            i,
                left:
                        e.left +
                        this.offset.relative.left * i +
                        this.offset.parent.left * i -
                        (this.cssPosition === "fixed"
                            ? -this.offset.scroll.left
                            : s
                                ? 0
                                : this.offset.scroll.left) *
                            i,
            };
        },
        _generatePosition(t, e) {
            let i;
            let s;
            let n;
            let o;
            const a = this.options;
            const r = this._isRootNode(this.scrollParent[0]);
            let h = t.pageX;
            let l = t.pageY;
            return (
                (r && this.offset.scroll) ||
                        (this.offset.scroll = {
                            top: this.scrollParent.scrollTop(),
                            left: this.scrollParent.scrollLeft(),
                        }),
                e &&
                        (this.containment &&
                            (this.relativeContainer
                                ? ((s = this.relativeContainer.offset()),
                                (i = [
                                    this.containment[0] + s.left,
                                    this.containment[1] + s.top,
                                    this.containment[2] + s.left,
                                    this.containment[3] + s.top,
                                ]))
                                : (i = this.containment),
                            t.pageX - this.offset.click.left < i[0] &&
                                (h = i[0] + this.offset.click.left),
                            t.pageY - this.offset.click.top < i[1] &&
                                (l = i[1] + this.offset.click.top),
                            t.pageX - this.offset.click.left > i[2] &&
                                (h = i[2] + this.offset.click.left),
                            t.pageY - this.offset.click.top > i[3] &&
                                (l = i[3] + this.offset.click.top)),
                        a.grid &&
                            ((n = a.grid[1]
                                ? this.originalPageY +
                                  Math.round((l - this.originalPageY) / a.grid[1]) * a.grid[1]
                                : this.originalPageY),
                            (l = i
                                ? n - this.offset.click.top >= i[1] ||
                                  n - this.offset.click.top > i[3]
                                    ? n
                                    : n - this.offset.click.top >= i[1]
                                        ? n - a.grid[1]
                                        : n + a.grid[1]
                                : n),
                            (o = a.grid[0]
                                ? this.originalPageX +
                                  Math.round((h - this.originalPageX) / a.grid[0]) * a.grid[0]
                                : this.originalPageX),
                            (h = i
                                ? o - this.offset.click.left >= i[0] ||
                                  o - this.offset.click.left > i[2]
                                    ? o
                                    : o - this.offset.click.left >= i[0]
                                        ? o - a.grid[0]
                                        : o + a.grid[0]
                                : o)),
                        a.axis === "y" && (h = this.originalPageX),
                        a.axis === "x" && (l = this.originalPageY)),
                {
                    top:
                            l -
                            this.offset.click.top -
                            this.offset.relative.top -
                            this.offset.parent.top +
                            (this.cssPosition === "fixed"
                                ? -this.offset.scroll.top
                                : r
                                    ? 0
                                    : this.offset.scroll.top),
                    left:
                            h -
                            this.offset.click.left -
                            this.offset.relative.left -
                            this.offset.parent.left +
                            (this.cssPosition === "fixed"
                                ? -this.offset.scroll.left
                                : r
                                    ? 0
                                    : this.offset.scroll.left),
                }
            );
        },
        _clear() {
            this._removeClass(this.helper, "ui-draggable-dragging"),
            this.helper[0] === this.element[0] ||
                        this.cancelHelperRemoval ||
                        this.helper.remove(),
            (this.helper = null),
            (this.cancelHelperRemoval = !1),
            this.destroyOnClear && this.destroy();
        },
        _trigger(e, i, s) {
            return (
                (s = s || this._uiHash()),
                t.ui.plugin.call(this, e, [i, s, this], !0),
                /^(drag|start|stop)/.test(e) &&
                        ((this.positionAbs = this._convertPositionTo("absolute")),
                        (s.offset = this.positionAbs)),
                t.Widget.prototype._trigger.call(this, e, i, s)
            );
        },
        plugins: {},
        _uiHash() {
            return {
                helper: this.helper,
                position: this.position,
                originalPosition: this.originalPosition,
                offset: this.positionAbs,
            };
        },
    }),
    t.ui.plugin.add("draggable", "connectToSortable", {
        start(e, i, s) {
            const n = t.extend({}, i, { item: s.element });
            (s.sortables = []),
            t(s.options.connectToSortable).each(function () {
                const i = t(this).sortable("instance");
                i &&
                            !i.options.disabled &&
                            (s.sortables.push(i),
                            i.refreshPositions(),
                            i._trigger("activate", e, n));
            });
        },
        stop(e, i, s) {
            const n = t.extend({}, i, { item: s.element });
            (s.cancelHelperRemoval = !1),
            t.each(s.sortables, function () {
                const t = this;
                t.isOver
                    ? ((t.isOver = 0),
                    (s.cancelHelperRemoval = !0),
                    (t.cancelHelperRemoval = !1),
                    (t._storedCSS = {
                        position: t.placeholder.css("position"),
                        top: t.placeholder.css("top"),
                        left: t.placeholder.css("left"),
                    }),
                    t._mouseStop(e),
                    (t.options.helper = t.options._helper))
                    : ((t.cancelHelperRemoval = !0), t._trigger("deactivate", e, n));
            });
        },
        drag(e, i, s) {
            t.each(s.sortables, function () {
                let n = !1;
                const o = this;
                (o.positionAbs = s.positionAbs),
                (o.helperProportions = s.helperProportions),
                (o.offset.click = s.offset.click),
                o._intersectsWith(o.containerCache) &&
                            ((n = !0),
                            t.each(s.sortables, function () {
                                return (
                                    (this.positionAbs = s.positionAbs),
                                    (this.helperProportions = s.helperProportions),
                                    (this.offset.click = s.offset.click),
                                    this !== o &&
                                        this._intersectsWith(this.containerCache) &&
                                        t.contains(o.element[0], this.element[0]) &&
                                        (n = !1),
                                    n
                                );
                            })),
                n
                    ? (o.isOver ||
                                  ((o.isOver = 1),
                                  (s._parent = i.helper.parent()),
                                  (o.currentItem = i.helper
                                      .appendTo(o.element)
                                      .data("ui-sortable-item", !0)),
                                  (o.options._helper = o.options.helper),
                                  (o.options.helper = function () {
                                      return i.helper[0];
                                  }),
                                  (e.target = o.currentItem[0]),
                                  o._mouseCapture(e, !0),
                                  o._mouseStart(e, !0, !0),
                                  (o.offset.click.top = s.offset.click.top),
                                  (o.offset.click.left = s.offset.click.left),
                                  (o.offset.parent.left -=
                                      s.offset.parent.left - o.offset.parent.left),
                                  (o.offset.parent.top -=
                                      s.offset.parent.top - o.offset.parent.top),
                                  s._trigger("toSortable", e),
                                  (s.dropped = o.element),
                                  t.each(s.sortables, function () {
                                      this.refreshPositions();
                                  }),
                                  (s.currentItem = s.element),
                                  (o.fromOutside = s)),
                    o.currentItem && (o._mouseDrag(e), (i.position = o.position)))
                    : o.isOver &&
                              ((o.isOver = 0),
                              (o.cancelHelperRemoval = !0),
                              (o.options._revert = o.options.revert),
                              (o.options.revert = !1),
                              o._trigger("out", e, o._uiHash(o)),
                              o._mouseStop(e, !0),
                              (o.options.revert = o.options._revert),
                              (o.options.helper = o.options._helper),
                              o.placeholder && o.placeholder.remove(),
                              i.helper.appendTo(s._parent),
                              s._refreshOffsets(e),
                              (i.position = s._generatePosition(e, !0)),
                              s._trigger("fromSortable", e),
                              (s.dropped = !1),
                              t.each(s.sortables, function () {
                                  this.refreshPositions();
                              }));
            });
        },
    }),
    t.ui.plugin.add("draggable", "cursor", {
        start(e, i, s) {
            const n = t("body");
            const o = s.options;
            n.css("cursor") && (o._cursor = n.css("cursor")), n.css("cursor", o.cursor);
        },
        stop(e, i, s) {
            const n = s.options;
            n._cursor && t("body").css("cursor", n._cursor);
        },
    }),
    t.ui.plugin.add("draggable", "opacity", {
        start(e, i, s) {
            const n = t(i.helper);
            const o = s.options;
            n.css("opacity") && (o._opacity = n.css("opacity")), n.css("opacity", o.opacity);
        },
        stop(e, i, s) {
            const n = s.options;
            n._opacity && t(i.helper).css("opacity", n._opacity);
        },
    }),
    t.ui.plugin.add("draggable", "scroll", {
        start(t, e, i) {
            i.scrollParentNotHidden || (i.scrollParentNotHidden = i.helper.scrollParent(!1)),
            i.scrollParentNotHidden[0] !== i.document[0] &&
                        i.scrollParentNotHidden[0].tagName !== "HTML" &&
                        (i.overflowOffset = i.scrollParentNotHidden.offset());
        },
        drag(e, i, s) {
            const n = s.options;
            let o = !1;
            const a = s.scrollParentNotHidden[0];
            const r = s.document[0];
            a !== r && a.tagName !== "HTML"
                ? ((n.axis && n.axis === "x") ||
                          (s.overflowOffset.top + a.offsetHeight - e.pageY < n.scrollSensitivity
                              ? (a.scrollTop = o = a.scrollTop + n.scrollSpeed)
                              : e.pageY - s.overflowOffset.top < n.scrollSensitivity &&
                                (a.scrollTop = o = a.scrollTop - n.scrollSpeed)),
                (n.axis && n.axis === "y") ||
                          (s.overflowOffset.left + a.offsetWidth - e.pageX < n.scrollSensitivity
                              ? (a.scrollLeft = o = a.scrollLeft + n.scrollSpeed)
                              : e.pageX - s.overflowOffset.left < n.scrollSensitivity &&
                                (a.scrollLeft = o = a.scrollLeft - n.scrollSpeed)))
                : ((n.axis && n.axis === "x") ||
                          (e.pageY - t(r).scrollTop() < n.scrollSensitivity
                              ? (o = t(r).scrollTop(t(r).scrollTop() - n.scrollSpeed))
                              : t(window).height() - (e.pageY - t(r).scrollTop()) <
                                    n.scrollSensitivity &&
                                (o = t(r).scrollTop(t(r).scrollTop() + n.scrollSpeed))),
                (n.axis && n.axis === "y") ||
                          (e.pageX - t(r).scrollLeft() < n.scrollSensitivity
                              ? (o = t(r).scrollLeft(t(r).scrollLeft() - n.scrollSpeed))
                              : t(window).width() - (e.pageX - t(r).scrollLeft()) <
                                    n.scrollSensitivity &&
                                (o = t(r).scrollLeft(t(r).scrollLeft() + n.scrollSpeed)))),
            o !== !1 &&
                        t.ui.ddmanager &&
                        !n.dropBehaviour &&
                        t.ui.ddmanager.prepareOffsets(s, e);
        },
    }),
    t.ui.plugin.add("draggable", "snap", {
        start(e, i, s) {
            const n = s.options;
            (s.snapElements = []),
            t(
                n.snap.constructor !== String
                    ? n.snap.items || ":data(ui-draggable)"
                    : n.snap,
            ).each(function () {
                const e = t(this);
                const i = e.offset();
                this !== s.element[0] &&
                            s.snapElements.push({
                                item: this,
                                width: e.outerWidth(),
                                height: e.outerHeight(),
                                top: i.top,
                                left: i.left,
                            });
            });
        },
        drag(e, i, s) {
            let n;
            let o;
            let a;
            let r;
            let h;
            let l;
            let c;
            let u;
            let d;
            let p;
            const f = s.options;
            const g = f.snapTolerance;
            const m = i.offset.left;
            const _ = m + s.helperProportions.width;
            const v = i.offset.top;
            const b = v + s.helperProportions.height;
            for (d = s.snapElements.length - 1; d >= 0; d--)
                (h = s.snapElements[d].left - s.margins.left),
                (l = h + s.snapElements[d].width),
                (c = s.snapElements[d].top - s.margins.top),
                (u = c + s.snapElements[d].height),
                h - g > _ ||
                        m > l + g ||
                        c - g > b ||
                        v > u + g ||
                        !t.contains(s.snapElements[d].item.ownerDocument, s.snapElements[d].item)
                    ? (s.snapElements[d].snapping &&
                                  s.options.snap.release &&
                                  s.options.snap.release.call(
                                      s.element,
                                      e,
                                      t.extend(s._uiHash(), { snapItem: s.snapElements[d].item }),
                                  ),
                    (s.snapElements[d].snapping = !1))
                    : (f.snapMode !== "inner" &&
                                  ((n = g >= Math.abs(c - b)),
                                  (o = g >= Math.abs(u - v)),
                                  (a = g >= Math.abs(h - _)),
                                  (r = g >= Math.abs(l - m)),
                                  n &&
                                      (i.position.top = s._convertPositionTo("relative", {
                                          top: c - s.helperProportions.height,
                                          left: 0,
                                      }).top),
                                  o &&
                                      (i.position.top = s._convertPositionTo("relative", {
                                          top: u,
                                          left: 0,
                                      }).top),
                                  a &&
                                      (i.position.left = s._convertPositionTo("relative", {
                                          top: 0,
                                          left: h - s.helperProportions.width,
                                      }).left),
                                  r &&
                                      (i.position.left = s._convertPositionTo("relative", {
                                          top: 0,
                                          left: l,
                                      }).left)),
                    (p = n || o || a || r),
                    f.snapMode !== "outer" &&
                                  ((n = g >= Math.abs(c - v)),
                                  (o = g >= Math.abs(u - b)),
                                  (a = g >= Math.abs(h - m)),
                                  (r = g >= Math.abs(l - _)),
                                  n &&
                                      (i.position.top = s._convertPositionTo("relative", {
                                          top: c,
                                          left: 0,
                                      }).top),
                                  o &&
                                      (i.position.top = s._convertPositionTo("relative", {
                                          top: u - s.helperProportions.height,
                                          left: 0,
                                      }).top),
                                  a &&
                                      (i.position.left = s._convertPositionTo("relative", {
                                          top: 0,
                                          left: h,
                                      }).left),
                                  r &&
                                      (i.position.left = s._convertPositionTo("relative", {
                                          top: 0,
                                          left: l - s.helperProportions.width,
                                      }).left)),
                    !s.snapElements[d].snapping &&
                                  (n || o || a || r || p) &&
                                  s.options.snap.snap &&
                                  s.options.snap.snap.call(
                                      s.element,
                                      e,
                                      t.extend(s._uiHash(), { snapItem: s.snapElements[d].item }),
                                  ),
                    (s.snapElements[d].snapping = n || o || a || r || p));
        },
    }),
    t.ui.plugin.add("draggable", "stack", {
        start(e, i, s) {
            let n;
            const o = s.options;
            const a = t
                .makeArray(t(o.stack))
                .sort(
                    (e, i) =>
                        (parseInt(t(e).css("zIndex"), 10) || 0) -
                            (parseInt(t(i).css("zIndex"), 10) || 0),
                );
            a.length &&
                    ((n = parseInt(t(a[0]).css("zIndex"), 10) || 0),
                    t(a).each(function (e) {
                        t(this).css("zIndex", n + e);
                    }),
                    this.css("zIndex", n + a.length));
        },
    }),
    t.ui.plugin.add("draggable", "zIndex", {
        start(e, i, s) {
            const n = t(i.helper);
            const o = s.options;
            n.css("zIndex") && (o._zIndex = n.css("zIndex")), n.css("zIndex", o.zIndex);
        },
        stop(e, i, s) {
            const n = s.options;
            n._zIndex && t(i.helper).css("zIndex", n._zIndex);
        },
    }),
    t.ui.draggable,
    t.widget("ui.resizable", t.ui.mouse, {
        version: "1.12.1",
        widgetEventPrefix: "resize",
        options: {
            alsoResize: !1,
            animate: !1,
            animateDuration: "slow",
            animateEasing: "swing",
            aspectRatio: !1,
            autoHide: !1,
            classes: { "ui-resizable-se": "ui-icon ui-icon-gripsmall-diagonal-se" },
            containment: !1,
            ghost: !1,
            grid: !1,
            handles: "e,s,se",
            helper: !1,
            maxHeight: null,
            maxWidth: null,
            minHeight: 10,
            minWidth: 10,
            zIndex: 90,
            resize: null,
            start: null,
            stop: null,
        },
        _num(t) {
            return parseFloat(t) || 0;
        },
        _isNumber(t) {
            return !isNaN(parseFloat(t));
        },
        _hasScroll(e, i) {
            if (t(e).css("overflow") === "hidden") return !1;
            const s = i && i === "left" ? "scrollLeft" : "scrollTop";
            let n = !1;
            return e[s] > 0 ? !0 : ((e[s] = 1), (n = e[s] > 0), (e[s] = 0), n);
        },
        _create() {
            let e;
            const i = this.options;
            const s = this;
            this._addClass("ui-resizable"),
            t.extend(this, {
                _aspectRatio: !!i.aspectRatio,
                aspectRatio: i.aspectRatio,
                originalElement: this.element,
                _proportionallyResizeElements: [],
                _helper:
                            i.helper || i.ghost || i.animate
                                ? i.helper || "ui-resizable-helper"
                                : null,
            }),
            this.element[0].nodeName.match(
                /^(canvas|textarea|input|select|button|img)$/i,
            ) &&
                        (this.element.wrap(
                            t("<div class='ui-wrapper' style='overflow: hidden;'></div>").css({
                                position: this.element.css("position"),
                                width: this.element.outerWidth(),
                                height: this.element.outerHeight(),
                                top: this.element.css("top"),
                                left: this.element.css("left"),
                            }),
                        ),
                        (this.element = this.element
                            .parent()
                            .data("ui-resizable", this.element.resizable("instance"))),
                        (this.elementIsWrapper = !0),
                        (e = {
                            marginTop: this.originalElement.css("marginTop"),
                            marginRight: this.originalElement.css("marginRight"),
                            marginBottom: this.originalElement.css("marginBottom"),
                            marginLeft: this.originalElement.css("marginLeft"),
                        }),
                        this.element.css(e),
                        this.originalElement.css("margin", 0),
                        (this.originalResizeStyle = this.originalElement.css("resize")),
                        this.originalElement.css("resize", "none"),
                        this._proportionallyResizeElements.push(
                            this.originalElement.css({
                                position: "static",
                                zoom: 1,
                                display: "block",
                            }),
                        ),
                        this.originalElement.css(e),
                        this._proportionallyResize()),
            this._setupHandles(),
            i.autoHide &&
                        t(this.element)
                            .on("mouseenter", () => {
                                i.disabled ||
                                    (s._removeClass("ui-resizable-autohide"), s._handles.show());
                            })
                            .on("mouseleave", () => {
                                i.disabled ||
                                    s.resizing ||
                                    (s._addClass("ui-resizable-autohide"), s._handles.hide());
                            }),
            this._mouseInit();
        },
        _destroy() {
            this._mouseDestroy();
            let e;
            const i = function (e) {
                t(e)
                    .removeData("resizable")
                    .removeData("ui-resizable")
                    .off(".resizable")
                    .find(".ui-resizable-handle")
                    .remove();
            };
            return (
                this.elementIsWrapper &&
                        (i(this.element),
                        (e = this.element),
                        this.originalElement
                            .css({
                                position: e.css("position"),
                                width: e.outerWidth(),
                                height: e.outerHeight(),
                                top: e.css("top"),
                                left: e.css("left"),
                            })
                            .insertAfter(e),
                        e.remove()),
                this.originalElement.css("resize", this.originalResizeStyle),
                i(this.originalElement),
                this
            );
        },
        _setOption(t, e) {
            switch ((this._super(t, e), t)) {
            case "handles":
                this._removeHandles(), this._setupHandles();
                break;
            default:
            }
        },
        _setupHandles() {
            let e;
            let i;
            let s;
            let n;
            let o;
            const a = this.options;
            const r = this;
            if (
                ((this.handles =
                        a.handles ||
                        (t(".ui-resizable-handle", this.element).length
                            ? {
                                n: ".ui-resizable-n",
                                e: ".ui-resizable-e",
                                s: ".ui-resizable-s",
                                w: ".ui-resizable-w",
                                se: ".ui-resizable-se",
                                sw: ".ui-resizable-sw",
                                ne: ".ui-resizable-ne",
                                nw: ".ui-resizable-nw",
                            }
                            : "e,s,se")),
                (this._handles = t()),
                this.handles.constructor === String)
            )
                for (
                    this.handles === "all" && (this.handles = "n,e,s,w,se,sw,ne,nw"),
                    s = this.handles.split(","),
                    this.handles = {},
                    i = 0;
                    s.length > i;
                    i++
                )
                    (e = t.trim(s[i])),
                    (n = `ui-resizable-${e}`),
                    (o = t("<div>")),
                    this._addClass(o, `ui-resizable-handle ${n}`),
                    o.css({ zIndex: a.zIndex }),
                    (this.handles[e] = `.ui-resizable-${e}`),
                    this.element.append(o);
            (this._renderAxis = function (e) {
                let i;
                let s;
                let n;
                let o;
                e = e || this.element;
                for (i in this.handles)
                    this.handles[i].constructor === String
                        ? (this.handles[i] = this.element
                            .children(this.handles[i])
                            .first()
                            .show())
                        : (this.handles[i].jquery || this.handles[i].nodeType) &&
                              ((this.handles[i] = t(this.handles[i])),
                              this._on(this.handles[i], { mousedown: r._mouseDown })),
                    this.elementIsWrapper &&
                                this.originalElement[0].nodeName.match(
                                    /^(textarea|input|select|button)$/i,
                                ) &&
                                ((s = t(this.handles[i], this.element)),
                                (o = /sw|ne|nw|se|n|s/.test(i) ? s.outerHeight() : s.outerWidth()),
                                (n = [
                                    "padding",
                                    /ne|nw|n/.test(i)
                                        ? "Top"
                                        : /se|sw|s/.test(i)
                                            ? "Bottom"
                                            : /^e$/.test(i)
                                                ? "Right"
                                                : "Left",
                                ].join("")),
                                e.css(n, o),
                                this._proportionallyResize()),
                    (this._handles = this._handles.add(this.handles[i]));
            }),
            this._renderAxis(this.element),
            (this._handles = this._handles.add(this.element.find(".ui-resizable-handle"))),
            this._handles.disableSelection(),
            this._handles.on("mouseover", function () {
                r.resizing ||
                            (this.className &&
                                (o = this.className.match(/ui-resizable-(se|sw|ne|nw|n|e|s|w)/i)),
                            (r.axis = o && o[1] ? o[1] : "se"));
            }),
            a.autoHide && (this._handles.hide(), this._addClass("ui-resizable-autohide"));
        },
        _removeHandles() {
            this._handles.remove();
        },
        _mouseCapture(e) {
            let i;
            let s;
            let n = !1;
            for (i in this.handles)
                (s = t(this.handles[i])[0]),
                (s === e.target || t.contains(s, e.target)) && (n = !0);
            return !this.options.disabled && n;
        },
        _mouseStart(e) {
            let i;
            let s;
            let n;
            const o = this.options;
            const a = this.element;
            return (
                (this.resizing = !0),
                this._renderProxy(),
                (i = this._num(this.helper.css("left"))),
                (s = this._num(this.helper.css("top"))),
                o.containment &&
                        ((i += t(o.containment).scrollLeft() || 0),
                        (s += t(o.containment).scrollTop() || 0)),
                (this.offset = this.helper.offset()),
                (this.position = { left: i, top: s }),
                (this.size = this._helper
                    ? { width: this.helper.width(), height: this.helper.height() }
                    : { width: a.width(), height: a.height() }),
                (this.originalSize = this._helper
                    ? { width: a.outerWidth(), height: a.outerHeight() }
                    : { width: a.width(), height: a.height() }),
                (this.sizeDiff = {
                    width: a.outerWidth() - a.width(),
                    height: a.outerHeight() - a.height(),
                }),
                (this.originalPosition = { left: i, top: s }),
                (this.originalMousePosition = { left: e.pageX, top: e.pageY }),
                (this.aspectRatio =
                        typeof o.aspectRatio === "number"
                            ? o.aspectRatio
                            : this.originalSize.width / this.originalSize.height || 1),
                (n = t(`.ui-resizable-${this.axis}`).css("cursor")),
                t("body").css("cursor", n === "auto" ? `${this.axis}-resize` : n),
                this._addClass("ui-resizable-resizing"),
                this._propagate("start", e),
                !0
            );
        },
        _mouseDrag(e) {
            let i;
            let s;
            const n = this.originalMousePosition;
            const o = this.axis;
            const a = e.pageX - n.left || 0;
            const r = e.pageY - n.top || 0;
            const h = this._change[o];
            return (
                this._updatePrevProperties(),
                h
                    ? ((i = h.apply(this, [e, a, r])),
                    this._updateVirtualBoundaries(e.shiftKey),
                    (this._aspectRatio || e.shiftKey) && (i = this._updateRatio(i, e)),
                    (i = this._respectSize(i, e)),
                    this._updateCache(i),
                    this._propagate("resize", e),
                    (s = this._applyChanges()),
                    !this._helper &&
                              this._proportionallyResizeElements.length &&
                              this._proportionallyResize(),
                    t.isEmptyObject(s) ||
                              (this._updatePrevProperties(),
                              this._trigger("resize", e, this.ui()),
                              this._applyChanges()),
                    !1)
                    : !1
            );
        },
        _mouseStop(e) {
            this.resizing = !1;
            let i;
            let s;
            let n;
            let o;
            let a;
            let r;
            let h;
            const l = this.options;
            const c = this;
            return (
                this._helper &&
                        ((i = this._proportionallyResizeElements),
                        (s = i.length && /textarea/i.test(i[0].nodeName)),
                        (n = s && this._hasScroll(i[0], "left") ? 0 : c.sizeDiff.height),
                        (o = s ? 0 : c.sizeDiff.width),
                        (a = { width: c.helper.width() - o, height: c.helper.height() - n }),
                        (r =
                            parseFloat(c.element.css("left")) +
                                (c.position.left - c.originalPosition.left) || null),
                        (h =
                            parseFloat(c.element.css("top")) +
                                (c.position.top - c.originalPosition.top) || null),
                        l.animate || this.element.css(t.extend(a, { top: h, left: r })),
                        c.helper.height(c.size.height),
                        c.helper.width(c.size.width),
                        this._helper && !l.animate && this._proportionallyResize()),
                t("body").css("cursor", "auto"),
                this._removeClass("ui-resizable-resizing"),
                this._propagate("stop", e),
                this._helper && this.helper.remove(),
                !1
            );
        },
        _updatePrevProperties() {
            (this.prevPosition = { top: this.position.top, left: this.position.left }),
            (this.prevSize = { width: this.size.width, height: this.size.height });
        },
        _applyChanges() {
            const t = {};
            return (
                this.position.top !== this.prevPosition.top &&
                        (t.top = `${this.position.top}px`),
                this.position.left !== this.prevPosition.left &&
                        (t.left = `${this.position.left}px`),
                this.size.width !== this.prevSize.width && (t.width = `${this.size.width}px`),
                this.size.height !== this.prevSize.height &&
                        (t.height = `${this.size.height}px`),
                this.helper.css(t),
                t
            );
        },
        _updateVirtualBoundaries(t) {
            let e;
            let i;
            let s;
            let n;
            let o;
            const a = this.options;
            (o = {
                minWidth: this._isNumber(a.minWidth) ? a.minWidth : 0,
                maxWidth: this._isNumber(a.maxWidth) ? a.maxWidth : 1 / 0,
                minHeight: this._isNumber(a.minHeight) ? a.minHeight : 0,
                maxHeight: this._isNumber(a.maxHeight) ? a.maxHeight : 1 / 0,
            }),
            (this._aspectRatio || t) &&
                        ((e = o.minHeight * this.aspectRatio),
                        (s = o.minWidth / this.aspectRatio),
                        (i = o.maxHeight * this.aspectRatio),
                        (n = o.maxWidth / this.aspectRatio),
                        e > o.minWidth && (o.minWidth = e),
                        s > o.minHeight && (o.minHeight = s),
                        o.maxWidth > i && (o.maxWidth = i),
                        o.maxHeight > n && (o.maxHeight = n)),
            (this._vBoundaries = o);
        },
        _updateCache(t) {
            (this.offset = this.helper.offset()),
            this._isNumber(t.left) && (this.position.left = t.left),
            this._isNumber(t.top) && (this.position.top = t.top),
            this._isNumber(t.height) && (this.size.height = t.height),
            this._isNumber(t.width) && (this.size.width = t.width);
        },
        _updateRatio(t) {
            const e = this.position;
            const i = this.size;
            const s = this.axis;
            return (
                this._isNumber(t.height)
                    ? (t.width = t.height * this.aspectRatio)
                    : this._isNumber(t.width) && (t.height = t.width / this.aspectRatio),
                s === "sw" && ((t.left = e.left + (i.width - t.width)), (t.top = null)),
                s === "nw" &&
                        ((t.top = e.top + (i.height - t.height)),
                        (t.left = e.left + (i.width - t.width))),
                t
            );
        },
        _respectSize(t) {
            const e = this._vBoundaries;
            const i = this.axis;
            const s = this._isNumber(t.width) && e.maxWidth && e.maxWidth < t.width;
            const n = this._isNumber(t.height) && e.maxHeight && e.maxHeight < t.height;
            const o = this._isNumber(t.width) && e.minWidth && e.minWidth > t.width;
            const a = this._isNumber(t.height) && e.minHeight && e.minHeight > t.height;
            const r = this.originalPosition.left + this.originalSize.width;
            const h = this.originalPosition.top + this.originalSize.height;
            const l = /sw|nw|w/.test(i);
            const c = /nw|ne|n/.test(i);
            return (
                o && (t.width = e.minWidth),
                a && (t.height = e.minHeight),
                s && (t.width = e.maxWidth),
                n && (t.height = e.maxHeight),
                o && l && (t.left = r - e.minWidth),
                s && l && (t.left = r - e.maxWidth),
                a && c && (t.top = h - e.minHeight),
                n && c && (t.top = h - e.maxHeight),
                t.width || t.height || t.left || !t.top
                    ? t.width || t.height || t.top || !t.left || (t.left = null)
                    : (t.top = null),
                t
            );
        },
        _getPaddingPlusBorderDimensions(t) {
            for (
                var e = 0,
                    i = [],
                    s = [
                        t.css("borderTopWidth"),
                        t.css("borderRightWidth"),
                        t.css("borderBottomWidth"),
                        t.css("borderLeftWidth"),
                    ],
                    n = [
                        t.css("paddingTop"),
                        t.css("paddingRight"),
                        t.css("paddingBottom"),
                        t.css("paddingLeft"),
                    ];
                e < 4;
                e++
            )
                (i[e] = parseFloat(s[e]) || 0), (i[e] += parseFloat(n[e]) || 0);
            return { height: i[0] + i[2], width: i[1] + i[3] };
        },
        _proportionallyResize() {
            if (this._proportionallyResizeElements.length)
                for (
                    var t, e = 0, i = this.helper || this.element;
                    this._proportionallyResizeElements.length > e;
                    e++
                )
                    (t = this._proportionallyResizeElements[e]),
                    this.outerDimensions ||
                                (this.outerDimensions = this._getPaddingPlusBorderDimensions(t)),
                    t.css({
                        height: i.height() - this.outerDimensions.height || 0,
                        width: i.width() - this.outerDimensions.width || 0,
                    });
        },
        _renderProxy() {
            const e = this.element;
            const i = this.options;
            (this.elementOffset = e.offset()),
            this._helper
                ? ((this.helper = this.helper || t("<div style='overflow:hidden;'></div>")),
                this._addClass(this.helper, this._helper),
                this.helper.css({
                    width: this.element.outerWidth(),
                    height: this.element.outerHeight(),
                    position: "absolute",
                    left: `${this.elementOffset.left}px`,
                    top: `${this.elementOffset.top}px`,
                    zIndex: ++i.zIndex,
                }),
                this.helper.appendTo("body").disableSelection())
                : (this.helper = this.element);
        },
        _change: {
            e(t, e) {
                return { width: this.originalSize.width + e };
            },
            w(t, e) {
                const i = this.originalSize;
                const s = this.originalPosition;
                return { left: s.left + e, width: i.width - e };
            },
            n(t, e, i) {
                const s = this.originalSize;
                const n = this.originalPosition;
                return { top: n.top + i, height: s.height - i };
            },
            s(t, e, i) {
                return { height: this.originalSize.height + i };
            },
            se(e, i, s) {
                return t.extend(
                    this._change.s.apply(this, arguments),
                    this._change.e.apply(this, [e, i, s]),
                );
            },
            sw(e, i, s) {
                return t.extend(
                    this._change.s.apply(this, arguments),
                    this._change.w.apply(this, [e, i, s]),
                );
            },
            ne(e, i, s) {
                return t.extend(
                    this._change.n.apply(this, arguments),
                    this._change.e.apply(this, [e, i, s]),
                );
            },
            nw(e, i, s) {
                return t.extend(
                    this._change.n.apply(this, arguments),
                    this._change.w.apply(this, [e, i, s]),
                );
            },
        },
        _propagate(e, i) {
            t.ui.plugin.call(this, e, [i, this.ui()]),
            e !== "resize" && this._trigger(e, i, this.ui());
        },
        plugins: {},
        ui() {
            return {
                originalElement: this.originalElement,
                element: this.element,
                helper: this.helper,
                position: this.position,
                size: this.size,
                originalSize: this.originalSize,
                originalPosition: this.originalPosition,
            };
        },
    }),
    t.ui.plugin.add("resizable", "animate", {
        stop(e) {
            const i = t(this).resizable("instance");
            const s = i.options;
            const n = i._proportionallyResizeElements;
            const o = n.length && /textarea/i.test(n[0].nodeName);
            const a = o && i._hasScroll(n[0], "left") ? 0 : i.sizeDiff.height;
            const r = o ? 0 : i.sizeDiff.width;
            const h = { width: i.size.width - r, height: i.size.height - a };
            const l =
                    parseFloat(i.element.css("left")) +
                        (i.position.left - i.originalPosition.left) || null;
            const c =
                    parseFloat(i.element.css("top")) + (i.position.top - i.originalPosition.top) ||
                    null;
            i.element.animate(t.extend(h, c && l ? { top: c, left: l } : {}), {
                duration: s.animateDuration,
                easing: s.animateEasing,
                step() {
                    const s = {
                        width: parseFloat(i.element.css("width")),
                        height: parseFloat(i.element.css("height")),
                        top: parseFloat(i.element.css("top")),
                        left: parseFloat(i.element.css("left")),
                    };
                    n && n.length && t(n[0]).css({ width: s.width, height: s.height }),
                    i._updateCache(s),
                    i._propagate("resize", e);
                },
            });
        },
    }),
    t.ui.plugin.add("resizable", "containment", {
        start() {
            let e;
            let i;
            let s;
            let n;
            let o;
            let a;
            let r;
            const h = t(this).resizable("instance");
            const l = h.options;
            const c = h.element;
            const u = l.containment;
            const d = u instanceof t ? u.get(0) : /parent/.test(u) ? c.parent().get(0) : u;
            d &&
                    ((h.containerElement = t(d)),
                    /document/.test(u) || u === document
                        ? ((h.containerOffset = { left: 0, top: 0 }),
                        (h.containerPosition = { left: 0, top: 0 }),
                        (h.parentData = {
                            element: t(document),
                            left: 0,
                            top: 0,
                            width: t(document).width(),
                            height: t(document).height() || document.body.parentNode.scrollHeight,
                        }))
                        : ((e = t(d)),
                        (i = []),
                        t(["Top", "Right", "Left", "Bottom"]).each((t, s) => {
                            i[t] = h._num(e.css(`padding${s}`));
                        }),
                        (h.containerOffset = e.offset()),
                        (h.containerPosition = e.position()),
                        (h.containerSize = {
                            height: e.innerHeight() - i[3],
                            width: e.innerWidth() - i[1],
                        }),
                        (s = h.containerOffset),
                        (n = h.containerSize.height),
                        (o = h.containerSize.width),
                        (a = h._hasScroll(d, "left") ? d.scrollWidth : o),
                        (r = h._hasScroll(d) ? d.scrollHeight : n),
                        (h.parentData = {
                            element: d,
                            left: s.left,
                            top: s.top,
                            width: a,
                            height: r,
                        })));
        },
        resize(e) {
            let i;
            let s;
            let n;
            let o;
            const a = t(this).resizable("instance");
            const r = a.options;
            const h = a.containerOffset;
            const l = a.position;
            const c = a._aspectRatio || e.shiftKey;
            let u = { top: 0, left: 0 };
            const d = a.containerElement;
            let p = !0;
            d[0] !== document && /static/.test(d.css("position")) && (u = h),
            l.left < (a._helper ? h.left : 0) &&
                        ((a.size.width =
                            a.size.width +
                            (a._helper ? a.position.left - h.left : a.position.left - u.left)),
                        c && ((a.size.height = a.size.width / a.aspectRatio), (p = !1)),
                        (a.position.left = r.helper ? h.left : 0)),
            l.top < (a._helper ? h.top : 0) &&
                        ((a.size.height =
                            a.size.height + (a._helper ? a.position.top - h.top : a.position.top)),
                        c && ((a.size.width = a.size.height * a.aspectRatio), (p = !1)),
                        (a.position.top = a._helper ? h.top : 0)),
            (n = a.containerElement.get(0) === a.element.parent().get(0)),
            (o = /relative|absolute/.test(a.containerElement.css("position"))),
            n && o
                ? ((a.offset.left = a.parentData.left + a.position.left),
                (a.offset.top = a.parentData.top + a.position.top))
                : ((a.offset.left = a.element.offset().left),
                (a.offset.top = a.element.offset().top)),
            (i = Math.abs(
                a.sizeDiff.width +
                            (a._helper ? a.offset.left - u.left : a.offset.left - h.left),
            )),
            (s = Math.abs(
                a.sizeDiff.height +
                            (a._helper ? a.offset.top - u.top : a.offset.top - h.top),
            )),
            i + a.size.width >= a.parentData.width &&
                        ((a.size.width = a.parentData.width - i),
                        c && ((a.size.height = a.size.width / a.aspectRatio), (p = !1))),
            s + a.size.height >= a.parentData.height &&
                        ((a.size.height = a.parentData.height - s),
                        c && ((a.size.width = a.size.height * a.aspectRatio), (p = !1))),
            p ||
                        ((a.position.left = a.prevPosition.left),
                        (a.position.top = a.prevPosition.top),
                        (a.size.width = a.prevSize.width),
                        (a.size.height = a.prevSize.height));
        },
        stop() {
            const e = t(this).resizable("instance");
            const i = e.options;
            const s = e.containerOffset;
            const n = e.containerPosition;
            const o = e.containerElement;
            const a = t(e.helper);
            const r = a.offset();
            const h = a.outerWidth() - e.sizeDiff.width;
            const l = a.outerHeight() - e.sizeDiff.height;
            e._helper &&
                    !i.animate &&
                    /relative/.test(o.css("position")) &&
                    t(this).css({ left: r.left - n.left - s.left, width: h, height: l }),
            e._helper &&
                        !i.animate &&
                        /static/.test(o.css("position")) &&
                        t(this).css({ left: r.left - n.left - s.left, width: h, height: l });
        },
    }),
    t.ui.plugin.add("resizable", "alsoResize", {
        start() {
            const e = t(this).resizable("instance");
            const i = e.options;
            t(i.alsoResize).each(function () {
                const e = t(this);
                e.data("ui-resizable-alsoresize", {
                    width: parseFloat(e.width()),
                    height: parseFloat(e.height()),
                    left: parseFloat(e.css("left")),
                    top: parseFloat(e.css("top")),
                });
            });
        },
        resize(e, i) {
            const s = t(this).resizable("instance");
            const n = s.options;
            const o = s.originalSize;
            const a = s.originalPosition;
            const r = {
                height: s.size.height - o.height || 0,
                width: s.size.width - o.width || 0,
                top: s.position.top - a.top || 0,
                left: s.position.left - a.left || 0,
            };
            t(n.alsoResize).each(function () {
                const e = t(this);
                const s = t(this).data("ui-resizable-alsoresize");
                const n = {};
                const o = e.parents(i.originalElement[0]).length
                    ? ["width", "height"]
                    : ["width", "height", "top", "left"];
                t.each(o, (t, e) => {
                    const i = (s[e] || 0) + (r[e] || 0);
                    i && i >= 0 && (n[e] = i || null);
                }),
                e.css(n);
            });
        },
        stop() {
            t(this).removeData("ui-resizable-alsoresize");
        },
    }),
    t.ui.plugin.add("resizable", "ghost", {
        start() {
            const e = t(this).resizable("instance");
            const i = e.size;
            (e.ghost = e.originalElement.clone()),
            e.ghost.css({
                opacity: 0.25,
                display: "block",
                position: "relative",
                height: i.height,
                width: i.width,
                margin: 0,
                left: 0,
                top: 0,
            }),
            e._addClass(e.ghost, "ui-resizable-ghost"),
            t.uiBackCompat !== !1 &&
                        typeof e.options.ghost === "string" &&
                        e.ghost.addClass(this.options.ghost),
            e.ghost.appendTo(e.helper);
        },
        resize() {
            const e = t(this).resizable("instance");
            e.ghost &&
                    e.ghost.css({
                        position: "relative",
                        height: e.size.height,
                        width: e.size.width,
                    });
        },
        stop() {
            const e = t(this).resizable("instance");
            e.ghost && e.helper && e.helper.get(0).removeChild(e.ghost.get(0));
        },
    }),
    t.ui.plugin.add("resizable", "grid", {
        resize() {
            let e;
            const i = t(this).resizable("instance");
            const s = i.options;
            const n = i.size;
            const o = i.originalSize;
            const a = i.originalPosition;
            const r = i.axis;
            const h = typeof s.grid === "number" ? [s.grid, s.grid] : s.grid;
            const l = h[0] || 1;
            const c = h[1] || 1;
            const u = Math.round((n.width - o.width) / l) * l;
            const d = Math.round((n.height - o.height) / c) * c;
            let p = o.width + u;
            let f = o.height + d;
            const g = s.maxWidth && p > s.maxWidth;
            const m = s.maxHeight && f > s.maxHeight;
            const _ = s.minWidth && s.minWidth > p;
            const v = s.minHeight && s.minHeight > f;
            (s.grid = h),
            _ && (p += l),
            v && (f += c),
            g && (p -= l),
            m && (f -= c),
            /^(se|s|e)$/.test(r)
                ? ((i.size.width = p), (i.size.height = f))
                : /^(ne)$/.test(r)
                    ? ((i.size.width = p), (i.size.height = f), (i.position.top = a.top - d))
                    : /^(sw)$/.test(r)
                        ? ((i.size.width = p), (i.size.height = f), (i.position.left = a.left - u))
                        : ((f - c <= 0 || p - l <= 0) &&
                              (e = i._getPaddingPlusBorderDimensions(this)),
                        f - c > 0
                            ? ((i.size.height = f), (i.position.top = a.top - d))
                            : ((f = c - e.height),
                            (i.size.height = f),
                            (i.position.top = a.top + o.height - f)),
                        p - l > 0
                            ? ((i.size.width = p), (i.position.left = a.left - u))
                            : ((p = l - e.width),
                            (i.size.width = p),
                            (i.position.left = a.left + o.width - p)));
        },
    }),
    t.ui.resizable,
    t.widget("ui.dialog", {
        version: "1.12.1",
        options: {
            appendTo: "body",
            autoOpen: !0,
            buttons: [],
            classes: { "ui-dialog": "ui-corner-all", "ui-dialog-titlebar": "ui-corner-all" },
            closeOnEscape: !0,
            closeText: "Close",
            draggable: !0,
            hide: null,
            height: "auto",
            maxHeight: null,
            maxWidth: null,
            minHeight: 150,
            minWidth: 150,
            modal: !1,
            position: {
                my: "center",
                at: "center",
                of: window,
                collision: "fit",
                using(e) {
                    const i = t(this).css(e).offset().top;
                    i < 0 && t(this).css("top", e.top - i);
                },
            },
            resizable: !0,
            show: null,
            title: null,
            width: 300,
            beforeClose: null,
            close: null,
            drag: null,
            dragStart: null,
            dragStop: null,
            focus: null,
            open: null,
            resize: null,
            resizeStart: null,
            resizeStop: null,
        },
        sizeRelatedOptions: {
            buttons: !0,
            height: !0,
            maxHeight: !0,
            maxWidth: !0,
            minHeight: !0,
            minWidth: !0,
            width: !0,
        },
        resizableRelatedOptions: {
            maxHeight: !0,
            maxWidth: !0,
            minHeight: !0,
            minWidth: !0,
        },
        _create() {
            (this.originalCss = {
                display: this.element[0].style.display,
                width: this.element[0].style.width,
                minHeight: this.element[0].style.minHeight,
                maxHeight: this.element[0].style.maxHeight,
                height: this.element[0].style.height,
            }),
            (this.originalPosition = {
                parent: this.element.parent(),
                index: this.element.parent().children().index(this.element),
            }),
            (this.originalTitle = this.element.attr("title")),
            this.options.title == null &&
                        this.originalTitle != null &&
                        (this.options.title = this.originalTitle),
            this.options.disabled && (this.options.disabled = !1),
            this._createWrapper(),
            this.element.show().removeAttr("title").appendTo(this.uiDialog),
            this._addClass("ui-dialog-content", "ui-widget-content"),
            this._createTitlebar(),
            this._createButtonPane(),
            this.options.draggable && t.fn.draggable && this._makeDraggable(),
            this.options.resizable && t.fn.resizable && this._makeResizable(),
            (this._isOpen = !1),
            this._trackFocus();
        },
        _init() {
            this.options.autoOpen && this.open();
        },
        _appendTo() {
            const e = this.options.appendTo;
            return e && (e.jquery || e.nodeType) ? t(e) : this.document.find(e || "body").eq(0);
        },
        _destroy() {
            let t;
            const e = this.originalPosition;
            this._untrackInstance(),
            this._destroyOverlay(),
            this.element.removeUniqueId().css(this.originalCss).detach(),
            this.uiDialog.remove(),
            this.originalTitle && this.element.attr("title", this.originalTitle),
            (t = e.parent.children().eq(e.index)),
            t.length && t[0] !== this.element[0]
                ? t.before(this.element)
                : e.parent.append(this.element);
        },
        widget() {
            return this.uiDialog;
        },
        disable: t.noop,
        enable: t.noop,
        close(e) {
            const i = this;
            this._isOpen &&
                    this._trigger("beforeClose", e) !== !1 &&
                    ((this._isOpen = !1),
                    (this._focusedElement = null),
                    this._destroyOverlay(),
                    this._untrackInstance(),
                    this.opener.filter(":focusable").trigger("focus").length ||
                        t.ui.safeBlur(t.ui.safeActiveElement(this.document[0])),
                    this._hide(this.uiDialog, this.options.hide, () => {
                        i._trigger("close", e);
                    }));
        },
        isOpen() {
            return this._isOpen;
        },
        moveToTop() {
            this._moveToTop();
        },
        _moveToTop(e, i) {
            let s = !1;
            const n = this.uiDialog
                .siblings(".ui-front:visible")
                .map(function () {
                    return +t(this).css("z-index");
                })
                .get();
            const o = Math.max.apply(null, n);
            return (
                o >= +this.uiDialog.css("z-index") &&
                        (this.uiDialog.css("z-index", o + 1), (s = !0)),
                s && !i && this._trigger("focus", e),
                s
            );
        },
        open() {
            const e = this;
            return this._isOpen
                ? (this._moveToTop() && this._focusTabbable(), void 0)
                : ((this._isOpen = !0),
                (this.opener = t(t.ui.safeActiveElement(this.document[0]))),
                this._size(),
                this._position(),
                this._createOverlay(),
                this._moveToTop(null, !0),
                this.overlay && this.overlay.css("z-index", this.uiDialog.css("z-index") - 1),
                this._show(this.uiDialog, this.options.show, () => {
                    e._focusTabbable(), e._trigger("focus");
                }),
                this._makeFocusTarget(),
                this._trigger("open"),
                void 0);
        },
        _focusTabbable() {
            let t = this._focusedElement;
            t || (t = this.element.find("[autofocus]")),
            t.length || (t = this.element.find(":tabbable")),
            t.length || (t = this.uiDialogButtonPane.find(":tabbable")),
            t.length || (t = this.uiDialogTitlebarClose.filter(":tabbable")),
            t.length || (t = this.uiDialog),
            t.eq(0).trigger("focus");
        },
        _keepFocus(e) {
            function i() {
                const e = t.ui.safeActiveElement(this.document[0]);
                const i = this.uiDialog[0] === e || t.contains(this.uiDialog[0], e);
                i || this._focusTabbable();
            }
            e.preventDefault(), i.call(this), this._delay(i);
        },
        _createWrapper() {
            (this.uiDialog = t("<div>")
                .hide()
                .attr({ tabIndex: -1, role: "dialog" })
                .appendTo(this._appendTo())),
            this._addClass(
                this.uiDialog,
                "ui-dialog",
                "ui-widget ui-widget-content ui-front",
            ),
            this._on(this.uiDialog, {
                keydown(e) {
                    if (
                        this.options.closeOnEscape &&
                                !e.isDefaultPrevented() &&
                                e.keyCode &&
                                e.keyCode === t.ui.keyCode.ESCAPE
                    )
                        return e.preventDefault(), this.close(e), void 0;
                    if (e.keyCode === t.ui.keyCode.TAB && !e.isDefaultPrevented()) {
                        const i = this.uiDialog.find(":tabbable");
                        const s = i.filter(":first");
                        const n = i.filter(":last");
                        (e.target !== n[0] && e.target !== this.uiDialog[0]) || e.shiftKey
                            ? (e.target !== s[0] && e.target !== this.uiDialog[0]) ||
                                      !e.shiftKey ||
                                      (this._delay(() => {
                                          n.trigger("focus");
                                      }),
                                      e.preventDefault())
                            : (this._delay(() => {
                                s.trigger("focus");
                            }),
                            e.preventDefault());
                    }
                },
                mousedown(t) {
                    this._moveToTop(t) && this._focusTabbable();
                },
            }),
            this.element.find("[aria-describedby]").length ||
                        this.uiDialog.attr({
                            "aria-describedby": this.element.uniqueId().attr("id"),
                        });
        },
        _createTitlebar() {
            let e;
            (this.uiDialogTitlebar = t("<div>")),
            this._addClass(
                this.uiDialogTitlebar,
                "ui-dialog-titlebar",
                "ui-widget-header ui-helper-clearfix",
            ),
            this._on(this.uiDialogTitlebar, {
                mousedown(e) {
                    t(e.target).closest(".ui-dialog-titlebar-close") ||
                                this.uiDialog.trigger("focus");
                },
            }),
            (this.uiDialogTitlebarClose = t("<button type='button'></button>")
                .button({
                    label: t("<a>").text(this.options.closeText).html(),
                    icon: "ui-icon-closethick",
                    showLabel: !1,
                })
                .appendTo(this.uiDialogTitlebar)),
            this._addClass(this.uiDialogTitlebarClose, "ui-dialog-titlebar-close"),
            this._on(this.uiDialogTitlebarClose, {
                click(t) {
                    t.preventDefault(), this.close(t);
                },
            }),
            (e = t("<span>").uniqueId().prependTo(this.uiDialogTitlebar)),
            this._addClass(e, "ui-dialog-title"),
            this._title(e),
            this.uiDialogTitlebar.prependTo(this.uiDialog),
            this.uiDialog.attr({ "aria-labelledby": e.attr("id") });
        },
        _title(t) {
            this.options.title ? t.text(this.options.title) : t.html("&#160;");
        },
        _createButtonPane() {
            (this.uiDialogButtonPane = t("<div>")),
            this._addClass(
                this.uiDialogButtonPane,
                "ui-dialog-buttonpane",
                "ui-widget-content ui-helper-clearfix",
            ),
            (this.uiButtonSet = t("<div>").appendTo(this.uiDialogButtonPane)),
            this._addClass(this.uiButtonSet, "ui-dialog-buttonset"),
            this._createButtons();
        },
        _createButtons() {
            const e = this;
            const i = this.options.buttons;
            return (
                this.uiDialogButtonPane.remove(),
                this.uiButtonSet.empty(),
                t.isEmptyObject(i) || (t.isArray(i) && !i.length)
                    ? (this._removeClass(this.uiDialog, "ui-dialog-buttons"), void 0)
                    : (t.each(i, (i, s) => {
                        let n;
                        let o;
                        (s = t.isFunction(s) ? { click: s, text: i } : s),
                        (s = t.extend({ type: "button" }, s)),
                        (n = s.click),
                        (o = {
                            icon: s.icon,
                            iconPosition: s.iconPosition,
                            showLabel: s.showLabel,
                            icons: s.icons,
                            text: s.text,
                        }),
                        delete s.click,
                        delete s.icon,
                        delete s.iconPosition,
                        delete s.showLabel,
                        delete s.icons,
                        typeof s.text === "boolean" && delete s.text,
                        t("<button></button>", s)
                            .button(o)
                            .appendTo(e.uiButtonSet)
                            .on("click", function () {
                                n.apply(e.element[0], arguments);
                            });
                    }),
                    this._addClass(this.uiDialog, "ui-dialog-buttons"),
                    this.uiDialogButtonPane.appendTo(this.uiDialog),
                    void 0)
            );
        },
        _makeDraggable() {
            function e(t) {
                return { position: t.position, offset: t.offset };
            }
            const i = this;
            const s = this.options;
            this.uiDialog.draggable({
                cancel: ".ui-dialog-content, .ui-dialog-titlebar-close",
                handle: ".ui-dialog-titlebar",
                containment: "document",
                start(s, n) {
                    i._addClass(t(this), "ui-dialog-dragging"),
                    i._blockFrames(),
                    i._trigger("dragStart", s, e(n));
                },
                drag(t, s) {
                    i._trigger("drag", t, e(s));
                },
                stop(n, o) {
                    const a = o.offset.left - i.document.scrollLeft();
                    const r = o.offset.top - i.document.scrollTop();
                    (s.position = {
                        my: "left top",
                        at: `left${a >= 0 ? "+" : ""}${a} ` + `top${r >= 0 ? "+" : ""}${r}`,
                        of: i.window,
                    }),
                    i._removeClass(t(this), "ui-dialog-dragging"),
                    i._unblockFrames(),
                    i._trigger("dragStop", n, e(o));
                },
            });
        },
        _makeResizable() {
            function e(t) {
                return {
                    originalPosition: t.originalPosition,
                    originalSize: t.originalSize,
                    position: t.position,
                    size: t.size,
                };
            }
            const i = this;
            const s = this.options;
            const n = s.resizable;
            const o = this.uiDialog.css("position");
            const a = typeof n === "string" ? n : "n,e,s,w,se,sw,ne,nw";
            this.uiDialog
                .resizable({
                    cancel: ".ui-dialog-content",
                    containment: "document",
                    alsoResize: this.element,
                    maxWidth: s.maxWidth,
                    maxHeight: s.maxHeight,
                    minWidth: s.minWidth,
                    minHeight: this._minHeight(),
                    handles: a,
                    start(s, n) {
                        i._addClass(t(this), "ui-dialog-resizing"),
                        i._blockFrames(),
                        i._trigger("resizeStart", s, e(n));
                    },
                    resize(t, s) {
                        i._trigger("resize", t, e(s));
                    },
                    stop(n, o) {
                        const a = i.uiDialog.offset();
                        const r = a.left - i.document.scrollLeft();
                        const h = a.top - i.document.scrollTop();
                        (s.height = i.uiDialog.height()),
                        (s.width = i.uiDialog.width()),
                        (s.position = {
                            my: "left top",
                            at:
                                        `left${r >= 0 ? "+" : ""}${r} ` +
                                        `top${h >= 0 ? "+" : ""}${h}`,
                            of: i.window,
                        }),
                        i._removeClass(t(this), "ui-dialog-resizing"),
                        i._unblockFrames(),
                        i._trigger("resizeStop", n, e(o));
                    },
                })
                .css("position", o);
        },
        _trackFocus() {
            this._on(this.widget(), {
                focusin(e) {
                    this._makeFocusTarget(), (this._focusedElement = t(e.target));
                },
            });
        },
        _makeFocusTarget() {
            this._untrackInstance(), this._trackingInstances().unshift(this);
        },
        _untrackInstance() {
            const e = this._trackingInstances();
            const i = t.inArray(this, e);
            i !== -1 && e.splice(i, 1);
        },
        _trackingInstances() {
            let t = this.document.data("ui-dialog-instances");
            return t || ((t = []), this.document.data("ui-dialog-instances", t)), t;
        },
        _minHeight() {
            const t = this.options;
            return t.height === "auto" ? t.minHeight : Math.min(t.minHeight, t.height);
        },
        _position() {
            const t = this.uiDialog.is(":visible");
            t || this.uiDialog.show(),
            this.uiDialog.position(this.options.position),
            t || this.uiDialog.hide();
        },
        _setOptions(e) {
            const i = this;
            let s = !1;
            const n = {};
            t.each(e, (t, e) => {
                i._setOption(t, e),
                t in i.sizeRelatedOptions && (s = !0),
                t in i.resizableRelatedOptions && (n[t] = e);
            }),
            s && (this._size(), this._position()),
            this.uiDialog.is(":data(ui-resizable)") && this.uiDialog.resizable("option", n);
        },
        _setOption(e, i) {
            let s;
            let n;
            const o = this.uiDialog;
            e !== "disabled" &&
                    (this._super(e, i),
                    e === "appendTo" && this.uiDialog.appendTo(this._appendTo()),
                    e === "buttons" && this._createButtons(),
                    e === "closeText" &&
                        this.uiDialogTitlebarClose.button({
                            label: t("<a>").text(`${this.options.closeText}`).html(),
                        }),
                    e === "draggable" &&
                        ((s = o.is(":data(ui-draggable)")),
                        s && !i && o.draggable("destroy"),
                        !s && i && this._makeDraggable()),
                    e === "position" && this._position(),
                    e === "resizable" &&
                        ((n = o.is(":data(ui-resizable)")),
                        n && !i && o.resizable("destroy"),
                        n && typeof i === "string" && o.resizable("option", "handles", i),
                        n || i === !1 || this._makeResizable()),
                    e === "title" && this._title(this.uiDialogTitlebar.find(".ui-dialog-title")));
        },
        _size() {
            let t;
            let e;
            let i;
            const s = this.options;
            this.element.show().css({
                width: "auto",
                minHeight: 0,
                maxHeight: "none",
                height: 0,
            }),
            s.minWidth > s.width && (s.width = s.minWidth),
            (t = this.uiDialog.css({ height: "auto", width: s.width }).outerHeight()),
            (e = Math.max(0, s.minHeight - t)),
            (i = typeof s.maxHeight === "number" ? Math.max(0, s.maxHeight - t) : "none"),
            s.height === "auto"
                ? this.element.css({ minHeight: e, maxHeight: i, height: "auto" })
                : this.element.height(Math.max(0, s.height - t)),
            this.uiDialog.is(":data(ui-resizable)") &&
                        this.uiDialog.resizable("option", "minHeight", this._minHeight());
        },
        _blockFrames() {
            this.iframeBlocks = this.document.find("iframe").map(function () {
                const e = t(this);
                return t("<div>")
                    .css({
                        position: "absolute",
                        width: e.outerWidth(),
                        height: e.outerHeight(),
                    })
                    .appendTo(e.parent())
                    .offset(e.offset())[0];
            });
        },
        _unblockFrames() {
            this.iframeBlocks && (this.iframeBlocks.remove(), delete this.iframeBlocks);
        },
        _allowInteraction(e) {
            return t(e.target).closest(".ui-dialog").length
                ? !0
                : !!t(e.target).closest(".ui-datepicker").length;
        },
        _createOverlay() {
            if (this.options.modal) {
                let e = !0;
                this._delay(() => {
                    e = !1;
                }),
                this.document.data("ui-dialog-overlays") ||
                            this._on(this.document, {
                                focusin(t) {
                                    e ||
                                        this._allowInteraction(t) ||
                                        (t.preventDefault(),
                                        this._trackingInstances()[0]._focusTabbable());
                                },
                            }),
                (this.overlay = t("<div>").appendTo(this._appendTo())),
                this._addClass(this.overlay, null, "ui-widget-overlay ui-front"),
                this._on(this.overlay, { mousedown: "_keepFocus" }),
                this.document.data(
                    "ui-dialog-overlays",
                    (this.document.data("ui-dialog-overlays") || 0) + 1,
                );
            }
        },
        _destroyOverlay() {
            if (this.options.modal && this.overlay) {
                const t = this.document.data("ui-dialog-overlays") - 1;
                t
                    ? this.document.data("ui-dialog-overlays", t)
                    : (this._off(this.document, "focusin"),
                    this.document.removeData("ui-dialog-overlays")),
                this.overlay.remove(),
                (this.overlay = null);
            }
        },
    }),
    t.uiBackCompat !== !1 &&
            t.widget("ui.dialog", t.ui.dialog, {
                options: { dialogClass: "" },
                _createWrapper() {
                    this._super(), this.uiDialog.addClass(this.options.dialogClass);
                },
                _setOption(t, e) {
                    t === "dialogClass" &&
                        this.uiDialog.removeClass(this.options.dialogClass).addClass(e),
                    this._superApply(arguments);
                },
            }),
    t.ui.dialog,
    t.widget("ui.droppable", {
        version: "1.12.1",
        widgetEventPrefix: "drop",
        options: {
            accept: "*",
            addClasses: !0,
            greedy: !1,
            scope: "default",
            tolerance: "intersect",
            activate: null,
            deactivate: null,
            drop: null,
            out: null,
            over: null,
        },
        _create() {
            let e;
            const i = this.options;
            const s = i.accept;
            (this.isover = !1),
            (this.isout = !0),
            (this.accept = t.isFunction(s)
                ? s
                : function (t) {
                    return t.is(s);
                }),
            (this.proportions = function () {
                return arguments.length
                    ? ((e = arguments[0]), void 0)
                    : e ||
                                  (e = {
                                      width: this.element[0].offsetWidth,
                                      height: this.element[0].offsetHeight,
                                  });
            }),
            this._addToManager(i.scope),
            i.addClasses && this._addClass("ui-droppable");
        },
        _addToManager(e) {
            (t.ui.ddmanager.droppables[e] = t.ui.ddmanager.droppables[e] || []),
            t.ui.ddmanager.droppables[e].push(this);
        },
        _splice(t) {
            for (let e = 0; t.length > e; e++) t[e] === this && t.splice(e, 1);
        },
        _destroy() {
            const e = t.ui.ddmanager.droppables[this.options.scope];
            this._splice(e);
        },
        _setOption(e, i) {
            if (e === "accept")
                this.accept = t.isFunction(i)
                    ? i
                    : function (t) {
                        return t.is(i);
                    };
            else if (e === "scope") {
                const s = t.ui.ddmanager.droppables[this.options.scope];
                this._splice(s), this._addToManager(i);
            }
            this._super(e, i);
        },
        _activate(e) {
            const i = t.ui.ddmanager.current;
            this._addActiveClass(), i && this._trigger("activate", e, this.ui(i));
        },
        _deactivate(e) {
            const i = t.ui.ddmanager.current;
            this._removeActiveClass(), i && this._trigger("deactivate", e, this.ui(i));
        },
        _over(e) {
            const i = t.ui.ddmanager.current;
            i &&
                    (i.currentItem || i.element)[0] !== this.element[0] &&
                    this.accept.call(this.element[0], i.currentItem || i.element) &&
                    (this._addHoverClass(), this._trigger("over", e, this.ui(i)));
        },
        _out(e) {
            const i = t.ui.ddmanager.current;
            i &&
                    (i.currentItem || i.element)[0] !== this.element[0] &&
                    this.accept.call(this.element[0], i.currentItem || i.element) &&
                    (this._removeHoverClass(), this._trigger("out", e, this.ui(i)));
        },
        _drop(e, i) {
            const s = i || t.ui.ddmanager.current;
            let n = !1;
            return s && (s.currentItem || s.element)[0] !== this.element[0]
                ? (this.element
                    .find(":data(ui-droppable)")
                    .not(".ui-draggable-dragging")
                    .each(function () {
                        const i = t(this).droppable("instance");
                        return i.options.greedy &&
                                  !i.options.disabled &&
                                  i.options.scope === s.options.scope &&
                                  i.accept.call(i.element[0], s.currentItem || s.element) &&
                                  v(
                                      s,
                                      t.extend(i, { offset: i.element.offset() }),
                                      i.options.tolerance,
                                      e,
                                  )
                            ? ((n = !0), !1)
                            : void 0;
                    }),
                n
                    ? !1
                    : this.accept.call(this.element[0], s.currentItem || s.element)
                        ? (this._removeActiveClass(),
                        this._removeHoverClass(),
                        this._trigger("drop", e, this.ui(s)),
                        this.element)
                        : !1)
                : !1;
        },
        ui(t) {
            return {
                draggable: t.currentItem || t.element,
                helper: t.helper,
                position: t.position,
                offset: t.positionAbs,
            };
        },
        _addHoverClass() {
            this._addClass("ui-droppable-hover");
        },
        _removeHoverClass() {
            this._removeClass("ui-droppable-hover");
        },
        _addActiveClass() {
            this._addClass("ui-droppable-active");
        },
        _removeActiveClass() {
            this._removeClass("ui-droppable-active");
        },
    });
    var v = (t.ui.intersect = (function () {
        function t(t, e, i) {
            return t >= e && e + i > t;
        }
        return function (e, i, s, n) {
            if (!i.offset) return !1;
            const o = (e.positionAbs || e.position.absolute).left + e.margins.left;
            const a = (e.positionAbs || e.position.absolute).top + e.margins.top;
            const r = o + e.helperProportions.width;
            const h = a + e.helperProportions.height;
            const l = i.offset.left;
            const c = i.offset.top;
            const u = l + i.proportions().width;
            const d = c + i.proportions().height;
            switch (s) {
            case "fit":
                return o >= l && u >= r && a >= c && d >= h;
            case "intersect":
                return (
                    o + e.helperProportions.width / 2 > l &&
                        u > r - e.helperProportions.width / 2 &&
                        a + e.helperProportions.height / 2 > c &&
                        d > h - e.helperProportions.height / 2
                );
            case "pointer":
                return (
                    t(n.pageY, c, i.proportions().height) &&
                        t(n.pageX, l, i.proportions().width)
                );
            case "touch":
                return (
                    ((a >= c && d >= a) || (h >= c && d >= h) || (c > a && h > d)) &&
                        ((o >= l && u >= o) || (r >= l && u >= r) || (l > o && r > u))
                );
            default:
                return !1;
            }
        };
    })());
    (t.ui.ddmanager = {
        current: null,
        droppables: { default: [] },
        prepareOffsets(e, i) {
            let s;
            let n;
            const o = t.ui.ddmanager.droppables[e.options.scope] || [];
            const a = i ? i.type : null;
            const r = (e.currentItem || e.element).find(":data(ui-droppable)").addBack();
            t: for (s = 0; o.length > s; s++)
                if (
                    !(
                        o[s].options.disabled ||
                        (e && !o[s].accept.call(o[s].element[0], e.currentItem || e.element))
                    )
                ) {
                    for (n = 0; r.length > n; n++)
                        if (r[n] === o[s].element[0]) {
                            o[s].proportions().height = 0;
                            continue t;
                        }
                    (o[s].visible = o[s].element.css("display") !== "none"),
                    o[s].visible &&
                            (a === "mousedown" && o[s]._activate.call(o[s], i),
                            (o[s].offset = o[s].element.offset()),
                            o[s].proportions({
                                width: o[s].element[0].offsetWidth,
                                height: o[s].element[0].offsetHeight,
                            }));
                }
        },
        drop(e, i) {
            let s = !1;
            return (
                t.each((t.ui.ddmanager.droppables[e.options.scope] || []).slice(), function () {
                    this.options &&
                        (!this.options.disabled &&
                            this.visible &&
                            v(e, this, this.options.tolerance, i) &&
                            (s = this._drop.call(this, i) || s),
                        !this.options.disabled &&
                            this.visible &&
                            this.accept.call(this.element[0], e.currentItem || e.element) &&
                            ((this.isout = !0),
                            (this.isover = !1),
                            this._deactivate.call(this, i)));
                }),
                s
            );
        },
        dragStart(e, i) {
            e.element.parentsUntil("body").on("scroll.droppable", () => {
                e.options.refreshPositions || t.ui.ddmanager.prepareOffsets(e, i);
            });
        },
        drag(e, i) {
            e.options.refreshPositions && t.ui.ddmanager.prepareOffsets(e, i),
            t.each(t.ui.ddmanager.droppables[e.options.scope] || [], function () {
                if (!this.options.disabled && !this.greedyChild && this.visible) {
                    let s;
                    let n;
                    let o;
                    const a = v(e, this, this.options.tolerance, i);
                    const r = !a && this.isover ? "isout" : a && !this.isover ? "isover" : null;
                    r &&
                            (this.options.greedy &&
                                ((n = this.options.scope),
                                (o = this.element
                                    .parents(":data(ui-droppable)")
                                    .filter(function () {
                                        return t(this).droppable("instance").options.scope === n;
                                    })),
                                o.length &&
                                    ((s = t(o[0]).droppable("instance")),
                                    (s.greedyChild = r === "isover"))),
                            s &&
                                r === "isover" &&
                                ((s.isover = !1), (s.isout = !0), s._out.call(s, i)),
                            (this[r] = !0),
                            (this[r === "isout" ? "isover" : "isout"] = !1),
                            this[r === "isover" ? "_over" : "_out"].call(this, i),
                            s &&
                                r === "isout" &&
                                ((s.isout = !1), (s.isover = !0), s._over.call(s, i)));
                }
            });
        },
        dragStop(e, i) {
            e.element.parentsUntil("body").off("scroll.droppable"),
            e.options.refreshPositions || t.ui.ddmanager.prepareOffsets(e, i);
        },
    }),
    t.uiBackCompat !== !1 &&
            t.widget("ui.droppable", t.ui.droppable, {
                options: { hoverClass: !1, activeClass: !1 },
                _addActiveClass() {
                    this._super(),
                    this.options.activeClass && this.element.addClass(this.options.activeClass);
                },
                _removeActiveClass() {
                    this._super(),
                    this.options.activeClass &&
                            this.element.removeClass(this.options.activeClass);
                },
                _addHoverClass() {
                    this._super(),
                    this.options.hoverClass && this.element.addClass(this.options.hoverClass);
                },
                _removeHoverClass() {
                    this._super(),
                    this.options.hoverClass &&
                            this.element.removeClass(this.options.hoverClass);
                },
            }),
    t.ui.droppable,
    t.widget("ui.progressbar", {
        version: "1.12.1",
        options: {
            classes: {
                "ui-progressbar": "ui-corner-all",
                "ui-progressbar-value": "ui-corner-left",
                "ui-progressbar-complete": "ui-corner-right",
            },
            max: 100,
            value: 0,
            change: null,
            complete: null,
        },
        min: 0,
        _create() {
            (this.oldValue = this.options.value = this._constrainedValue()),
            this.element.attr({ role: "progressbar", "aria-valuemin": this.min }),
            this._addClass("ui-progressbar", "ui-widget ui-widget-content"),
            (this.valueDiv = t("<div>").appendTo(this.element)),
            this._addClass(this.valueDiv, "ui-progressbar-value", "ui-widget-header"),
            this._refreshValue();
        },
        _destroy() {
            this.element.removeAttr("role aria-valuemin aria-valuemax aria-valuenow"),
            this.valueDiv.remove();
        },
        value(t) {
            return void 0 === t
                ? this.options.value
                : ((this.options.value = this._constrainedValue(t)),
                this._refreshValue(),
                void 0);
        },
        _constrainedValue(t) {
            return (
                void 0 === t && (t = this.options.value),
                (this.indeterminate = t === !1),
                typeof t !== "number" && (t = 0),
                this.indeterminate ? !1 : Math.min(this.options.max, Math.max(this.min, t))
            );
        },
        _setOptions(t) {
            const e = t.value;
            delete t.value,
            this._super(t),
            (this.options.value = this._constrainedValue(e)),
            this._refreshValue();
        },
        _setOption(t, e) {
            t === "max" && (e = Math.max(this.min, e)), this._super(t, e);
        },
        _setOptionDisabled(t) {
            this._super(t),
            this.element.attr("aria-disabled", t),
            this._toggleClass(null, "ui-state-disabled", !!t);
        },
        _percentage() {
            return this.indeterminate
                ? 100
                : (100 * (this.options.value - this.min)) / (this.options.max - this.min);
        },
        _refreshValue() {
            const e = this.options.value;
            const i = this._percentage();
            this.valueDiv.toggle(this.indeterminate || e > this.min).width(`${i.toFixed(0)}%`),
            this._toggleClass(
                this.valueDiv,
                "ui-progressbar-complete",
                null,
                e === this.options.max,
            )._toggleClass("ui-progressbar-indeterminate", null, this.indeterminate),
            this.indeterminate
                ? (this.element.removeAttr("aria-valuenow"),
                this.overlayDiv ||
                              ((this.overlayDiv = t("<div>").appendTo(this.valueDiv)),
                              this._addClass(this.overlayDiv, "ui-progressbar-overlay")))
                : (this.element.attr({
                    "aria-valuemax": this.options.max,
                    "aria-valuenow": e,
                }),
                this.overlayDiv && (this.overlayDiv.remove(), (this.overlayDiv = null))),
            this.oldValue !== e && ((this.oldValue = e), this._trigger("change")),
            e === this.options.max && this._trigger("complete");
        },
    }),
    t.widget("ui.selectable", t.ui.mouse, {
        version: "1.12.1",
        options: {
            appendTo: "body",
            autoRefresh: !0,
            distance: 0,
            filter: "*",
            tolerance: "touch",
            selected: null,
            selecting: null,
            start: null,
            stop: null,
            unselected: null,
            unselecting: null,
        },
        _create() {
            const e = this;
            this._addClass("ui-selectable"),
            (this.dragged = !1),
            (this.refresh = function () {
                (e.elementPos = t(e.element[0]).offset()),
                (e.selectees = t(e.options.filter, e.element[0])),
                e._addClass(e.selectees, "ui-selectee"),
                e.selectees.each(function () {
                    const i = t(this);
                    const s = i.offset();
                    const n = {
                        left: s.left - e.elementPos.left,
                        top: s.top - e.elementPos.top,
                    };
                    t.data(this, "selectable-item", {
                        element: this,
                        $element: i,
                        left: n.left,
                        top: n.top,
                        right: n.left + i.outerWidth(),
                        bottom: n.top + i.outerHeight(),
                        startselected: !1,
                        selected: i.hasClass("ui-selected"),
                        selecting: i.hasClass("ui-selecting"),
                        unselecting: i.hasClass("ui-unselecting"),
                    });
                });
            }),
            this.refresh(),
            this._mouseInit(),
            (this.helper = t("<div>")),
            this._addClass(this.helper, "ui-selectable-helper");
        },
        _destroy() {
            this.selectees.removeData("selectable-item"), this._mouseDestroy();
        },
        _mouseStart(e) {
            const i = this;
            const s = this.options;
            (this.opos = [e.pageX, e.pageY]),
            (this.elementPos = t(this.element[0]).offset()),
            this.options.disabled ||
                        ((this.selectees = t(s.filter, this.element[0])),
                        this._trigger("start", e),
                        t(s.appendTo).append(this.helper),
                        this.helper.css({
                            left: e.pageX,
                            top: e.pageY,
                            width: 0,
                            height: 0,
                        }),
                        s.autoRefresh && this.refresh(),
                        this.selectees.filter(".ui-selected").each(function () {
                            const s = t.data(this, "selectable-item");
                            (s.startselected = !0),
                            e.metaKey ||
                                    e.ctrlKey ||
                                    (i._removeClass(s.$element, "ui-selected"),
                                    (s.selected = !1),
                                    i._addClass(s.$element, "ui-unselecting"),
                                    (s.unselecting = !0),
                                    i._trigger("unselecting", e, { unselecting: s.element }));
                        }),
                        t(e.target)
                            .parents()
                            .addBack()
                            .each(function () {
                                let s;
                                const n = t.data(this, "selectable-item");
                                return n
                                    ? ((s =
                                          (!e.metaKey && !e.ctrlKey) ||
                                          !n.$element.hasClass("ui-selected")),
                                    i
                                        ._removeClass(
                                            n.$element,
                                            s ? "ui-unselecting" : "ui-selected",
                                        )
                                        ._addClass(
                                            n.$element,
                                            s ? "ui-selecting" : "ui-unselecting",
                                        ),
                                    (n.unselecting = !s),
                                    (n.selecting = s),
                                    (n.selected = s),
                                    s
                                        ? i._trigger("selecting", e, { selecting: n.element })
                                        : i._trigger("unselecting", e, {
                                            unselecting: n.element,
                                        }),
                                    !1)
                                    : void 0;
                            }));
        },
        _mouseDrag(e) {
            if (((this.dragged = !0), !this.options.disabled)) {
                let i;
                const s = this;
                const n = this.options;
                let o = this.opos[0];
                let a = this.opos[1];
                let r = e.pageX;
                let h = e.pageY;
                return (
                    o > r && ((i = r), (r = o), (o = i)),
                    a > h && ((i = h), (h = a), (a = i)),
                    this.helper.css({
                        left: o,
                        top: a,
                        width: r - o,
                        height: h - a,
                    }),
                    this.selectees.each(function () {
                        const i = t.data(this, "selectable-item");
                        let l = !1;
                        const c = {};
                        i &&
                                i.element !== s.element[0] &&
                                ((c.left = i.left + s.elementPos.left),
                                (c.right = i.right + s.elementPos.left),
                                (c.top = i.top + s.elementPos.top),
                                (c.bottom = i.bottom + s.elementPos.top),
                                n.tolerance === "touch"
                                    ? (l = !(
                                        c.left > r ||
                                          o > c.right ||
                                          c.top > h ||
                                          a > c.bottom
                                    ))
                                    : n.tolerance === "fit" &&
                                      (l = c.left > o && r > c.right && c.top > a && h > c.bottom),
                                l
                                    ? (i.selected &&
                                          (s._removeClass(i.$element, "ui-selected"),
                                          (i.selected = !1)),
                                    i.unselecting &&
                                          (s._removeClass(i.$element, "ui-unselecting"),
                                          (i.unselecting = !1)),
                                    i.selecting ||
                                          (s._addClass(i.$element, "ui-selecting"),
                                          (i.selecting = !0),
                                          s._trigger("selecting", e, { selecting: i.element })))
                                    : (i.selecting &&
                                          ((e.metaKey || e.ctrlKey) && i.startselected
                                              ? (s._removeClass(i.$element, "ui-selecting"),
                                              (i.selecting = !1),
                                              s._addClass(i.$element, "ui-selected"),
                                              (i.selected = !0))
                                              : (s._removeClass(i.$element, "ui-selecting"),
                                              (i.selecting = !1),
                                              i.startselected &&
                                                    (s._addClass(i.$element, "ui-unselecting"),
                                                    (i.unselecting = !0)),
                                              s._trigger("unselecting", e, {
                                                  unselecting: i.element,
                                              }))),
                                    i.selected &&
                                          (e.metaKey ||
                                              e.ctrlKey ||
                                              i.startselected ||
                                              (s._removeClass(i.$element, "ui-selected"),
                                              (i.selected = !1),
                                              s._addClass(i.$element, "ui-unselecting"),
                                              (i.unselecting = !0),
                                              s._trigger("unselecting", e, {
                                                  unselecting: i.element,
                                              })))));
                    }),
                    !1
                );
            }
        },
        _mouseStop(e) {
            const i = this;
            return (
                (this.dragged = !1),
                t(".ui-unselecting", this.element[0]).each(function () {
                    const s = t.data(this, "selectable-item");
                    i._removeClass(s.$element, "ui-unselecting"),
                    (s.unselecting = !1),
                    (s.startselected = !1),
                    i._trigger("unselected", e, { unselected: s.element });
                }),
                t(".ui-selecting", this.element[0]).each(function () {
                    const s = t.data(this, "selectable-item");
                    i
                        ._removeClass(s.$element, "ui-selecting")
                        ._addClass(s.$element, "ui-selected"),
                    (s.selecting = !1),
                    (s.selected = !0),
                    (s.startselected = !0),
                    i._trigger("selected", e, { selected: s.element });
                }),
                this._trigger("stop", e),
                this.helper.remove(),
                !1
            );
        },
    }),
    t.widget("ui.selectmenu", [
        t.ui.formResetMixin,
        {
            version: "1.12.1",
            defaultElement: "<select>",
            options: {
                appendTo: null,
                classes: {
                    "ui-selectmenu-button-open": "ui-corner-top",
                    "ui-selectmenu-button-closed": "ui-corner-all",
                },
                disabled: null,
                icons: { button: "ui-icon-triangle-1-s" },
                position: { my: "left top", at: "left bottom", collision: "none" },
                width: !1,
                change: null,
                close: null,
                focus: null,
                open: null,
                select: null,
            },
            _create() {
                const e = this.element.uniqueId().attr("id");
                (this.ids = { element: e, button: `${e}-button`, menu: `${e}-menu` }),
                this._drawButton(),
                this._drawMenu(),
                this._bindFormResetHandler(),
                (this._rendered = !1),
                (this.menuItems = t());
            },
            _drawButton() {
                let e;
                const i = this;
                const s = this._parseOption(
                    this.element.find("option:selected"),
                    this.element[0].selectedIndex,
                );
                (this.labels = this.element.labels().attr("for", this.ids.button)),
                this._on(this.labels, {
                    click(t) {
                        this.button.focus(), t.preventDefault();
                    },
                }),
                this.element.hide(),
                (this.button = t("<span>", {
                    tabindex: this.options.disabled ? -1 : 0,
                    id: this.ids.button,
                    role: "combobox",
                    "aria-expanded": "false",
                    "aria-autocomplete": "list",
                    "aria-owns": this.ids.menu,
                    "aria-haspopup": "true",
                    title: this.element.attr("title"),
                }).insertAfter(this.element)),
                this._addClass(
                    this.button,
                    "ui-selectmenu-button ui-selectmenu-button-closed",
                    "ui-button ui-widget",
                ),
                (e = t("<span>").appendTo(this.button)),
                this._addClass(
                    e,
                    "ui-selectmenu-icon",
                    `ui-icon ${this.options.icons.button}`,
                ),
                (this.buttonItem = this._renderButtonItem(s).appendTo(this.button)),
                this.options.width !== !1 && this._resizeButton(),
                this._on(this.button, this._buttonEvents),
                this.button.one("focusin", () => {
                    i._rendered || i._refreshMenu();
                });
            },
            _drawMenu() {
                const e = this;
                (this.menu = t("<ul>", {
                    "aria-hidden": "true",
                    "aria-labelledby": this.ids.button,
                    id: this.ids.menu,
                })),
                (this.menuWrap = t("<div>").append(this.menu)),
                this._addClass(this.menuWrap, "ui-selectmenu-menu", "ui-front"),
                this.menuWrap.appendTo(this._appendTo()),
                (this.menuInstance = this.menu
                    .menu({
                        classes: { "ui-menu": "ui-corner-bottom" },
                        role: "listbox",
                        select(t, i) {
                            t.preventDefault(),
                            e._setSelection(),
                            e._select(i.item.data("ui-selectmenu-item"), t);
                        },
                        focus(t, i) {
                            const s = i.item.data("ui-selectmenu-item");
                            e.focusIndex != null &&
                                        s.index !== e.focusIndex &&
                                        (e._trigger("focus", t, { item: s }),
                                        e.isOpen || e._select(s, t)),
                            (e.focusIndex = s.index),
                            e.button.attr(
                                "aria-activedescendant",
                                e.menuItems.eq(s.index).attr("id"),
                            );
                        },
                    })
                    .menu("instance")),
                this.menuInstance._off(this.menu, "mouseleave"),
                (this.menuInstance._closeOnDocumentClick = function () {
                    return !1;
                }),
                (this.menuInstance._isDivider = function () {
                    return !1;
                });
            },
            refresh() {
                this._refreshMenu(),
                this.buttonItem.replaceWith(
                    (this.buttonItem = this._renderButtonItem(
                        this._getSelectedItem().data("ui-selectmenu-item") || {},
                    )),
                ),
                this.options.width === null && this._resizeButton();
            },
            _refreshMenu() {
                let t;
                const e = this.element.find("option");
                this.menu.empty(),
                this._parseOptions(e),
                this._renderMenu(this.menu, this.items),
                this.menuInstance.refresh(),
                (this.menuItems = this.menu
                    .find("li")
                    .not(".ui-selectmenu-optgroup")
                    .find(".ui-menu-item-wrapper")),
                (this._rendered = !0),
                e.length &&
                            ((t = this._getSelectedItem()),
                            this.menuInstance.focus(null, t),
                            this._setAria(t.data("ui-selectmenu-item")),
                            this._setOption("disabled", this.element.prop("disabled")));
            },
            open(t) {
                this.options.disabled ||
                        (this._rendered
                            ? (this._removeClass(
                                this.menu.find(".ui-state-active"),
                                null,
                                "ui-state-active",
                            ),
                            this.menuInstance.focus(null, this._getSelectedItem()))
                            : this._refreshMenu(),
                        this.menuItems.length &&
                            ((this.isOpen = !0),
                            this._toggleAttr(),
                            this._resizeMenu(),
                            this._position(),
                            this._on(this.document, this._documentClick),
                            this._trigger("open", t)));
            },
            _position() {
                this.menuWrap.position(t.extend({ of: this.button }, this.options.position));
            },
            close(t) {
                this.isOpen &&
                        ((this.isOpen = !1),
                        this._toggleAttr(),
                        (this.range = null),
                        this._off(this.document),
                        this._trigger("close", t));
            },
            widget() {
                return this.button;
            },
            menuWidget() {
                return this.menu;
            },
            _renderButtonItem(e) {
                const i = t("<span>");
                return this._setText(i, e.label), this._addClass(i, "ui-selectmenu-text"), i;
            },
            _renderMenu(e, i) {
                const s = this;
                let n = "";
                t.each(i, (i, o) => {
                    let a;
                    o.optgroup !== n &&
                            ((a = t("<li>", { text: o.optgroup })),
                            s._addClass(
                                a,
                                "ui-selectmenu-optgroup",
                                `ui-menu-divider${
                                    o.element.parent("optgroup").prop("disabled")
                                        ? " ui-state-disabled"
                                        : ""
                                }`,
                            ),
                            a.appendTo(e),
                            (n = o.optgroup)),
                    s._renderItemData(e, o);
                });
            },
            _renderItemData(t, e) {
                return this._renderItem(t, e).data("ui-selectmenu-item", e);
            },
            _renderItem(e, i) {
                const s = t("<li>");
                const n = t("<div>", { title: i.element.attr("title") });
                return (
                    i.disabled && this._addClass(s, null, "ui-state-disabled"),
                    this._setText(n, i.label),
                    s.append(n).appendTo(e)
                );
            },
            _setText(t, e) {
                e ? t.text(e) : t.html("&#160;");
            },
            _move(t, e) {
                let i;
                let s;
                let n = ".ui-menu-item";
                this.isOpen
                    ? (i = this.menuItems.eq(this.focusIndex).parent("li"))
                    : ((i = this.menuItems.eq(this.element[0].selectedIndex).parent("li")),
                    (n += ":not(.ui-state-disabled)")),
                (s =
                            t === "first" || t === "last"
                                ? i[t === "first" ? "prevAll" : "nextAll"](n).eq(-1)
                                : i[`${t}All`](n).eq(0)),
                s.length && this.menuInstance.focus(e, s);
            },
            _getSelectedItem() {
                return this.menuItems.eq(this.element[0].selectedIndex).parent("li");
            },
            _toggle(t) {
                this[this.isOpen ? "close" : "open"](t);
            },
            _setSelection() {
                let t;
                this.range &&
                        (window.getSelection
                            ? ((t = window.getSelection()),
                            t.removeAllRanges(),
                            t.addRange(this.range))
                            : this.range.select(),
                        this.button.focus());
            },
            _documentClick: {
                mousedown(e) {
                    this.isOpen &&
                            (t(e.target).closest(
                                `.ui-selectmenu-menu, #${t.ui.escapeSelector(this.ids.button)}`,
                            ).length ||
                                this.close(e));
                },
            },
            _buttonEvents: {
                mousedown() {
                    let t;
                    window.getSelection
                        ? ((t = window.getSelection()),
                        t.rangeCount && (this.range = t.getRangeAt(0)))
                        : (this.range = document.selection.createRange());
                },
                click(t) {
                    this._setSelection(), this._toggle(t);
                },
                keydown(e) {
                    let i = !0;
                    switch (e.keyCode) {
                    case t.ui.keyCode.TAB:
                    case t.ui.keyCode.ESCAPE:
                        this.close(e), (i = !1);
                        break;
                    case t.ui.keyCode.ENTER:
                        this.isOpen && this._selectFocusedItem(e);
                        break;
                    case t.ui.keyCode.UP:
                        e.altKey ? this._toggle(e) : this._move("prev", e);
                        break;
                    case t.ui.keyCode.DOWN:
                        e.altKey ? this._toggle(e) : this._move("next", e);
                        break;
                    case t.ui.keyCode.SPACE:
                        this.isOpen ? this._selectFocusedItem(e) : this._toggle(e);
                        break;
                    case t.ui.keyCode.LEFT:
                        this._move("prev", e);
                        break;
                    case t.ui.keyCode.RIGHT:
                        this._move("next", e);
                        break;
                    case t.ui.keyCode.HOME:
                    case t.ui.keyCode.PAGE_UP:
                        this._move("first", e);
                        break;
                    case t.ui.keyCode.END:
                    case t.ui.keyCode.PAGE_DOWN:
                        this._move("last", e);
                        break;
                    default:
                        this.menu.trigger(e), (i = !1);
                    }
                    i && e.preventDefault();
                },
            },
            _selectFocusedItem(t) {
                const e = this.menuItems.eq(this.focusIndex).parent("li");
                e.hasClass("ui-state-disabled") ||
                        this._select(e.data("ui-selectmenu-item"), t);
            },
            _select(t, e) {
                const i = this.element[0].selectedIndex;
                (this.element[0].selectedIndex = t.index),
                this.buttonItem.replaceWith((this.buttonItem = this._renderButtonItem(t))),
                this._setAria(t),
                this._trigger("select", e, { item: t }),
                t.index !== i && this._trigger("change", e, { item: t }),
                this.close(e);
            },
            _setAria(t) {
                const e = this.menuItems.eq(t.index).attr("id");
                this.button.attr({ "aria-labelledby": e, "aria-activedescendant": e }),
                this.menu.attr("aria-activedescendant", e);
            },
            _setOption(t, e) {
                if (t === "icons") {
                    const i = this.button.find("span.ui-icon");
                    this._removeClass(i, null, this.options.icons.button)._addClass(
                        i,
                        null,
                        e.button,
                    );
                }
                this._super(t, e),
                t === "appendTo" && this.menuWrap.appendTo(this._appendTo()),
                t === "width" && this._resizeButton();
            },
            _setOptionDisabled(t) {
                this._super(t),
                this.menuInstance.option("disabled", t),
                this.button.attr("aria-disabled", t),
                this._toggleClass(this.button, null, "ui-state-disabled", t),
                this.element.prop("disabled", t),
                t
                    ? (this.button.attr("tabindex", -1), this.close())
                    : this.button.attr("tabindex", 0);
            },
            _appendTo() {
                let e = this.options.appendTo;
                return (
                    e && (e = e.jquery || e.nodeType ? t(e) : this.document.find(e).eq(0)),
                    (e && e[0]) || (e = this.element.closest(".ui-front, dialog")),
                    e.length || (e = this.document[0].body),
                    e
                );
            },
            _toggleAttr() {
                this.button.attr("aria-expanded", this.isOpen),
                this._removeClass(
                    this.button,
                    `ui-selectmenu-button-${this.isOpen ? "closed" : "open"}`,
                )
                    ._addClass(
                        this.button,
                        `ui-selectmenu-button-${this.isOpen ? "open" : "closed"}`,
                    )
                    ._toggleClass(this.menuWrap, "ui-selectmenu-open", null, this.isOpen),
                this.menu.attr("aria-hidden", !this.isOpen);
            },
            _resizeButton() {
                let t = this.options.width;
                return t === !1
                    ? (this.button.css("width", ""), void 0)
                    : (t === null &&
                              ((t = this.element.show().outerWidth()), this.element.hide()),
                    this.button.outerWidth(t),
                    void 0);
            },
            _resizeMenu() {
                this.menu.outerWidth(
                    Math.max(this.button.outerWidth(), this.menu.width("").outerWidth() + 1),
                );
            },
            _getCreateOptions() {
                const t = this._super();
                return (t.disabled = this.element.prop("disabled")), t;
            },
            _parseOptions(e) {
                const i = this;
                const s = [];
                e.each((e, n) => {
                    s.push(i._parseOption(t(n), e));
                }),
                (this.items = s);
            },
            _parseOption(t, e) {
                const i = t.parent("optgroup");
                return {
                    element: t,
                    index: e,
                    value: t.val(),
                    label: t.text(),
                    optgroup: i.attr("label") || "",
                    disabled: i.prop("disabled") || t.prop("disabled"),
                };
            },
            _destroy() {
                this._unbindFormResetHandler(),
                this.menuWrap.remove(),
                this.button.remove(),
                this.element.show(),
                this.element.removeUniqueId(),
                this.labels.attr("for", this.ids.element);
            },
        },
    ]),
    t.widget("ui.slider", t.ui.mouse, {
        version: "1.12.1",
        widgetEventPrefix: "slide",
        options: {
            animate: !1,
            classes: {
                "ui-slider": "ui-corner-all",
                "ui-slider-handle": "ui-corner-all",
                "ui-slider-range": "ui-corner-all ui-widget-header",
            },
            distance: 0,
            max: 100,
            min: 0,
            orientation: "horizontal",
            range: !1,
            step: 1,
            value: 0,
            values: null,
            change: null,
            slide: null,
            start: null,
            stop: null,
        },
        numPages: 5,
        _create() {
            (this._keySliding = !1),
            (this._mouseSliding = !1),
            (this._animateOff = !0),
            (this._handleIndex = null),
            this._detectOrientation(),
            this._mouseInit(),
            this._calculateNewMax(),
            this._addClass(
                `ui-slider ui-slider-${this.orientation}`,
                "ui-widget ui-widget-content",
            ),
            this._refresh(),
            (this._animateOff = !1);
        },
        _refresh() {
            this._createRange(),
            this._createHandles(),
            this._setupEvents(),
            this._refreshValue();
        },
        _createHandles() {
            let e;
            let i;
            const s = this.options;
            let n = this.element.find(".ui-slider-handle");
            const o = "<span tabindex='0'></span>";
            const a = [];
            for (
                i = (s.values && s.values.length) || 1,
                n.length > i && (n.slice(i).remove(), (n = n.slice(0, i))),
                e = n.length;
                i > e;
                e++
            )
                a.push(o);
            (this.handles = n.add(t(a.join("")).appendTo(this.element))),
            this._addClass(this.handles, "ui-slider-handle", "ui-state-default"),
            (this.handle = this.handles.eq(0)),
            this.handles.each(function (e) {
                t(this).data("ui-slider-handle-index", e).attr("tabIndex", 0);
            });
        },
        _createRange() {
            const e = this.options;
            e.range
                ? (e.range === !0 &&
                          (e.values
                              ? e.values.length && e.values.length !== 2
                                  ? (e.values = [e.values[0], e.values[0]])
                                  : t.isArray(e.values) && (e.values = e.values.slice(0))
                              : (e.values = [this._valueMin(), this._valueMin()])),
                this.range && this.range.length
                    ? (this._removeClass(
                        this.range,
                        "ui-slider-range-min ui-slider-range-max",
                    ),
                    this.range.css({ left: "", bottom: "" }))
                    : ((this.range = t("<div>").appendTo(this.element)),
                    this._addClass(this.range, "ui-slider-range")),
                (e.range === "min" || e.range === "max") &&
                          this._addClass(this.range, `ui-slider-range-${e.range}`))
                : (this.range && this.range.remove(), (this.range = null));
        },
        _setupEvents() {
            this._off(this.handles),
            this._on(this.handles, this._handleEvents),
            this._hoverable(this.handles),
            this._focusable(this.handles);
        },
        _destroy() {
            this.handles.remove(), this.range && this.range.remove(), this._mouseDestroy();
        },
        _mouseCapture(e) {
            let i;
            let s;
            let n;
            let o;
            let a;
            let r;
            let h;
            let l;
            const c = this;
            const u = this.options;
            return u.disabled
                ? !1
                : ((this.elementSize = {
                    width: this.element.outerWidth(),
                    height: this.element.outerHeight(),
                }),
                (this.elementOffset = this.element.offset()),
                (i = { x: e.pageX, y: e.pageY }),
                (s = this._normValueFromMouse(i)),
                (n = this._valueMax() - this._valueMin() + 1),
                this.handles.each(function (e) {
                    const i = Math.abs(s - c.values(e));
                    (n > i ||
                              (n === i && (e === c._lastChangedValue || c.values(e) === u.min))) &&
                              ((n = i), (o = t(this)), (a = e));
                }),
                (r = this._start(e, a)),
                r === !1
                    ? !1
                    : ((this._mouseSliding = !0),
                    (this._handleIndex = a),
                    this._addClass(o, null, "ui-state-active"),
                    o.trigger("focus"),
                    (h = o.offset()),
                    (l = !t(e.target).parents().addBack().is(".ui-slider-handle")),
                    (this._clickOffset = l
                        ? { left: 0, top: 0 }
                        : {
                            left: e.pageX - h.left - o.width() / 2,
                            top:
                                          e.pageY -
                                          h.top -
                                          o.height() / 2 -
                                          (parseInt(o.css("borderTopWidth"), 10) || 0) -
                                          (parseInt(o.css("borderBottomWidth"), 10) || 0) +
                                          (parseInt(o.css("marginTop"), 10) || 0),
                        }),
                    this.handles.hasClass("ui-state-hover") || this._slide(e, a, s),
                    (this._animateOff = !0),
                    !0));
        },
        _mouseStart() {
            return !0;
        },
        _mouseDrag(t) {
            const e = { x: t.pageX, y: t.pageY };
            const i = this._normValueFromMouse(e);
            return this._slide(t, this._handleIndex, i), !1;
        },
        _mouseStop(t) {
            return (
                this._removeClass(this.handles, null, "ui-state-active"),
                (this._mouseSliding = !1),
                this._stop(t, this._handleIndex),
                this._change(t, this._handleIndex),
                (this._handleIndex = null),
                (this._clickOffset = null),
                (this._animateOff = !1),
                !1
            );
        },
        _detectOrientation() {
            this.orientation =
                    this.options.orientation === "vertical" ? "vertical" : "horizontal";
        },
        _normValueFromMouse(t) {
            let e;
            let i;
            let s;
            let n;
            let o;
            return (
                this.orientation === "horizontal"
                    ? ((e = this.elementSize.width),
                    (i =
                              t.x -
                              this.elementOffset.left -
                              (this._clickOffset ? this._clickOffset.left : 0)))
                    : ((e = this.elementSize.height),
                    (i =
                              t.y -
                              this.elementOffset.top -
                              (this._clickOffset ? this._clickOffset.top : 0))),
                (s = i / e),
                s > 1 && (s = 1),
                s < 0 && (s = 0),
                this.orientation === "vertical" && (s = 1 - s),
                (n = this._valueMax() - this._valueMin()),
                (o = this._valueMin() + s * n),
                this._trimAlignValue(o)
            );
        },
        _uiHash(t, e, i) {
            const s = {
                handle: this.handles[t],
                handleIndex: t,
                value: void 0 !== e ? e : this.value(),
            };
            return (
                this._hasMultipleValues() &&
                        ((s.value = void 0 !== e ? e : this.values(t)),
                        (s.values = i || this.values())),
                s
            );
        },
        _hasMultipleValues() {
            return this.options.values && this.options.values.length;
        },
        _start(t, e) {
            return this._trigger("start", t, this._uiHash(e));
        },
        _slide(t, e, i) {
            let s;
            let n;
            let o = this.value();
            const a = this.values();
            this._hasMultipleValues() &&
                    ((n = this.values(e ? 0 : 1)),
                    (o = this.values(e)),
                    this.options.values.length === 2 &&
                        this.options.range === !0 &&
                        (i = e === 0 ? Math.min(n, i) : Math.max(n, i)),
                    (a[e] = i)),
            i !== o &&
                        ((s = this._trigger("slide", t, this._uiHash(e, i, a))),
                        s !== !1 &&
                            (this._hasMultipleValues() ? this.values(e, i) : this.value(i)));
        },
        _stop(t, e) {
            this._trigger("stop", t, this._uiHash(e));
        },
        _change(t, e) {
            this._keySliding ||
                    this._mouseSliding ||
                    ((this._lastChangedValue = e), this._trigger("change", t, this._uiHash(e)));
        },
        value(t) {
            return arguments.length
                ? ((this.options.value = this._trimAlignValue(t)),
                this._refreshValue(),
                this._change(null, 0),
                void 0)
                : this._value();
        },
        values(e, i) {
            let s;
            let n;
            let o;
            if (arguments.length > 1)
                return (
                    (this.options.values[e] = this._trimAlignValue(i)),
                    this._refreshValue(),
                    this._change(null, e),
                    void 0
                );
            if (!arguments.length) return this._values();
            if (!t.isArray(arguments[0]))
                return this._hasMultipleValues() ? this._values(e) : this.value();
            for (s = this.options.values, n = arguments[0], o = 0; s.length > o; o += 1)
                (s[o] = this._trimAlignValue(n[o])), this._change(null, o);
            this._refreshValue();
        },
        _setOption(e, i) {
            let s;
            let n = 0;
            switch (
                (e === "range" &&
                        this.options.range === !0 &&
                        (i === "min"
                            ? ((this.options.value = this._values(0)), (this.options.values = null))
                            : i === "max" &&
                              ((this.options.value = this._values(this.options.values.length - 1)),
                              (this.options.values = null))),
                t.isArray(this.options.values) && (n = this.options.values.length),
                this._super(e, i),
                e)
            ) {
            case "orientation":
                this._detectOrientation(),
                this._removeClass("ui-slider-horizontal ui-slider-vertical")._addClass(
                    `ui-slider-${this.orientation}`,
                ),
                this._refreshValue(),
                this.options.range && this._refreshRange(i),
                this.handles.css(i === "horizontal" ? "bottom" : "left", "");
                break;
            case "value":
                (this._animateOff = !0),
                this._refreshValue(),
                this._change(null, 0),
                (this._animateOff = !1);
                break;
            case "values":
                for (this._animateOff = !0, this._refreshValue(), s = n - 1; s >= 0; s--)
                    this._change(null, s);
                this._animateOff = !1;
                break;
            case "step":
            case "min":
            case "max":
                (this._animateOff = !0),
                this._calculateNewMax(),
                this._refreshValue(),
                (this._animateOff = !1);
                break;
            case "range":
                (this._animateOff = !0), this._refresh(), (this._animateOff = !1);
            }
        },
        _setOptionDisabled(t) {
            this._super(t), this._toggleClass(null, "ui-state-disabled", !!t);
        },
        _value() {
            let t = this.options.value;
            return (t = this._trimAlignValue(t));
        },
        _values(t) {
            let e;
            let i;
            let s;
            if (arguments.length)
                return (e = this.options.values[t]), (e = this._trimAlignValue(e));
            if (this._hasMultipleValues()) {
                for (i = this.options.values.slice(), s = 0; i.length > s; s += 1)
                    i[s] = this._trimAlignValue(i[s]);
                return i;
            }
            return [];
        },
        _trimAlignValue(t) {
            if (this._valueMin() >= t) return this._valueMin();
            if (t >= this._valueMax()) return this._valueMax();
            const e = this.options.step > 0 ? this.options.step : 1;
            const i = (t - this._valueMin()) % e;
            let s = t - i;
            return 2 * Math.abs(i) >= e && (s += i > 0 ? e : -e), parseFloat(s.toFixed(5));
        },
        _calculateNewMax() {
            let t = this.options.max;
            const e = this._valueMin();
            const i = this.options.step;
            const s = Math.round((t - e) / i) * i;
            (t = s + e),
            t > this.options.max && (t -= i),
            (this.max = parseFloat(t.toFixed(this._precision())));
        },
        _precision() {
            let t = this._precisionOf(this.options.step);
            return (
                this.options.min !== null &&
                        (t = Math.max(t, this._precisionOf(this.options.min))),
                t
            );
        },
        _precisionOf(t) {
            const e = `${t}`;
            const i = e.indexOf(".");
            return i === -1 ? 0 : e.length - i - 1;
        },
        _valueMin() {
            return this.options.min;
        },
        _valueMax() {
            return this.max;
        },
        _refreshRange(t) {
            t === "vertical" && this.range.css({ width: "", left: "" }),
            t === "horizontal" && this.range.css({ height: "", bottom: "" });
        },
        _refreshValue() {
            let e;
            let i;
            let s;
            let n;
            let o;
            const a = this.options.range;
            const r = this.options;
            const h = this;
            const l = this._animateOff ? !1 : r.animate;
            const c = {};
            this._hasMultipleValues()
                ? this.handles.each(function (s) {
                    (i =
                              100 *
                              ((h.values(s) - h._valueMin()) / (h._valueMax() - h._valueMin()))),
                    (c[h.orientation === "horizontal" ? "left" : "bottom"] = `${i}%`),
                    t(this).stop(1, 1)[l ? "animate" : "css"](c, r.animate),
                    h.options.range === !0 &&
                                  (h.orientation === "horizontal"
                                      ? (s === 0 &&
                                            h.range
                                                .stop(1, 1)
                                                [l ? "animate" : "css"](
                                                    { left: `${i}%` },
                                                    r.animate,
                                                ),
                                      s === 1 &&
                                            h.range[l ? "animate" : "css"](
                                                { width: `${i - e}%` },
                                                { queue: !1, duration: r.animate },
                                            ))
                                      : (s === 0 &&
                                            h.range
                                                .stop(1, 1)
                                                [l ? "animate" : "css"](
                                                    { bottom: `${i}%` },
                                                    r.animate,
                                                ),
                                      s === 1 &&
                                            h.range[l ? "animate" : "css"](
                                                { height: `${i - e}%` },
                                                { queue: !1, duration: r.animate },
                                            ))),
                    (e = i);
                })
                : ((s = this.value()),
                (n = this._valueMin()),
                (o = this._valueMax()),
                (i = o !== n ? 100 * ((s - n) / (o - n)) : 0),
                (c[this.orientation === "horizontal" ? "left" : "bottom"] = `${i}%`),
                this.handle.stop(1, 1)[l ? "animate" : "css"](c, r.animate),
                a === "min" &&
                          this.orientation === "horizontal" &&
                          this.range
                              .stop(1, 1)
                              [l ? "animate" : "css"]({ width: `${i}%` }, r.animate),
                a === "max" &&
                          this.orientation === "horizontal" &&
                          this.range
                              .stop(1, 1)
                              [l ? "animate" : "css"]({ width: `${100 - i}%` }, r.animate),
                a === "min" &&
                          this.orientation === "vertical" &&
                          this.range
                              .stop(1, 1)
                              [l ? "animate" : "css"]({ height: `${i}%` }, r.animate),
                a === "max" &&
                          this.orientation === "vertical" &&
                          this.range
                              .stop(1, 1)
                              [l ? "animate" : "css"]({ height: `${100 - i}%` }, r.animate));
        },
        _handleEvents: {
            keydown(e) {
                let i;
                let s;
                let n;
                let o;
                const a = t(e.target).data("ui-slider-handle-index");
                switch (e.keyCode) {
                case t.ui.keyCode.HOME:
                case t.ui.keyCode.END:
                case t.ui.keyCode.PAGE_UP:
                case t.ui.keyCode.PAGE_DOWN:
                case t.ui.keyCode.UP:
                case t.ui.keyCode.RIGHT:
                case t.ui.keyCode.DOWN:
                case t.ui.keyCode.LEFT:
                    if (
                        (e.preventDefault(),
                        !this._keySliding &&
                                    ((this._keySliding = !0),
                                    this._addClass(t(e.target), null, "ui-state-active"),
                                    (i = this._start(e, a)),
                                    i === !1))
                    )
                        return;
                }
                switch (
                    ((o = this.options.step),
                    (s = n = this._hasMultipleValues() ? this.values(a) : this.value()),
                    e.keyCode)
                ) {
                case t.ui.keyCode.HOME:
                    n = this._valueMin();
                    break;
                case t.ui.keyCode.END:
                    n = this._valueMax();
                    break;
                case t.ui.keyCode.PAGE_UP:
                    n = this._trimAlignValue(
                        s + (this._valueMax() - this._valueMin()) / this.numPages,
                    );
                    break;
                case t.ui.keyCode.PAGE_DOWN:
                    n = this._trimAlignValue(
                        s - (this._valueMax() - this._valueMin()) / this.numPages,
                    );
                    break;
                case t.ui.keyCode.UP:
                case t.ui.keyCode.RIGHT:
                    if (s === this._valueMax()) return;
                    n = this._trimAlignValue(s + o);
                    break;
                case t.ui.keyCode.DOWN:
                case t.ui.keyCode.LEFT:
                    if (s === this._valueMin()) return;
                    n = this._trimAlignValue(s - o);
                }
                this._slide(e, a, n);
            },
            keyup(e) {
                const i = t(e.target).data("ui-slider-handle-index");
                this._keySliding &&
                        ((this._keySliding = !1),
                        this._stop(e, i),
                        this._change(e, i),
                        this._removeClass(t(e.target), null, "ui-state-active"));
            },
        },
    }),
    t.widget("ui.sortable", t.ui.mouse, {
        version: "1.12.1",
        widgetEventPrefix: "sort",
        ready: !1,
        options: {
            appendTo: "parent",
            axis: !1,
            connectWith: !1,
            containment: !1,
            cursor: "auto",
            cursorAt: !1,
            dropOnEmpty: !0,
            forcePlaceholderSize: !1,
            forceHelperSize: !1,
            grid: !1,
            handle: !1,
            helper: "original",
            items: "> *",
            opacity: !1,
            placeholder: !1,
            revert: !1,
            scroll: !0,
            scrollSensitivity: 20,
            scrollSpeed: 20,
            scope: "default",
            tolerance: "intersect",
            zIndex: 1e3,
            activate: null,
            beforeStop: null,
            change: null,
            deactivate: null,
            out: null,
            over: null,
            receive: null,
            remove: null,
            sort: null,
            start: null,
            stop: null,
            update: null,
        },
        _isOverAxis(t, e, i) {
            return t >= e && e + i > t;
        },
        _isFloating(t) {
            return (
                /left|right/.test(t.css("float")) || /inline|table-cell/.test(t.css("display"))
            );
        },
        _create() {
            (this.containerCache = {}),
            this._addClass("ui-sortable"),
            this.refresh(),
            (this.offset = this.element.offset()),
            this._mouseInit(),
            this._setHandleClassName(),
            (this.ready = !0);
        },
        _setOption(t, e) {
            this._super(t, e), t === "handle" && this._setHandleClassName();
        },
        _setHandleClassName() {
            const e = this;
            this._removeClass(this.element.find(".ui-sortable-handle"), "ui-sortable-handle"),
            t.each(this.items, function () {
                e._addClass(
                    this.instance.options.handle
                        ? this.item.find(this.instance.options.handle)
                        : this.item,
                    "ui-sortable-handle",
                );
            });
        },
        _destroy() {
            this._mouseDestroy();
            for (let t = this.items.length - 1; t >= 0; t--)
                this.items[t].item.removeData(`${this.widgetName}-item`);
            return this;
        },
        _mouseCapture(e, i) {
            let s = null;
            let n = !1;
            const o = this;
            return this.reverting
                ? !1
                : this.options.disabled || this.options.type === "static"
                    ? !1
                    : (this._refreshItems(e),
                    t(e.target)
                        .parents()
                        .each(function () {
                            return t.data(this, `${o.widgetName}-item`) === o
                                ? ((s = t(this)), !1)
                                : void 0;
                        }),
                    t.data(e.target, `${o.widgetName}-item`) === o && (s = t(e.target)),
                    s
                        ? !this.options.handle ||
                            i ||
                            (t(this.options.handle, s)
                                .find("*")
                                .addBack()
                                .each(function () {
                                    this === e.target && (n = !0);
                                }),
                            n)
                            ? ((this.currentItem = s), this._removeCurrentsFromItems(), !0)
                            : !1
                        : !1);
        },
        _mouseStart(e, i, s) {
            let n;
            let o;
            const a = this.options;
            if (
                ((this.currentContainer = this),
                this.refreshPositions(),
                (this.helper = this._createHelper(e)),
                this._cacheHelperProportions(),
                this._cacheMargins(),
                (this.scrollParent = this.helper.scrollParent()),
                (this.offset = this.currentItem.offset()),
                (this.offset = {
                    top: this.offset.top - this.margins.top,
                    left: this.offset.left - this.margins.left,
                }),
                t.extend(this.offset, {
                    click: { left: e.pageX - this.offset.left, top: e.pageY - this.offset.top },
                    parent: this._getParentOffset(),
                    relative: this._getRelativeOffset(),
                }),
                this.helper.css("position", "absolute"),
                (this.cssPosition = this.helper.css("position")),
                (this.originalPosition = this._generatePosition(e)),
                (this.originalPageX = e.pageX),
                (this.originalPageY = e.pageY),
                a.cursorAt && this._adjustOffsetFromHelper(a.cursorAt),
                (this.domPosition = {
                    prev: this.currentItem.prev()[0],
                    parent: this.currentItem.parent()[0],
                }),
                this.helper[0] !== this.currentItem[0] && this.currentItem.hide(),
                this._createPlaceholder(),
                a.containment && this._setContainment(),
                a.cursor &&
                        a.cursor !== "auto" &&
                        ((o = this.document.find("body")),
                        (this.storedCursor = o.css("cursor")),
                        o.css("cursor", a.cursor),
                        (this.storedStylesheet = t(
                            `<style>*{ cursor: ${a.cursor} !important; }</style>`,
                        ).appendTo(o))),
                a.opacity &&
                        (this.helper.css("opacity") &&
                            (this._storedOpacity = this.helper.css("opacity")),
                        this.helper.css("opacity", a.opacity)),
                a.zIndex &&
                        (this.helper.css("zIndex") &&
                            (this._storedZIndex = this.helper.css("zIndex")),
                        this.helper.css("zIndex", a.zIndex)),
                this.scrollParent[0] !== this.document[0] &&
                        this.scrollParent[0].tagName !== "HTML" &&
                        (this.overflowOffset = this.scrollParent.offset()),
                this._trigger("start", e, this._uiHash()),
                this._preserveHelperProportions || this._cacheHelperProportions(),
                !s)
            )
                for (n = this.containers.length - 1; n >= 0; n--)
                    this.containers[n]._trigger("activate", e, this._uiHash(this));
            return (
                t.ui.ddmanager && (t.ui.ddmanager.current = this),
                t.ui.ddmanager && !a.dropBehaviour && t.ui.ddmanager.prepareOffsets(this, e),
                (this.dragging = !0),
                this._addClass(this.helper, "ui-sortable-helper"),
                this._mouseDrag(e),
                !0
            );
        },
        _mouseDrag(e) {
            let i;
            let s;
            let n;
            let o;
            const a = this.options;
            let r = !1;
            for (
                this.position = this._generatePosition(e),
                this.positionAbs = this._convertPositionTo("absolute"),
                this.lastPositionAbs || (this.lastPositionAbs = this.positionAbs),
                this.options.scroll &&
                            (this.scrollParent[0] !== this.document[0] &&
                            this.scrollParent[0].tagName !== "HTML"
                                ? (this.overflowOffset.top +
                                      this.scrollParent[0].offsetHeight -
                                      e.pageY <
                                  a.scrollSensitivity
                                    ? (this.scrollParent[0].scrollTop = r =
                                            this.scrollParent[0].scrollTop + a.scrollSpeed)
                                    : e.pageY - this.overflowOffset.top < a.scrollSensitivity &&
                                        (this.scrollParent[0].scrollTop = r =
                                            this.scrollParent[0].scrollTop - a.scrollSpeed),
                                this.overflowOffset.left +
                                      this.scrollParent[0].offsetWidth -
                                      e.pageX <
                                  a.scrollSensitivity
                                    ? (this.scrollParent[0].scrollLeft = r =
                                            this.scrollParent[0].scrollLeft + a.scrollSpeed)
                                    : e.pageX - this.overflowOffset.left < a.scrollSensitivity &&
                                        (this.scrollParent[0].scrollLeft = r =
                                            this.scrollParent[0].scrollLeft - a.scrollSpeed))
                                : (e.pageY - this.document.scrollTop() < a.scrollSensitivity
                                    ? (r = this.document.scrollTop(
                                        this.document.scrollTop() - a.scrollSpeed,
                                    ))
                                    : this.window.height() -
                                            (e.pageY - this.document.scrollTop()) <
                                            a.scrollSensitivity &&
                                        (r = this.document.scrollTop(
                                            this.document.scrollTop() + a.scrollSpeed,
                                        )),
                                e.pageX - this.document.scrollLeft() < a.scrollSensitivity
                                    ? (r = this.document.scrollLeft(
                                        this.document.scrollLeft() - a.scrollSpeed,
                                    ))
                                    : this.window.width() -
                                            (e.pageX - this.document.scrollLeft()) <
                                            a.scrollSensitivity &&
                                        (r = this.document.scrollLeft(
                                            this.document.scrollLeft() + a.scrollSpeed,
                                        ))),
                            r !== !1 &&
                                t.ui.ddmanager &&
                                !a.dropBehaviour &&
                                t.ui.ddmanager.prepareOffsets(this, e)),
                this.positionAbs = this._convertPositionTo("absolute"),
                (this.options.axis && this.options.axis === "y") ||
                            (this.helper[0].style.left = `${this.position.left}px`),
                (this.options.axis && this.options.axis === "x") ||
                            (this.helper[0].style.top = `${this.position.top}px`),
                i = this.items.length - 1;
                i >= 0;
                i--
            )
                if (
                    ((s = this.items[i]),
                    (n = s.item[0]),
                    (o = this._intersectsWithPointer(s)),
                    o &&
                            s.instance === this.currentContainer &&
                            n !== this.currentItem[0] &&
                            this.placeholder[o === 1 ? "next" : "prev"]()[0] !== n &&
                            !t.contains(this.placeholder[0], n) &&
                            (this.options.type === "semi-dynamic"
                                ? !t.contains(this.element[0], n)
                                : !0))
                ) {
                    if (
                        ((this.direction = o === 1 ? "down" : "up"),
                        this.options.tolerance !== "pointer" && !this._intersectsWithSides(s))
                    )
                        break;
                    this._rearrange(e, s), this._trigger("change", e, this._uiHash());
                    break;
                }
            return (
                this._contactContainers(e),
                t.ui.ddmanager && t.ui.ddmanager.drag(this, e),
                this._trigger("sort", e, this._uiHash()),
                (this.lastPositionAbs = this.positionAbs),
                !1
            );
        },
        _mouseStop(e, i) {
            if (e) {
                if (
                    (t.ui.ddmanager &&
                            !this.options.dropBehaviour &&
                            t.ui.ddmanager.drop(this, e),
                    this.options.revert)
                ) {
                    const s = this;
                    const n = this.placeholder.offset();
                    const o = this.options.axis;
                    const a = {};
                    (o && o !== "x") ||
                            (a.left =
                                n.left -
                                this.offset.parent.left -
                                this.margins.left +
                                (this.offsetParent[0] === this.document[0].body
                                    ? 0
                                    : this.offsetParent[0].scrollLeft)),
                    (o && o !== "y") ||
                                (a.top =
                                    n.top -
                                    this.offset.parent.top -
                                    this.margins.top +
                                    (this.offsetParent[0] === this.document[0].body
                                        ? 0
                                        : this.offsetParent[0].scrollTop)),
                    (this.reverting = !0),
                    t(this.helper).animate(
                        a,
                        parseInt(this.options.revert, 10) || 500,
                        () => {
                            s._clear(e);
                        },
                    );
                } else this._clear(e, i);
                return !1;
            }
        },
        cancel() {
            if (this.dragging) {
                this._mouseUp(new t.Event("mouseup", { target: null })),
                this.options.helper === "original"
                    ? (this.currentItem.css(this._storedCSS),
                    this._removeClass(this.currentItem, "ui-sortable-helper"))
                    : this.currentItem.show();
                for (let e = this.containers.length - 1; e >= 0; e--)
                    this.containers[e]._trigger("deactivate", null, this._uiHash(this)),
                    this.containers[e].containerCache.over &&
                                (this.containers[e]._trigger("out", null, this._uiHash(this)),
                                (this.containers[e].containerCache.over = 0));
            }
            return (
                this.placeholder &&
                        (this.placeholder[0].parentNode &&
                            this.placeholder[0].parentNode.removeChild(this.placeholder[0]),
                        this.options.helper !== "original" &&
                            this.helper &&
                            this.helper[0].parentNode &&
                            this.helper.remove(),
                        t.extend(this, {
                            helper: null,
                            dragging: !1,
                            reverting: !1,
                            _noFinalSort: null,
                        }),
                        this.domPosition.prev
                            ? t(this.domPosition.prev).after(this.currentItem)
                            : t(this.domPosition.parent).prepend(this.currentItem)),
                this
            );
        },
        serialize(e) {
            const i = this._getItemsAsjQuery(e && e.connected);
            const s = [];
            return (
                (e = e || {}),
                t(i).each(function () {
                    const i = (t(e.item || this).attr(e.attribute || "id") || "").match(
                        e.expression || /(.+)[\-=_](.+)/,
                    );
                    i &&
                            s.push(
                                `${e.key || `${i[1]}[]`}=${e.key && e.expression ? i[1] : i[2]}`,
                            );
                }),
                !s.length && e.key && s.push(`${e.key}=`),
                s.join("&")
            );
        },
        toArray(e) {
            const i = this._getItemsAsjQuery(e && e.connected);
            const s = [];
            return (
                (e = e || {}),
                i.each(function () {
                    s.push(t(e.item || this).attr(e.attribute || "id") || "");
                }),
                s
            );
        },
        _intersectsWith(t) {
            const e = this.positionAbs.left;
            const i = e + this.helperProportions.width;
            const s = this.positionAbs.top;
            const n = s + this.helperProportions.height;
            const o = t.left;
            const a = o + t.width;
            const r = t.top;
            const h = r + t.height;
            const l = this.offset.click.top;
            const c = this.offset.click.left;
            const u = this.options.axis === "x" || (s + l > r && h > s + l);
            const d = this.options.axis === "y" || (e + c > o && a > e + c);
            const p = u && d;
            return this.options.tolerance === "pointer" ||
                    this.options.forcePointerForContainers ||
                    (this.options.tolerance !== "pointer" &&
                        this.helperProportions[this.floating ? "width" : "height"] >
                            t[this.floating ? "width" : "height"])
                ? p
                : e + this.helperProportions.width / 2 > o &&
                          a > i - this.helperProportions.width / 2 &&
                          s + this.helperProportions.height / 2 > r &&
                          h > n - this.helperProportions.height / 2;
        },
        _intersectsWithPointer(t) {
            let e;
            let i;
            const s =
                    this.options.axis === "x" ||
                    this._isOverAxis(this.positionAbs.top + this.offset.click.top, t.top, t.height);
            const n =
                    this.options.axis === "y" ||
                    this._isOverAxis(
                        this.positionAbs.left + this.offset.click.left,
                        t.left,
                        t.width,
                    );
            const o = s && n;
            return o
                ? ((e = this._getDragVerticalDirection()),
                (i = this._getDragHorizontalDirection()),
                this.floating
                    ? i === "right" || e === "down"
                        ? 2
                        : 1
                    : e && (e === "down" ? 2 : 1))
                : !1;
        },
        _intersectsWithSides(t) {
            const e = this._isOverAxis(
                this.positionAbs.top + this.offset.click.top,
                t.top + t.height / 2,
                t.height,
            );
            const i = this._isOverAxis(
                this.positionAbs.left + this.offset.click.left,
                t.left + t.width / 2,
                t.width,
            );
            const s = this._getDragVerticalDirection();
            const n = this._getDragHorizontalDirection();
            return this.floating && n
                ? (n === "right" && i) || (n === "left" && !i)
                : s && ((s === "down" && e) || (s === "up" && !e));
        },
        _getDragVerticalDirection() {
            const t = this.positionAbs.top - this.lastPositionAbs.top;
            return t !== 0 && (t > 0 ? "down" : "up");
        },
        _getDragHorizontalDirection() {
            const t = this.positionAbs.left - this.lastPositionAbs.left;
            return t !== 0 && (t > 0 ? "right" : "left");
        },
        refresh(t) {
            return (
                this._refreshItems(t), this._setHandleClassName(), this.refreshPositions(), this
            );
        },
        _connectWith() {
            const t = this.options;
            return t.connectWith.constructor === String ? [t.connectWith] : t.connectWith;
        },
        _getItemsAsjQuery(e) {
            function i() {
                r.push(this);
            }
            let s;
            let n;
            let o;
            let a;
            var r = [];
            const h = [];
            const l = this._connectWith();
            if (l && e)
                for (s = l.length - 1; s >= 0; s--)
                    for (o = t(l[s], this.document[0]), n = o.length - 1; n >= 0; n--)
                        (a = t.data(o[n], this.widgetFullName)),
                        a &&
                                    a !== this &&
                                    !a.options.disabled &&
                                    h.push([
                                        t.isFunction(a.options.items)
                                            ? a.options.items.call(a.element)
                                            : t(a.options.items, a.element)
                                                .not(".ui-sortable-helper")
                                                .not(".ui-sortable-placeholder"),
                                        a,
                                    ]);
            for (
                h.push([
                    t.isFunction(this.options.items)
                        ? this.options.items.call(this.element, null, {
                            options: this.options,
                            item: this.currentItem,
                        })
                        : t(this.options.items, this.element)
                            .not(".ui-sortable-helper")
                            .not(".ui-sortable-placeholder"),
                    this,
                ]),
                s = h.length - 1;
                s >= 0;
                s--
            )
                h[s][0].each(i);
            return t(r);
        },
        _removeCurrentsFromItems() {
            const e = this.currentItem.find(`:data(${this.widgetName}-item)`);
            this.items = t.grep(this.items, (t) => {
                for (let i = 0; e.length > i; i++) if (e[i] === t.item[0]) return !1;
                return !0;
            });
        },
        _refreshItems(e) {
            (this.items = []), (this.containers = [this]);
            let i;
            let s;
            let n;
            let o;
            let a;
            let r;
            let h;
            let l;
            const c = this.items;
            const u = [
                [
                    t.isFunction(this.options.items)
                        ? this.options.items.call(this.element[0], e, {
                            item: this.currentItem,
                        })
                        : t(this.options.items, this.element),
                    this,
                ],
            ];
            const d = this._connectWith();
            if (d && this.ready)
                for (i = d.length - 1; i >= 0; i--)
                    for (n = t(d[i], this.document[0]), s = n.length - 1; s >= 0; s--)
                        (o = t.data(n[s], this.widgetFullName)),
                        o &&
                                    o !== this &&
                                    !o.options.disabled &&
                                    (u.push([
                                        t.isFunction(o.options.items)
                                            ? o.options.items.call(o.element[0], e, {
                                                item: this.currentItem,
                                            })
                                            : t(o.options.items, o.element),
                                        o,
                                    ]),
                                    this.containers.push(o));
            for (i = u.length - 1; i >= 0; i--) {
                for (a = u[i][1], r = u[i][0], s = 0, l = r.length; l > s; s++) {
                    (h = t(r[s])),
                    h.data(`${this.widgetName}-item`, a),
                    c.push({
                        item: h,
                        instance: a,
                        width: 0,
                        height: 0,
                        left: 0,
                        top: 0,
                    });
                }
            }
        },
        refreshPositions(e) {
            (this.floating = this.items.length
                ? this.options.axis === "x" || this._isFloating(this.items[0].item)
                : !1),
            this.offsetParent &&
                        this.helper &&
                        (this.offset.parent = this._getParentOffset());
            let i;
            let s;
            let n;
            let o;
            for (i = this.items.length - 1; i >= 0; i--)
                (s = this.items[i]),
                (s.instance !== this.currentContainer &&
                            this.currentContainer &&
                            s.item[0] !== this.currentItem[0]) ||
                            ((n = this.options.toleranceElement
                                ? t(this.options.toleranceElement, s.item)
                                : s.item),
                            e || ((s.width = n.outerWidth()), (s.height = n.outerHeight())),
                            (o = n.offset()),
                            (s.left = o.left),
                            (s.top = o.top));
            if (this.options.custom && this.options.custom.refreshContainers)
                this.options.custom.refreshContainers.call(this);
            else
                for (i = this.containers.length - 1; i >= 0; i--)
                    (o = this.containers[i].element.offset()),
                    (this.containers[i].containerCache.left = o.left),
                    (this.containers[i].containerCache.top = o.top),
                    (this.containers[i].containerCache.width =
                                this.containers[i].element.outerWidth()),
                    (this.containers[i].containerCache.height =
                                this.containers[i].element.outerHeight());
            return this;
        },
        _createPlaceholder(e) {
            e = e || this;
            let i;
            const s = e.options;
            (s.placeholder && s.placeholder.constructor !== String) ||
                    ((i = s.placeholder),
                    (s.placeholder = {
                        element() {
                            const s = e.currentItem[0].nodeName.toLowerCase();
                            const n = t(`<${s}>`, e.document[0]);
                            return (
                                e
                                    ._addClass(
                                        n,
                                        "ui-sortable-placeholder",
                                        i || e.currentItem[0].className,
                                    )
                                    ._removeClass(n, "ui-sortable-helper"),
                                s === "tbody"
                                    ? e._createTrPlaceholder(
                                        e.currentItem.find("tr").eq(0),
                                        t("<tr>", e.document[0]).appendTo(n),
                                    )
                                    : s === "tr"
                                        ? e._createTrPlaceholder(e.currentItem, n)
                                        : s === "img" && n.attr("src", e.currentItem.attr("src")),
                                i || n.css("visibility", "hidden"),
                                n
                            );
                        },
                        update(t, n) {
                            (!i || s.forcePlaceholderSize) &&
                                (n.height() ||
                                    n.height(
                                        e.currentItem.innerHeight() -
                                            parseInt(e.currentItem.css("paddingTop") || 0, 10) -
                                            parseInt(e.currentItem.css("paddingBottom") || 0, 10),
                                    ),
                                n.width() ||
                                    n.width(
                                        e.currentItem.innerWidth() -
                                            parseInt(e.currentItem.css("paddingLeft") || 0, 10) -
                                            parseInt(e.currentItem.css("paddingRight") || 0, 10),
                                    ));
                        },
                    })),
            (e.placeholder = t(s.placeholder.element.call(e.element, e.currentItem))),
            e.currentItem.after(e.placeholder),
            s.placeholder.update(e, e.placeholder);
        },
        _createTrPlaceholder(e, i) {
            const s = this;
            e.children().each(function () {
                t("<td>&#160;</td>", s.document[0])
                    .attr("colspan", t(this).attr("colspan") || 1)
                    .appendTo(i);
            });
        },
        _contactContainers(e) {
            let i;
            let s;
            let n;
            let o;
            let a;
            let r;
            let h;
            let l;
            let c;
            let u;
            let d = null;
            let p = null;
            for (i = this.containers.length - 1; i >= 0; i--)
                if (!t.contains(this.currentItem[0], this.containers[i].element[0]))
                    if (this._intersectsWith(this.containers[i].containerCache)) {
                        if (d && t.contains(this.containers[i].element[0], d.element[0]))
                            continue;
                        (d = this.containers[i]), (p = i);
                    } else
                        this.containers[i].containerCache.over &&
                                (this.containers[i]._trigger("out", e, this._uiHash(this)),
                                (this.containers[i].containerCache.over = 0));
            if (d)
                if (this.containers.length === 1)
                    this.containers[p].containerCache.over ||
                            (this.containers[p]._trigger("over", e, this._uiHash(this)),
                            (this.containers[p].containerCache.over = 1));
                else {
                    for (
                        n = 1e4,
                        o = null,
                        c = d.floating || this._isFloating(this.currentItem),
                        a = c ? "left" : "top",
                        r = c ? "width" : "height",
                        u = c ? "pageX" : "pageY",
                        s = this.items.length - 1;
                        s >= 0;
                        s--
                    )
                        t.contains(this.containers[p].element[0], this.items[s].item[0]) &&
                                this.items[s].item[0] !== this.currentItem[0] &&
                                ((h = this.items[s].item.offset()[a]),
                                (l = !1),
                                e[u] - h > this.items[s][r] / 2 && (l = !0),
                                n > Math.abs(e[u] - h) &&
                                    ((n = Math.abs(e[u] - h)),
                                    (o = this.items[s]),
                                    (this.direction = l ? "up" : "down")));
                    if (!o && !this.options.dropOnEmpty) return;
                    if (this.currentContainer === this.containers[p])
                        return (
                            this.currentContainer.containerCache.over ||
                                    (this.containers[p]._trigger("over", e, this._uiHash()),
                                    (this.currentContainer.containerCache.over = 1)),
                            void 0
                        );
                    o
                        ? this._rearrange(e, o, null, !0)
                        : this._rearrange(e, null, this.containers[p].element, !0),
                    this._trigger("change", e, this._uiHash()),
                    this.containers[p]._trigger("change", e, this._uiHash(this)),
                    (this.currentContainer = this.containers[p]),
                    this.options.placeholder.update(
                        this.currentContainer,
                        this.placeholder,
                    ),
                    this.containers[p]._trigger("over", e, this._uiHash(this)),
                    (this.containers[p].containerCache.over = 1);
                }
        },
        _createHelper(e) {
            const i = this.options;
            const s = t.isFunction(i.helper)
                ? t(i.helper.apply(this.element[0], [e, this.currentItem]))
                : i.helper === "clone"
                    ? this.currentItem.clone()
                    : this.currentItem;
            return (
                s.parents("body").length ||
                        t(
                            i.appendTo !== "parent" ? i.appendTo : this.currentItem[0].parentNode,
                        )[0].appendChild(s[0]),
                s[0] === this.currentItem[0] &&
                        (this._storedCSS = {
                            width: this.currentItem[0].style.width,
                            height: this.currentItem[0].style.height,
                            position: this.currentItem.css("position"),
                            top: this.currentItem.css("top"),
                            left: this.currentItem.css("left"),
                        }),
                (!s[0].style.width || i.forceHelperSize) && s.width(this.currentItem.width()),
                (!s[0].style.height || i.forceHelperSize) &&
                        s.height(this.currentItem.height()),
                s
            );
        },
        _adjustOffsetFromHelper(e) {
            typeof e === "string" && (e = e.split(" ")),
            t.isArray(e) && (e = { left: +e[0], top: +e[1] || 0 }),
            "left" in e && (this.offset.click.left = e.left + this.margins.left),
            "right" in e &&
                        (this.offset.click.left =
                            this.helperProportions.width - e.right + this.margins.left),
            "top" in e && (this.offset.click.top = e.top + this.margins.top),
            "bottom" in e &&
                        (this.offset.click.top =
                            this.helperProportions.height - e.bottom + this.margins.top);
        },
        _getParentOffset() {
            this.offsetParent = this.helper.offsetParent();
            let e = this.offsetParent.offset();
            return (
                this.cssPosition === "absolute" &&
                        this.scrollParent[0] !== this.document[0] &&
                        t.contains(this.scrollParent[0], this.offsetParent[0]) &&
                        ((e.left += this.scrollParent.scrollLeft()),
                        (e.top += this.scrollParent.scrollTop())),
                (this.offsetParent[0] === this.document[0].body ||
                        (this.offsetParent[0].tagName &&
                            this.offsetParent[0].tagName.toLowerCase() === "html" &&
                            t.ui.ie)) &&
                        (e = { top: 0, left: 0 }),
                {
                    top: e.top + (parseInt(this.offsetParent.css("borderTopWidth"), 10) || 0),
                    left:
                            e.left + (parseInt(this.offsetParent.css("borderLeftWidth"), 10) || 0),
                }
            );
        },
        _getRelativeOffset() {
            if (this.cssPosition === "relative") {
                const t = this.currentItem.position();
                return {
                    top:
                            t.top -
                            (parseInt(this.helper.css("top"), 10) || 0) +
                            this.scrollParent.scrollTop(),
                    left:
                            t.left -
                            (parseInt(this.helper.css("left"), 10) || 0) +
                            this.scrollParent.scrollLeft(),
                };
            }
            return { top: 0, left: 0 };
        },
        _cacheMargins() {
            this.margins = {
                left: parseInt(this.currentItem.css("marginLeft"), 10) || 0,
                top: parseInt(this.currentItem.css("marginTop"), 10) || 0,
            };
        },
        _cacheHelperProportions() {
            this.helperProportions = {
                width: this.helper.outerWidth(),
                height: this.helper.outerHeight(),
            };
        },
        _setContainment() {
            let e;
            let i;
            let s;
            const n = this.options;
            n.containment === "parent" && (n.containment = this.helper[0].parentNode),
            (n.containment === "document" || n.containment === "window") &&
                        (this.containment = [
                            0 - this.offset.relative.left - this.offset.parent.left,
                            0 - this.offset.relative.top - this.offset.parent.top,
                            n.containment === "document"
                                ? this.document.width()
                                : this.window.width() -
                                  this.helperProportions.width -
                                  this.margins.left,
                            (n.containment === "document"
                                ? this.document.height() || document.body.parentNode.scrollHeight
                                : this.window.height() ||
                                  this.document[0].body.parentNode.scrollHeight) -
                                this.helperProportions.height -
                                this.margins.top,
                        ]),
            /^(document|window|parent)$/.test(n.containment) ||
                        ((e = t(n.containment)[0]),
                        (i = t(n.containment).offset()),
                        (s = t(e).css("overflow") !== "hidden"),
                        (this.containment = [
                            i.left +
                                (parseInt(t(e).css("borderLeftWidth"), 10) || 0) +
                                (parseInt(t(e).css("paddingLeft"), 10) || 0) -
                                this.margins.left,
                            i.top +
                                (parseInt(t(e).css("borderTopWidth"), 10) || 0) +
                                (parseInt(t(e).css("paddingTop"), 10) || 0) -
                                this.margins.top,
                            i.left +
                                (s ? Math.max(e.scrollWidth, e.offsetWidth) : e.offsetWidth) -
                                (parseInt(t(e).css("borderLeftWidth"), 10) || 0) -
                                (parseInt(t(e).css("paddingRight"), 10) || 0) -
                                this.helperProportions.width -
                                this.margins.left,
                            i.top +
                                (s ? Math.max(e.scrollHeight, e.offsetHeight) : e.offsetHeight) -
                                (parseInt(t(e).css("borderTopWidth"), 10) || 0) -
                                (parseInt(t(e).css("paddingBottom"), 10) || 0) -
                                this.helperProportions.height -
                                this.margins.top,
                        ]));
        },
        _convertPositionTo(e, i) {
            i || (i = this.position);
            const s = e === "absolute" ? 1 : -1;
            const n =
                    this.cssPosition !== "absolute" ||
                    (this.scrollParent[0] !== this.document[0] &&
                        t.contains(this.scrollParent[0], this.offsetParent[0]))
                        ? this.scrollParent
                        : this.offsetParent;
            const o = /(html|body)/i.test(n[0].tagName);
            return {
                top:
                        i.top +
                        this.offset.relative.top * s +
                        this.offset.parent.top * s -
                        (this.cssPosition === "fixed"
                            ? -this.scrollParent.scrollTop()
                            : o
                                ? 0
                                : n.scrollTop()) *
                            s,
                left:
                        i.left +
                        this.offset.relative.left * s +
                        this.offset.parent.left * s -
                        (this.cssPosition === "fixed"
                            ? -this.scrollParent.scrollLeft()
                            : o
                                ? 0
                                : n.scrollLeft()) *
                            s,
            };
        },
        _generatePosition(e) {
            let i;
            let s;
            const n = this.options;
            let o = e.pageX;
            let a = e.pageY;
            const r =
                    this.cssPosition !== "absolute" ||
                    (this.scrollParent[0] !== this.document[0] &&
                        t.contains(this.scrollParent[0], this.offsetParent[0]))
                        ? this.scrollParent
                        : this.offsetParent;
            const h = /(html|body)/i.test(r[0].tagName);
            return (
                this.cssPosition !== "relative" ||
                        (this.scrollParent[0] !== this.document[0] &&
                            this.scrollParent[0] !== this.offsetParent[0]) ||
                        (this.offset.relative = this._getRelativeOffset()),
                this.originalPosition &&
                        (this.containment &&
                            (e.pageX - this.offset.click.left < this.containment[0] &&
                                (o = this.containment[0] + this.offset.click.left),
                            e.pageY - this.offset.click.top < this.containment[1] &&
                                (a = this.containment[1] + this.offset.click.top),
                            e.pageX - this.offset.click.left > this.containment[2] &&
                                (o = this.containment[2] + this.offset.click.left),
                            e.pageY - this.offset.click.top > this.containment[3] &&
                                (a = this.containment[3] + this.offset.click.top)),
                        n.grid &&
                            ((i =
                                this.originalPageY +
                                Math.round((a - this.originalPageY) / n.grid[1]) * n.grid[1]),
                            (a = this.containment
                                ? i - this.offset.click.top >= this.containment[1] &&
                                  i - this.offset.click.top <= this.containment[3]
                                    ? i
                                    : i - this.offset.click.top >= this.containment[1]
                                        ? i - n.grid[1]
                                        : i + n.grid[1]
                                : i),
                            (s =
                                this.originalPageX +
                                Math.round((o - this.originalPageX) / n.grid[0]) * n.grid[0]),
                            (o = this.containment
                                ? s - this.offset.click.left >= this.containment[0] &&
                                  s - this.offset.click.left <= this.containment[2]
                                    ? s
                                    : s - this.offset.click.left >= this.containment[0]
                                        ? s - n.grid[0]
                                        : s + n.grid[0]
                                : s))),
                {
                    top:
                            a -
                            this.offset.click.top -
                            this.offset.relative.top -
                            this.offset.parent.top +
                            (this.cssPosition === "fixed"
                                ? -this.scrollParent.scrollTop()
                                : h
                                    ? 0
                                    : r.scrollTop()),
                    left:
                            o -
                            this.offset.click.left -
                            this.offset.relative.left -
                            this.offset.parent.left +
                            (this.cssPosition === "fixed"
                                ? -this.scrollParent.scrollLeft()
                                : h
                                    ? 0
                                    : r.scrollLeft()),
                }
            );
        },
        _rearrange(t, e, i, s) {
            i
                ? i[0].appendChild(this.placeholder[0])
                : e.item[0].parentNode.insertBefore(
                    this.placeholder[0],
                    this.direction === "down" ? e.item[0] : e.item[0].nextSibling,
                ),
            (this.counter = this.counter ? ++this.counter : 1);
            const n = this.counter;
            this._delay(function () {
                n === this.counter && this.refreshPositions(!s);
            });
        },
        _clear(t, e) {
            function i(t, e, i) {
                return function (s) {
                    i._trigger(t, s, e._uiHash(e));
                };
            }
            this.reverting = !1;
            let s;
            const n = [];
            if (
                (!this._noFinalSort &&
                        this.currentItem.parent().length &&
                        this.placeholder.before(this.currentItem),
                (this._noFinalSort = null),
                this.helper[0] === this.currentItem[0])
            ) {
                for (s in this._storedCSS)
                    (this._storedCSS[s] === "auto" || this._storedCSS[s] === "static") &&
                            (this._storedCSS[s] = "");
                this.currentItem.css(this._storedCSS),
                this._removeClass(this.currentItem, "ui-sortable-helper");
            } else this.currentItem.show();
            for (
                this.fromOutside &&
                        !e &&
                        n.push(function (t) {
                            this._trigger("receive", t, this._uiHash(this.fromOutside));
                        }),
                (!this.fromOutside &&
                            this.domPosition.prev ===
                                this.currentItem.prev().not(".ui-sortable-helper")[0] &&
                            this.domPosition.parent === this.currentItem.parent()[0]) ||
                            e ||
                            n.push(function (t) {
                                this._trigger("update", t, this._uiHash());
                            }),
                this !== this.currentContainer &&
                            (e ||
                                (n.push(function (t) {
                                    this._trigger("remove", t, this._uiHash());
                                }),
                                n.push(
                                    function (t) {
                                        return function (e) {
                                            t._trigger("receive", e, this._uiHash(this));
                                        };
                                    }.call(this, this.currentContainer),
                                ),
                                n.push(
                                    function (t) {
                                        return function (e) {
                                            t._trigger("update", e, this._uiHash(this));
                                        };
                                    }.call(this, this.currentContainer),
                                ))),
                s = this.containers.length - 1;
                s >= 0;
                s--
            )
                e || n.push(i("deactivate", this, this.containers[s])),
                this.containers[s].containerCache.over &&
                            (n.push(i("out", this, this.containers[s])),
                            (this.containers[s].containerCache.over = 0));
            if (
                (this.storedCursor &&
                        (this.document.find("body").css("cursor", this.storedCursor),
                        this.storedStylesheet.remove()),
                this._storedOpacity && this.helper.css("opacity", this._storedOpacity),
                this._storedZIndex &&
                        this.helper.css(
                            "zIndex",
                            this._storedZIndex === "auto" ? "" : this._storedZIndex,
                        ),
                (this.dragging = !1),
                e || this._trigger("beforeStop", t, this._uiHash()),
                this.placeholder[0].parentNode.removeChild(this.placeholder[0]),
                this.cancelHelperRemoval ||
                        (this.helper[0] !== this.currentItem[0] && this.helper.remove(),
                        (this.helper = null)),
                !e)
            ) {
                for (s = 0; n.length > s; s++) n[s].call(this, t);
                this._trigger("stop", t, this._uiHash());
            }
            return (this.fromOutside = !1), !this.cancelHelperRemoval;
        },
        _trigger() {
            t.Widget.prototype._trigger.apply(this, arguments) === !1 && this.cancel();
        },
        _uiHash(e) {
            const i = e || this;
            return {
                helper: i.helper,
                placeholder: i.placeholder || t([]),
                position: i.position,
                originalPosition: i.originalPosition,
                offset: i.positionAbs,
                item: i.currentItem,
                sender: e ? e.element : null,
            };
        },
    }),
    t.widget("ui.spinner", {
        version: "1.12.1",
        defaultElement: "<input>",
        widgetEventPrefix: "spin",
        options: {
            classes: {
                "ui-spinner": "ui-corner-all",
                "ui-spinner-down": "ui-corner-br",
                "ui-spinner-up": "ui-corner-tr",
            },
            culture: null,
            icons: { down: "ui-icon-triangle-1-s", up: "ui-icon-triangle-1-n" },
            incremental: !0,
            max: null,
            min: null,
            numberFormat: null,
            page: 10,
            step: 1,
            change: null,
            spin: null,
            start: null,
            stop: null,
        },
        _create() {
            this._setOption("max", this.options.max),
            this._setOption("min", this.options.min),
            this._setOption("step", this.options.step),
            this.value() !== "" && this._value(this.element.val(), !0),
            this._draw(),
            this._on(this._events),
            this._refresh(),
            this._on(this.window, {
                beforeunload() {
                    this.element.removeAttr("autocomplete");
                },
            });
        },
        _getCreateOptions() {
            const e = this._super();
            const i = this.element;
            return (
                t.each(["min", "max", "step"], (t, s) => {
                    const n = i.attr(s);
                    n != null && n.length && (e[s] = n);
                }),
                e
            );
        },
        _events: {
            keydown(t) {
                this._start(t) && this._keydown(t) && t.preventDefault();
            },
            keyup: "_stop",
            focus() {
                this.previous = this.element.val();
            },
            blur(t) {
                return this.cancelBlur
                    ? (delete this.cancelBlur, void 0)
                    : (this._stop(),
                    this._refresh(),
                    this.previous !== this.element.val() && this._trigger("change", t),
                    void 0);
            },
            mousewheel(t, e) {
                if (e) {
                    if (!this.spinning && !this._start(t)) return !1;
                    this._spin((e > 0 ? 1 : -1) * this.options.step, t),
                    clearTimeout(this.mousewheelTimer),
                    (this.mousewheelTimer = this._delay(function () {
                        this.spinning && this._stop(t);
                    }, 100)),
                    t.preventDefault();
                }
            },
            "mousedown .ui-spinner-button": function (e) {
                function i() {
                    const e = this.element[0] === t.ui.safeActiveElement(this.document[0]);
                    e ||
                            (this.element.trigger("focus"),
                            (this.previous = s),
                            this._delay(function () {
                                this.previous = s;
                            }));
                }
                let s;
                (s =
                        this.element[0] === t.ui.safeActiveElement(this.document[0])
                            ? this.previous
                            : this.element.val()),
                e.preventDefault(),
                i.call(this),
                (this.cancelBlur = !0),
                this._delay(function () {
                    delete this.cancelBlur, i.call(this);
                }),
                this._start(e) !== !1 &&
                            this._repeat(
                                null,
                                t(e.currentTarget).hasClass("ui-spinner-up") ? 1 : -1,
                                e,
                            );
            },
            "mouseup .ui-spinner-button": "_stop",
            "mouseenter .ui-spinner-button": function (e) {
                return t(e.currentTarget).hasClass("ui-state-active")
                    ? this._start(e) === !1
                        ? !1
                        : (this._repeat(
                            null,
                            t(e.currentTarget).hasClass("ui-spinner-up") ? 1 : -1,
                            e,
                        ),
                        void 0)
                    : void 0;
            },
            "mouseleave .ui-spinner-button": "_stop",
        },
        _enhance() {
            this.uiSpinner = this.element
                .attr("autocomplete", "off")
                .wrap("<span>")
                .parent()
                .append("<a></a><a></a>");
        },
        _draw() {
            this._enhance(),
            this._addClass(this.uiSpinner, "ui-spinner", "ui-widget ui-widget-content"),
            this._addClass("ui-spinner-input"),
            this.element.attr("role", "spinbutton"),
            (this.buttons = this.uiSpinner
                .children("a")
                .attr("tabIndex", -1)
                .attr("aria-hidden", !0)
                .button({ classes: { "ui-button": "" } })),
            this._removeClass(this.buttons, "ui-corner-all"),
            this._addClass(this.buttons.first(), "ui-spinner-button ui-spinner-up"),
            this._addClass(this.buttons.last(), "ui-spinner-button ui-spinner-down"),
            this.buttons.first().button({ icon: this.options.icons.up, showLabel: !1 }),
            this.buttons.last().button({ icon: this.options.icons.down, showLabel: !1 }),
            this.buttons.height() > Math.ceil(0.5 * this.uiSpinner.height()) &&
                        this.uiSpinner.height() > 0 &&
                        this.uiSpinner.height(this.uiSpinner.height());
        },
        _keydown(e) {
            const i = this.options;
            const s = t.ui.keyCode;
            switch (e.keyCode) {
            case s.UP:
                return this._repeat(null, 1, e), !0;
            case s.DOWN:
                return this._repeat(null, -1, e), !0;
            case s.PAGE_UP:
                return this._repeat(null, i.page, e), !0;
            case s.PAGE_DOWN:
                return this._repeat(null, -i.page, e), !0;
            }
            return !1;
        },
        _start(t) {
            return this.spinning || this._trigger("start", t) !== !1
                ? (this.counter || (this.counter = 1), (this.spinning = !0), !0)
                : !1;
        },
        _repeat(t, e, i) {
            (t = t || 500),
            clearTimeout(this.timer),
            (this.timer = this._delay(function () {
                this._repeat(40, e, i);
            }, t)),
            this._spin(e * this.options.step, i);
        },
        _spin(t, e) {
            let i = this.value() || 0;
            this.counter || (this.counter = 1),
            (i = this._adjustValue(i + t * this._increment(this.counter))),
            (this.spinning && this._trigger("spin", e, { value: i }) === !1) ||
                        (this._value(i), this.counter++);
        },
        _increment(e) {
            const i = this.options.incremental;
            return i
                ? t.isFunction(i)
                    ? i(e)
                    : Math.floor((e * e * e) / 5e4 - (e * e) / 500 + (17 * e) / 200 + 1)
                : 1;
        },
        _precision() {
            let t = this._precisionOf(this.options.step);
            return (
                this.options.min !== null &&
                        (t = Math.max(t, this._precisionOf(this.options.min))),
                t
            );
        },
        _precisionOf(t) {
            const e = `${t}`;
            const i = e.indexOf(".");
            return i === -1 ? 0 : e.length - i - 1;
        },
        _adjustValue(t) {
            let e;
            let i;
            const s = this.options;
            return (
                (e = s.min !== null ? s.min : 0),
                (i = t - e),
                (i = Math.round(i / s.step) * s.step),
                (t = e + i),
                (t = parseFloat(t.toFixed(this._precision()))),
                s.max !== null && t > s.max ? s.max : s.min !== null && s.min > t ? s.min : t
            );
        },
        _stop(t) {
            this.spinning &&
                    (clearTimeout(this.timer),
                    clearTimeout(this.mousewheelTimer),
                    (this.counter = 0),
                    (this.spinning = !1),
                    this._trigger("stop", t));
        },
        _setOption(t, e) {
            let i;
            let s;
            let n;
            return t === "culture" || t === "numberFormat"
                ? ((i = this._parse(this.element.val())),
                (this.options[t] = e),
                this.element.val(this._format(i)),
                void 0)
                : ((t === "max" || t === "min" || t === "step") &&
                          typeof e === "string" &&
                          (e = this._parse(e)),
                t === "icons" &&
                          ((s = this.buttons.first().find(".ui-icon")),
                          this._removeClass(s, null, this.options.icons.up),
                          this._addClass(s, null, e.up),
                          (n = this.buttons.last().find(".ui-icon")),
                          this._removeClass(n, null, this.options.icons.down),
                          this._addClass(n, null, e.down)),
                this._super(t, e),
                void 0);
        },
        _setOptionDisabled(t) {
            this._super(t),
            this._toggleClass(this.uiSpinner, null, "ui-state-disabled", !!t),
            this.element.prop("disabled", !!t),
            this.buttons.button(t ? "disable" : "enable");
        },
        _setOptions: r(function (t) {
            this._super(t);
        }),
        _parse(t) {
            return (
                typeof t === "string" &&
                        t !== "" &&
                        (t =
                            window.Globalize && this.options.numberFormat
                                ? Globalize.parseFloat(t, 10, this.options.culture)
                                : +t),
                t === "" || isNaN(t) ? null : t
            );
        },
        _format(t) {
            return t === ""
                ? ""
                : window.Globalize && this.options.numberFormat
                    ? Globalize.format(t, this.options.numberFormat, this.options.culture)
                    : t;
        },
        _refresh() {
            this.element.attr({
                "aria-valuemin": this.options.min,
                "aria-valuemax": this.options.max,
                "aria-valuenow": this._parse(this.element.val()),
            });
        },
        isValid() {
            const t = this.value();
            return t === null ? !1 : t === this._adjustValue(t);
        },
        _value(t, e) {
            let i;
            t !== "" &&
                    ((i = this._parse(t)),
                    i !== null && (e || (i = this._adjustValue(i)), (t = this._format(i)))),
            this.element.val(t),
            this._refresh();
        },
        _destroy() {
            this.element
                .prop("disabled", !1)
                .removeAttr("autocomplete role aria-valuemin aria-valuemax aria-valuenow"),
            this.uiSpinner.replaceWith(this.element);
        },
        stepUp: r(function (t) {
            this._stepUp(t);
        }),
        _stepUp(t) {
            this._start() && (this._spin((t || 1) * this.options.step), this._stop());
        },
        stepDown: r(function (t) {
            this._stepDown(t);
        }),
        _stepDown(t) {
            this._start() && (this._spin((t || 1) * -this.options.step), this._stop());
        },
        pageUp: r(function (t) {
            this._stepUp((t || 1) * this.options.page);
        }),
        pageDown: r(function (t) {
            this._stepDown((t || 1) * this.options.page);
        }),
        value(t) {
            return arguments.length
                ? (r(this._value).call(this, t), void 0)
                : this._parse(this.element.val());
        },
        widget() {
            return this.uiSpinner;
        },
    }),
    t.uiBackCompat !== !1 &&
            t.widget("ui.spinner", t.ui.spinner, {
                _enhance() {
                    this.uiSpinner = this.element
                        .attr("autocomplete", "off")
                        .wrap(this._uiSpinnerHtml())
                        .parent()
                        .append(this._buttonHtml());
                },
                _uiSpinnerHtml() {
                    return "<span>";
                },
                _buttonHtml() {
                    return "<a></a><a></a>";
                },
            }),
    t.ui.spinner,
    t.widget("ui.tabs", {
        version: "1.12.1",
        delay: 300,
        options: {
            active: null,
            classes: {
                "ui-tabs": "ui-corner-all",
                "ui-tabs-nav": "ui-corner-all",
                "ui-tabs-panel": "ui-corner-bottom",
                "ui-tabs-tab": "ui-corner-top",
            },
            collapsible: !1,
            event: "click",
            heightStyle: "content",
            hide: null,
            show: null,
            activate: null,
            beforeActivate: null,
            beforeLoad: null,
            load: null,
        },
        _isLocal: (function () {
            const t = /#.*$/;
            return function (e) {
                let i;
                let s;
                (i = e.href.replace(t, "")), (s = location.href.replace(t, ""));
                try {
                    i = decodeURIComponent(i);
                } catch (n) {}
                try {
                    s = decodeURIComponent(s);
                } catch (n) {}
                return e.hash.length > 1 && i === s;
            };
        })(),
        _create() {
            const e = this;
            const i = this.options;
            (this.running = !1),
            this._addClass("ui-tabs", "ui-widget ui-widget-content"),
            this._toggleClass("ui-tabs-collapsible", null, i.collapsible),
            this._processTabs(),
            (i.active = this._initialActive()),
            t.isArray(i.disabled) &&
                        (i.disabled = t
                            .unique(
                                i.disabled.concat(
                                    t.map(this.tabs.filter(".ui-state-disabled"), (t) =>
                                        e.tabs.index(t),
                                    ),
                                ),
                            )
                            .sort()),
            (this.active =
                        this.options.active !== !1 && this.anchors.length
                            ? this._findActive(i.active)
                            : t()),
            this._refresh(),
            this.active.length && this.load(i.active);
        },
        _initialActive() {
            let e = this.options.active;
            const i = this.options.collapsible;
            const s = location.hash.substring(1);
            return (
                e === null &&
                        (s &&
                            this.tabs.each((i, n) =>
                                t(n).attr("aria-controls") === s ? ((e = i), !1) : void 0,
                            ),
                        e === null && (e = this.tabs.index(this.tabs.filter(".ui-tabs-active"))),
                        (e === null || e === -1) && (e = this.tabs.length ? 0 : !1)),
                e !== !1 &&
                        ((e = this.tabs.index(this.tabs.eq(e))), e === -1 && (e = i ? !1 : 0)),
                !i && e === !1 && this.anchors.length && (e = 0),
                e
            );
        },
        _getCreateEventData() {
            return {
                tab: this.active,
                panel: this.active.length ? this._getPanelForTab(this.active) : t(),
            };
        },
        _tabKeydown(e) {
            const i = t(t.ui.safeActiveElement(this.document[0])).closest("li");
            let s = this.tabs.index(i);
            let n = !0;
            if (!this._handlePageNav(e)) {
                switch (e.keyCode) {
                case t.ui.keyCode.RIGHT:
                case t.ui.keyCode.DOWN:
                    s++;
                    break;
                case t.ui.keyCode.UP:
                case t.ui.keyCode.LEFT:
                    (n = !1), s--;
                    break;
                case t.ui.keyCode.END:
                    s = this.anchors.length - 1;
                    break;
                case t.ui.keyCode.HOME:
                    s = 0;
                    break;
                case t.ui.keyCode.SPACE:
                    return (
                        e.preventDefault(),
                        clearTimeout(this.activating),
                        this._activate(s),
                        void 0
                    );
                case t.ui.keyCode.ENTER:
                    return (
                        e.preventDefault(),
                        clearTimeout(this.activating),
                        this._activate(s === this.options.active ? !1 : s),
                        void 0
                    );
                default:
                    return;
                }
                e.preventDefault(),
                clearTimeout(this.activating),
                (s = this._focusNextTab(s, n)),
                e.ctrlKey ||
                            e.metaKey ||
                            (i.attr("aria-selected", "false"),
                            this.tabs.eq(s).attr("aria-selected", "true"),
                            (this.activating = this._delay(function () {
                                this.option("active", s);
                            }, this.delay)));
            }
        },
        _panelKeydown(e) {
            this._handlePageNav(e) ||
                    (e.ctrlKey &&
                        e.keyCode === t.ui.keyCode.UP &&
                        (e.preventDefault(), this.active.trigger("focus")));
        },
        _handlePageNav(e) {
            return e.altKey && e.keyCode === t.ui.keyCode.PAGE_UP
                ? (this._activate(this._focusNextTab(this.options.active - 1, !1)), !0)
                : e.altKey && e.keyCode === t.ui.keyCode.PAGE_DOWN
                    ? (this._activate(this._focusNextTab(this.options.active + 1, !0)), !0)
                    : void 0;
        },
        _findNextTab(e, i) {
            function s() {
                return e > n && (e = 0), e < 0 && (e = n), e;
            }
            for (var n = this.tabs.length - 1; t.inArray(s(), this.options.disabled) !== -1; )
                e = i ? e + 1 : e - 1;
            return e;
        },
        _focusNextTab(t, e) {
            return (t = this._findNextTab(t, e)), this.tabs.eq(t).trigger("focus"), t;
        },
        _setOption(t, e) {
            return t === "active"
                ? (this._activate(e), void 0)
                : (this._super(t, e),
                t === "collapsible" &&
                          (this._toggleClass("ui-tabs-collapsible", null, e),
                          e || this.options.active !== !1 || this._activate(0)),
                t === "event" && this._setupEvents(e),
                t === "heightStyle" && this._setupHeightStyle(e),
                void 0);
        },
        _sanitizeSelector(t) {
            return t ? t.replace(/[!"$%&'()*+,.\/:;<=>?@\[\]\^`{|}~]/g, "\\$&") : "";
        },
        refresh() {
            const e = this.options;
            const i = this.tablist.children(":has(a[href])");
            (e.disabled = t.map(i.filter(".ui-state-disabled"), (t) => i.index(t))),
            this._processTabs(),
            e.active !== !1 && this.anchors.length
                ? this.active.length && !t.contains(this.tablist[0], this.active[0])
                    ? this.tabs.length === e.disabled.length
                        ? ((e.active = !1), (this.active = t()))
                        : this._activate(this._findNextTab(Math.max(0, e.active - 1), !1))
                    : (e.active = this.tabs.index(this.active))
                : ((e.active = !1), (this.active = t())),
            this._refresh();
        },
        _refresh() {
            this._setOptionDisabled(this.options.disabled),
            this._setupEvents(this.options.event),
            this._setupHeightStyle(this.options.heightStyle),
            this.tabs
                .not(this.active)
                .attr({ "aria-selected": "false", "aria-expanded": "false", tabIndex: -1 }),
            this.panels
                .not(this._getPanelForTab(this.active))
                .hide()
                .attr({ "aria-hidden": "true" }),
            this.active.length
                ? (this.active.attr({
                    "aria-selected": "true",
                    "aria-expanded": "true",
                    tabIndex: 0,
                }),
                this._addClass(this.active, "ui-tabs-active", "ui-state-active"),
                this._getPanelForTab(this.active).show().attr({ "aria-hidden": "false" }))
                : this.tabs.eq(0).attr("tabIndex", 0);
        },
        _processTabs() {
            const e = this;
            const i = this.tabs;
            const s = this.anchors;
            const n = this.panels;
            (this.tablist = this._getList().attr("role", "tablist")),
            this._addClass(
                this.tablist,
                "ui-tabs-nav",
                "ui-helper-reset ui-helper-clearfix ui-widget-header",
            ),
            this.tablist
                .on(`mousedown${this.eventNamespace}`, "> li", function (e) {
                    t(this).is(".ui-state-disabled") && e.preventDefault();
                })
                .on(`focus${this.eventNamespace}`, ".ui-tabs-anchor", function () {
                    t(this).closest("li").is(".ui-state-disabled") && this.blur();
                }),
            (this.tabs = this.tablist
                .find("> li:has(a[href])")
                .attr({ role: "tab", tabIndex: -1 })),
            this._addClass(this.tabs, "ui-tabs-tab", "ui-state-default"),
            (this.anchors = this.tabs
                .map(function () {
                    return t("a", this)[0];
                })
                .attr({ role: "presentation", tabIndex: -1 })),
            this._addClass(this.anchors, "ui-tabs-anchor"),
            (this.panels = t()),
            this.anchors.each((i, s) => {
                let n;
                let o;
                let a;
                const r = t(s).uniqueId().attr("id");
                const h = t(s).closest("li");
                const l = h.attr("aria-controls");
                e._isLocal(s)
                    ? ((n = s.hash),
                    (a = n.substring(1)),
                    (o = e.element.find(e._sanitizeSelector(n))))
                    : ((a = h.attr("aria-controls") || t({}).uniqueId()[0].id),
                    (n = `#${a}`),
                    (o = e.element.find(n)),
                    o.length ||
                                  ((o = e._createPanel(a)),
                                  o.insertAfter(e.panels[i - 1] || e.tablist)),
                    o.attr("aria-live", "polite")),
                o.length && (e.panels = e.panels.add(o)),
                l && h.data("ui-tabs-aria-controls", l),
                h.attr({ "aria-controls": a, "aria-labelledby": r }),
                o.attr("aria-labelledby", r);
            }),
            this.panels.attr("role", "tabpanel"),
            this._addClass(this.panels, "ui-tabs-panel", "ui-widget-content"),
            i &&
                        (this._off(i.not(this.tabs)),
                        this._off(s.not(this.anchors)),
                        this._off(n.not(this.panels)));
        },
        _getList() {
            return this.tablist || this.element.find("ol, ul").eq(0);
        },
        _createPanel(e) {
            return t("<div>").attr("id", e).data("ui-tabs-destroy", !0);
        },
        _setOptionDisabled(e) {
            let i;
            let s;
            let n;
            for (
                t.isArray(e) &&
                        (e.length ? e.length === this.anchors.length && (e = !0) : (e = !1)),
                n = 0;
                (s = this.tabs[n]);
                n++
            )
                (i = t(s)),
                e === !0 || t.inArray(n, e) !== -1
                    ? (i.attr("aria-disabled", "true"),
                    this._addClass(i, null, "ui-state-disabled"))
                    : (i.removeAttr("aria-disabled"),
                    this._removeClass(i, null, "ui-state-disabled"));
            (this.options.disabled = e),
            this._toggleClass(
                this.widget(),
                `${this.widgetFullName}-disabled`,
                null,
                e === !0,
            );
        },
        _setupEvents(e) {
            const i = {};
            e &&
                    t.each(e.split(" "), (t, e) => {
                        i[e] = "_eventHandler";
                    }),
            this._off(this.anchors.add(this.tabs).add(this.panels)),
            this._on(!0, this.anchors, {
                click(t) {
                    t.preventDefault();
                },
            }),
            this._on(this.anchors, i),
            this._on(this.tabs, { keydown: "_tabKeydown" }),
            this._on(this.panels, { keydown: "_panelKeydown" }),
            this._focusable(this.tabs),
            this._hoverable(this.tabs);
        },
        _setupHeightStyle(e) {
            let i;
            const s = this.element.parent();
            e === "fill"
                ? ((i = s.height()),
                (i -= this.element.outerHeight() - this.element.height()),
                this.element.siblings(":visible").each(function () {
                    const e = t(this);
                    const s = e.css("position");
                    s !== "absolute" && s !== "fixed" && (i -= e.outerHeight(!0));
                }),
                this.element
                    .children()
                    .not(this.panels)
                    .each(function () {
                        i -= t(this).outerHeight(!0);
                    }),
                this.panels
                    .each(function () {
                        t(this).height(
                            Math.max(0, i - t(this).innerHeight() + t(this).height()),
                        );
                    })
                    .css("overflow", "auto"))
                : e === "auto" &&
                      ((i = 0),
                      this.panels
                          .each(function () {
                              i = Math.max(i, t(this).height("").height());
                          })
                          .height(i));
        },
        _eventHandler(e) {
            const i = this.options;
            const s = this.active;
            const n = t(e.currentTarget);
            const o = n.closest("li");
            const a = o[0] === s[0];
            const r = a && i.collapsible;
            const h = r ? t() : this._getPanelForTab(o);
            const l = s.length ? this._getPanelForTab(s) : t();
            const c = {
                oldTab: s,
                oldPanel: l,
                newTab: r ? t() : o,
                newPanel: h,
            };
            e.preventDefault(),
            o.hasClass("ui-state-disabled") ||
                        o.hasClass("ui-tabs-loading") ||
                        this.running ||
                        (a && !i.collapsible) ||
                        this._trigger("beforeActivate", e, c) === !1 ||
                        ((i.active = r ? !1 : this.tabs.index(o)),
                        (this.active = a ? t() : o),
                        this.xhr && this.xhr.abort(),
                        l.length ||
                            h.length ||
                            t.error("jQuery UI Tabs: Mismatching fragment identifier."),
                        h.length && this.load(this.tabs.index(o), e),
                        this._toggle(e, c));
        },
        _toggle(e, i) {
            function s() {
                (o.running = !1), o._trigger("activate", e, i);
            }
            function n() {
                o._addClass(i.newTab.closest("li"), "ui-tabs-active", "ui-state-active"),
                a.length && o.options.show
                    ? o._show(a, o.options.show, s)
                    : (a.show(), s());
            }
            var o = this;
            var a = i.newPanel;
            const r = i.oldPanel;
            (this.running = !0),
            r.length && this.options.hide
                ? this._hide(r, this.options.hide, () => {
                    o._removeClass(
                        i.oldTab.closest("li"),
                        "ui-tabs-active",
                        "ui-state-active",
                    ),
                    n();
                })
                : (this._removeClass(
                    i.oldTab.closest("li"),
                    "ui-tabs-active",
                    "ui-state-active",
                ),
                r.hide(),
                n()),
            r.attr("aria-hidden", "true"),
            i.oldTab.attr({ "aria-selected": "false", "aria-expanded": "false" }),
            a.length && r.length
                ? i.oldTab.attr("tabIndex", -1)
                : a.length &&
                          this.tabs
                              .filter(function () {
                                  return t(this).attr("tabIndex") === 0;
                              })
                              .attr("tabIndex", -1),
            a.attr("aria-hidden", "false"),
            i.newTab.attr({
                "aria-selected": "true",
                "aria-expanded": "true",
                tabIndex: 0,
            });
        },
        _activate(e) {
            let i;
            let s = this._findActive(e);
            s[0] !== this.active[0] &&
                    (s.length || (s = this.active),
                    (i = s.find(".ui-tabs-anchor")[0]),
                    this._eventHandler({ target: i, currentTarget: i, preventDefault: t.noop }));
        },
        _findActive(e) {
            return e === !1 ? t() : this.tabs.eq(e);
        },
        _getIndex(e) {
            return (
                typeof e === "string" &&
                        (e = this.anchors.index(
                            this.anchors.filter(`[href$='${t.ui.escapeSelector(e)}']`),
                        )),
                e
            );
        },
        _destroy() {
            this.xhr && this.xhr.abort(),
            this.tablist.removeAttr("role").off(this.eventNamespace),
            this.anchors.removeAttr("role tabIndex").removeUniqueId(),
            this.tabs.add(this.panels).each(function () {
                t.data(this, "ui-tabs-destroy")
                    ? t(this).remove()
                    : t(this).removeAttr(
                        "role tabIndex aria-live aria-busy aria-selected aria-labelledby aria-hidden aria-expanded",
                    );
            }),
            this.tabs.each(function () {
                const e = t(this);
                const i = e.data("ui-tabs-aria-controls");
                i
                    ? e.attr("aria-controls", i).removeData("ui-tabs-aria-controls")
                    : e.removeAttr("aria-controls");
            }),
            this.panels.show(),
            this.options.heightStyle !== "content" && this.panels.css("height", "");
        },
        enable(e) {
            let i = this.options.disabled;
            i !== !1 &&
                    (void 0 === e
                        ? (i = !1)
                        : ((e = this._getIndex(e)),
                        (i = t.isArray(i)
                            ? t.map(i, (t) => (t !== e ? t : null))
                            : t.map(this.tabs, (t, i) => (i !== e ? i : null)))),
                    this._setOptionDisabled(i));
        },
        disable(e) {
            let i = this.options.disabled;
            if (i !== !0) {
                if (void 0 === e) i = !0;
                else {
                    if (((e = this._getIndex(e)), t.inArray(e, i) !== -1)) return;
                    i = t.isArray(i) ? t.merge([e], i).sort() : [e];
                }
                this._setOptionDisabled(i);
            }
        },
        load(e, i) {
            e = this._getIndex(e);
            const s = this;
            const n = this.tabs.eq(e);
            const o = n.find(".ui-tabs-anchor");
            const a = this._getPanelForTab(n);
            const r = { tab: n, panel: a };
            const h = function (t, e) {
                e === "abort" && s.panels.stop(!1, !0),
                s._removeClass(n, "ui-tabs-loading"),
                a.removeAttr("aria-busy"),
                t === s.xhr && delete s.xhr;
            };
            this._isLocal(o[0]) ||
                    ((this.xhr = t.ajax(this._ajaxSettings(o, i, r))),
                    this.xhr &&
                        this.xhr.statusText !== "canceled" &&
                        (this._addClass(n, "ui-tabs-loading"),
                        a.attr("aria-busy", "true"),
                        this.xhr
                            .done((t, e, n) => {
                                setTimeout(() => {
                                    a.html(t), s._trigger("load", i, r), h(n, e);
                                }, 1);
                            })
                            .fail((t, e) => {
                                setTimeout(() => {
                                    h(t, e);
                                }, 1);
                            })));
        },
        _ajaxSettings(e, i, s) {
            const n = this;
            return {
                url: e.attr("href").replace(/#.*$/, ""),
                beforeSend(e, o) {
                    return n._trigger(
                        "beforeLoad",
                        i,
                        t.extend({ jqXHR: e, ajaxSettings: o }, s),
                    );
                },
            };
        },
        _getPanelForTab(e) {
            const i = t(e).attr("aria-controls");
            return this.element.find(this._sanitizeSelector(`#${i}`));
        },
    }),
    t.uiBackCompat !== !1 &&
            t.widget("ui.tabs", t.ui.tabs, {
                _processTabs() {
                    this._superApply(arguments), this._addClass(this.tabs, "ui-tab");
                },
            }),
    t.ui.tabs,
    t.widget("ui.tooltip", {
        version: "1.12.1",
        options: {
            classes: { "ui-tooltip": "ui-corner-all ui-widget-shadow" },
            content() {
                const e = t(this).attr("title") || "";
                return t("<a>").text(e).html();
            },
            hide: !0,
            items: "[title]:not([disabled])",
            position: { my: "left top+15", at: "left bottom", collision: "flipfit flip" },
            show: !0,
            track: !1,
            close: null,
            open: null,
        },
        _addDescribedBy(e, i) {
            const s = (e.attr("aria-describedby") || "").split(/\s+/);
            s.push(i), e.data("ui-tooltip-id", i).attr("aria-describedby", t.trim(s.join(" ")));
        },
        _removeDescribedBy(e) {
            const i = e.data("ui-tooltip-id");
            let s = (e.attr("aria-describedby") || "").split(/\s+/);
            const n = t.inArray(i, s);
            n !== -1 && s.splice(n, 1),
            e.removeData("ui-tooltip-id"),
            (s = t.trim(s.join(" "))),
            s ? e.attr("aria-describedby", s) : e.removeAttr("aria-describedby");
        },
        _create() {
            this._on({ mouseover: "open", focusin: "open" }),
            (this.tooltips = {}),
            (this.parents = {}),
            (this.liveRegion = t("<div>")
                .attr({
                    role: "log",
                    "aria-live": "assertive",
                    "aria-relevant": "additions",
                })
                .appendTo(this.document[0].body)),
            this._addClass(this.liveRegion, null, "ui-helper-hidden-accessible"),
            (this.disabledTitles = t([]));
        },
        _setOption(e, i) {
            const s = this;
            this._super(e, i),
            e === "content" &&
                        t.each(this.tooltips, (t, e) => {
                            s._updateContent(e.element);
                        });
        },
        _setOptionDisabled(t) {
            this[t ? "_disable" : "_enable"]();
        },
        _disable() {
            const e = this;
            t.each(this.tooltips, (i, s) => {
                const n = t.Event("blur");
                (n.target = n.currentTarget = s.element[0]), e.close(n, !0);
            }),
            (this.disabledTitles = this.disabledTitles.add(
                this.element
                    .find(this.options.items)
                    .addBack()
                    .filter(function () {
                        const e = t(this);
                        return e.is("[title]")
                            ? e
                                .data("ui-tooltip-title", e.attr("title"))
                                .removeAttr("title")
                            : void 0;
                    }),
            ));
        },
        _enable() {
            this.disabledTitles.each(function () {
                const e = t(this);
                e.data("ui-tooltip-title") && e.attr("title", e.data("ui-tooltip-title"));
            }),
            (this.disabledTitles = t([]));
        },
        open(e) {
            const i = this;
            const s = t(e ? e.target : this.element).closest(this.options.items);
            s.length &&
                    !s.data("ui-tooltip-id") &&
                    (s.attr("title") && s.data("ui-tooltip-title", s.attr("title")),
                    s.data("ui-tooltip-open", !0),
                    e &&
                        e.type === "mouseover" &&
                        s.parents().each(function () {
                            let e;
                            const s = t(this);
                            s.data("ui-tooltip-open") &&
                                ((e = t.Event("blur")),
                                (e.target = e.currentTarget = this),
                                i.close(e, !0)),
                            s.attr("title") &&
                                    (s.uniqueId(),
                                    (i.parents[this.id] = {
                                        element: this,
                                        title: s.attr("title"),
                                    }),
                                    s.attr("title", ""));
                        }),
                    this._registerCloseHandlers(e, s),
                    this._updateContent(s, e));
        },
        _updateContent(t, e) {
            let i;
            const s = this.options.content;
            const n = this;
            const o = e ? e.type : null;
            return typeof s === "string" || s.nodeType || s.jquery
                ? this._open(e, t, s)
                : ((i = s.call(t[0], (i) => {
                    n._delay(function () {
                        t.data("ui-tooltip-open") && (e && (e.type = o), this._open(e, t, i));
                    });
                })),
                i && this._open(e, t, i),
                void 0);
        },
        _open(e, i, s) {
            function n(t) {
                (l.of = t), a.is(":hidden") || a.position(l);
            }
            let o;
            let a;
            let r;
            let h;
            var l = t.extend({}, this.options.position);
            if (s) {
                if ((o = this._find(i)))
                    return o.tooltip.find(".ui-tooltip-content").html(s), void 0;
                i.is("[title]") &&
                        (e && e.type === "mouseover" ? i.attr("title", "") : i.removeAttr("title")),
                (o = this._tooltip(i)),
                (a = o.tooltip),
                this._addDescribedBy(i, a.attr("id")),
                a.find(".ui-tooltip-content").html(s),
                this.liveRegion.children().hide(),
                (h = t("<div>").html(a.find(".ui-tooltip-content").html())),
                h.removeAttr("name").find("[name]").removeAttr("name"),
                h.removeAttr("id").find("[id]").removeAttr("id"),
                h.appendTo(this.liveRegion),
                this.options.track && e && /^mouse/.test(e.type)
                    ? (this._on(this.document, { mousemove: n }), n(e))
                    : a.position(t.extend({ of: i }, this.options.position)),
                a.hide(),
                this._show(a, this.options.show),
                this.options.track &&
                            this.options.show &&
                            this.options.show.delay &&
                            (r = this.delayedShow =
                                setInterval(() => {
                                    a.is(":visible") && (n(l.of), clearInterval(r));
                                }, t.fx.interval)),
                this._trigger("open", e, { tooltip: a });
            }
        },
        _registerCloseHandlers(e, i) {
            const s = {
                keyup(e) {
                    if (e.keyCode === t.ui.keyCode.ESCAPE) {
                        const s = t.Event(e);
                        (s.currentTarget = i[0]), this.close(s, !0);
                    }
                },
            };
            i[0] !== this.element[0] &&
                    (s.remove = function () {
                        this._removeTooltip(this._find(i).tooltip);
                    }),
            (e && e.type !== "mouseover") || (s.mouseleave = "close"),
            (e && e.type !== "focusin") || (s.focusout = "close"),
            this._on(!0, i, s);
        },
        close(e) {
            let i;
            const s = this;
            const n = t(e ? e.currentTarget : this.element);
            const o = this._find(n);
            return o
                ? ((i = o.tooltip),
                o.closing ||
                          (clearInterval(this.delayedShow),
                          n.data("ui-tooltip-title") &&
                              !n.attr("title") &&
                              n.attr("title", n.data("ui-tooltip-title")),
                          this._removeDescribedBy(n),
                          (o.hiding = !0),
                          i.stop(!0),
                          this._hide(i, this.options.hide, function () {
                              s._removeTooltip(t(this));
                          }),
                          n.removeData("ui-tooltip-open"),
                          this._off(n, "mouseleave focusout keyup"),
                          n[0] !== this.element[0] && this._off(n, "remove"),
                          this._off(this.document, "mousemove"),
                          e &&
                              e.type === "mouseleave" &&
                              t.each(this.parents, (e, i) => {
                                  t(i.element).attr("title", i.title), delete s.parents[e];
                              }),
                          (o.closing = !0),
                          this._trigger("close", e, { tooltip: i }),
                          o.hiding || (o.closing = !1)),
                void 0)
                : (n.removeData("ui-tooltip-open"), void 0);
        },
        _tooltip(e) {
            const i = t("<div>").attr("role", "tooltip");
            const s = t("<div>").appendTo(i);
            const n = i.uniqueId().attr("id");
            return (
                this._addClass(s, "ui-tooltip-content"),
                this._addClass(i, "ui-tooltip", "ui-widget ui-widget-content"),
                i.appendTo(this._appendTo(e)),
                (this.tooltips[n] = { element: e, tooltip: i })
            );
        },
        _find(t) {
            const e = t.data("ui-tooltip-id");
            return e ? this.tooltips[e] : null;
        },
        _removeTooltip(t) {
            t.remove(), delete this.tooltips[t.attr("id")];
        },
        _appendTo(t) {
            let e = t.closest(".ui-front, dialog");
            return e.length || (e = this.document[0].body), e;
        },
        _destroy() {
            const e = this;
            t.each(this.tooltips, (i, s) => {
                const n = t.Event("blur");
                const o = s.element;
                (n.target = n.currentTarget = o[0]),
                e.close(n, !0),
                t(`#${i}`).remove(),
                o.data("ui-tooltip-title") &&
                            (o.attr("title") || o.attr("title", o.data("ui-tooltip-title")),
                            o.removeData("ui-tooltip-title"));
            }),
            this.liveRegion.remove();
        },
    }),
    t.uiBackCompat !== !1 &&
            t.widget("ui.tooltip", t.ui.tooltip, {
                options: { tooltipClass: null },
                _tooltip() {
                    const t = this._superApply(arguments);
                    return (
                        this.options.tooltipClass && t.tooltip.addClass(this.options.tooltipClass),
                        t
                    );
                },
            }),
    t.ui.tooltip;
});
