import Swiper from "swiper";

new Swiper(".js-reviews-slider .swiper-container", {
    speed: 400,
    slidesPerView: 1,
    pagination: {
        el: ".js-reviews-slider-controls > .swiper-pagination",
        clickable: true,
        dynamicBullets: true,
        dynamicMainBullets: 3,
    },
    watchOverflow: true,
    navigation: {
        nextEl: ".js-reviews-slider-controls > .swiper-button-next",
        prevEl: ".js-reviews-slider-controls > .swiper-button-prev",
    },
});
