export default class SlidesCounter {
    constructor(elem) {
        this.elem = elem;
        if (!this.elem) {
            console.error(new Error("Отсутствует элемент счетчика слайдов"));
            return false;
        }
        this.counterCurrentElem = this.elem.querySelector(".js-slides-counter-current");
        this.counterAllElem = this.elem.querySelector(".js-slides-counter-all");
    }

    changeText(slider) {
        if (this.counterCurrentElem) {
            this.counterCurrentElem.innerHTML = `${slider.activeIndex + 1} и ${
                slider.activeIndex + 2
            }`;
        }
    }

    hide() {
        this.elem.classList.add("hidden");
    }

    show() {
        this.elem.classList.remove("hidden");
    }

    setValueOfAllCounter(slider) {
        if (this.counterAllElem) {
            this.counterAllElem.innerHTML = slider.slides.length;
        }
    }
}
