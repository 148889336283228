import Swiper, { Navigation, Pagination, Lazy, Autoplay } from "swiper";

Swiper.use([Navigation, Pagination, Lazy, Autoplay]);

const sliderMain = document.querySelector(".js-slider-main-swiper");

if (sliderMain) {
    const slides = sliderMain.querySelectorAll(".swiper-slide");
    const opts = {
        speed: 300,
        watchOverflow: true,
        preloadImages: false,
        lazy: {
            elementClass: "lazyload",
            loadPrevNext: true,
            loadPrevNextAmount: 2,
            loadedClass: "swiper-lazyloaded",
            loadingClass: "swiper-lazyloading",
        },
        autoplay: {
            delay: 4000,
            disableOnInteraction: false,
        },
        pagination: {
            el: ".js-slider-main-pagination",
            clickable: true,
        },
    };
    if (slides.length > 1) {
        opts.loop = true;
    }

    new Swiper(sliderMain, opts);
}

function initPromoutionsSlider() {
    const promoutionSliderElem = document.querySelector(".js-promoutions-list");

    if (promoutionSliderElem) {
        new Swiper(promoutionSliderElem, {
            slidesPerView: "auto",
            resistanceRatio: 0,
            freeMode: true,
            preventInteractionOnTransition: true,
            watchOverflow: true,
            nested: true,
        });
    }
}

if (window.mobileMedia) {
    initPromoutionsSlider();
    window.addEventListener("resize", () => {
        initPromoutionsSlider();
    });
}
