import { CollabsibleContainer } from "@plugins";

const filter = document.querySelector(".js-filter");

if (filter) {
    const collabsibleContaieners = filter.querySelectorAll(".js-filter-collabsible-container");

    const toggler = filter.querySelector(".js-filter-mob-toggler");
    if (toggler) {
        const filterForm = document.querySelector(".js-filter-form");

        toggler.addEventListener("click", () => {
            filterForm.classList.toggle("active");
            toggler.classList.toggle("active");
        });
    }

    for (let collabsibleContaiener of collabsibleContaieners) {
        collabsibleContaiener = new CollabsibleContainer(collabsibleContaiener, {
            buttonClasses: "filter__more js-filter-more",
            collapsibleItemsClass: "js-filter-collabsible-item",
            limiter: 5,
        });
    }
}
