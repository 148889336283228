import { elementScrollIntoView } from "seamless-scroll-polyfill";

class GlossaryBlock {
    constructor(elem) {
        this.elem = elem;
        this.title = this.elem.querySelector(".js-glossary-block-title");
        this.links = this.elem.querySelectorAll(".js-glossary-block-link");
        this.items = this.elem.querySelectorAll(".js-glossary-block-item");

        this.initListener();

        this.pastItem = null;
    }

    checkContainis(array, target) {
        let result = false;
        array.forEach((item) => {
            if (item.contains(target)) {
                result = true;
            }
        });
        return result;
    }

    initListener() {
        this.elem.addEventListener("click", (e) => {
            e.preventDefault();

            if (e.target === this.title) {
                this.toggle(e);
            }

            if (this.checkContainis(this.links, e.target)) {
                this.showOnlyOne(e);
            }
        });
    }

    open() {
        if (!this.elem.classList.contains("active")) {
            this.elem.classList.add("active");
        }
    }

    close() {
        this.elem.classList.remove("active");

        for (const item of this.items) {
            item.classList.remove("active");
        }
    }

    toggle() {
        if (!this.elem.classList.contains("active")) {
            this.open();
            this.showAll();
        } else {
            this.close();
        }
    }

    showOnlyOne(e) {
        const linkIndex = Array.from(this.links).indexOf(
            e.target.closest(".js-glossary-block-link"),
        );
        const itemsArray = Array.from(this.items);
        const currentItem = itemsArray[linkIndex];

        this.open();

        function closeOthers() {
            for (const item of itemsArray) {
                if (item !== currentItem) {
                    item.classList.remove("active");
                }
            }
        }

        currentItem.classList.add("active");
        closeOthers();
    }

    showAll() {
        for (const item of this.items) {
            item.classList.add("active");
        }
    }
}

const glossaryBlocks = document.querySelectorAll(".js-glossary-block");

for (const item of glossaryBlocks) {
    new GlossaryBlock(item);
}

window.addEventListener("load", () => {
    const activeGlossaryItem = document.querySelector(".js-glossary-block-item.active");
    if (activeGlossaryItem) {
        elementScrollIntoView(activeGlossaryItem, { behavior: "smooth", block: "center" });
    }
});
