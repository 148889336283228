import Swiper, { Navigation, Pagination } from "swiper";

Swiper.use([Navigation, Pagination]);

const slider = document.querySelector(".js-product-info-cards-slider");

function initSlider(section) {
    const controls = document.querySelector(".js-product-info-cards-controls");

    let buttonPrev;
    let buttonNext;
    let pagination;

    if (controls) {
        buttonPrev = controls.querySelector(".swiper-button-prev");
        buttonNext = controls.querySelector(".swiper-button-next");
        pagination = controls.querySelector(".swiper-pagination");
    }

    const PAGINATION = {
        pagination: {
            el: pagination,
            dynamicBullets: true,
            dynamicMainBullets: 3,
            clickable: true,
        },
    };
    const NAVIGATION = {
        navigation: {
            prevEl: buttonPrev,
            nextEl: buttonNext,
        },
    };

    const optionsSlider = {
        slidesPerView: "auto",
        spaceBetween: 26,
        observer: true,
        observeParents: true,
        watchOverflow: true,
        breakpoints: {
            768: {
                slidesPerView: 2,
            },
        },
    };

    if (pagination) {
        Object.assign(optionsSlider, PAGINATION);
    }

    if (buttonPrev && buttonNext) {
        Object.assign(optionsSlider, NAVIGATION);
    }

    new Swiper(section, optionsSlider);
}

if (slider) {
    initSlider(slider);
}
