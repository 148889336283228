import Swiper, { Navigation, Pagination } from "swiper";

Swiper.use([Navigation, Pagination]);

function initSlider(slider, options, sliderControls) {
    const controls = document.querySelector(sliderControls);

    let buttonPrev;
    let buttonNext;
    let pagination;

    if (controls) {
        buttonPrev = controls.querySelector(".swiper-button-prev");
        buttonNext = controls.querySelector(".swiper-button-next");
        pagination = controls.querySelector(".swiper-pagination");
    }

    const PAGINATION = {
        pagination: {
            el: pagination,
            dynamicBullets: true,
            dynamicMainBullets: 3,
            clickable: true,
        },
    };
    const NAVIGATION = {
        navigation: {
            prevEl: buttonPrev,
            nextEl: buttonNext,
        },
    };

    if (pagination) {
        Object.assign(options, PAGINATION);
    }

    if (buttonPrev && buttonNext) {
        Object.assign(options, NAVIGATION);
    }

    // new Swiper(slider, options);
    new Swiper(slider, options);
}

// Настройки слайдера с карточками товаров
const sliderCard = document.querySelector(".js-collections-thumbs-slider");
const optionsSlider = {
    slidesPerView: "auto",
    spaceBetween: 8,
    watchOverflow: true,
    observer: true,
    breakpoints: {
        768: {
            slidesPerView: 5,
            spaceBetween: 28,
        },
    },
};
if (sliderCard) {
    initSlider(sliderCard, optionsSlider, ".js-collections-thumbs-slider-controls");
}

// Настройки слайдера с категориями
const sliderThumbs = document.querySelector(".js-collections-thumbs-categories");
const optionsSliderThumbs = {
    slidesPerView: "auto",
    // Задается стилями, иначе слайдер некорректно работает
    // spaceBetween: 24,
    watchOverflow: true,
    slideToClickedSlide: true,
    centeredSlides: true,
    centeredSlidesBounds: true,
    observer: true,
};
if (sliderThumbs) {
    initSlider(sliderThumbs, optionsSliderThumbs, ".js-collections-thumbs-categories-controls");
}

// Переключение активной категории
const categories = document.querySelectorAll(".collections-thumbs__category");
if (categories.length) {
    categories.forEach((category) => {
        category.addEventListener("click", () => {
            categories.forEach((item) => {
                item.classList.remove("active");
            });
            category.classList.add("active");
        });
    });
}
