export class CollabsibleContainer {
    _defaults = {
        buttonClasses: "js-more-button",
        collapsibleItemsClass: "js-collapsing-item",
        limiter: 5,
    };

    constructor(elem, options) {
        this.elem = elem;
        Object.assign(this, this._defaults, options);

        if (!this.elem) {
            console.error(new Error("Элемент-контейнер не передан"));
            return;
        }
        this.collapsibleItems = this.elem.querySelectorAll(`.${this.collapsibleItemsClass}`);

        (this.hiddensCounter = 0), (this.statusIndicator = 0);

        this.toggleProps(true);

        if(this.collapsibleItems.length > this.limiter) {
            this.initButton();
        }
    }

    initButton() {
        this.button = document.createElement("span");
        this.button.innerHTML = `Показать ещё ${this.hiddensCounter}`;

        this.buttonClasses.split(" ").forEach((className) => {
            this.button.classList.add(className);
        });

        this.button.addEventListener("click", () => {
            this.changeText();
            this.toggleProps();
        });

        this.elem.after(this.button);
    }

    getButton() {
        return this.button;
    }

    changeText() {
        if (this.statusIndicator === 0 || this.statusIndicator % 2 === 0) {
            this.button.innerHTML = "Скрыть";
        } else {
            this.button.innerHTML = `Показать ещё ${this.hiddensCounter}`;
        }
        this.statusIndicator++;
    }

    toggleProps(isFirstLaunch) {
        for (let i = 0; i < this.collapsibleItems.length; i++) {
            let collabsibleItem = this.collapsibleItems[i];

            if (i >= this.limiter) {
                collabsibleItem.classList.toggle("hidden");

                if (isFirstLaunch) this.hiddensCounter++;
            }
        }
    }
}
