import Swiper from "swiper";

const buttonPrev = document.querySelector(".js-product-page-thumb-prev");
const buttonNext = document.querySelector(".js-product-page-thumb-next");
const thumbs = document.querySelector(".js-product-page-slider-thumbs");
const pagination = document.querySelector(".js-product-page-card-pagination");

const pageSliderThumbs = new Swiper(thumbs, {
    spaceBetween: 6,
    slidesPerView: 6,
    watchSlidesVisibility: true,
    watchSlidesProgress: true,
    observer: true,
    watchOverflow: true,
    navigation: {
        nextEl: buttonNext,
        prevEl: buttonPrev,
    },
});

new Swiper(".js-product-page-slider", {
    spaceBetween: 10,
    watchOverflow: true,

    pagination: {
        el: pagination,
        clickable: true,
    },
    thumbs: {
        swiper: pageSliderThumbs,
        slideThumbActiveClass: "active",
    },
});
