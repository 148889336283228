{
    const productDescriptionTitle = document.querySelector("[data-copy-title]");
    const productDescriptionText = document.querySelector("[data-copy-text]");

    const cardInfoDescriptionContainer = document.querySelector("[data-paste-title]");
    const cardInfoDescriptionText = document.querySelector("[data-paste-text]");

    if (
        window.mobileMedia &&
        productDescriptionTitle &&
        productDescriptionText &&
        cardInfoDescriptionContainer
    ) {
        cardInfoDescriptionContainer.prepend(productDescriptionTitle.cloneNode(true));
        cardInfoDescriptionText.insertAdjacentHTML("beforeend", productDescriptionText.innerHTML);
    }
}
