import tippy from "tippy.js";

const tooltips = document.querySelectorAll(".js-tooltip");
if (tooltips && tooltips.length > 0) {
    for (const tooltip of tooltips) {
        tippy(tooltip, {
            arrow: true,
            placement: "top", // top, right, bottom, left
            trigger: "mouseenter click",
            interactive: true,

            allowHTML: true,
            hideOnClick: true,
            theme: "light",
            content: tooltip.querySelector(".js-tooltip-content"),
            appendTo: () => document.body,
        });
    }
}
