export class SetVhElement {
    constructor(target) {
        this.target = target;

        if (window.mobileMedia) {
            this.init();
            window.addEventListener("resize", () => {
                this.init();
            });
        }
    }

    init() {
        const vh = window.innerHeight * 0.01;
        this.target.style.setProperty("--vh", `${vh}px`);
    }
}
