export default class CompareTable {
    constructor(
        options = {
            table: null,
            filterButtons: null,
            toggleAllBlocksTrigger: null,
        },
    ) {
        this.table = options.table;
        this.filterButtons = options.filterButtons;
        this.toggleAllBlocksTrigger = options.toggleAllBlocksTrigger;

        this.blocks = this.table.querySelectorAll(".js-compare-table-block");
        this.titles = this.table.querySelectorAll(".js-compare-block-title");
        this.rows = this.table.querySelectorAll(".js-compare-row");

        this.hasHiddenRows = false;
        this.hiddenRows = [];
        this.hiddenBlocks = [];

        this.table.addEventListener("click", (e) => {
            if (this.isTitle(e)) {
                this.toggleOne(e);
            }
        });

        // Подключаем модули
        if (this.filterButtons) {
            for (const filterButton of this.filterButtons) {
                const filterType = filterButton.getAttribute("data-compare");
                filterButton.onchange = () => {
                    this.toggleRows(filterType);
                };

                if (filterButton.checked) {
                    window.addEventListener("load", () => {
                        this.toggleRows(filterType);
                    });
                }
            }
        }

        if (this.toggleAllBlocksTrigger) {
            this.toggleAllBlocksTrigger.addEventListener("click", () => {
                this.toggleAll();
            });
        }
    }

    isTitle(e) {
        for (const item of this.titles) {
            if (item === e.target) {
                return true;
            }
        }

        return false;
    }

    open(block) {
        block.classList.add("active");
    }

    close(block) {
        block.classList.remove("active");
    }

    toggleOne(e) {
        const block = e.target.closest(".js-compare-table-block");

        if (!block.classList.contains("active")) {
            this.open(block);
        } else {
            this.close(block);
        }
    }

    closeAll() {
        for (const block of this.blocks) {
            this.close(block);
        }
    }

    openAll() {
        for (const block of this.blocks) {
            this.open(block);
        }
    }

    toggleAll() {
        if (this.toggleAllBlocksTrigger.innerHTML.indexOf("Открыть") !== -1) {
            this.openAll();
            this.toggleAllBlocksTrigger.innerHTML = this.toggleAllBlocksTrigger.innerHTML.replace(
                "Открыть",
                "Закрыть",
            );
            this.toggleAllBlocksTrigger.classList.add("active");
        } else {
            this.closeAll();
            this.toggleAllBlocksTrigger.innerHTML = this.toggleAllBlocksTrigger.innerHTML.replace(
                "Закрыть",
                "Открыть",
            );
            this.toggleAllBlocksTrigger.classList.remove("active");
        }
    }

    toggleRows(filterParameter) {
        switch (filterParameter) {
            case "DIFF":
                for (const row of this.rows) {
                    const cells = row.querySelectorAll(".js-compare-cell");
                    let hasHidden = false;
                    const characteristics = [];

                    for (const cell of cells) {
                        characteristics.push(cell.innerHTML.toLowerCase().replace(/\s+/g, ""));
                    }

                    if (this.isSameCharacteristics(characteristics)) {
                        row.classList.add("hidden");
                        hasHidden = true;
                        this.hiddenRows.push(row);
                    }

                    this.hasHiddenRows = hasHidden;
                }
                for (const block of this.blocks) {
                    if (this.isEmptyBlock(block)) {
                        this.hideBlock(block);
                    }
                }
                break;

            case "ALL":
                if (this.hiddenRows.length !== 0) {
                    for (const row of this.hiddenRows) {
                        row.classList.remove("hidden");
                    }
                }
                if (this.hiddenBlocks.length !== 0) {
                    for (const block of this.hiddenBlocks) {
                        block.classList.remove("hidden");
                    }
                }
                break;
        }
    }

    isSameCharacteristics(characteristics) {
        return characteristics.every((characteristic) => characteristic === characteristics[0]);
    }

    isEmptyBlock(block) {
        const rows = block.querySelectorAll(".js-compare-row");
        return Array.from(rows).every((row) => row.classList.contains("hidden"));
    }

    hideBlock(block) {
        block.classList.add("hidden");
        this.hiddenBlocks.push(block);
    }
}
