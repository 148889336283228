import Swiper from "swiper";

let videosAboutProductSliderContainer = document.querySelector(".js-videos-about-product-slider");

if (videosAboutProductSliderContainer && window.mobileMedia) {
    videosAboutProductSliderContainer = new Swiper(videosAboutProductSliderContainer, {
        slidesPerView: 1,
        spaceBetween: 20,

        navigation: {
            nextEl: ".js-videos-about-product-controls .swiper-button-next",
            prevEl: ".js-videos-about-product-controls .swiper-button-prev",
        },
        pagination: {
            el: ".js-videos-about-product-controls .swiper-pagination",
            dynamicBullets: true,
            dynamicMainBullets: 3,
            clickable: true,
        },
    });
}
