const compareSlidersDefaults = {
    slidesPerView: 2,
    allowTouchMove: true,
    spaceBetween: 8,
    resistanceRatio: 0.7,
    watchOverflow: true,

    breakpoints: {
        768: {
            resistanceRatio: 0,
            slidesPerView: 3,
            allowTouchMove: false,
            spaceBetween: 28,
        },
    },
};

const compareItemsSliderOptions = Object.assign({}, compareSlidersDefaults, {
    pagination: {
        el: ".js-compare-pagination",
        clickable: true,
        dynamicBullets: true,
        dynamicMainBullets: 3,
    },
    navigation: {
        prevEl: ".js-compare-prev, .js-compare-fixed-prev",
        nextEl: ".js-compare-next, .js-compare-fixed-next",
    },
});

const rowSlidersOptions = Object.assign({}, compareSlidersDefaults, {
    observer: true,
    observeParents: true,
    slideClass: "js-compare-cell",

    preventClicks: false,
});

export { compareSlidersDefaults, rowSlidersOptions, compareItemsSliderOptions };
