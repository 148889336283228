import tippy from "tippy.js";

tippy(".js-big-label", {
    arrow: true,
    placement: "top", // top, right, bottom, left
    maxWidth: 300, // px or string
    interactive: false,
    hideOnClick: true,
    allowHTML: true,
    trigger: "mouseenter click",
    theme: "light",
    ignoreAttributes: true,
    content(reference) {
        const title = reference.getAttribute("data-announcing");
        reference.removeAttribute("data-announcing");
        return title;
    },
});
