import { isPhone, SpollerAnimation } from "@plugins";

const animation = new SpollerAnimation();

const expandedMenus = document.querySelectorAll(".js-expanded-menu");
for (const expandedMenu of expandedMenus) {
    createAnimatedMenu(expandedMenu);
}

function createAnimatedMenu(menuContainer) {
    const menuTitle = menuContainer.querySelector(".js-expanded-menu-title");
    const menuListsWrapper = menuContainer.querySelector(".js-expanded-menu-lists");

    if (!menuTitle || !menuListsWrapper) {
        const errorMessage = new Error("Обертка для списков или заголовок не найдены");
        console.error(errorMessage, menuTitle);
        return;
    }

    if (isPhone()) {
        menuTitle.addEventListener("click", () => {
            menuTitle.classList.toggle("active");
            animation.slideToggle(menuListsWrapper, 300, "flex");
        });

        window.addEventListener("load", () => {
            menuTitle.classList.toggle("active");
            animation.slideToggle(menuListsWrapper, 300, "flex");
        });
    }
}
