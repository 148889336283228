import tippy from "tippy.js";
import jquery from "jquery";

const $ = jquery;
/* TODO: слишком много тулипов получается. Неплохо было бы выполнять проверку на тип тултипа - создавать один каждого типа
    (с одинаковыем текстом) и перемещать по старнице, где нужно */

$(document).ajaxSuccess(() => {
    initGlossaryTooltips();
});

window.addEventListener("load", initGlossaryTooltips());

function initGlossaryTooltips() {
    const glossaryTooltips = document.querySelectorAll("[data-glossary-tooltip]");
    const readyToInitTooltips = [];

    if (glossaryTooltips && glossaryTooltips.length > 0) {
        for (const glossaryTooltip of glossaryTooltips) {
            if (glossaryTooltip.querySelector("[data-tooltip-content]")) {
                readyToInitTooltips.push(glossaryTooltip);
            }
        }

        tippy(readyToInitTooltips, {
            arrow: true,
            placement: "auto", // top, right, bottom, left
            trigger: "mouseenter click",
            interactive: true,

            allowHTML: true,
            hideOnClick: true,
            theme: "light",

            popperOptions: {
                modifiers: [
                    {
                        name: "flip",
                        options: {
                            allowedAutoPlacements: ["top", "bottom"],
                        },
                    },
                ],
            },
            onCreate(instance) {
                instance.setContent(instance.reference.querySelector("[data-tooltip-content]"));
            },
            appendTo: () => document.body,
        });
    }
}
