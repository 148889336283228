import { Spoller, elementInViewport } from "@plugins";
import { elementScrollIntoView } from "seamless-scroll-polyfill";

new Spoller(".js-text-inner-accordion", {
    duration: 250,
    selectorToggleBtn: ".js-text-inner-accordion-title",
    selectorContent: ".js-text-inner-accordion-body",
    makeToggleBtnActive: false,
    isAccordion: true,
    isOpenFirst: true,
    clickCallback: (toggleBtn) => {
        if (!elementInViewport(toggleBtn)) {
            elementScrollIntoView(toggleBtn, {
                behavior: "smooth",
                block: "center",
                inline: "center",
            });
        }
    },
});
