import { ToDoNoScroll, SetVhElement } from "@plugins";
import { DropdownMenu } from "./js/dropdown-menu-core";
import { SwitchableMenu } from "./js/switchable-menu";

// import "./js/move-icons";

const menuParent = document.querySelector(".js-header-hamburger-target");
const dropdownMenuElem = document.querySelector(".js-dropdown");

if (dropdownMenuElem) {
    const dropdownMenu = new DropdownMenu(dropdownMenuElem, {
        on: {
            open: () => {
                if (window.mobileMedia) {
                    new SetVhElement(dropdownMenu.activeContainer);
                }
            },
            toggle: () => {
                if (window.mobileMedia) {
                    ToDoNoScroll.toggleNoScroll(menuParent);
                    menuParent.scrollTop = 0;
                }
            },
        },
    });

    const switchablesMenus = document.querySelectorAll(".js-switchable");

    if (switchablesMenus && switchablesMenus.length > 0) {
        for (const menu of switchablesMenus) {
            new SwitchableMenu(menu);
        }
    }
}
