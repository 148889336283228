import { addToGlobalThisRet } from "@plugins";

class Countdown {
    constructor(element) {
        this.element = element;
        this.targetDate = "";
        this.currentDate = "";
        this.gap = null;
        this.timerId = "";
        this.timer = [
            {
                elem: this.element.querySelector(".countdown__days"),
                formula: () => Math.floor(this.gap / 1000 / 60 / 60 / 24),
            },
            {
                elem: this.element.querySelector(".countdown__hours"),
                formula: () => Math.floor(this.gap / 1000 / 60 / 60) % 24,
            },
            {
                elem: this.element.querySelector(".countdown__minutes"),
                formula: () => Math.floor(this.gap / 1000 / 60) % 60,
            },
            {
                elem: this.element.querySelector(".countdown__seconds"),
                formula: () => Math.floor(this.gap / 1000) % 60,
            },
        ];
        this._init();
    }

    _init() {
        this._getTargetDate();

        this._counts();
        this.element.classList.add("initialized");
        this.timerId = setInterval(() => this._counts(), 1000);
    }

    _getTargetDate() {
        const dataCountdownValue = this.element.dataset.countdown;
        this.targetDate = new Date(dataCountdownValue);
    }

    _counts() {
        this.currentDate = Date.now();
        this.gap = this.targetDate - this.currentDate;

        if (this.gap < 0) {
            this.element.querySelector(".countdown__time").innerText = "Акция завершена";
            this.element.classList.add("ended");
            clearInterval(this.timerId);
        } else {
            for (const item of this.timer) {
                item.elem.innerText = item.formula();
            }
        }
    }
}

function initCountdowns() {
    const promoTimerList = [...document.querySelectorAll("[data-countdown]")];

    if (promoTimerList.length) {
        for (const promoTimer of promoTimerList) {
            new Countdown(promoTimer);
        }
    }
}
initCountdowns();
addToGlobalThisRet(initCountdowns, "initCountdowns");
