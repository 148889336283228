import tippy from "tippy.js";
import jquery from "jquery";

const $ = jquery;

$(".js-payment-label").each((i, el) => {
    tippy(el, {
        arrow: true,
        placement: "top", // top, right, bottom, left
        maxWidth: 253, // px or string
        interactive: true,
        allowHTML: true,
        hideOnClick: true,
        theme: "light",
        appendTo: () => document.body,
        trigger: "mouseenter click",
        content: el.querySelector(".js-payment-type-tooltip"),
    });
});
