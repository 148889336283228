import tippy from "tippy.js";
import jquery from "jquery";

const $ = jquery;

$(document).ajaxSuccess(() => {
    initLabelTooltips();
});

window.addEventListener("load", initLabelTooltips());

function initLabelTooltips() {
    const labels = document.querySelectorAll(".js-small-label");
    const readyToInitLabels = [];

    if (labels && labels.length > 0) {
        for (const label of labels) {
            if (label.hasAttribute("data-announcing")) {
                readyToInitLabels.push(label);
            }
        }

        tippy(readyToInitLabels, {
            arrow: true,
            theme: "light",
            placement: "right",
            maxWidth: 300,
            ignoreAttributes: true,
            trigger: "mouseenter click",
            hideOnClick: true,
            allowHTML: true,
            onShow(instance) {
                if (window.mobileMedia) {
                    instance.reference._tippy.props.placement = "top";
                }
            },
            onCreate(instance) {
                instance.setContent(instance.reference.dataset.announcing);
                instance.reference.removeAttribute("data-announcing");
            },
        });
    }
}
