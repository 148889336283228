import Swiper, { Pagination } from "swiper";
import { isPhone } from "@plugins";

Swiper.use([Pagination]);

const articlesOther = document.querySelector(".js-articles-other");

function initSlider() {
    if (!articlesOther) return;

    const sliderContainer = articlesOther.querySelector(".js-articles-other-slider-container");

    const controls = articlesOther.querySelector(".js-articles-other-controls");
    if (!controls) return;

    const pagination = controls.querySelector(".js-swiper-pagination");
    if (sliderContainer) {
        new Swiper(sliderContainer, {
            slidesPerView: 1,
            observer: true,
            observeParents: true,
            spaceBetween: 8,
            pagination: {
                el: pagination,
            },
        });
    }
}

if (isPhone()) {
    initSlider();
}
