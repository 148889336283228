// TODO: В дальнейшем можно будет использовать display: contents в css, а не скрипт. Сейчас поддержка ограничена

const includedArticleContainers = document.querySelectorAll(".js-included-article-container");
const articleContainersWithImg = [];

if (includedArticleContainers) {
    for (const articleContainer of includedArticleContainers) {
        if (articleContainer.querySelector(".js-included-article-img")) {
            articleContainersWithImg.push(articleContainer);
        }
    }
}

function moveImages() {
    if (window.mobileMedia && articleContainersWithImg.length > 0) {
        for (const articleContainer of articleContainersWithImg) {
            const img = articleContainer.querySelector(".js-included-article-img");
            const text = articleContainer.querySelector(".js-included-article-txt");

            if (!text.contains(img)) {
                const mutationEvent = new Event("includedArticleMutated", {
                    bubbles: true,
                });
                text.dispatchEvent(mutationEvent);
                text.prepend(img);
            }
        }
    } else if (!window.mobileMedia && articleContainersWithImg.length > 0) {
        for (const articleContainer of articleContainersWithImg) {
            const img = articleContainer.querySelector(".js-included-article-img");

            articleContainer.prepend(img);
        }
    }
}

["load", "resize"].forEach((evt) =>
    window.addEventListener(evt, () => {
        moveImages();
    }),
);
