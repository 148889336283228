// --------- Минификация изображений - модули ---------------
// Core-файл импортируется в to-head.js
import "lazysizes/plugins/unveilhooks/ls.unveilhooks";
import "lazysizes/plugins/respimg/ls.respimg";
import "lazysizes/plugins/attrchange/ls.attrchange";
import "./global/lazysizes-custom";
// ----------------------------------------------------------
import jquery from "jquery";
window.$ = window.jQuery = jquery;
// -------- Глобальная проверка разрешения экрана (window.mobileMedia) ---------------
import "./plugins/modules/check-phone";
// -----------------------------------------------------------------------------------

// ------------ Swiper глобальная конфигурация модулей -------------
// Существует для обратной совместимости. Для объявления новых swiper используйте модульность по примеру .benefits
import SwiperCore, {
    Navigation,
    Pagination,
    Thumbs,
    Autoplay,
    Controller,
    EffectFade,
    Lazy,
} from "swiper/core";
SwiperCore.use([Navigation, Pagination, Thumbs, Autoplay, Controller, EffectFade, Lazy]);
// -------------------------------------------------------------------------

// -------- Бандл глобальных плагинов (не требующих импорта) ---------------
import "./global/global-bundler";
// -------------------------------------------------------------------------

const cache = {};
function importAll(r) {
    r.keys().forEach((key) => (cache[key] = r(key)));
}

// --------- Импортируем скрипты компонентов --------
importAll(require.context("../components/", true, /\.js$/));
// --------------------------------------------------
