import { moveBetweenAnchors } from "@plugins";

const tags = document.querySelector(".js-catalog-tags");
const anchorEndpoint = document.getElementById("js-catalog-tags-anchor-endpoint");
const anchorInitial = document.getElementById("js-catalog-tags-anchor-initial");

if (tags && anchorEndpoint && anchorInitial) {
    moveBetweenAnchors(anchorInitial, anchorEndpoint, tags);
    window.addEventListener("resize", () => {
        moveBetweenAnchors(anchorInitial, anchorEndpoint, tags);
    });
}
