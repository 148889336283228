import { addToGlobalThisRet } from "./globalThis.ret";

function destroySlider(slider) {
    if (!slider) return;

    // С защитой от ошибок
    setTimeout(() => {
        slider.destroy();
    }, 100);
}

function updateHoverSliders() {
    if (window.hoverSliders && window.hoverSliders.length > 0) {
        for (const slider of window.hoverSliders) {
            slider.update();
        }
    }
}

addToGlobalThisRet(updateHoverSliders, "updateHoverSliders");

export { destroySlider, updateHoverSliders };
