export class ToDoNoScroll {
    static addNoScroll(target) {
        target.classList.add("no-scroll");
    }

    static removeNoScroll(target) {
        target.classList.remove("no-scroll");
    }

    static toggleNoScroll(target) {
        target.classList.toggle("no-scroll");
    }
}
