import Swiper from "swiper";

const sliderContainer = document.querySelector(".js-benefits-hover");
const controls = document.querySelector(".js-benefits-hover-controls");

if (sliderContainer && controls) {
    const pagination = controls.querySelector(".swiper-pagination");
    const buttonPrev = controls.querySelector(".swiper-button-prev");
    const buttonNext = controls.querySelector(".swiper-button-next");
    const buttons = [];
    buttons.push(buttonPrev, buttonNext);

    const benefitsSlider = new Swiper(sliderContainer, {
        spaceBetween: 10,
        navigation: {
            nextEl: buttonNext,
            prevEl: buttonPrev,
        },
        pagination: {
            el: pagination,
            dynamicBullets: true,
            dynamicMainBullets: 3,
            clickable: true,
        },
        breakpoints: {
            768: {
                allowTouchMove: false,
            },
        },
    });

    const points = document.querySelectorAll(".js-hover-point");

    if (points && buttons && !window.mobileMedia) {
        // Init first control point
        points[0].classList.add("active");

        // Create indexes
        for (let i = 0; i < points.length; i++) {
            points[i].index = i;
        }

        // Add listeners
        for (const point of points) {
            ["click", "pointerenter"].forEach((listener) => {
                point.addEventListener(listener, () => {
                    cleanActiveClases(points);
                    point.classList.add("active");
                    benefitsSlider.slideTo(point.index);
                });
            });
        }

        benefitsSlider.on("activeIndexChange", () => {
            for (const point of points) {
                if (point.index === benefitsSlider.activeIndex) {
                    const currentSlideIndex = benefitsSlider.activeIndex;
                    cleanActiveClases(points);
                    points[currentSlideIndex].classList.add("active");
                }
            }
        });
    }
}

function cleanActiveClases(points) {
    for (const point of points) {
        point.classList.remove("active");
    }
}
