class CharacteristicsOpener {
    constructor(el) {
        this.el = el;

        this.el.addEventListener("click", () => {
            this.toggleClass();
        });
    }

    open() {
        if (!this.el.classList.contains("active")) {
            this.el.classList.add("active");
        }
    }

    close() {
        this.el.classList.remove("active");
    }

    toggleClass() {
        if (this.el.classList.contains("active")) {
            this.close();
        } else {
            this.open();
        }
    }
}

const characteristicsBlocksTriggers = document.querySelectorAll(".js-characteristics-title");

if (characteristicsBlocksTriggers && characteristicsBlocksTriggers.length > 0) {
    for (let trigger of characteristicsBlocksTriggers) {
        trigger = new CharacteristicsOpener(trigger);
    }
}
