const checkSupportWebp = function (fn) {
    const WebP = new Image();

    WebP.onload = WebP.onerror = function () {
        const isSupported = WebP.height === 2;

        fn(isSupported);
    };

    WebP.src =
        "data:image/webp;base64,UklGRjoAAABXRUJQVlA4IC4AAACyAgCdASoCAAIALmk0mk0iIiIiIgBoSygABc6WWgAA/veff/0PP8bA//LwYAAA";
};

checkSupportWebp((isSupportedWebp) => {
    if (isSupportedWebp) {
        document.addEventListener("lazybeforeunveil", (e) => {
            const bg = e.target.getAttribute("data-bg-custom");
            const bgWebp = e.target.getAttribute("data-webp");

            if (bgWebp) {
                e.target.style.backgroundImage = `url(${bgWebp})`;
            } else if (bg) {
                // если есть подержка webp но нет атрибута webp
                e.target.style.backgroundImage = `url(${bg})`;
            }
        });
    } else {
        document.addEventListener(
            "lazybeforeunveil",
            (e) => {
                const bg = e.target.getAttribute("data-bg-custom");

                if (bg) {
                    e.target.style.backgroundImage = `url(${bg})`;
                }
            },
            false,
        );
    }

    let timerId;
    timerId = setTimeout(() => {
        if (window.lazySizes) {
            clearTimeout(timerId);
            window.lazySizes.init(); // include init lazysizes
        }
    });
});
