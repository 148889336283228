import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from "body-scroll-lock";

let lockedElems = 0;

function lockBody(target, options) {
    const opts = Object.assign(
        {
            allowTouchMove: (el) => {
                return el !== document.body ? true : false;
            },
        },
        options,
    );

    disableBodyScroll(target, opts);
    lockedElems++;
}

function unlockBody() {
    if (lockedElems <= 1) {
        clearAllBodyScrollLocks();
    }

    lockedElems--;
}

export { lockBody, unlockBody };
