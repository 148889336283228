import Swiper from "swiper";

new Swiper(".js-additional-products-slider", {
    spaceBetween: 8,
    slidesPerView: 1,

    navigation: {
        nextEl: ".js-additional-products-controls .swiper-button-next",
        prevEl: ".js-additional-products-controls .swiper-button-prev",
    },
    pagination: {
        el: ".js-additional-products-controls .swiper-pagination",
        dynamicBullets: true,
        dynamicMainBullets: 3,
        clickable: true,
    },

    observer: true,
    watchOverflow: true,
    observeParents: true,
    preventClicks: false,
    breakpoints: {
        406: {
            slidesPerView: 1.4,
            spaceBetween: 20,
        },
        576: {
            slidesPerView: 2,
            spaceBetween: 20,
        },
        768: {
            shortSwipes: false,
            slidesPerView: 3,
            spaceBetween: 28,
        },
        992: {
            shortSwipes: false,
            slidesPerView: 4,
            spaceBetween: 28,
        },
    },
});
