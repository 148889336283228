/* eslint-disable */
// TODO: Исправить ошибки линтера

import "./popup";
import { addToGlobalThisRet } from "@plugins";
import $ from "jquery";
import Inputmask from "inputmask";

window.globalPopup = new Popup();

function initPhoneMask() {
    Inputmask.extendAliases({
        customAlias: {
            mask: "+7 (999) 999-99-99",
        },
    });

    Inputmask("customAlias", {
        onBeforeMask: (value) => {
            return value.startsWith("7") ? value.slice(1) : value;
        },
    }).mask("[type=tel]");
}

function popupNormalizeScroll() {
    // если попап большой и имеет скролл и инпут поля то при фокусе по инпут полю
    // в андроиде появляется виртуальная клавитура и если инпут поле скроется под виртуальной клавиатурой
    // то браузер автоматом прокручивает попап в самый низ и инпут поле получивший фокус уходит за пределы видимости
    // Для фикса такого поведения используется этот скрипт
    // if (window.matchMedia("(max-width: 767px)").matches) {
    //     const isAbusePopup = $("#js-abuse-popup").length;
    //     if (isAbusePopup) {
    //         const scrollPos = $(window).scrollTop();
    //         $(window).scrollTop(0);
    //         $(".popup_active .popup__overlay, .popup_active .popup__close").one("click", () => {
    //             $(window).scrollTop(scrollPos);
    //         });
    //     }
    // }
}

$(document).on("click", "[data-ajax]", function (e) {
    e.preventDefault();

    $.get(this.getAttribute("data-url"), (response) => {
        window.globalPopup.html(response).show(popupNormalizeScroll);
    });

    window.globalPopup.options({
        closeButtons: ".js-close-popup, .js-close",
    });

    // upon clicking of the button do an ajax post
    $(document).ajaxSuccess(() => {
        initPhoneMask();
    });
});

if (document.querySelector("[type=tel]")) {
    setTimeout(() => {
        initPhoneMask();
    }, 300);
}

addToGlobalThisRet(initPhoneMask, "initPhoneMask");
addToGlobalThisRet(popupNormalizeScroll, "popupNormalizeScroll");
