import Swiper from "swiper";

function initSlider() {
    new Swiper(".js-video-reviews-slider", {
        slidesPerView: 1,
        spaceBetween: 17,
        pagination: {
            el: ".js-video-reviews-controls > .swiper-pagination",
            dynamicBullets: true,
            dynamicMainBullets: 3,
            clickable: true,
        },
        breakpoints: {
            567: {
                slidesPerView: 2,
                spaceBetween: 20,
            },
            768: {
                slidesPerView: 3,
                spaceBetween: 28,
            },
        },
    });
}

window.addEventListener("load", () => {
    if (window.mobileMedia) {
        initSlider();
    }
});
