const filterColors = document.querySelector(".js-filter-colors");

const toggleFilterBlocksView = () => {
    filterColors.classList.toggle("blocks-swapped");
};

const toggleFilterColors = (changer) => {
    const newText = changer.innerHTML === "Показать ещё" ? "Свернуть" : "Показать ещё";
    raplaceChangerText(changer, newText);
    toggleFilterBlocksView();
};

const raplaceChangerText = (changer, newText) => {
    if (changer.innerHTML !== newText) {
        changer.innerHTML = newText;
    }
};

if (filterColors) {
    const changer = filterColors.querySelector(".js-filter-colors-changer");

    if (changer) {
        changer.addEventListener("click", () => {
            toggleFilterColors(changer);
        });
    }
}
