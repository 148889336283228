import flatpickr from "flatpickr";
import { Russian } from "flatpickr/dist/l10n/ru";
import { addToGlobalThisRet } from "@plugins";

function initDatepickers(options) {
    const calendars = document.querySelectorAll(".js-date-input");
    if (!calendars) return;

    const today = new Date();
    const tomorrow = new Date(today.getTime() + 24 * 60 * 60 * 1000);
    const afterThreeMonth = new Date(tomorrow.getTime() + 8.147e9);

    const defaults = {
        minDate: tomorrow,
        maxDate: afterThreeMonth,
        dateFormat: "d.m.Y",
        locale: Russian, // locale for this instance only
        defaultDate: tomorrow,
        onReady: (currentDates, curretnDateText, instance) => {
            instance.setDate(correctedDate(currentDates[0]));
        },
        onChange: (currentDates, curretnDateText, instance) => {
            instance.setDate(correctedDate(currentDates[0]));
        },
    };
    const dateOptions = defaults;

    if (options) {
        Object.assign(dateOptions, options);
    }

    for (let calendar of calendars) {
        calendar = flatpickr(calendar, dateOptions);
    }
}

function correctedDate(date) {
    const today = new Date();
    const tomorrow = new Date(today.getTime() + 24 * 60 * 60 * 1000);
    const afterThreeMonth = new Date(tomorrow.getTime() + 8.147e9);

    if (!date || date < tomorrow || date > afterThreeMonth) {
        return tomorrow;
    }
    return date;
}

// Записываем в глобальный подобъект для бэка
addToGlobalThisRet(initDatepickers, "initDatepickers");
globalThis.ret.initDatepickers();
