import { isPhone, SpollerAnimation } from "@plugins";

const animation = new SpollerAnimation();

const footerMenus = document.querySelectorAll(".js-footer-menu");
for (const footerMenu of footerMenus) {
    createAnimatedMenu(footerMenu);
}

function createAnimatedMenu(menuContainer) {
    const menuTitle = menuContainer.querySelector(".js-footer-menu-title");
    const menuListsWrapper = menuContainer.querySelector(".js-footer-menu-lists");

    if (!menuTitle || !menuListsWrapper) {
        const errorMessage = new Error("Обертка для списков или заголовок не найдены");
        console.error(errorMessage, menuTitle);
        return;
    }

    menuTitle.addEventListener("click", () => {
        if (isPhone()) {
            menuTitle.classList.toggle("active");
            animation.slideToggle(menuListsWrapper, 300, "flex");
        }
    });
}
