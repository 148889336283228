import { windowScroll } from "seamless-scroll-polyfill";
import { isMobile } from "@plugins";

class Upbutton {
    constructor(selector) {
        this.elem = document.querySelector(selector);
        if (!this.elem) return;

        this.scrollBreakpoint = 500;
        this.init();
    }

    init() {
        this._addEventClick(this.elem);
        this._hideShowButton(this.elem);
    }

    _hideShowButton(elem) {
        window.addEventListener("scroll", () => {
            if (window.pageYOffset > this.scrollBreakpoint) {
                elem.classList.add("up-button--shown");
            } else {
                elem.classList.remove("up-button--shown");
            }
        });
    }

    _addEventClick(elem) {
        elem.addEventListener("click", () => {
            windowScroll({ behavior: "smooth", top: 0, left: 0 });
        });
    }
}

// В яндекс браузере на мобилке есть своя кнопка наверх, поэтому в этом случае мы не включаем кастомную кнопку
if (!(isMobile.any() && navigator.userAgent.includes("YaBrowser"))) {
    new Upbutton(".up-button");
}
