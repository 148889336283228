import { Ratings } from "@plugins";
import jquery from "jquery";

const $ = jquery;

const allFormsOpeners = document.querySelectorAll(".js-viewForm");

if (allFormsOpeners && allFormsOpeners.length > 0) {
    for (const opener of allFormsOpeners) {
        opener.addEventListener("click", () => {
            initRating();
        });
    }
}

function initRating() {
    $(document).ajaxSuccess(() => {
        const ratingElem = document.querySelector(".js-give-rating");

        if (ratingElem) {
            const ratingInput = ratingElem.querySelector(".js-review-rating");
            if (!ratingInput) {
                console.error(new Error("Input рейтинга не найден"));
                return;
            }

            new Ratings({
                element: ratingElem, // передаем элемент
                countRate: 5, // кол-во оценок
                clickFn(index) {
                    ratingInput.value = index;
                },
            });
        }
    });
}
initRating();
